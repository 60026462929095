import React, { FC, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled, { useTheme } from 'styled-components'
import { connectorsByName } from 'connectors'
import { Avatar } from "designStandard/components/atoms/Avatar"
import {
  ExternalLinkIcon,
  LogoutIcon,
} from 'designStandard/components/atoms/Svg'
import { Text } from 'designStandard/components/atoms/Text'
import { Button as BaseButton } from "designStandard/components/molecules/Button"
import { useToast } from 'state/hooks'
import { setupNetwork } from 'utils/wallet'
import { connectorLocalStorageKey } from '../ConnectWallet/config'

const Flex = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobile }) => isMobile? '8px 0' : '16px'};
`

const FlexWith = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`

// const WalletInfo = styled(Flex)`
//   padding: 8px;
//   background: ${({ theme }) => theme.colors.flatOverlay};
//   border-radius: 16px;
// `

const AccountAvatar = styled(Avatar)`
  margin-right: 12px;
`

const NetworkWrapper = styled(Flex)<{ isMobile?: boolean }>`
  padding: 8px 10px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  border-radius: 16px;
  margin-left: 16px;
  opacity: .65;
`

const SwitchNetworkBtn = styled(BaseButton)`
  padding: 18px 16px;
  border-radius: 16px;
  box-shadow: none;
  background: transparent;
  border: none;

  &:hover:not(:active):not(:disabled) {
    border: none;
    background: transparent;
    box-shadow: none;
  }

  &:active {
    background: transparent;
    box-shadow: none;
    border: none;
  }

  .logout-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.isDark ? '#463030' : '#FFDEDE'};
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .external-link-text {
    margin-left: 0;
  }

  .network-switch-outlined {
    border: 2px solid ${({ theme }) => theme.colors.flatGlobal};
    border-radius: 16px;
    padding: 1rem;
  }
`

// const LogoutBtn = styled(BaseButton)<{ isMobile?: boolean }>`
//   justify-content: center;
//   width: ${({ isMobile }) => isMobile? '100%' : 'calc(100% - 32px)'};
//   margin: ${({ isMobile }) => isMobile? '32px 0 16px' : '32px 16px 16px'};
//   padding: 18px;
//   // background: ${({ theme }) => theme.colors.flatOverlay};
//   border-radius: 16px;
//   border: none;

//   &:hover:not(:active):not(:disabled) {
//     border: none;
//   }

//   &:active {
//     background: ${({ theme }) => theme.colors.flatOverlay};
//     border: none;
//   }
// `


const NetworkStatus = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background: ${({ theme }) => theme.colors.primaryGreen};
  border-radius: 50%;
`

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement("textarea")
  textArea.value = text

  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  document.body.removeChild(textArea);
}

const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(() => {
    console.log('Async: Copying to clipboard was successful!')
  }, (err) => {
    console.error('Async: Could not copy text: ', err)
  })
}

interface LogoutCardProps {
  account: string
  chainId: number
  img: string
  // eslint-disable-next-line react/no-unused-prop-types
  network?: string
  logout: () => void
  isMobile?: boolean
  onClose?: () => void
}

const LogoutCard: FC<LogoutCardProps> = ({
  account,
  chainId,
  img,
  network, 
  logout,
  isMobile,
  onClose
}) => {
  // const history = useHistory();
  const scanLinks = {
    1: `https://etherscan.io/address/`,
		4: `https://rinkeby.etherscan.io/address/`,
    42: `https://kovan.etherscan.io/address/`,
    56: `https://bscscan.com/address/`,
    97: `https://testnet.bscscan.com/address/`,
  }

  const { toastSuccess } = useToast()
  const { activate } = useWeb3React()

  const base = scanLinks[chainId] ? scanLinks[chainId] : `https://etherscan.io/address/`
  const scanLink = `${base}${account}`
  const bscType = chainId === 97 || chainId === 56

  const handleCopyAction = useCallback(() => {
    copyTextToClipboard(account)
    toastSuccess('Address copied successfully!')
  }, [account, toastSuccess])

  const LogoutHandler = useCallback(() => {
    logout()
    window.localStorage.removeItem(connectorLocalStorageKey)
    onClose()
    // window.location.reload()
  }, [logout, onClose])

  const toggleNetworkHandler = useCallback(async (currentNetwork: string) => {
    const connector = connectorsByName.injected
    const hasSetup = await setupNetwork(currentNetwork)
    if (hasSetup) {
      activate(connector)
      window.localStorage.setItem(connectorLocalStorageKey, 'injected')
      onClose()
    }
  }, [activate, onClose])

  // const handleNavProfile = () => {
  //   window.location.href = "/nft/profile"
  // }
  const theme = useTheme()

  return (
    <>
      <Flex isMobile={isMobile}>
        
        <SwitchNetworkBtn variant='secondary' onClick={handleCopyAction}>
          <FlexWith>
          <AccountAvatar src={img} size={40} />
            <Text color="textSecondary" font="captionRegularLarge">
              {account.substring(0, 4)}...{account.substring(account.length - 4)}
            </Text>
          </FlexWith>
          <NetworkWrapper isMobile={isMobile} onClick={handleCopyAction}>
            <NetworkStatus />
            <Text font="walletDropNav" color="textSecondary" fontSize={11.5}>{ network }</Text>
           {/* <CopyIcon width={18} /> */}
          </NetworkWrapper>
        </SwitchNetworkBtn>
        <SwitchNetworkBtn variant='secondary' onClick={LogoutHandler}>
          <div className='logout-icon'>
            <LogoutIcon width={20}/>
          </div>
          {/* <Text font="bodySemiBoldSmall" mr="8px">
            View Profile
          </Text>
          <ProfileIcon width={16} height={16}/> */}
        </SwitchNetworkBtn>
      </Flex>
      <Flex isMobile={isMobile}>
        <SwitchNetworkBtn
          variant='secondary'
          disabled={!account}
          onClick={() => window.open(scanLink)}
        >
            <Text className="external-link-text" color={theme.colors.primaryBlue} font="bodySemiBoldSmall" mx="8px">
              {bscType ? 'View on BscScan' : 'View on Etherscan'}
            </Text>
          <ExternalLinkIcon width={16}/>
        </SwitchNetworkBtn>
        {![56, 97].includes(chainId) ? (
          <SwitchNetworkBtn  variant='secondary' onClick={() => toggleNetworkHandler('bsc')}>
            <div className={theme.isDark ? null : 'network-switch-outlined'} >
              <Text color="textSecondary" font="bodySemiBoldSmall">
                Switch To BSC
              </Text>
            </div>
        </SwitchNetworkBtn>
        ) : (
          <SwitchNetworkBtn variant='secondary' onClick={() => toggleNetworkHandler(chainId === 97 ? 'bsc' : 'chapel')}>
            <div  className={theme.isDark ? null : 'network-switch-outline'}>
              <Text  color="textSecondary" font="bodySemiBoldSmall">
                {chainId === 97 ? 'Switch To BSC' : 'Switch To BSC-Testnet'}
              </Text>
            </div>
          </SwitchNetworkBtn>
        )}
        {/* {![56, 97].includes(chainId) || chainId === 97 ? (
          <SwitchNetworkBtn variant='secondary' onClick={() => toggleNetworkHandler('bsc')}>
            <Text font="bodySemiBoldSmall">
              Switch To BSC
            </Text>
          </SwitchNetworkBtn>
        ) : null}
        {!chainId || chainId === 56 ? (
          <SwitchNetworkBtn variant='secondary' onClick={() => toggleNetworkHandler('chapel')}>
            <Text font="bodySemiBoldSmall">
              Switch To BSC-Test
            </Text>
          </SwitchNetworkBtn>
        ) : null} */}
      </Flex>
      {/* {!isMobile  && */}
      {/* <LogoutBtn variant='secondary' isMobile={isMobile} onClick={LogoutHandler}>
        <LogoutIcon />
        <Text font="bodySemiBoldSmall" ml="8px">
          Logout
        </Text>
      </LogoutBtn> */}
    </>
  )
}

export default LogoutCard