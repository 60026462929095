import { createReducer } from '@reduxjs/toolkit'
import { getPriceFeed } from './actions'

export interface ZapPriceFeed {
   PriceFeedList: { [key: string]: any}
}

const initialState: ZapPriceFeed = {
  PriceFeedList: {},
}

export default createReducer<ZapPriceFeed>(initialState, (builder) =>
  builder
    .addCase(getPriceFeed, (state, { payload: { priceFeed } }) => {
      const toSet: { [key: string]: any } = {}
      priceFeed.forEach((e) => {
        toSet[e.id] = e
      })
      return {
        ...state,
        PriceFeedList: toSet,
      }
    })
)
