
import { GraphQLClient } from "graphql-request"; // GraphQL request client

// Create client
export const mainTokenClient = new GraphQLClient(
  // Zora mainnet subgraph
  // "https://api.thegraph.com/subgraphs/name/ourzora/zora-v1"
  "https://api.thegraph.com/subgraphs/name/acemasterjb/providers"

);

export const kovanTokenClient = new GraphQLClient(
  "https://api.thegraph.com/subgraphs/name/acemasterjb/providers-kovan"
)

export const mainBlockClient = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/acemasterjb/zap-block-explorer'
)

export const kovanBlockClient = new GraphQLClient(
  "https://api.thegraph.com/subgraphs/name/acemasterjb/zap-block-explorer-kovan"
)

export const rinkebyNftClient = new GraphQLClient(
  'https://api.thegraph.com/subgraphs/name/pynchmeister/nft-marketplace'
)

export const chapelTokenClient = new GraphQLClient(
  "https://api.thegraph.com/subgraphs/name/bmalkmus/providers-bsc94"
)

export const chapelExplorerClient = new GraphQLClient(
  "https://api.thegraph.com/subgraphs/name/bmalkmus/zap-block-explorer-chapel"
)
