import React, { FC, useCallback } from "react"
import styled from "styled-components"
import { useMatchBreakpoints } from "designStandard/hooks"
import { Avatar } from "designStandard/components/atoms/Avatar"
import { Text } from "designStandard/components/atoms/Text"
import { Button } from "designStandard/components/molecules/Button"
import useModal from "designStandard/components/organisms/GenericModal/useModal"
import useConnectWallet from "pages/ConnectWallet/useConnectWallet"
import LogoutWallet from "pages/LogoutWallet"

const Flex = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`

const WalletWrapper = styled(Flex) <{ isMobile: boolean }>`
  justify-content: ${({ isMobile }) => !isMobile ? 'space-between' : 'center'};
  margin-top: 0;
  padding: 9px 0;
`

const NetworkWrapper = styled(Flex)`
  padding: 8px;
`

const NetworkStatus = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background: ${({ theme }) => theme.colors.primaryGreen};
  border-radius: 50%;
`

const AccountAvatar = styled(Avatar)`
  margin-right: 12px;
`

const ConnectWrapper = styled.div<{ isMobile: boolean }>`
  height: ${({ isMobile }) => !isMobile ? 'auto' : '152px'};
  display: grid;
  justify-content: center;
  align-items: center;
`

const ConnectButton = styled(Button)`
  width: 100%;
  padding: 16px;
  // background: ${({ theme }) => theme.colors.btnFlatBg};
  border-radius: 16px;
  box-shadow: none;

  &:hover:not(:active):not(:disabled) {
    // border: none;
    // background: ${({ theme }) => theme.colors.btnFlatBg};
    box-shadow: none;
  }

  &:active {
    background: ${({ theme }) => theme.colors.gradientBlue};
    border: none;
  }
`

const LogoutBtn = styled(Button)`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 16px;
  border: none;
  // box-shadow: none;
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  height: 48px;

  &:hover:not(:active):not(:disabled) {
    background: ${({ theme }) => theme.colors.flatOverlay};
    border: none;
    box-shadow: none;
  }

  &:active {
    background: ${({ theme }) => theme.colors.flatOverlay};
    border: none;
    box-shadow: none;
  }
`

const Spinner = styled.div`
  border: 1.5px solid transparent;
  border-top: 1.5px solid #FFFFFF;
  border-left: 1.5px solid #FFFFFF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 8px;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

interface ConnectorProps {
  account: string
  chainId: number
  img: string
  isMobile?: boolean
  isAuthenticating?: boolean
  network?: string
  logout: () => void
  closeDropdown?: () => void
}

const Connector: FC<ConnectorProps> = ({
  account,
  chainId,
  img,
  isMobile,
  isAuthenticating,
  network,
  logout,
  closeDropdown
}) => {
  const { onShowHandler } = useConnectWallet()

  const [onLogoutHandler] = useModal(
    <LogoutWallet
      isOpened
      account={account}
      chainId={chainId}
      img={img}
      network={network}
      logout={logout}
    />
  )

  const connectHandler = useCallback(() => {
    if (isMobile) closeDropdown()
    onShowHandler()
  }, [closeDropdown, isMobile, onShowHandler])

  const logoutHandler = useCallback(() => {
    onLogoutHandler()
  }, [onLogoutHandler])

  const {isMd} = useMatchBreakpoints()

  // const Spinner = () => <StyledDiv className="loader"></StyledDiv>;

  const screenWidth = window.innerWidth
  const isSmallWindow = screenWidth < 984 && !isMobile

  return (
    <WalletWrapper isMobile={isMobile}>
      {account ? (
        <LogoutBtn variant='secondary' onClick={logoutHandler}>
          <AccountAvatar src={img} size={isMd ? 24 : 32} />
          <Text color="textSecondary" font="captionRegularLarge" mr={isMd ? '4px' : '8px'}>
            {account.substring(0, 4)}...{account.substring(account.length - 4)}
          </Text>
          <NetworkWrapper>
            <NetworkStatus />
           {!isMd &&  <Text font="walletDropNav" color="textSecondary">{ network }</Text>}
          </NetworkWrapper>
        </LogoutBtn>
      ) : (
        <ConnectWrapper isMobile={isMobile}>
          <ConnectButton variant='primary' onClick={connectHandler} scale='sm' isTablet={isSmallWindow}>
            <Text font="connectPrimary" color="#FFFFFF">
              {isAuthenticating ? `Connecting` : `Connect Wallet`}
            </Text>
            {isAuthenticating ? <Spinner /> : null}
          </ConnectButton>
        </ConnectWrapper>
      )}
    </WalletWrapper>
  )
}

export default Connector