import React from "react"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 42 66" fill="none" {...props}>
      <path d="M40.9128 26.0653C40.9134 26.4186 40.8391 26.768 40.6949 27.0906L40.3884 27.6025L18.7484 63.7736C18.4653 64.2468 18.0348 64.614 17.523 64.8191C17.0112 65.0242 16.4462 65.0558 15.9146 64.9091C15.3831 64.7624 14.9144 64.4454 14.5802 64.0067C14.2461 63.5681 14.065 63.032 14.0647 62.4805C14.0646 62.3523 14.0743 62.2244 14.0939 62.0977L14.0647 62.0866L16.8395 38.231H3.5208C3.06344 38.2308 2.61474 38.1063 2.22273 37.8707C1.83073 37.6351 1.51017 37.2973 1.29539 36.8936C1.0806 36.4898 0.979668 36.0352 1.0034 35.5784C1.02713 35.1217 1.17462 34.68 1.43009 34.3006L21.2914 2.33049L21.4551 2.06826C21.4579 2.06101 21.4622 2.0544 21.4676 2.04883C21.7789 1.61594 22.2194 1.29293 22.7259 1.12627C23.2323 0.959607 23.7786 0.957887 24.2861 1.12135C24.7936 1.28481 25.2361 1.60505 25.5501 2.03596C25.8641 2.46688 26.0333 2.98627 26.0334 3.51944L26.0209 3.77748L26.0195 3.78443L25.0247 23.5445H38.3933C38.7243 23.5445 39.052 23.6097 39.3577 23.7364C39.6635 23.8632 39.9413 24.0489 40.1753 24.2829C40.4092 24.517 40.5948 24.7949 40.7213 25.1008C40.8479 25.4066 40.9129 25.7343 40.9128 26.0653Z" fill="url(#paint0_linear_3060_3471)" stroke="url(#paint1_linear_3060_3471)" strokeWidth="0.75" strokeMiterlimit="10"/>
      <defs>
        <linearGradient id="paint0_linear_3060_3471" x1="0.999996" y1="33" x2="40.9128" y2="33" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4479BD"/>
            <stop offset="1" stopColor="#63BDEB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3060_3471" x1="0.999999" y1="2.08738" x2="51.5327" y2="12.0465" gradientUnits="userSpaceOnUse">
            <stop stopColor="#ECEFF7"/>
            <stop offset="1" stopColor="#EBEBEC"/>
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon