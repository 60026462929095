/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import { Link }  from "designStandard/components/atoms/Link"
// import { Text } from "designStandard/components/atoms"
import useConnectWallet from "pages/ConnectWallet/useConnectWallet"

const NavbarWrapper = styled.div<{ isMobile }>`
  display: grid; 
  grid-template-columns: ${({ isMobile }) => !isMobile ? 'auto auto auto auto' : null};
  grid-template-rows: ${({ isMobile }) => isMobile ? '40px 40px 40px 40px' : null};
  align-items: center;
  grid-gap: 16px; // ${({isMobile}) => isMobile ? '16px' : '24px'};
  white-space: pre;

  @media(max-width: 374px) {
    grid-gap: 4px;
  }
  @media(max-width: 915px) {
    grid-gap: 8px;
  }
  @media(min-width: 1025px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`

const ProfileLink = styled(Link)<{ isMobile: boolean, isCurrent: boolean}>`
  color: ${({ theme, isCurrent }) => isCurrent ? theme.colors.textPrimary : theme.colors.textSecondary};
  opacity: 0.9;
  transition: all .1s ease;
  
  &:hover {
    transition: all .1s ease;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  &:link:active {
    font-weight: 600;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: 600;
    // font-size: 13.33px;
    line-height: 16px;
    opacity: 1;
    // letter-spacing: -0.015em;
  }
 
`

// const ProfileText = styled(Text)`
//   color: ${({ theme }) => theme.colors.textSecondary};
//   cursor: pointer;
//   // transition: all .4s ease;

//   &:hover {
//     text-decoration: none;
//     color: ${({ theme }) => theme.colors.textPrimary};
//     // transition: all .4s ease;
//     font-weight: 600;
//   }

//   &:active {
//     text-decoration: none;
//     color: ${({ theme }) => theme.colors.textPrimary};
//     font-weight: 600;
//     // font-size: 13.33px;
//     line-height: 16px;
//     // letter-spacing: -0.015em;
//   }
// `

interface NavbarProps {
  isMobile?: boolean
  account?: string
  dropdownHandler?: () => void
}

const Navbars: FC<NavbarProps> = ({ isMobile, account, dropdownHandler }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { onShowHandler } = useConnectWallet()

  const mintHandler = useCallback(() => {
    if (account) {
      history.push('/nft/create')
      dropdownHandler()
    } else {
      onShowHandler()
    }
  }, [account, dropdownHandler, history, onShowHandler])

  const profileHandler = useCallback(() => {
    if (account) {
      history.push('/nft/profile')
    } else {
      onShowHandler()
    }
  }, [account, history, onShowHandler])

  return (
    <NavbarWrapper isMobile={isMobile}>

      {/* <ProfileLink
        isCurrent={pathname.includes('nft/activitypage')}
        href="/exchange/"
        font={pathname === '/nft/activitypage' ? "bodyRegularExtraLargeSemiBold" : "bodyRegularExtraLargeBold"}
        isMobile={isMobile}
      >
        Exchange
      </ProfileLink> */}

      <ProfileLink
        isCurrent={pathname.includes('pricefeed')}
        href="/pricefeed"
        font={pathname === '/pricefeed' ? "bodyRegularExtraLargeSemiBold" : "bodyRegularExtraLargeBold"}
        isMobile={isMobile}
      >
        Oracle Feeds
      </ProfileLink>
      
			<ProfileLink
        isCurrent={pathname.includes('nft-marketplace')}
        href="/nft-marketplace"
        font={pathname === '/nft-marketplace' ? "bodyRegularExtraLargeSemiBold" : "bodyRegularExtraLargeBold"}
        isMobile={isMobile}
      >
				NFT Marketplace
      </ProfileLink>
    </NavbarWrapper>
  )
}

export default Navbars