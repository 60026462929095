import { createReducer } from '@reduxjs/toolkit'
import { getPriceDetail } from './actions'

export interface ZapPriceDetail {
   PriceDetailList: { [key: string]: any}
}

const initialState: ZapPriceDetail = {
  PriceDetailList: {},
}

export default createReducer<ZapPriceDetail>(initialState, (builder) =>
  builder
    .addCase(getPriceDetail, (state, { payload: { priceDetail } }) => {
      const toSet: { [key: string]: any } = {}
      priceDetail.forEach((e) => {
        toSet[e.id] = e
      })
      return {
        ...state,
        PriceDetailList: toSet,
      }
    })
)
