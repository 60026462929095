import React from 'react';
import styled from "styled-components";
import { ImageContainerProps } from "./types";

const ImageContainerWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${({ theme }) => `${theme.colors.flatCard}`};
  border-radius: 16px;
`

const ImageContainer: React.FC<ImageContainerProps> = ({ size }) => (
  <ImageContainerWrapper size={size} />
)

export default ImageContainer;
