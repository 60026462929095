import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Home: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const { theme } = useTheme();
  // const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none"  {...props}>
      <path d="M12.5026 18.7392V15.6142M9.39844 2.95793L3.78385 7.33293C2.84635 8.0621 2.08594 9.61418 2.08594 10.7913V18.51C2.08594 20.9267 4.05469 22.9059 6.47135 22.9059H18.5339C20.9505 22.9059 22.9193 20.9267 22.9193 18.5204V10.9371C22.9193 9.67668 22.0755 8.0621 21.0443 7.34335L14.6068 2.83293C13.1484 1.8121 10.8047 1.86418 9.39844 2.95793Z" stroke={color ?? theme.colors.gradIconColor2} fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Home;