import React from "react";
import { useTheme } from "styled-components"
import Svg from "../Svg";
import { SvgProps } from "../types";

const Notification: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7071 8.79633C18.7071 10.0523 19.039 10.7925 19.7695 11.6456C20.3231 12.2741 20.5 13.0808 20.5 13.956C20.5 14.8302 20.2128 15.6601 19.6373 16.3339C18.884 17.1417 17.8215 17.6573 16.7372 17.747C15.1659 17.8809 13.5937 17.9937 12.0005 17.9937C10.4063 17.9937 8.83505 17.9263 7.26375 17.747C6.17846 17.6573 5.11602 17.1417 4.36367 16.3339C3.78822 15.6601 3.5 14.8302 3.5 13.956C3.5 13.0808 3.6779 12.2741 4.23049 11.6456C4.98384 10.7925 5.29392 10.0523 5.29392 8.79633V8.3703C5.29392 6.68834 5.71333 5.58852 6.577 4.51186C7.86106 2.9417 9.91935 2 11.9558 2H12.0452C14.1254 2 16.2502 2.98702 17.5125 4.62466C18.3314 5.67916 18.7071 6.73265 18.7071 8.3703V8.79633ZM9.07367 20.0608C9.07367 19.5572 9.53582 19.3266 9.96318 19.2279C10.4631 19.1221 13.5093 19.1221 14.0092 19.2279C14.4366 19.3266 14.8987 19.5572 14.8987 20.0608C14.8738 20.5402 14.5926 20.9652 14.204 21.2351C13.7001 21.6279 13.1088 21.8767 12.4906 21.9663C12.1487 22.0107 11.8128 22.0117 11.4828 21.9663C10.8636 21.8767 10.2723 21.6279 9.76938 21.2341C9.37978 20.9652 9.09852 20.5402 9.07367 20.0608Z" fill="url(#paint0_linear_2:1569)"/>
      <defs>
        <linearGradient id="paint0_linear_2:1569" x1="20.5" y1="25.75" x2="-8.26255" y2="7.62087" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1}/>
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Notification;

