import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <g clipPath="url(#clip0)">
        <path d="M26.6378 52.7249C21.4389 52.7249 16.3567 51.1832 12.034 48.2948C7.71122 45.4065 4.34204 41.3011 2.3525 36.4979C0.362952 31.6948 -0.157604 26.4095 0.856657 21.3104C1.87092 16.2114 4.37444 11.5276 8.05064 7.85142C11.7268 4.17522 16.4106 1.6717 21.5096 0.657439C26.6087 -0.356823 31.894 0.163733 36.6972 2.15328C41.5003 4.14282 45.6057 7.512 48.4941 11.8348C51.3824 16.1575 52.9241 21.2397 52.9241 26.4386C52.9241 33.4102 50.1547 40.0962 45.225 45.0258C40.2954 49.9554 33.6094 52.7249 26.6378 52.7249ZM26.6378 3.90754C22.1816 3.90754 17.8255 5.22896 14.1202 7.70471C10.415 10.1805 7.52716 13.6993 5.82183 17.8163C4.11651 21.9334 3.67032 26.4636 4.53968 30.8342C5.40905 35.2048 7.55493 39.2195 10.706 42.3705C13.857 45.5215 17.8716 47.6674 22.2422 48.5368C26.6128 49.4061 31.1431 48.9599 35.2601 47.2546C39.3771 45.5493 42.896 42.6614 45.3717 38.9562C47.8475 35.251 49.1689 30.8948 49.1689 26.4386C49.1689 20.463 46.7951 14.7321 42.5697 10.5067C38.3443 6.28134 32.6135 3.90754 26.6378 3.90754Z" fill="#0A81FE"/>
        <path d="M26.6374 52.7249C26.1394 52.7249 25.6618 52.5271 25.3097 52.1749C24.9576 51.8228 24.7598 51.3453 24.7598 50.8473V2.02993C24.7598 1.53197 24.9576 1.05439 25.3097 0.702277C25.6618 0.350161 26.1394 0.152344 26.6374 0.152344C27.1353 0.152344 27.6129 0.350161 27.965 0.702277C28.3171 1.05439 28.5149 1.53197 28.5149 2.02993V50.8473C28.5149 51.3453 28.3171 51.8228 27.965 52.1749C27.6129 52.5271 27.1353 52.7249 26.6374 52.7249Z" fill="#0A81FE"/>
        <path d="M39.7805 28.3162C39.2825 28.3162 38.8049 28.1184 38.4528 27.7663C38.1007 27.4142 37.9029 26.9366 37.9029 26.4386C37.9029 14.2343 32.7395 3.90752 26.6374 3.90752C26.1394 3.90752 25.6618 3.70971 25.3097 3.35759C24.9576 3.00547 24.7598 2.5279 24.7598 2.02993C24.7598 1.53197 24.9576 1.05439 25.3097 0.702277C25.6618 0.350161 26.1394 0.152344 26.6374 0.152344C35.0677 0.152344 41.6581 11.6995 41.6581 26.4386C41.6581 26.9366 41.4603 27.4142 41.1081 27.7663C40.756 28.1184 40.2785 28.3162 39.7805 28.3162Z" fill="#0A81FE"/>
        <path d="M26.6374 52.7264C26.1394 52.7264 25.6618 52.5285 25.3097 52.1764C24.9576 51.8243 24.7598 51.3467 24.7598 50.8488C24.7598 50.3508 24.9576 49.8732 25.3097 49.5211C25.6618 49.169 26.1394 48.9712 26.6374 48.9712C32.7395 48.9712 37.9029 38.6444 37.9029 26.4401C37.9029 25.9421 38.1007 25.4646 38.4528 25.1124C38.8049 24.7603 39.2825 24.5625 39.7805 24.5625C40.2785 24.5625 40.756 24.7603 41.1081 25.1124C41.4603 25.4646 41.6581 25.9421 41.6581 26.4401C41.6581 41.1792 35.0677 52.7264 26.6374 52.7264Z" fill="#0A81FE"/>
        <path d="M13.4938 28.3162C12.9958 28.3162 12.5183 28.1184 12.1661 27.7663C11.814 27.4142 11.6162 26.9366 11.6162 26.4386C11.6162 11.6995 18.2066 0.152344 26.6369 0.152344C27.1349 0.152344 27.6125 0.350161 27.9646 0.702277C28.3167 1.05439 28.5145 1.53197 28.5145 2.02993C28.5145 2.5279 28.3167 3.00547 27.9646 3.35759C27.6125 3.70971 27.1349 3.90752 26.6369 3.90752C20.5348 3.90752 15.3714 14.2343 15.3714 26.4386C15.3714 26.9366 15.1736 27.4142 14.8215 27.7663C14.4693 28.1184 13.9918 28.3162 13.4938 28.3162Z" fill="#0A81FE"/>
        <path d="M26.6369 52.7264C18.2066 52.7264 11.6162 41.1792 11.6162 26.4401C11.6162 25.9421 11.814 25.4646 12.1661 25.1124C12.5183 24.7603 12.9958 24.5625 13.4938 24.5625C13.9918 24.5625 14.4693 24.7603 14.8215 25.1124C15.1736 25.4646 15.3714 25.9421 15.3714 26.4401C15.3714 38.6444 20.5348 48.9712 26.6369 48.9712C27.1349 48.9712 27.6125 49.169 27.9646 49.5211C28.3167 49.8732 28.5145 50.3508 28.5145 50.8488C28.5145 51.3467 28.3167 51.8243 27.9646 52.1764C27.6125 52.5285 27.1349 52.7264 26.6369 52.7264Z" fill="#0A81FE"/>
        <path d="M51.0455 28.3177H2.22818C1.73021 28.3177 1.25264 28.1199 0.900519 27.7677C0.548403 27.4156 0.350586 26.9381 0.350586 26.4401C0.350586 25.9421 0.548403 25.4646 0.900519 25.1124C1.25264 24.7603 1.73021 24.5625 2.22818 24.5625H51.0455C51.5435 24.5625 52.0211 24.7603 52.3732 25.1124C52.7253 25.4646 52.9231 25.9421 52.9231 26.4401C52.9231 26.9381 52.7253 27.4156 52.3732 27.7677C52.0211 28.1199 51.5435 28.3177 51.0455 28.3177Z" fill="#0A81FE"/>
        <path d="M47.291 17.0501H5.98404C5.48607 17.0501 5.0085 16.8523 4.65638 16.5002C4.30426 16.1481 4.10645 15.6705 4.10645 15.1725C4.10645 14.6745 4.30426 14.197 4.65638 13.8449C5.0085 13.4927 5.48607 13.2949 5.98404 13.2949H47.291C47.789 13.2949 48.2666 13.4927 48.6187 13.8449C48.9708 14.197 49.1686 14.6745 49.1686 15.1725C49.1686 15.6705 48.9708 16.1481 48.6187 16.5002C48.2666 16.8523 47.789 17.0501 47.291 17.0501Z" fill="#0A81FE"/>
        <path d="M47.291 39.5814H5.98404C5.48607 39.5814 5.0085 39.3835 4.65638 39.0314C4.30426 38.6793 4.10645 38.2017 4.10645 37.7038C4.10645 37.2058 4.30426 36.7282 4.65638 36.3761C5.0085 36.024 5.48607 35.8262 5.98404 35.8262H47.291C47.789 35.8262 48.2666 36.024 48.6187 36.3761C48.9708 36.7282 49.1686 37.2058 49.1686 37.7038C49.1686 38.2017 48.9708 38.6793 48.6187 39.0314C48.2666 39.3835 47.789 39.5814 47.291 39.5814Z" fill="#0A81FE"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="52.7258" height="52.7258" fill="white" transform="translate(0.274414 0.0761719)"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
