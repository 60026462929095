import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { NotificationCardProps } from './types'
import { Text } from '../../atoms/Text'
import { Avatar } from '../../atoms/Avatar'
import { Link } from '../../atoms/Link'
import { ExternalLinkIcon } from "../../atoms/Svg";

const NotificationCardWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 66.53px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  box-shadow: ${({ theme }) => theme.shadows.popMedium};
  border-radius: 16px;
`

const AvatarWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.flatOverlay};
`

const TextGroupWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
`
const TextRowWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`

const Group = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`
// const IconWrapper = styled.div`
// `

const NotificationCard: React.FC<NotificationCardProps> = ({data}) => {
    const theme = useTheme();
    const history = useHistory()
    const handleProfile = (param: string) => () => {
      history.push(`/nft/profile/${param}`)
    }
    return (
    <NotificationCardWrapper>
        <Group>
            <AvatarWrapper>
                <Avatar size={32} src={data.thumbnail ?? '/zappy_profile.png'}/>
            </AvatarWrapper>
            <TextGroupWrapper>
                <TextRowWrapper>
                    <Text font="bodySemiBoldSmall">{data.type} by&nbsp;</Text>
                    {/* <Link href={`/nft/profile/${data.user}`}> */}
                        <Text style={{cursor: 'pointer'}} onClick={handleProfile(data.user)} font="bodySemiBoldSmall" color={theme.colors.primaryBlue}>@{data.user}</Text>
                    {/* </Link> */}
                </TextRowWrapper>
                <Text font="cardRegularNormal">{data.date}</Text>
            </TextGroupWrapper>
        </Group>
        <Link href={data.href}>
            <ExternalLinkIcon/>
        </Link>
    </NotificationCardWrapper>
)}

export default NotificationCard