import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'designStandard/hooks'
import { Avatar, Text } from 'designStandard/components/atoms'
import { FeaturedProps } from './types'

const rotateTime = 8000

const Container = styled.div<{ size?: number; infoCard: boolean }>`
  display: grid;
  grid-auto-rows: auto;
  width: ${(size) => (size ? '' : '100%')};
  padding: 16px 0 0;
  overflow-x: hidden;
`

const ImageContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: ${({ isMobile }) => (isMobile ? '100vw' : 'min(36vw, 1620px/3)')};
  max-width: 100%;
  @media (max-width: 1507px) {
    height: ${({ isMobile }) => (isMobile ? '100vw' : 'min(36vw, 1287px/3)')};
  }
  @media (max-width: 1208px) {
    height: ${({ isMobile }) => (isMobile ? '100vw' : 'min(36vw, 1032px/3)')};
  }
  @media (max-width: 915px) {
    height: ${({ isMobile }) => (isMobile ? '100vw' : 'min(36vw, 780px/3)')};
  }
`

const Card = styled.div<{ pos: string; isMobile: boolean }>`
  cursor: pointer;
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1620px/3)')};
  left: 50%;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1620px/3)')};
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 6%;
  background: ${({theme}) => theme.colors.strokeGradNorm} padding-box, ${({theme}) => theme.colors.strokeGradNorm} border-box;
  border-image-source: ${({theme}) => theme.colors.strokeGradNorm};
  transition: .3s ease;
  ${({ pos, isMobile }) => posMap(pos, isMobile)};
  @media (max-width: 1507px) {
    width: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1287px/3)')};
    height: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1287px/3)')};
  }
  @media (max-width: 1208px) {
    width: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1032px/3)')};
    height: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 1032px/3)')};
  }
  @media (max-width: 915px) {
    width: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 780px/3)')};
    height: ${({ isMobile }) => (isMobile ? '100%' : 'min(36vw, 780px/3)')};
  }
`

const posMap = (pos, isMobile) => {
  if (pos === 'left') {
    return `
    left: ${isMobile ? '-40%' : `calc(50% - ${isMobile ? '100vw' : 'min(36vw, 1620px/3)'} - min(13vw, 210px))`};
    margin: 0px; // 32px 0px 32px 0px;
    opacity: 0.8;
    z-index: 1;
    transform: scale(0.9);
    @media (max-width: 1507px) {
      left: ${isMobile ? '-40%' : `calc(50% - ${isMobile ? '100vw' : 'min(36vw, 1287px/3)'} - 160px)`};
    }
    @media (max-width: 1208px) {
      left: ${isMobile ? '-40%' : `calc(50% - ${isMobile ? '100vw' : 'min(36vw, 1032px/3)'} - 100px)`};
    }
    @media (max-width: 915px) {
      left: ${isMobile ? '-40%' : `calc(50% - ${isMobile ? '100vw' : 'min(36vw, 780px/3)'} - 50px)`};
      transform: scale(0.75);
    }
    `
  }
  if (pos === 'right') {
    return `
    left: ${isMobile ? '110%' : `calc(50% + min(13vw, 210px))`};
    margin: 0px; // 32px 0px 32px 0px;
    opacity: 0.8;
    z-index: 1;
    transform: scale(0.9);
    @media (max-width: 1507px) {
      left: ${isMobile ? '110%' : `calc(50% + 160px)`};
    }
    @media (max-width: 1208px) {
      left: ${isMobile ? '110%' : `calc(50% + 100px)`};
    }
    @media (max-width: 915px) {
      left: ${isMobile ? '110%' : `calc(50% + 50px)`};
      transform: scale(0.75);
    }
    `
  }
  if (pos === 'offLeft') {
    return `
    left: ${isMobile ? '-80%' : '-25%'};
    margin: 32px 0px 32px 0px;
    opacity: 0;
    z-index: 0;
    width: ${isMobile ? '100%' : '20%'};
    transform: scale(0.1);
    `
  }
  if (pos === 'offRight') {
    return `
    left: ${isMobile ? '150%' : '125%'};
    margin: 32px 0px 32px 0px;
    opacity: 0;
    z-index: 0;
    width: ${isMobile ? '100%' : '20%'};
    transform: scale(0.1);
    `
  }
  if (pos === 'center') {
    return `
      border: 2px solid transparent;
    `
  }
  return `
    top: -100%;
    opacity: 0;
    }
  `
}

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6%;
  object-fit: cover;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 6%;
  object-fit: cover;
`

const ProgressBarWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  height: 20px;
  max-width: 100%;
  margin-bottom: 24px;
`

const ProgressBar = styled.div<{ sliderId: number; currentId: number }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background: ${({ theme, sliderId, currentId }) =>
    sliderId === currentId ? theme.colors.primaryReverseBlue : theme.colors.flatOverlay};
  border-radius: 50%;
  cursor: pointer;
`

const ArtistCard = styled.div`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  height: 52px;
  padding: 10px 16px 10px 12px;
  max-width: 180px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  box-shadow: ${({ theme }) => theme.shadows.idleSoft};
  border-radius: 24px;
  margin: 16px auto;
`

const Featured: React.FC<FeaturedProps> = ({
  nfts,
  infoCard,
  artists,
  onNftSelect = () => null,
  onArtistSelect = () => null,
}) => {
  const { isSm, isXs } = useMatchBreakpoints()
  const isMobile = isXs || isSm
  const [id, setId] = useState(0)
  const shiftId = (dir, distance, value) => {
    console.log(value, dir, distance)
    if(value === 0 && dir < 0) setId(nfts.length - 1)
    else if(value === nfts.length - 1 && dir > 0) setId(0)
    setId(value)
    if(distance > 0) setTimeout(() => shiftId(dir, distance - 1, value + dir), 130)
  }

  const handleIdChange = async (index) => {
    const distance = index - id;
    const dir = distance < 0 ? -1 : 1;
    console.log(id, dir, index, distance)
    shiftId(dir, Math.abs(distance), id)
  }

  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      if (id === nfts.length - 1) setId(0)
      else setId(id + 1)
    }, rotateTime)
    return () => {
      clearTimeout(scrollTimer)
    }
  }, [id, nfts])

  return (
    <Container infoCard={infoCard}>
      <ImageContainer isMobile={isMobile}>
        {nfts.map((nft, index) => {
          let pos = ''
          if (index === id) {
            pos = 'center'
          } else {
            // right edge case 1
            if (id === nfts.length - 1) {
              if (index === 0) pos = 'right'
              else if (index === 1) pos = 'offRight'
            }
            // right edge case 2
            else if (id === nfts.length - 2) {
              if (index === nfts.length - 1) pos = 'right'
              if (index === 0) pos = 'offRight'
            }
            if (index === id + 1) pos = 'right'
            else if (index === id + 2) pos = 'offRight'
            if (nfts.length === 3) {
              // left edge case 1
              if (id === 0) {
                if (index === nfts.length - 1) pos = 'left'
                else if (index === nfts.length - 2) pos = 'right'
              }
              // left edge case 2
              if (id === 1) {
                if (index === 0) pos = 'left'
                else if (index === nfts.length - 1) pos = 'right'
              }
              if (index === id - 1) pos = 'left'
              else if (index === id - 2) pos = 'right'
            } else {
              // left edge case 1
              if (id === 0) {
                if (index === nfts.length - 1) pos = 'left'
                else if (index === nfts.length - 2) pos = 'offLeft'
              }
              // left edge case 2
              if (id === 1) {
                if (index === 0) pos = 'left'
                else if (index === nfts.length - 1) pos = 'offLeft'
              }
              if (index === id - 1) pos = 'left'
              else if (index === id - 2) pos = 'offLeft'
            }
          }

          console.log("pos: ", pos)

          return (
            <Card pos={pos} isMobile={isMobile} onClick={() => onNftSelect(nft.id.toString())}>
              {nft.category === 'video' ? (
                <Video src={nft.contentURI} autoPlay muted loop/>
              ) : (
                <Img src={nft.contentURI} alt="featured-content" />
              )}
            </Card>
          )
        })}
      </ImageContainer>

      {infoCard && (
        <ArtistCard
          onClick={() => {
            const usrId = artists[id < nfts.length ? id : id - nfts.length]?.attributes
            if (usrId) {
              onArtistSelect(usrId.name.toString())
            }
          }}
        >
          <Avatar src={artists[id < nfts.length ? id : id - nfts.length]?.attributes?.profileImageRef} size={32} />
          <Text font="captionRegularSmall" color="textPrimary">
            {artists[id < nfts.length ? id : id - nfts.length]?.attributes?.name}
          </Text>
        </ArtistCard>
      )}
      <ProgressBarWrapper>
        {nfts.map((nft, index) => {
          return <ProgressBar key={nft.id} sliderId={index} currentId={id < nfts.length ? id : id - nfts.length} onClick={async () => handleIdChange(index)}/>
        })}
      </ProgressBarWrapper>
    </Container>
  )
}

export default Featured
