import React, { useEffect, useRef, useState } from "react";

export interface UseDynamicSVGImportOptions {
  onCompleted?: (
    name: string,
    SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | null
  ) => void;
  onError?: (err: Error) => void;
}
  
const useDynamicSVGImport = ( name: string, options: UseDynamicSVGImportOptions = {} ) => {
    const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
  
    const { onCompleted, onError } = options;
    useEffect(() => {
      setLoading(true);
      const importIcon = async (): Promise<void> => {
        try {
          ImportedIconRef.current = (
            await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../../node_modules/cryptocurrency-icons/svg/color/${name}.svg`)
          ).default;
          onCompleted?.(name, ImportedIconRef.current);
        } catch (err) {
          if (onError) {
            onError(err as Error);
          }
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      };
      importIcon();
    }, [name, onCompleted, onError]);
  
    return { error, loading, SvgIcon: ImportedIconRef.current };
  }

  export default useDynamicSVGImport;