import React, { FC, useCallback } from 'react'
import styled from 'styled-components'
import { CloseIcon } from 'designStandard/components/atoms/Svg'
import { Text } from 'designStandard/components/atoms/Text'
import { GenericModal } from 'designStandard/components/organisms/GenericModal'
import LogoutCard from './LogoutCard'

const Modal = styled(GenericModal)`
  width: 430px;
  height: auto;
  padding: 8px 0 0;
  border: 2px solid transparent;
`

const ModalHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  margin: 0 22px;
`

const CloseWrapper = styled.div`
  cursor: pointer;
`

const Divider = styled.div`
  height: 9px;
  margin: 16px 0 0;
  background: ${({ theme }) => theme.colors.dividerMorphBody};
`

const Flex = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobile }) => isMobile? '16px 0' : '16px'};
`

const NetworkStatus = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background: ${({ theme }) => theme.colors.primaryGreen};
  border-radius: 50%;
`

const NetworkWrapper = styled(Flex)<{ isMobile?: boolean }>`
  padding: ${({ isMobile }) => isMobile? '8px 0 8px 12px' : '8px 0 8px 20px'};
`

const NetworkCloseWrapper = styled(Flex)`
  grid-gap: 16px;
`

interface LogoutWalletProps {
  isOpened?: boolean
  onClose?: () => void
  account: string
  chainId: number
  img: string
  network?: string
  logout: () => void
}
  
const LogoutWallet: FC<LogoutWalletProps> = ({
  isOpened,
  onClose,
  account,
  chainId,
  img,
  network,
  logout
}) => {
  const closeHandler = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal isOpened={isOpened}>
      <ModalHeader>
        <Text font='walletPrimaryBold'>Your Wallet</Text>
        <NetworkCloseWrapper>
          <NetworkWrapper>
            <NetworkStatus />
            <Text font="walletDropNav" color="textSecondary">{ network }</Text>
          </NetworkWrapper>
          <CloseWrapper onClick={closeHandler}>
            <CloseIcon />
          </CloseWrapper>
        </NetworkCloseWrapper>
      </ModalHeader>
      <Divider />
      <LogoutCard
        account={account}
        chainId={chainId}
        img={img}
        network={network}
        logout={logout}
        onClose={onClose}
      />
    </Modal>
  )
}

export default LogoutWallet