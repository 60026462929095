import { ReactNode, useContext, useCallback } from 'react'
import { Context } from './ModalContext'
import { HandlerProps } from './types'

const useModal = (modal: ReactNode): [HandlerProps, HandlerProps] => {
  const { onShow, onClose } = useContext(Context)

  const onShowHandler = useCallback(() => {
    onShow(modal)
  }, [modal, onShow])

  return [ onShowHandler, onClose ]
}

export default useModal