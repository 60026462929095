import React from 'react'
import Lottie from 'react-lottie-player'
import styled from 'styled-components'
import lottieData from './lottie.json'

const LottieWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background: #2527277D;
  backdrop-filter: blur(4px);
  z-index: 999;
`

const LoadingSpinner = (props) => (
  <LottieWrapper {...props}>
    <Lottie
      loop
      animationData={lottieData}
      play
      style={{ width: 300, height: 300 }}
    />
  </LottieWrapper>
)

export default LoadingSpinner
