import React from "react";
import styled, { DefaultTheme } from "styled-components";
import {
  MinusIcon,
  InformationIcon,
  CloseIcon,
  CheckIcon,
  } from "../Svg";
import { Text } from "../Text";
import { IconButton } from "../../molecules/IconButton";
import {GridBox} from "../GridLayout";
import { AlertProps, variants } from "./types";

interface ThemedIconLabel {
  variant: AlertProps["variant"];
  theme: DefaultTheme;
  hasDescription: boolean;
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.ERROR:
      return theme.colors.failure;
    case variants.WARNING:
      return theme.colors.warning;
    case variants.SUCCESS:
      return theme.colors.success;
    case variants.INFO:
    default:
      return theme.colors.secondary;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getIcon = (variant: AlertProps["variant"] = variants.INFO) => {
  switch (variant) {
    case variants.ERROR:
      return MinusIcon;
    case variants.WARNING:
      return InformationIcon;
    case variants.SUCCESS:
      return CheckIcon;
    case variants.INFO:
    default:
      return InformationIcon;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IconLabel = styled.div<ThemedIconLabel>`
  background-color: ${getThemeColor};
  border-radius: 16px 0 0 16px;
  color: ${({ theme }) => theme.alert.background};
`;

const withHandlerSpacing = 32 + 12 + 8; // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean }>`
  flex: 1;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: ${({ hasHandler }) => (hasHandler ? `${withHandlerSpacing}px` : "12px")};
  padding-top: 12px;
`;

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  // right: 8px;
  // position: absolute;
  top: 8px;
`;

const StyledAlert = styled(GridBox)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.flatGlobal};
  border-radius: 16px;
  border: none;
  box-shadow: transparent; 
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Alert: React.FC<AlertProps> = ({ children, variant, onClick }) => {
  // const Icon = getIcon(variant);

  return (
    <StyledAlert>
      {/* <IconLabel variant={variant} hasDescription={!!children}>
        <Icon color="transparent" width="24px" size={24} />
      </IconLabel> */}
      <Details hasHandler={!!onClick}>
        {/* <Text bold>{title}</Text> */}
        {typeof children === "string" ? <Text as="p">{children}</Text> : children}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" onClick={onClick}>
            <CloseIcon width="24px" color="currentColor" />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  );
};

export default Alert;
