import React, { useState } from 'react'
import { ModalProvider } from 'toolkitUI'
import { Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { getLibrary } from 'utils/web3React'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import SidebarProvider from 'contexts/SidebarContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { store, persistorStore } from 'state'
import { MoralisProvider } from 'react-moralis'
import Initialize from 'state/Initialize'
import ModalContext from 'designStandard/components/organisms/GenericModal/ModalContext'

const Providers: React.FC = ({ children }) => {
  const [chainId, setChainId] = useState(0)
  
  const getChainId = async () => {
    // const fallbackChainId = 56
    //   await window.ethereum?.request({method: 'eth_chainId'})
    //     .then((res) => {
    //       if (res === undefined) {
    //         setChainId(fallbackChainId)
    //       } else {
    //         setChainId(Number(res))
    //       }
    //   }).catch ((e) => {
    //   setChainId(fallbackChainId)
    // })
  // const _chainId = ethereum ? await ethereum.request({ method: 'eth_chainId' }) : 56// '|| await 56' is a failsafe for browsers that have deprecated this request method
      // setChainId(Number(_chainId))
      const _chainId = window.ethereum ? await window.ethereum.request({ method: 'eth_chainId' }) || await 56 : await 56 // '|| await 56' is a failsafe for browsers that have deprecated this request method
      setChainId(Number(_chainId))
      console.log(Number(_chainId))
  }

    getChainId()


  const getAppId = () => {
    console.log("chainId", chainId)
    if (chainId === 56 || chainId === 1) {
      return process.env.REACT_APP_MORALIS_MAIN_APPLICATION_ID
    }
    return process.env.REACT_APP_MORALIS_APPLICATION_ID
  }

  const getServerURL = () => {
    if (chainId === 56 || chainId === 1) {
      return process.env.REACT_APP_MORALIS_MAIN_SERVER_URL
    }
    return process.env.REACT_APP_MORALIS_SERVER_URL
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      {chainId === 0 ? (
        <></>
      ) : (
        <MoralisProvider appId={getAppId()} serverUrl={getServerURL()}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistorStore}>
              <HelmetProvider>
                <SidebarProvider>
                  <ThemeContextProvider>
                    {/* <LanguageContextProvider> */}
                    <RefreshContextProvider>
                      <ModalContext>
                        <ModalProvider>
                          <Initialize>{children}</Initialize>
                        </ModalProvider>
                      </ModalContext>
                    </RefreshContextProvider>
                    {/* </LanguageContextProvider> */}
                  </ThemeContextProvider>
                </SidebarProvider>
              </HelmetProvider>
            </PersistGate>
          </Provider>
        </MoralisProvider>
      )}
    </Web3ReactProvider>
  )
}

export default Providers
