import React from "react"
import Svg from "../Svg"
import { SvgProps} from "../types"

interface ClearProps extends SvgProps {
  isTransparent?: boolean
}

const Icon: React.FC<ClearProps> = (props) => {
  const { isTransparent } = props

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <rect width="24" height="24" transform="translate(0.5)" fill={isTransparent? "none" : "white"} />
      <path d="M9.09091 6.36364H7.43182L11.1818 12.1818L7.43182 18H9.09091L12.0909 13.25H12.1818L15.1818 18H16.8409L13.1818 12.1818L16.8409 6.36364H15.1818L12.1818 11.2045H12.0909L9.09091 6.36364Z" fill="black" />
    </Svg>
  )
}

export default Icon
