import React from "react";
import Svg from "../../Svg";
import { SvgProps } from "../../types";

const Icon: React.FC<SvgProps>
  = (props) => {
  return (
  <Svg viewBox="0 0 1024 1024" {...props}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1024 1024" xmlSpace="preserve" enableBackground = "new 0 0 1024 1024">
    <g id="Guides" />
    <g id="Icon">
        <circle className="st0" fill="#5848D5" cx="512" cy="512" r="512"/>
        <path className="st1" fill = "#FFFFFF" d="M256.1,601.4c0-9.7,0.2-19.5-0.1-29.2c-0.4-18.9,0.9-37.5,4.1-56.2c5.4-31.1,15.2-60.5,31.2-87.7
            c26.9-45.7,64.8-79.2,112.6-101.8c21.2-10,43.4-17.1,66.7-20.6c12.1-1.8,24.2-3.3,36.5-3.2c52.3,0.4,101,13.3,145,42.2
            c43.1,28.3,75.5,65.7,94.9,113.8c9.1,22.6,14.8,46.2,17.7,70.4c3.3,26.7,3.5,53.5,3,80.3c-0.3,14.5-0.2,29-0.4,43.4
            c-0.1,6.3-0.3,12.6-2,18.8c-6.5,24-33.9,34.9-54,21.6c-9-5.9-13.4-14.6-15-25c-1.1-7-1.7-14-1.7-21.1c0-29.1,0-58.3,0-87.4
            c0-11.4-3.6-21.6-11.4-30c-13.6-14.6-34.6-15.2-49.9-1.7c-8.6,7.6-12.5,17.3-13.3,28.5c-0.3,4.2-0.4,8.4-0.4,12.5
            c-0.1,32.1,0.1,64.2-0.2,96.4c-0.2,18.2-14,32.1-32.6,33.4c-17.8,1.2-33.5-11-36.5-28.6c-0.8-4.8-1.1-9.7-1.1-14.7
            c0-32.3,0.1-64.6,0-96.9c-0.1-10.9-3.7-20.5-11.3-28.5c-15.3-16.1-38.4-15.6-53.2,0.9c-7.3,8.1-10.5,17.5-10.5,28.2
            c0,31.9-0.1,63.9,0,95.8c0,8.2-1,16.3-4.4,23.9c-5.9,13.1-18.3,20.8-32.6,20.3c-13.6-0.4-25.2-9-30.4-22.7c-2.1-5.4-3-11.1-3-16.9
            c0.1-30.9,0.1-61.8-0.1-92.7c0-6.9-0.3-13.8-2.3-20.4c-4.7-15.2-14.8-24.9-30.5-27.5c-15.9-2.6-27.7,4.9-36.6,17.7
            c-4.2,6.1-5.6,13-5.6,20.3c0,29.4,0.1,58.7,0.1,88c0,8.6,0.2,17.3-1.8,25.8c-3.2,14.1-16.8,29.6-37.4,28.1
            c-14.1-1.1-27.7-12.8-31.7-27.7c-1.4-5.3-1.8-10.8-1.8-16.3C256.2,637.1,256.2,619.2,256.1,601.4
            C256.2,601.4,256.1,601.4,256.1,601.4z"/>
    </g>
    </svg>
  </Svg>
  );
};

export default Icon;
