import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps as BaseSvgProps } from "../types"

interface SvgProps extends BaseSvgProps {
  fillColor1?: string
  strokeColor1?: string
  fillColor2?: string
  strokeColor2?: string
}

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  fillColor1="ethereumIconFill1",
  strokeColor1="ethereumIconStroke1",
  fillColor2="ethereumIconFill2",
  strokeColor2="ethereumIconStroke2",
  ...props
}) => {
  const theme = useTheme()
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M14.9724 20.1859V7.08199L15.0742 6.7431L21.2191 16.719L15.0662 20.2776L14.9724 20.1859Z" fill="url(#paint0_linear_1151:552)" stroke="url(#paint1_linear_1151:552)" strokeWidth="0.1" />
      <path d="M15.0078 20.2522L8.89717 16.7181L15.0078 6.7976V13.9649V20.2522Z" fill="url(#paint2_linear_1151:552)" stroke="url(#paint3_linear_1151:552)" strokeWidth="0.1" />
      <path d="M15.0319 26.256V21.6047L15.0913 21.5339L21.114 18.0544L15.0743 26.377L15.0319 26.256Z" fill="url(#paint4_linear_1151:552)" stroke="url(#paint5_linear_1151:552)" strokeWidth="0.1" />
      <path d="M9.00661 18.0545L15.0083 21.5239V26.3295L9.00661 18.0545Z" fill="url(#paint6_linear_1151:552)" stroke="url(#paint7_linear_1151:552)" strokeWidth="0.1" />
      <path d="M15.1091 14.0428L21.1793 16.7424L15.1091 20.2531V14.0428Z" fill="url(#paint8_linear_1151:552)" stroke="url(#paint9_linear_1151:552)" strokeWidth="0.1" />
      <path d="M15.0082 14.0428V20.2531L8.93807 16.7424L15.0082 14.0428Z" fill="url(#paint10_linear_1151:552)" stroke="url(#paint11_linear_1151:552)" strokeWidth="0.1" />
      <defs>
        <linearGradient id="paint0_linear_1151:552" x1="14.9224" y1="13.4809" x2="21.2886" y2="13.4809" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint1_linear_1151:552" x1="20.9703" y1="20.2134" x2="14.271" y2="17.7211" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint2_linear_1151:552" x1="8.82764" y1="13.48" x2="15.0578" y2="13.48" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint3_linear_1151:552" x1="14.7463" y1="20.2124" x2="8.1563" y2="17.8132" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint4_linear_1151:552" x1="14.9819" y1="22.1886" x2="21.2925" y2="22.1886" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint5_linear_1151:552" x1="20.977" y1="26.4045" x2="15.3638" y2="23.0989" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint6_linear_1151:552" x1="8.82812" y1="22.1886" x2="15.0583" y2="22.1886" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint7_linear_1151:552" x1="14.7468" y1="26.4044" x2="9.16881" y2="23.1614" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint8_linear_1151:552" x1="15.0591" y1="17.1528" x2="21.2892" y2="17.1528" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint9_linear_1151:552" x1="20.9777" y1="20.281" x2="16.3533" y2="16.6576" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
        <linearGradient id="paint10_linear_1151:552" x1="8.82812" y1="17.1528" x2="15.0582" y2="17.1528" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]} />
          <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint11_linear_1151:552" x1="14.7467" y1="20.281" x2="10.1224" y2="16.6576" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]} />
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon