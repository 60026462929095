import { Colors } from './types'

export const baseColors = {
  morphDisabled: '#929292',
}

export const lightColors: Colors = {
  ...baseColors,
  textPrimary: "#2D2D2D",
  textReversePrimary: "#E1E1E1",
  textSecondary: "#737478",
  textOnHover: "#2F2F32",
  textOnDisabled: "#858686",
  textPlaceHolder: "#ABABAB",
  textDisabled: "#393B3B",
  textPrimaryCard: "#000000",
  textSecondaryCard: "#000000",
  primaryRed: "#FF3B30",
  primaryGreen: "#34C759",
  primaryBlue: "#007AFF",
  primaryReverseBlue: "#0A84FF",
  brandOne: "#3480F6",
  brandTwo: "#4CBDFE",
  gradBtnNorm: "linear-gradient(180deg, #0066FF 9.26%, #004388 100%)",
  gradBtnInvert: "linear-gradient(0deg, #0066FF 0%, #004388 96.25%)",
  gradBgRadioDisabled: "#EBECF0",
  strokeGradNorm: "linear-gradient(107.54deg, #ECEFF7 0.41%, #EBEBEC 100.35%)",
  strokeGradError: "linear-gradient(107.54deg, #FF3B30 0.41%, #FF453A 100.35%)",
  strokeGradRadio: "linear-gradient(107.54deg, #CCCCCC 0.41%, #D7D7DA 100.35%)",
  gradStroke: "linear-gradient(107.54deg, rgba(244, 244, 244, 0.48) 0.41%, #EBEBEC 100.35%)",
  gradIcons: "linear-gradient(306.56deg, #535252 -7.98%, rgba(0, 0, 0, 0.8) 127.76%)",
  flatGlobal: "#E5E7EF",
  flatCheckBox: "#F5F5F9",
  flatOverlay: "#F0F2FB", // "#EAECF5",
  flatLikeBtn: "linear-gradient(180deg, #FF2D55 0%, #FF204A 100%)",
  btnBGSecondary: "#F9F9F9",
  zapIconFill: "linear-gradient(90deg, #4479BD 0%, #63BDEB 100%)",
  zapIconStroke: "linear-gradient(326.79deg, #FFFFFF 27.62%, rgba(218, 218, 218, 0) 83.24%)",
  strokeFlatNorm: "#D9D9D9",
  gradIconColor1: '#535252',
  gradIconColor2: '#535252',
  flatSearchBox: "rgba(33, 33, 33, 0.08)",
  searchIconInactiveStroke: "#989898",
  searchIconActiveStroke: "#535252",
  searchBoxBorder: "#F0F2FB",
  flatCard: "#CACACA",
  ethereumIconFill1: "#4479BD",
  ethereumIconStroke1: "#63BDEB",
  ethereumIconFill2: "#E6E6E6",
  ethereumIconStroke2: "#B7B7B7",
  propertyCardBoxShadow: "-1px 1px 2px rgba(188, 189, 196, 0.2), 1px -1px 2px rgba(188, 189, 196, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(188, 189, 196, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(188, 189, 196, 0.5)",
  heartFill: "#FF2D55",
  heartStroke: "#FF204A",
  carouselFill: "#252727",
  carouselDeselected: "#494949",
  gradientBtnBoxShadow: "-5px 5px 10px rgba(206, 208, 215, 0.2), 5px -5px 10px rgba(206, 208, 215, 0.2), -5px -5px 10px rgba(252, 254, 255, 0.9), 5px 5px 13px rgba(206, 208, 215, 0.9), inset 1px 1px 2px rgba(252, 254, 255, 0.3), inset -1px -1px 2px rgba(206, 208, 215, 0.5)",
  gradientBtnPressedBoxShadow: "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(143, 144, 149, 0.5), inset -2px 2px 4px rgba(143, 144, 149, 0.2), inset 2px -2px 4px rgba(143, 144, 149, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(143, 144, 149, 0.9)",
  gradientBtnHoverBoxShadow: "-4px 4px 8px rgba(183, 185, 191, 0.2), 4px -4px 8px rgba(183, 185, 191, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(183, 185, 191, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(183, 185, 191, 0.5)",
  gradientBtnFocusBoxShadow: "-1px 1px 2px rgba(142, 143, 148, 0.2), 1px -1px 2px rgba(142, 143, 148, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(142, 143, 148, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(142, 143, 148, 0.5)",
  flatBtnHoverBoxShadow: "-1px 1px 2px rgba(188, 189, 196, 0.2), 1px -1px 2px rgba(188, 189, 196, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(188, 189, 196, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(188, 189, 196, 0.5)",
  btnFlatBg: "#FFFFFF",
  dividerMorphLine: "linear-gradient(270deg, #E5E7EF 23.54%, #FFFFFF 50.85%, #E5E7EF 74.21%)",
  dividerMorphBody: "linear-gradient(180deg, #DDDEE3 0%, #E5E7EF 100%)",
  radialGradient: "radial-gradient(69.73% 150% at 50% -50%, #007AFF 0%, rgba(0, 122, 255, 0) 100%)",
  switchBoxShadow: "1px 1px 2px rgba(252, 254, 255, 0.3), -1px -1px 2px rgba(206, 208, 215, 0.5), inset -5px 5px 10px rgba(206, 208, 215, 0.2), inset 5px -5px 10px rgba(206, 208, 215, 0.2), inset -5px -5px 10px rgba(252, 254, 255, 0.9), inset 5px 5px 13px rgba(206, 208, 215, 0.9)",
  switchBorder: "1px solid",
  modalBorder: "8px solid",
  saleButtonPressed: "#B6B6B6",
  gradientBlue: 'linear-gradient(180deg, #3480F6 0%, #4CBDFE 100%)',
  btnTextPrimary: '#FFFFFF',
  skeletonBg: 'linear-gradient(127.76deg, #E5E7EF 2.39%, #EAECF5 88.89%)',
  skeletonCard: '#F0F2FB',
  skeletonSecondary: '#EAECF5',
  skeletonPrimary: '#E5E7EF',
	inputBorder: '#808080',
  vwLogo: '#335080',
  dropdownHover: '#FFFFFF',
  priceCardPrimary: '#F0F2FB',
  priceCardSecondary: '#2D2D2D',
  tokenPairSkeleton1: '#E5E7EF',
  tokenPairSkeleton2: '#F7F8FF',
  scrollbarThumb: '#D8D8D8',
}

export const darkColors: Colors = {
  ...baseColors,
  textPrimary: "#F3F3F3",
  textReversePrimary: "#5C5C60",
  textSecondary: "#888888",
  textOnHover: "#F9F9F9",
  textOnDisabled: "#858686",
  textPlaceHolder: "#636366",
  textDisabled: "#F5F5F5",
  textPrimaryCard: "#E1E1E1",
  textSecondaryCard: "#B6B6B6",
  primaryRed: "#FF453A",
  primaryGreen: "#30D158",
  primaryBlue: "#0A84FF",
  primaryReverseBlue: "#007AFF",  
  brandOne: "#488CF6",
  brandTwo: "#5DC3FE",
  gradBtnNorm: "linear-gradient(180deg, #0066FF 9.26%, #0854A2 100%)",
  gradBtnInvert: "linear-gradient(358.57deg, #0066FF 1.19%, #0854A2 98.81%)",
  gradBgRadioDisabled: "#858686",
  strokeGradNorm: "linear-gradient(180deg, #373737 0%, #303030 100%)",
  strokeGradError: "linear-gradient(107.54deg, #FF746C 0.41%, #FF6259 100.35%)",
  strokeGradRadio: "linear-gradient(103.62deg, #464646 7.23%, #222424 96.5%)",
  gradStroke: "linear-gradient(180deg, #373737 0%, #303030 100%)",
  gradIcons: "linear-gradient(308.72deg, #E6E6E6 3.19%, rgba(183, 183, 183, 0.8) 69.68%)",
  flatGlobal: "#252727",
  flatCheckBox: "#161717",
  flatOverlay: "#141515", // "#1F2121",
  flatLikeBtn: "linear-gradient(180deg, #FF2D55 0%, #FF204A 100%)",
  btnBGSecondary: "#090909",
  zapIconFill: "linear-gradient(90deg, #4479BD 0%, #63BDEB 100%)",
  zapIconStroke: "linear-gradient(#585858 0%, #000000 100%)",
  strokeFlatNorm: "#232323", // "#121313",
  gradIconColor1: '#E6E6E6',
  gradIconColor2: '#B7B7B7',
  flatSearchBox: "rgba(240, 240, 240, 0.08)",
  searchIconInactiveStroke: "#989898",
  searchIconActiveStroke: "#E6E6E6",
  searchBoxBorder: "#1E1E1E",
  flatCard: "#171717",
  ethereumIconFill1: "#4479BD",
  ethereumIconStroke1: "#63BDEB",
  ethereumIconFill2: "#E6E6E6",
  ethereumIconStroke2: "#B7B7B7",
  propertyCardBoxShadow: "",
  heartFill: "#FF2D55",
  heartStroke: "#FF204A",
  carouselFill: "#E5E7EF",
  carouselDeselected: "#B7B9BF",
  gradientBtnBoxShadow: "-5px 5px 10px rgba(31, 33, 33, 0.2), 5px -5px 10px rgba(31, 33, 33, 0.2), -5px -5px 10px rgba(43, 45, 45, 0.9), 5px 5px 13px rgba(31, 33, 33, 0.9), inset 1px 1px 2px rgba(43, 45, 45, 0.3), inset -1px -1px 2px rgba(31, 33, 33, 0.5)",
  gradientBtnPressedBoxShadow: "inset -2px 2px 4px rgba(15, 16, 16, 0.2), inset 2px -2px 4px rgba(15, 16, 16, 0.2), inset -2px -2px 4px rgba(59, 62, 62, 0.9), inset 2px 2px 5px rgba(15, 16, 16, 0.9);",
  gradientBtnHoverBoxShadow: "-1px 1px 2px rgba(27, 29, 29, 0.2), 1px -1px 2px rgba(27, 29, 29, 0.2), -1px -1px 2px rgba(47, 49, 49, 0.9), 1px 1px 3px rgba(27, 29, 29, 0.9), inset 1px 1px 2px rgba(47, 49, 49, 0.3), inset -1px -1px 2px rgba(27, 29, 29, 0.5)",
  gradientBtnFocusBoxShadow: "-2px 2px 4px rgba(17, 18, 18, 0.2), 2px -2px 4px rgba(17, 18, 18, 0.2), -2px -2px 4px rgba(57, 60, 60, 0.9), 2px 2px 5px rgba(17, 18, 18, 0.9), inset 1px 1px 2px rgba(57, 60, 60, 0.3), inset -1px -1px 2px rgba(17, 18, 18, 0.5)",
  flatBtnHoverBoxShadow: "-5px 5px 10px rgba(33, 35, 35, 0.2), 5px -5px 10px rgba(33, 35, 35, 0.2), -5px -5px 10px rgba(41, 43, 43, 0.9), 5px 5px 13px rgba(33, 35, 35, 0.9), inset 1px 1px 2px rgba(41, 43, 43, 0.3), inset -1px -1px 2px rgba(33, 35, 35, 0.5)",
  btnFlatBg: "#000000",
  dividerMorphLine: "linear-gradient(308.69deg, rgba(88, 88, 88, 0) 58.44%, #000000 73.03%, rgba(88, 88, 88, 0) 88.67%)",
  dividerMorphBody: "linear-gradient(180deg, rgba(60, 60, 60, 0) 0%, rgba(81, 81, 81, 0.18) 100%)",
  radialGradient: "radial-gradient(69.73% 150% at 50% -50%, #007AFF 0%, rgba(0, 122, 255, 0) 100%)",
  switchBoxShadow: "1px 1px 2px rgba(41, 43, 43, 0.3), -1px -1px 2px rgba(33, 35, 35, 0.5), inset -5px 5px 10px rgba(33, 35, 35, 0.2), inset 5px -5px 10px rgba(33, 35, 35, 0.2), inset -5px -5px 10px rgba(41, 43, 43, 0.9), inset 5px 5px 13px rgba(33, 35, 35, 0.9)",
  switchBorder: "1px solid",
  modalBorder: "8px solid",
  saleButtonPressed: "#737478",
  gradientBlue: 'linear-gradient(180deg, #3480F6 0%, #4CBDFE 100%)',
  btnTextPrimary: '#E1E1E1',
  skeletonBg: 'linear-gradient(127.76deg, #1F2121 2.39%, #1C1E1E 88.89%)',
  skeletonCard: '#181A1A',
  skeletonSecondary: '#2F2F32',
  skeletonPrimary: '#252727',
	inputBorder: '#808080',
  vwLogo: '#ADB0BB',
  dropdownHover: '#2D2D2D',
  priceCardPrimary: '#141515',
  priceCardSecondary: '#F3F3F3',
  tokenPairSkeleton1: '#252726',
  tokenPairSkeleton2: '#333333',
  scrollbarThumb: '#4C4C4D',
}
