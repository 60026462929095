import React from "react"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 64 64" fill="none" {...props}>
      <path d="M19.5707 26.8933L32 14.464L44.4352 26.8987L51.6672 19.6667L32 0L12.3392 19.6608L19.5709 26.8928L19.5707 26.8933ZM0 32L7.23226 24.7667L14.464 31.9985L7.23174 39.2307L0 32ZM19.5707 37.1075L32 49.536L44.4349 37.1016L51.6708 44.3297L51.6672 44.3336L32 64L12.3392 44.3392L12.329 44.329L19.5715 37.1067L19.5707 37.1075ZM49.536 32.0031L56.7683 24.7708L64 32.0026L56.768 39.2348L49.536 32.0031Z" fill="#F3BA2F"/>
      <path d="M39.3349 31.9963H39.338L32 24.6577L26.5761 30.0801L25.953 30.7035L24.6679 31.9888L24.6577 31.9988L24.6679 32.0093L32 39.3425L39.3385 32.004L39.3421 31.9999L39.3354 31.9963" fill="#F3BA2F"/>
    </Svg>
  )
}

export default Icon