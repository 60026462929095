import React, { Suspense, lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { TabIndexContextProvider } from 'contexts/TabIndexContext'
import useEnableWeb3 from 'hooks/useEnableWeb3'
import { ResetCSS } from 'toolkitUI'
import GasPrice from 'components/GasPrice'
import { useMatchBreakpoints } from 'toolkitUI/hooks'
import LoadingSpinner from 'designStandard/components/atoms/LoadingSpinner'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import ZapToastListener from './components/ZapToastListener'
import history from './routerHistory'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const NFTMarketplace = lazy(() => import('./views/NFTMarketplace'))
// const NFTWizard = lazy(() => import('./views/NFTWizard'))
// const NFTAsset = lazy(() => import('./views/NFTAsset'))
const TokenMarketplace = lazy(() => import('./views/TokenMarketplace'))
const NotFound = lazy(() => import('./views/NotFound'))
const Bond = lazy(() => import('./views/Bond'))
const TokenWizard = lazy(() => import('./views/TokenWizard'))
const ProviderWizard = lazy(() => import('./views/ProviderTitle/ProviderWizard'))
const BlockExplorer = lazy(() => import('./views/BlockExplorer'))
// const PriceFeed = lazy(() => import('./views/PriceFeed'))
const PriceDetails = lazy(() => import('./views/PriceFeed/components/PriceDetails/PriceDetails'))
// const MinerEvents = lazy(() => import('./views/MinerEvents'))
const Docs = lazy(() => import('./views/Docs'))
const AllDisputes = lazy(() => import('./views/AllDisputes/AllDisputes'))

// ***** Redesign components ***** //
const CreateNFT = lazy(() => import('./pages/CreateNFT'))
const Asset = lazy(() => import('./pages/Asset'))
const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const RDMarket = lazy(() => import('./pages/RDMarketplace'))
const ProfilePage = lazy(() => import('./pages/Profile'))
const EditProfile = lazy(() => import('./pages/Profile/EditProfile'))
const Collection = lazy(() => import('./pages/NFTCollection'))
const EditCollection = lazy(() => import('./pages/NFTCollection/EditCollectionPage'))
const ActivityPage = lazy(() => import('./pages/ActivityPage'))
const VoteWatcher = lazy(() => import('./pages/VoteWatcher'))
const VoteWatcherRecorder = lazy(() => import('./pages/VoteWatcher/components/Recorder'))
const AdminPortal = lazy(() => import('./pages/VoteWatcher/components/AdminPortal'))
const PriceFeed = lazy(() => import('./pages/PriceFeed'))
const MINER_EVENTS = lazy(() => import('./pages/MinerEvents'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

// const PrivateRoute: React.FC = () => {
//   const user = useSelector<AppState, AppState['user']>((state) => state.user)
//   console.log('user', user)
//   return user.isAdmin ? <Admin /> : <Redirect to="/nftmarket" />
// }

const App: React.FC = () => {
  const { isSm, isXs } = useMatchBreakpoints()
  const isMobile = isXs || isSm

  useEnableWeb3()

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router history={history}>
        <ResetCSS />
        <GlobalStyle />
        <Menu>
          <Switch>
            <Route path="/nft-marketplace" exact>
              <RDMarket />
            </Route>
            <Route path="/nft-marketplace/:category" exact>
              <RDMarket />
            </Route>
            <Route path="/nft" exact>
              <Home />
            </Route>
            <Route path="/nft/about" exact>
              <About />
            </Route>
            <Route path="/nft/asset/:key" exact>
              <Asset />
            </Route>
            <Route path="/nft/editcollection/:key" exact>
              <TabIndexContextProvider>
                <EditCollection />
              </TabIndexContextProvider>
            </Route>
            <Route path="/nft/activitypage" exact>
              <ActivityPage />
            </Route>
            <Route path="/nft/collection/:key" exact>
              <Collection />
            </Route>
            <Route path="/nft/profile" exact>
              <ProfilePage />
            </Route>
            <Route path="/nft/profile/:key" exact>
              <ProfilePage />
            </Route>
            <Route path="/nft/editprofile" exact>
              <TabIndexContextProvider>
                <EditProfile />
              </TabIndexContextProvider>
            </Route>
            <Route path="/nft/create" exact>
              <CreateNFT />
            </Route>
            <Route path="/alldisputes" exact>
              <AllDisputes />
            </Route>
            <Route path="/labs/bond/:key" exact>
              <Bond />
            </Route>
            <Route path="/labs/curvetokens" exact>
              <TokenMarketplace />
            </Route>
            <Route path="/labs/newtoken" exact>
              <TokenWizard />
            </Route>
            <Route path="/labs/providerwizard" exact>
              <ProviderWizard />
            </Route>
            <Route path="/labs/blockexplorer">
              <BlockExplorer />
            </Route>
            <Route path="/labs" exact>
              <Redirect to="/labs/curvetokens" />
            </Route>
            <Route path="/pricefeed" exact>
              <PriceFeed />
            </Route>
            <Route path="/pricefeed/:id" exact>
              <PriceDetails />
            </Route>
            <Route path="/miningevents" exact>
              <MINER_EVENTS />
            </Route>
            <Route path="/docs/:key">
              <Docs />
            </Route>
            <Route path="/docs">
              <Redirect to="/docs/overview" />
            </Route>
            <Route path="/votewatcher" exact>
              <VoteWatcher />
            </Route>
            <Route path="/votewatcher/recorder" exact>
              <VoteWatcherRecorder />
            </Route>
            <Route path="/votewatcher/admin" exact>
              <AdminPortal />
            </Route>
            <Route path="/" exact>
              <Redirect to="/pricefeed" />
            </Route>

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Menu>
        {/* <ToastListener /> */}
        <ZapToastListener />
        {!isMobile && <GasPrice />}
      </Router>
    </Suspense>
  )
}

export default React.memo(App)
