// import Web3 from 'web3'
import { commify } from 'ethers/lib/utils'
import ZapPriceFeed from '../entities/zapPriceFeed'

export default async function useZapPriceFeedList(): Promise<ZapPriceFeed[]> {

  const timestampToString = (time: string) => {
    if (time == null) return ''
    const timestampRaw = new Date(Number(time) * 1000).toISOString()
    const toShow = timestampRaw.replace('T', ' ').substring(0, timestampRaw.length - 5)
    const displayTimestamp = time ? toShow.toLowerCase() : '-'
    return displayTimestamp
  }

  const getPricesAggregated = (): Promise<ZapPriceFeed[]> => {
    try {
      return new Promise((resolve) => {
        // /requestinfo/__id__  forex locol host 1.7.0.0.1
        fetch(process.env.REACT_APP_SCAN_URL.concat('/testnet/prices'))
          .then((response) => response.json())
          .then((data) => {
            const tokenRows = []
            const updatedData = data
            // console.log("LENSE", updatedData)
            updatedData?.forEach((tokenPair, i: number) => {
              const blockNum = {
                block: parseInt(tokenPair.blockNumber),
              }
              const currentItem = {
                id: tokenPair.id,
                block: blockNum,
                rank: `${i + 1}`,
                requestId: tokenPair.id,
                symbol: tokenPair.name,
                timestamp: timestampToString(tokenPair.timestamp),
                tip: parseInt(tokenPair.tip),
                value: commify(tokenPair.value.toFixed(6)),
                gdp: tokenPair.countryGDP,
              }
              tokenRows.push(currentItem)
            })
            resolve(tokenRows)
          })
      })
    } catch (e) {
      // console.error('error', e)
      return null
    }
  }

  const retVal: ZapPriceFeed[] = []
  const priceFeedItems = await getPricesAggregated()

  priceFeedItems.forEach((e) => {
    const priceFeedItem = new ZapPriceFeed()
    priceFeedItem.id = e.id
    priceFeedItem.block = e.block
    priceFeedItem.rank = e.rank
    priceFeedItem.requestId = e.requestId
    priceFeedItem.symbol = e.symbol
    priceFeedItem.timestamp = e.timestamp
    priceFeedItem.tip = e.tip
    priceFeedItem.value = e.value
    priceFeedItem.gdp = e.gdp

    retVal.push(priceFeedItem)
  })
  return retVal
}
