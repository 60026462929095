import React, { cloneElement, ElementType, isValidElement, ReactElement } from "react";
import StyledButton from "./StyledButton";
import { ButtonProps, scales, variants } from "./types";

const Button = <E extends ElementType = "button">(props: ButtonProps<E>): JSX.Element => {
  const {  startIcon, endIcon, className, isLoading, disabled, children, ...rest } = props;
  const isDisabled = isLoading || disabled;
  const classNames = className ? [className] : [];

  if (isLoading) {
    classNames.push("zap-button--loading");
  }

  if (isDisabled && !isLoading) {
    classNames.push("zap-button--disabled");
  }

  return (
    <StyledButton
      $isLoading={isLoading}
      className={classNames.join(" ")}
      disabled={isDisabled}
      {...rest}
    >
			<>
			{isValidElement(startIcon) &&
          cloneElement(startIcon as ReactElement<any>, {
            mr: "0.5rem",
          })}
      {children}
			{isValidElement(endIcon) &&
          cloneElement(endIcon as ReactElement<any>, {
            ml: "0.5rem",
          })}
			</>
    </StyledButton>
  );
};

Button.defaultProps = {
  isLoading: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
};

export default Button;
