import React, { FC, createContext, useState } from 'react';

interface SidebarContextProps {
  isSidebar: boolean;
  toggleSidebar: (status: boolean) => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  isSidebar: false,
  toggleSidebar: () => null,
});

const SidebarProvider: FC = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(false);

  const toggleSidebar = (status: boolean) => {
    setIsSidebar(status);
  };

  return (
    <SidebarContext.Provider value={{ isSidebar, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
