import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = (props) => {
  const theme = useTheme()

  return (
    <Svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path d="M1.44018 9.5C1.20127 9.5 0.962357 9.41198 0.773743 9.22337C0.598358 9.0459 0.5 8.80644 0.5 8.55693C0.5 8.30742 0.598358 8.06797 0.773743 7.8905L7.89075 0.773489C8.25541 0.408837 8.85897 0.408837 9.22362 0.773489C9.58827 1.13814 9.58827 1.7417 9.22362 2.10636L2.10661 9.22337C1.93057 9.41198 1.67909 9.5 1.44018 9.5Z" fill={theme.colors.gradIconColor1}/>
      <path d="M8.55719 9.5C8.31828 9.5 8.07937 9.41198 7.89075 9.22337L0.773743 2.10636C0.598358 1.92889 0.5 1.68943 0.5 1.43992C0.5 1.19041 0.598358 0.950959 0.773743 0.773489C1.1384 0.408837 1.74196 0.408837 2.10661 0.773489L9.22362 7.8905C9.58827 8.25515 9.58827 8.85871 9.22362 9.22337C9.03501 9.41198 8.7961 9.5 8.55719 9.5Z" fill={theme.colors.gradIconColor1}/>
    </Svg>
  )
}

export default Icon