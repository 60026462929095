import React from 'react'
import { useTheme } from 'styled-components';
import Svg from '../Svg'
import { SvgProps } from '../types'

const SideMenu: React.FC<SvgProps> = (props) => {
  // const {color} = props;
  const theme = useTheme();
  
  return (
  <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.0533 7.56C19.9232 7.56 20.7575 7.21443 21.3726 6.59931C21.9878 5.98419 22.3333 5.14991 22.3333 4.28C22.3333 3.41009 21.9878 2.57581 21.3726 1.96069C20.7575 1.34557 19.9232 1 19.0533 1C18.1834 1 17.3491 1.34557 16.734 1.96069C16.1189 2.57581 15.7733 3.41009 15.7733 4.28C15.7733 5.14991 16.1189 5.98419 16.734 6.59931C17.3491 7.21443 18.1834 7.56 19.0533 7.56V7.56ZM4.28 7.56C5.14991 7.56 5.98419 7.21443 6.59931 6.59931C7.21443 5.98419 7.56 5.14991 7.56 4.28C7.56 3.41009 7.21443 2.57581 6.59931 1.96069C5.98419 1.34557 5.14991 1 4.28 1C3.41009 1 2.57581 1.34557 1.96069 1.96069C1.34557 2.57581 1 3.41009 1 4.28C1 5.14991 1.34557 5.98419 1.96069 6.59931C2.57581 7.21443 3.41009 7.56 4.28 7.56V7.56ZM19.0533 23.96C19.4841 23.96 19.9106 23.8752 20.3085 23.7103C20.7065 23.5455 21.0681 23.3039 21.3726 22.9993C21.6772 22.6947 21.9188 22.3332 22.0837 21.9352C22.2485 21.5373 22.3333 21.1107 22.3333 20.68C22.3333 20.2493 22.2485 19.8227 22.0837 19.4248C21.9188 19.0269 21.6772 18.6653 21.3726 18.3607C21.0681 18.0561 20.7065 17.8145 20.3085 17.6497C19.9106 17.4848 19.4841 17.4 19.0533 17.4C18.1834 17.4 17.3491 17.7456 16.734 18.3607C16.1189 18.9758 15.7733 19.8101 15.7733 20.68C15.7733 21.5499 16.1189 22.3842 16.734 22.9993C17.3491 23.6144 18.1834 23.96 19.0533 23.96V23.96ZM4.28 23.96C4.71074 23.96 5.13725 23.8752 5.5352 23.7103C5.93315 23.5455 6.29473 23.3039 6.59931 22.9993C6.90389 22.6947 7.14549 22.3332 7.31032 21.9352C7.47516 21.5373 7.56 21.1107 7.56 20.68C7.56 20.2493 7.47516 19.8227 7.31032 19.4248C7.14549 19.0269 6.90389 18.6653 6.59931 18.3607C6.29473 18.0561 5.93315 17.8145 5.5352 17.6497C5.13725 17.4848 4.71074 17.4 4.28 17.4C3.41009 17.4 2.57581 17.7456 1.96069 18.3607C1.34557 18.9758 1 19.8101 1 20.68C1 21.5499 1.34557 22.3842 1.96069 22.9993C2.57581 23.6144 3.41009 23.96 4.28 23.96V23.96Z" stroke={theme.colors.gradIconColor1} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
  </Svg>
  )
  }

export default SideMenu