/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Toast } from 'designStandard/components/molecules'

import { ToastsState } from 'state/types'

const initialState: ToastsState = {
  data: [],
}

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
    
  reducers: {
    /**
     * @load action configures any available list of predefined default Toasts.
     * Each of these toasts are customizable to some extent. Refer to hooks/useZapToast for details on new toast variants.
     */
    // load: (state: ToastsState, action: PayloadAction<Toast[]>) => { 
    //   const { payload } = action
    //   // clear the state of Toasts bundle before reconfigure if set previously
    //   const previousLength = state.data.length
    //     state.data.splice(0, previousLength)
    //     state.data.push(...payload)
    // },
    clear: (state: ToastsState) => {
      state.data = []
    },
    push: (state: ToastsState, action: PayloadAction<Toast>) => {
    const { payload } = action
    const toastIndex = state.data.findIndex((toast) => toast.id === action.payload.id)

    // If id already matches remove it before adding it to the top of the stack
    if (toastIndex >= 0) {
      state.data.splice(toastIndex, 1)
    }

    state.data.unshift(payload)
  },
    scan: (state: ToastsState, action: PayloadAction<Toast>) => {
      /**
       * @param {action: string} id - selected toast's id, in the format of ${Date}-${scan URL}.
       * ex. `${Date.now()}-${"https://etherscan.io/tx/0x123abc567def890hij1z1"}`
       * Opens a new window to where the url redirect to (typically after completion of a transaction)
       */
    const { payload } = action
    const toastIndex = state.data.findIndex((toast) => toast.id === payload.id)
    // let selectedToast
    if (toastIndex >= 0) {
      // selectedToast = state.data[toastIndex]
      const scanURL = payload.id.split('-')[1]
      if (window && scanURL?.includes('https://')) {
        window.open(scanURL)
      }
      // remove toast item
      state.data.splice(toastIndex, 1)
    }
      // update toast indices
      state.data.unshift(payload)
  },
  remove: (state: ToastsState, action: PayloadAction<string>) => {
    const toastIndex = state.data.findIndex((toast) => toast.id === action.payload)

    if (toastIndex >= 0) {
      state.data.splice(toastIndex, 1)
    }
  },  
}

})

// Actions
export const { clear, push, remove, scan } = toastsSlice.actions

export default toastsSlice.reducer
