import Web3 from 'web3'
// import { useWeb3React } from '@web3-react/core'
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { getBondageContract, getRegistryContract, getTokenDotFactoryContract, getErc20 } from 'utils/contractHelpers'
// import { getAllOracles as getOraclesFromRedis } from 'hooks/useRedis'
import { mainTokenClient, kovanTokenClient, chapelTokenClient } from 'graph/index'
import { TOKENS, USERBOUND } from 'graph/queries'
import ZapLabToken from '../entities/zapLabToken'

function removeNullChars(s: string): string {
  return s.replace(/\u0000/g, '')
}

function truncateValue(v: string): string {
  const numArr = v.split('.')
  if (numArr[0] === '0') {
    const deciAdd = `.${numArr[1]}`
    const numz = Number(deciAdd)
    if (numz < 0.0001) {
      return '< 0.0001'
    }
  }
  if (numArr[1]) {
    numArr[1] = `.${numArr[1].slice(0, 4)}`
  }
  numArr[0] = numArr[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
  const numString = numArr.join('')
  return numString
}

export async function getZapLabTokens(web3: Web3, chainId, account): Promise<ZapLabToken[]> {
  // const chainId = await web3.eth.getChainId();
  const client = chainId === 42 ? kovanTokenClient : chainId === 97 ? chapelTokenClient : mainTokenClient
  let lowercaseAccount
  const boundTokens = {}
  const tdfsOwned = new Set()

  const retVal: ZapLabToken[] = []

  const tokensG = await client.request(TOKENS)
  if (account) {
    lowercaseAccount = account.toLowerCase()
    const userBound = await client.request(USERBOUND(lowercaseAccount))
    if (userBound.user) {
      // console.log(userBound.user)
      if (userBound.user.userBound) {
        userBound.user.userBound.forEach(boundToken => {
          boundTokens[boundToken.endpoint.tokenAdd] = boundToken.bounded
        })
      }
      if (userBound.user.tdfsOwned) {
        userBound.user.tdfsOwned.forEach((tdfOwned) => {
          tdfsOwned.add(tdfOwned.id)
        })
      }
    }
  }

  for (let i = 0; i < tokensG.endpoints.length; i++) {
    const t = tokensG.endpoints[i]
    const tok = new ZapLabToken()
    try {
      tok.providerTitle = ethers.utils.parseBytes32String(t.provider.title) 
    } catch (error) {
      tok.providerTitle = "Error getting title"
    }
    tok.providerAddress = t.provider.id
    tok.providerParams = t.provider.provider_params
    tok.brokerAddress = t.broker
    tok.name = t.endpointStr
    tok.curve = t.curve
    tok.tokenAddress = t.tokenAdd
    tok.userBound = boundTokens[t.tokenAdd] || 0
    // tok.userBound = '-'
    tok.totalBound = t.dotsIssued
    tok.dotLimit = t.dotLimit
    tok.spotPrice = truncateValue(Web3.utils.fromWei(t.spotPrice)) // String((Number(t.spotPrice) * (10 ** -18)));
    tok.markdown = ''
    tok.json = ''
    tok.zapBound = t.zapBound
    tok.isToken = t.isToken
    tok.symbol = t.symbol
    tok.isTDFOwner = tdfsOwned.has(t.provider.id)

    tok.timeCreated = t.timestamp
    retVal.push(tok)
  }

  if (retVal.length === 0) {
    try {
      const registry = getRegistryContract(web3, chainId)
      const bondage = getBondageContract(web3, chainId)

      const allOracles = await registry.methods.getAllOracles().call()

      for (let i = 0; i < allOracles.length; i++) {
        const provider = allOracles[i]
        const ofProvider = await getAllOfProviderJustNames(provider, registry, bondage, web3)
        retVal.push(...ofProvider)
      }
    } catch (e) {
      console.error('ISSUE WITH GETTING ALL ORACLES FROM BLOCKCHAIN \n', e)
    }
  }

  // for (let i = 0; i < retVal.length; i++) {
  //   const curr = retVal[i]
  //   curr.timeCreated = await getCreationTime(curr.providerAddress, curr.name, curr.curve, curr.providerAddress, web3)
  // }

  return retVal
}

export async function getAllOfProviderJustNames(
  provider: string,
  registry: Contract,
  bondage: Contract,
  web3: Web3,
): Promise<ZapLabToken[]> {
  const retVal: ZapLabToken[] = []
  try {
    const endpoints = await registry.methods.getProviderEndpoints(provider).call()

    for (let i = 0; i < endpoints.length; i++) {
      const endpoint = removeNullChars(Web3.utils.hexToAscii(endpoints[i]))

      // Use the following line to do a full pull of all the tokens and their data
      const toAdd = await getSingleLabToken(provider, endpoint, registry, bondage, web3)

      // Use the following 4 lines to do a partial pull of all the tokens and their data
      // const toAdd = new ZapLabToken()
      // toAdd.providerTitle = title
      // toAdd.providerAddress = provider
      // toAdd.name = endpoint

      retVal.push(toAdd)
    }
  } catch (e) {
    console.error(`ISSUE WITH PROVIDER ${provider} \n`, e)
  }
  return retVal
}
// Updates a Single Token
export async function getSingleLabToken(
  provider: string,
  endpoint: string,
  registry: Contract,
  bondage: Contract,
  web3: Web3,
): Promise<ZapLabToken> {
  const retVal: ZapLabToken = new ZapLabToken()
  retVal.name = endpoint
  retVal.providerAddress = provider

  try {
    const account = (await web3.eth.getAccounts())[0]

    const name = Web3.utils.asciiToHex(endpoint)

    const title = await registry.methods.getTitle(provider).call()
    const params = await registry.methods.getAllProviderParams(provider).call()
    const paramLinks = []
    for (let i = 1; i < params.length; i++) {
      const current = await registry.methods.getProviderParameter(provider, params[i]).call()
      paramLinks.push(current)
    }

    const curve = await registry.methods.getProviderCurve(provider, name).call()
    const broker = await registry.methods.getEndpointBroker(provider, name).call()

    const dotsIssued = await bondage.methods.getDotsIssued(provider, name).call()
    const dotLimit = await bondage.methods.dotLimit(provider, name).call()
    const userBound = await bondage.methods.getBoundDots(account, provider, name).call()

    const cost = await bondage.methods.calcZapForDots(provider, name, 1).call()
    const zapBound = await bondage.methods.getZapBound(provider, name).call()

    const tokenCheck = await checkIsToken(provider, name, web3)

    retVal.providerTitle = removeNullChars(Web3.utils.hexToAscii(title))
    retVal.curve = curve
    retVal.providerParams = {}
    for (let i = 1; i < params.length; i++) {
      try {
        const k = removeNullChars(Web3.utils.hexToAscii(params[i]))
        retVal.providerParams[k] = Web3.utils.isHex(paramLinks[i]) ? Web3.utils.hexToAscii(paramLinks[i]) : ''
      } catch (e) {
        console.error(e)
      }
    }
    retVal.brokerAddress = broker
    retVal.totalBound = dotsIssued
    retVal.dotLimit = dotLimit
    retVal.spotPrice = truncateValue(Web3.utils.fromWei(cost))
    retVal.markdown = ''
    retVal.json = ''
    retVal.userBound = tokenCheck.isToken ? tokenCheck.balance : userBound
    retVal.zapBound = zapBound
    retVal.isToken = tokenCheck.isToken
    retVal.symbol = tokenCheck.symbol
    retVal.tokenAddress = tokenCheck.tokenAdd
    retVal.isTDFOwner = tokenCheck.isTDFOwner
    retVal.timeCreated = await getCreationTime(retVal.providerAddress, retVal.name, retVal.curve, retVal.brokerAddress, web3)
  } catch (e) {
    console.error(`ISSUE WITH ${endpoint} BY ${provider} \n`, e)
  }
  return retVal
}
// Check if endpoint is a token
const checkIsToken = async (tdfAddress: string, tokenAsBytes32: string, web3: Web3) => {
  const retVal = {
    isToken: false,
    symbol: '',
    tokenAdd: '',
    balance: 0,
    isTDFOwner: false,
  }

  const wallet = (await web3.eth.getAccounts())[0]

  try {
    // Connection to the released Token Dot Facotry
    const tokenDotFactory = getTokenDotFactoryContract(tdfAddress, web3)

    const tokenAdd = await tokenDotFactory.methods.curves(tokenAsBytes32).call() // Returns an address

    const isToken = Web3.utils.isAddress(tokenAdd)

    if (!isToken) {
      return retVal
    }

    retVal.isToken = true
    retVal.tokenAdd = tokenAdd
    retVal.isTDFOwner = wallet === (await tokenDotFactory.methods.owner().call())
  } catch (e) {
    console.error('Probably not a token \n', e)
    return retVal
  }

  try {
    const token = getErc20(retVal.tokenAdd, web3)
    const symbol = await token.methods.symbol().call()
    retVal.symbol = removeNullChars(symbol)
    retVal.balance = await token.methods.balanceOf(wallet).call()
  } catch (e) {
    return retVal
  }
  // console.log('IS A TOKEN!')
  return retVal
}

const getCreationTime = async (
  provider: string,
  endpoint: string,
  curve: number[] | string[],
  broker: string,
  web3: Web3,
): Promise<string> => {
  let retVal = '0'
  try {
    const chainId = await web3.eth.getChainId()
    const registry = getRegistryContract(web3, chainId)
    const events = await registry.getPastEvents('NewCurve', {
      filter: {
        provider,
        endpoint: Web3.utils.asciiToHex(endpoint),
        curve,
        broker,
      },
      fromBlock: 0,
      toBlock: 'latest',
    })
    if (!(events.length > 0)) {
      return retVal
    }
    const block = await web3.eth.getBlock(events[0].blockNumber)
    retVal = typeof block.timestamp === 'string' ? block.timestamp : block.timestamp.toString()
  } catch (e) {
    console.error('Issue getting curve creation timestamp. \n', e)
  }
  // console.log('TIME', retVal)
  return retVal
}
