import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const Trade: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="m7.33 14.49 2.38-3.09c.34-.44.97-.52 1.41-.18l1.83 1.44c.44.34 1.07.26 1.41-.17l2.31-2.98" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Trade