import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const New: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.9934 2.86973L16.5106 7.52933C16.6459 7.95077 16.9115 8.31836 17.2691 8.57913C17.6267 8.83989 18.0579 8.98036 18.5005 8.98027H23.4005C23.8422 8.98139 24.2723 9.12189 24.6295 9.38179C24.9866 9.64169 25.2526 10.0077 25.3895 10.4277C25.5264 10.8476 25.5273 11.3001 25.392 11.7205C25.2566 12.141 24.992 12.508 24.6359 12.7693L20.6728 15.6546C20.3137 15.9127 20.0462 16.2785 19.909 16.6989C19.7719 17.1193 19.7722 17.5725 19.91 17.9927L21.4272 22.6522C21.5638 23.0732 21.5637 23.5266 21.4269 23.9475C21.2902 24.3684 21.0238 24.7353 20.6659 24.9956C20.3081 25.256 19.877 25.3965 19.4345 25.397C18.9919 25.3975 18.5605 25.2581 18.202 24.9986L14.2389 22.1133C13.8775 21.8504 13.4421 21.7088 12.9952 21.7088C12.5483 21.7088 12.1129 21.8504 11.7516 22.1133L7.80502 25.0318C7.44651 25.2913 7.01513 25.4307 6.57257 25.4302C6.13001 25.4296 5.69895 25.2891 5.34108 25.0288C4.98321 24.7684 4.71685 24.4015 4.58011 23.9806C4.44337 23.5597 4.44325 23.1064 4.57978 22.6854L6.09706 18.0258C6.23483 17.6056 6.23515 17.1524 6.098 16.732C5.96085 16.3116 5.69333 15.9459 5.33427 15.6877L1.39601 12.7693C1.03985 12.508 0.775254 12.141 0.63993 11.7205C0.504607 11.3001 0.505465 10.8476 0.642385 10.4277C0.779305 10.0077 1.04529 9.64169 1.40244 9.38179C1.75959 9.12189 2.18967 8.98139 2.63138 8.98027H7.5314C7.974 8.98036 8.40519 8.83989 8.76281 8.57913C9.12044 8.31836 9.38601 7.95077 9.52125 7.52933L11.0385 2.86973C11.1793 2.45709 11.4457 2.09885 11.8003 1.84516C12.1549 1.59148 12.58 1.45508 13.016 1.45508C13.452 1.45508 13.877 1.59148 14.2316 1.84516C14.5862 2.09885 14.8526 2.45709 14.9934 2.86973Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M21.1045 5.07483L19.7199 9.77588C19.5954 10.2008 19.6085 10.6543 19.7575 11.0713C19.9064 11.4883 20.1835 11.8475 20.549 12.0974L24.5951 14.8666C24.9584 15.1177 25.2331 15.4771 25.3802 15.8935C25.5273 16.3099 25.5392 16.7621 25.4143 17.1857C25.2893 17.6093 25.0339 17.9826 24.6843 18.2525C24.3347 18.5224 23.9089 18.6751 23.4675 18.6888L18.5675 18.8214C18.125 18.8352 17.6983 18.9886 17.3483 19.2596C16.9983 19.5307 16.7431 19.9055 16.619 20.3304L15.2179 25.0314C15.0957 25.4575 14.8411 25.8337 14.491 26.1055C14.1409 26.3774 13.7135 26.5309 13.2704 26.5438C12.8274 26.5567 12.3917 26.4284 12.0264 26.1774C11.661 25.9264 11.385 25.5657 11.2382 25.1475L9.57994 20.5294C9.43039 20.1134 9.15372 19.755 8.78914 19.505C8.42456 19.255 7.99058 19.1261 7.54864 19.1365L2.64862 19.2691C2.20685 19.2799 1.77297 19.1508 1.40888 18.9004C1.04479 18.65 0.769102 18.291 0.621117 17.8746C0.473133 17.4582 0.46043 17.0057 0.584809 16.5817C0.709187 16.1577 0.964289 15.7838 1.31374 15.5133L5.19396 12.5285C5.54486 12.2587 5.80182 11.8852 5.92833 11.461C6.05484 11.0368 6.04448 10.5835 5.8987 10.1656L4.24049 5.54743C4.09422 5.13075 4.08318 4.67854 4.20896 4.25522C4.33474 3.8319 4.59092 3.45905 4.94098 3.18984C5.29105 2.92063 5.71716 2.76878 6.15858 2.7559C6.60001 2.74303 7.03422 2.8698 7.39938 3.11815L11.4371 5.88737C11.804 6.14445 12.2439 6.27677 12.6917 6.26483C13.1396 6.25289 13.5718 6.0973 13.9245 5.82103L17.8047 2.82796C18.155 2.55579 18.5825 2.40167 19.0259 2.38775C19.4693 2.37383 19.9056 2.50082 20.2723 2.75049C20.639 3.00015 20.9171 3.35962 21.0666 3.77726C21.2161 4.19489 21.2294 4.64918 21.1045 5.07483V5.07483Z" fill="none" stroke={color ?? theme.colors.gradIconColor2}  strokeMiterlimit="10"/>
      <mask id="path-3-inside-1_1678_36778" fill="white">
      <path d="M10.6573 11.9067V14.9247H10.1681L8.75034 12.8685V14.9247H8.20312V11.9067H8.6923L10.1101 13.9629V11.9067H10.6573Z"/>
      </mask>
      <path d="M10.6573 11.9067V14.9247H10.1681L8.75034 12.8685V14.9247H8.20312V11.9067H8.6923L10.1101 13.9629V11.9067H10.6573Z" fill="#F5F5F9"/>
      <path d="M10.6573 11.9067H11.6573V10.9067H10.6573V11.9067ZM10.6573 14.9247V15.9247H11.6573V14.9247H10.6573ZM10.1681 14.9247L9.34484 15.4923L9.64294 15.9247H10.1681V14.9247ZM8.75034 12.8685L9.57361 12.3008L7.75034 9.65653V12.8685H8.75034ZM8.75034 14.9247V15.9247H9.75034V14.9247H8.75034ZM8.20312 14.9247H7.20312V15.9247H8.20312V14.9247ZM8.20312 11.9067V10.9067H7.20312V11.9067H8.20312ZM8.6923 11.9067L9.51556 11.3391L9.21745 10.9067H8.6923V11.9067ZM10.1101 13.9629L9.28683 14.5306L11.1101 17.1748V13.9629H10.1101ZM10.1101 11.9067V10.9067H9.11009V11.9067H10.1101ZM9.6573 11.9067V14.9247H11.6573V11.9067H9.6573ZM10.6573 13.9247H10.1681V15.9247H10.6573V13.9247ZM10.9914 14.357L9.57361 12.3008L7.92707 13.4361L9.34484 15.4923L10.9914 14.357ZM7.75034 12.8685V14.9247H9.75034V12.8685H7.75034ZM8.75034 13.9247H8.20312V15.9247H8.75034V13.9247ZM9.20312 14.9247V11.9067H7.20312V14.9247H9.20312ZM8.20312 12.9067H8.6923V10.9067H8.20312V12.9067ZM7.86903 12.4744L9.28683 14.5306L10.9333 13.3952L9.51556 11.3391L7.86903 12.4744ZM11.1101 13.9629V11.9067H9.11009V13.9629H11.1101ZM10.1101 12.9067H10.6573V10.9067H10.1101V12.9067Z" stroke={color ?? theme.colors.gradIconColor2}  mask="url(#path-3-inside-1_1678_36778)"/>
      <mask id="path-5-inside-2_1678_36778" fill="white">
      <path d="M11.5625 14.9247V11.9067H13.5275V12.3627H12.1097V13.1918H13.4197V13.6478H12.1097V14.4769H13.5358V14.933L11.5625 14.9247Z"/>
      </mask>
      <path d="M11.5625 14.9247V11.9067H13.5275V12.3627H12.1097V13.1918H13.4197V13.6478H12.1097V14.4769H13.5358V14.933L11.5625 14.9247Z" fill="#F5F5F9"/>
      <path d="M11.5625 14.9247H10.5625V15.9205L11.5583 15.9247L11.5625 14.9247ZM11.5625 11.9067V10.9067H10.5625V11.9067H11.5625ZM13.5275 11.9067H14.5275V10.9067H13.5275V11.9067ZM13.5275 12.3627V13.3627H14.5275V12.3627H13.5275ZM12.1097 12.3627V11.3627H11.1097V12.3627H12.1097ZM12.1097 13.1918H11.1097V14.1918H12.1097V13.1918ZM13.4197 13.1918H14.4197V12.1918H13.4197V13.1918ZM13.4197 13.6478V14.6478H14.4197V13.6478H13.4197ZM12.1097 13.6478V12.6478H11.1097V13.6478H12.1097ZM12.1097 14.4769H11.1097V15.4769H12.1097V14.4769ZM13.5358 14.4769H14.5358V13.4769H13.5358V14.4769ZM13.5358 14.933L13.5316 15.933L14.5358 15.9372V14.933H13.5358ZM12.5625 14.9247V11.9067H10.5625V14.9247H12.5625ZM11.5625 12.9067H13.5275V10.9067H11.5625V12.9067ZM12.5275 11.9067V12.3627H14.5275V11.9067H12.5275ZM13.5275 11.3627H12.1097V13.3627H13.5275V11.3627ZM11.1097 12.3627V13.1918H13.1097V12.3627H11.1097ZM12.1097 14.1918H13.4197V12.1918H12.1097V14.1918ZM12.4197 13.1918V13.6478H14.4197V13.1918H12.4197ZM13.4197 12.6478H12.1097V14.6478H13.4197V12.6478ZM11.1097 13.6478V14.4769H13.1097V13.6478H11.1097ZM12.1097 15.4769H13.5358V13.4769H12.1097V15.4769ZM12.5358 14.4769V14.933H14.5358V14.4769H12.5358ZM13.54 13.933L11.5667 13.9247L11.5583 15.9247L13.5316 15.933L13.54 13.933Z" stroke={color ?? theme.colors.gradIconColor2} mask="url(#path-5-inside-2_1678_36778)"/>
      <mask id="path-7-inside-3_1678_36778" fill="white">
      <path d="M15.0244 14.9247L14.1953 11.9067H14.784L15.3312 14.1204L15.9116 11.9067H16.4422L17.0226 14.1287L17.5615 11.9067H18.1501L17.321 14.9247H16.7821L16.1852 12.8105L15.5799 14.9247H15.0244Z"/>
      </mask>
      <path d="M15.0244 14.9247L14.1953 11.9067H14.784L15.3312 14.1204L15.9116 11.9067H16.4422L17.0226 14.1287L17.5615 11.9067H18.1501L17.321 14.9247H16.7821L16.1852 12.8105L15.5799 14.9247H15.0244Z" fill="#F5F5F9"/>
      <path d="M15.0244 14.9247L14.0601 15.1896L14.2621 15.9247H15.0244V14.9247ZM14.1953 11.9067V10.9067H12.8835L13.231 12.1716L14.1953 11.9067ZM14.784 11.9067L15.7548 11.6668L15.5669 10.9067H14.784V11.9067ZM15.3312 14.1204L14.3604 14.3604L16.2985 14.374L15.3312 14.1204ZM15.9116 11.9067V10.9067H15.14L14.9443 11.6531L15.9116 11.9067ZM16.4422 11.9067L17.4097 11.654L17.2145 10.9067H16.4422V11.9067ZM17.0226 14.1287L16.055 14.3815L17.9944 14.3644L17.0226 14.1287ZM17.5615 11.9067V10.9067H16.775L16.5897 11.671L17.5615 11.9067ZM18.1501 11.9067L19.1144 12.1716L19.4619 10.9067H18.1501V11.9067ZM17.321 14.9247V15.9247H18.0834L18.2853 15.1896L17.321 14.9247ZM16.7821 14.9247L15.8197 15.1964L16.0254 15.9247H16.7821V14.9247ZM16.1852 12.8105L17.1475 12.5387L16.1918 9.15386L15.2238 12.5352L16.1852 12.8105ZM15.5799 14.9247V15.9247H16.3338L16.5413 15.1999L15.5799 14.9247ZM15.9887 14.6598L15.1596 11.6418L13.231 12.1716L14.0601 15.1896L15.9887 14.6598ZM14.1953 12.9067H14.784V10.9067H14.1953V12.9067ZM13.8132 12.1467L14.3604 14.3604L16.302 13.8805L15.7548 11.6668L13.8132 12.1467ZM16.2985 14.374L16.8789 12.1603L14.9443 11.6531L14.3639 13.8668L16.2985 14.374ZM15.9116 12.9067H16.4422V10.9067H15.9116V12.9067ZM15.4746 12.1595L16.055 14.3815L17.9901 13.876L17.4097 11.654L15.4746 12.1595ZM17.9944 14.3644L18.5333 12.1424L16.5897 11.671L16.0507 13.893L17.9944 14.3644ZM17.5615 12.9067H18.1501V10.9067H17.5615V12.9067ZM17.1859 11.6418L16.3568 14.6598L18.2853 15.1896L19.1144 12.1716L17.1859 11.6418ZM17.321 13.9247H16.7821V15.9247H17.321V13.9247ZM17.7445 14.6529L17.1475 12.5387L15.2228 13.0822L15.8197 15.1964L17.7445 14.6529ZM15.2238 12.5352L14.6185 14.6495L16.5413 15.1999L17.1465 13.0857L15.2238 12.5352ZM15.5799 13.9247H15.0244V15.9247H15.5799V13.9247Z" stroke={color ?? theme.colors.gradIconColor2}  mask="url(#path-7-inside-3_1678_36778)"/>
    </Svg>
  )
}

export default New
