import React, { FC, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'designStandard/components/atoms/Text'
import { GenericModal } from 'designStandard/components/organisms/GenericModal'
import { CloseIcon } from 'designStandard/components/atoms/Svg'
import WalletCard from './WalletCard'
import wallets from './config'

const Modal = styled(GenericModal)`
  height: auto;
  width: calc(100vw - 20px);
  max-width: 430px;
  margin: 0 auto;

  @media (min-width: 390px) {
    width: 368px;
  }

  @media (min-width: 450px) {
    width: 430px;
  }
`

const ModalHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  margin: 0 22px;
`

const CloseWrapper = styled.div`
  cursor: pointer;
`

const Divider = styled.div`
  height: 9px;
  margin: 16px 0 0;
  background: ${({ theme }) => theme.colors.dividerMorphBody};
`

const CardWrapper = styled.div`
  display: block;
`

interface ConnectWalletProps {
  isOpened?: boolean
  onClose?: () => void
}

const ConnectWallet: FC<ConnectWalletProps> = ({ isOpened, onClose }) => {
  const closeHandler = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal isOpened={isOpened}>
      <ModalHeader>
        <Text font='walletPrimaryBold'>Connect a wallet</Text>
        <CloseWrapper onClick={closeHandler}>
          <CloseIcon />
        </CloseWrapper>
      </ModalHeader>
      <Divider />
      <CardWrapper>
        {wallets.map((wallet) => (
          <WalletCard
            key={wallet.name}
            walletConfig={wallet}
            onClose={onClose}
          />
        ))}
      </CardWrapper>
    </Modal>
  )
}

export default ConnectWallet
