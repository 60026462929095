import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Collections: React.FC<SvgProps> = () => {
  const theme = useTheme()
  const opacity = theme.isDark ? '0.8' : '0.65'

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M16 12.6919V18.0819C16 20.4219 14.44 21.9719 12.11 21.9719H5.89C3.56 21.9719 2 20.4219 2 18.0819V10.3119C2 7.97188 3.56 6.42188 5.89 6.42188H9.72C10.75 6.42188 11.74 6.83187 12.47 7.56187L14.86 9.94188C15.59 10.6719 16 11.6619 16 12.6919Z"
        fill="url(#paint0_linear_2_10918)"
      />
      <path
        d="M22 8.24924V13.6392C22 15.9692 20.44 17.5292 18.11 17.5292H16V12.6892C16 11.6592 15.59 10.6692 14.86 9.93924L12.47 7.55924C11.7407 6.82964 10.7516 6.41958 9.72 6.41924H8V5.85924C8 3.52924 9.56 1.96924 11.89 1.96924H15.72C16.75 1.96924 17.74 2.37924 18.47 3.10924L20.86 5.49924C21.5896 6.22849 21.9997 7.21768 22 8.24924Z"
        fill="url(#paint1_linear_2_10918)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_10918"
          x1="15.3"
          y1="21.8285"
          x2="4.27255"
          y2="13.8698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E6E6" />
          <stop offset="1" stopColor="#B7B7B7" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_10918"
          x1="22"
          y1="20.4467"
          x2="-0.902362"
          y2="5.16653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.gradIconColor1} />
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Collections
