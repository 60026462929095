import Moralis from 'moralis'

const handleMoralisError = (err) => {
    switch (err.code) {
        case Moralis.Error.INVALID_SESSION_TOKEN:
            Moralis.User.logOut();
            break;
    }
}

export default handleMoralisError