import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Categories2: React.FC<SvgProps> = (props) => {
  const theme = useTheme()

  return (
    <Svg width="34" height="34" viewBox="0 0 34 34" fill="none" {...props}>
      {theme.isDark ? (
        <>
            <path d="M21.6641 12.3337H24.3307C26.9974 12.3337 28.3307 11.0003 28.3307 8.33366V5.66699C28.3307 3.00033 26.9974 1.66699 24.3307 1.66699H21.6641C18.9974 1.66699 17.6641 3.00033 17.6641 5.66699V8.33366C17.6641 11.0003 18.9974 12.3337 21.6641 12.3337ZM5.66406 28.3337H8.33073C10.9974 28.3337 12.3307 27.0003 12.3307 24.3337V21.667C12.3307 19.0003 10.9974 17.667 8.33073 17.667H5.66406C2.9974 17.667 1.66406 19.0003 1.66406 21.667V24.3337C1.66406 27.0003 2.9974 28.3337 5.66406 28.3337ZM6.9974 12.3337C8.41188 12.3337 9.76844 11.7718 10.7686 10.7716C11.7688 9.77137 12.3307 8.41481 12.3307 7.00033C12.3307 5.58584 11.7688 4.22928 10.7686 3.22909C9.76844 2.2289 8.41188 1.66699 6.9974 1.66699C5.58291 1.66699 4.22635 2.2289 3.22616 3.22909C2.22597 4.22928 1.66406 5.58584 1.66406 7.00033C1.66406 8.41481 2.22597 9.77137 3.22616 10.7716C4.22635 11.7718 5.58291 12.3337 6.9974 12.3337V12.3337ZM22.9974 28.3337C24.4119 28.3337 25.7684 27.7718 26.7686 26.7716C27.7688 25.7714 28.3307 24.4148 28.3307 23.0003C28.3307 21.5858 27.7688 20.2293 26.7686 19.2291C25.7684 18.2289 24.4119 17.667 22.9974 17.667C21.5829 17.667 20.2264 18.2289 19.2262 19.2291C18.226 20.2293 17.6641 21.5858 17.6641 23.0003C17.6641 24.4148 18.226 25.7714 19.2262 26.7716C20.2264 27.7718 21.5829 28.3337 22.9974 28.3337Z" stroke="#E6E6E6" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </>
      ) : (
        <>
            <path d="M21.6641 12.3337H24.3307C26.9974 12.3337 28.3307 11.0003 28.3307 8.33366V5.66699C28.3307 3.00033 26.9974 1.66699 24.3307 1.66699H21.6641C18.9974 1.66699 17.6641 3.00033 17.6641 5.66699V8.33366C17.6641 11.0003 18.9974 12.3337 21.6641 12.3337ZM5.66406 28.3337H8.33073C10.9974 28.3337 12.3307 27.0003 12.3307 24.3337V21.667C12.3307 19.0003 10.9974 17.667 8.33073 17.667H5.66406C2.9974 17.667 1.66406 19.0003 1.66406 21.667V24.3337C1.66406 27.0003 2.9974 28.3337 5.66406 28.3337ZM6.9974 12.3337C8.41188 12.3337 9.76844 11.7718 10.7686 10.7716C11.7688 9.77137 12.3307 8.41481 12.3307 7.00033C12.3307 5.58584 11.7688 4.22928 10.7686 3.22909C9.76844 2.2289 8.41188 1.66699 6.9974 1.66699C5.58291 1.66699 4.22635 2.2289 3.22616 3.22909C2.22597 4.22928 1.66406 5.58584 1.66406 7.00033C1.66406 8.41481 2.22597 9.77137 3.22616 10.7716C4.22635 11.7718 5.58291 12.3337 6.9974 12.3337V12.3337ZM22.9974 28.3337C24.4119 28.3337 25.7684 27.7718 26.7686 26.7716C27.7688 25.7714 28.3307 24.4148 28.3307 23.0003C28.3307 21.5858 27.7688 20.2293 26.7686 19.2291C25.7684 18.2289 24.4119 17.667 22.9974 17.667C21.5829 17.667 20.2264 18.2289 19.2262 19.2291C18.226 20.2293 17.6641 21.5858 17.6641 23.0003C17.6641 24.4148 18.226 25.7714 19.2262 26.7716C20.2264 27.7718 21.5829 28.3337 22.9974 28.3337Z" stroke="#535252" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </>
      )}
    </Svg>
  )
}

export default Categories2