import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Coinbase: React.FC<SvgProps> = (props) => (
  <Svg width="25" height="25" viewBox="0 0 337 337" fill="none" {...props}>
    <circle cx="168.5" cy="168.5" r="168.5" fill="#0C5BFB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M159.002 28.3061C120.132 30.9891 86.1252 48.4001 59.8389 79.0751C23.1479 121.893 17.4219 190.68 46.5569 238.638C47.4619 240.127 48.2019 241.422 48.2019 241.514C48.2019 242.227 55.6229 252.604 59.7499 257.664C63.4779 262.233 73.1052 272.167 76.1202 274.554C77.1552 275.374 78.4522 276.456 79.0022 276.959C90.8612 287.806 115.299 300.197 133.602 304.643C146.508 307.778 153.918 308.642 168.002 308.654C180.761 308.665 185.3 308.256 196.602 306.075C212.101 303.084 230.576 295.476 244.803 286.225C247.244 284.638 248.084 284.039 253.402 280.093C262.428 273.397 272.932 262.88 280.002 253.461C281.322 251.703 282.537 250.204 282.702 250.131C282.867 250.057 283.002 249.847 283.002 249.663C283.002 249.478 283.971 247.918 285.155 246.196C306.77 214.746 313.932 172.911 304.385 133.864C301.667 122.747 294.729 105.853 289.574 97.7931C288.819 96.6131 288.202 95.5681 288.202 95.4691C288.202 95.2071 284.634 89.7031 283.402 88.0641C282.823 87.2941 281.284 85.2241 279.981 83.4641C278.679 81.7041 277.52 80.1741 277.405 80.0641C277.291 79.9541 276.227 78.6941 275.04 77.2641C247.856 44.4901 202.397 25.3111 159.002 28.3061ZM207.505 123.849C209.621 124.733 211.332 126.353 212.422 128.503L213.402 130.437V168.402C213.402 210.041 213.534 207.719 210.998 210.607C208.134 213.87 211.013 213.664 168.255 213.664C126.626 213.664 128.947 213.796 126.059 211.26C122.796 208.395 123.002 211.283 123.002 168.455V130.437L123.962 128.551C125.141 126.235 126.263 125.158 128.602 124.098C130.999 123.011 204.941 122.778 207.505 123.849Z" fill="#FBFBFB" />
  </Svg>
)

export default Coinbase