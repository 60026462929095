import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Linkedin: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme();

  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.099 19.1031H16.3229V14.7521C16.3229 13.7146 16.3021 12.3792 14.875 12.3792C13.4281 12.3792 13.2073 13.5083 13.2073 14.676V19.1031H10.4302V10.1563H13.0969V11.375H13.1333C13.5062 10.6729 14.4125 9.93021 15.7667 9.93021C18.5792 9.93021 19.1 11.7823 19.1 14.1917V19.1031H19.099ZM7.29479 8.93229C7.08287 8.93257 6.87298 8.89101 6.67715 8.81001C6.48132 8.729 6.3034 8.61015 6.1536 8.46025C6.0038 8.31035 5.88505 8.13235 5.80418 7.93647C5.7233 7.74059 5.68188 7.53067 5.68229 7.31875C5.6825 6.99983 5.77727 6.68813 5.95463 6.42307C6.13198 6.15801 6.38395 5.95149 6.67868 5.82964C6.9734 5.70778 7.29764 5.67606 7.6104 5.73848C7.92315 5.8009 8.21037 5.95466 8.43574 6.18032C8.6611 6.40598 8.81449 6.6934 8.87651 7.00623C8.93853 7.31907 8.90638 7.64326 8.78415 7.93783C8.66191 8.2324 8.45507 8.4841 8.18978 8.66112C7.92449 8.83813 7.61267 8.9325 7.29375 8.93229H7.29479ZM8.68646 19.1031H5.90208V10.1563H8.6875V19.1031H8.68646ZM20.4896 3.125H4.50937C3.74271 3.125 3.125 3.72917 3.125 4.47604V20.524C3.125 21.2708 3.74375 21.875 4.50833 21.875H20.4854C21.25 21.875 21.875 21.2708 21.875 20.524V4.47604C21.875 3.72917 21.25 3.125 20.4854 3.125H20.4885H20.4896Z" fill={color ?? theme.colors.gradIconColor1}/>
    </Svg>
  );
};

export default Linkedin;