import React from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../atoms/Svg'
import { GenericModalProps } from './types'

// backdrop-filter: blur(4px);
// box-shadow: inset 1px 1px 2px rgba(43, 45, 45, 0.3), inset -1px -1px 2px rgba(31, 33, 33, 0.5);
// filter: drop-shadow(-5px 5px 10px rgba(31, 33, 33, 0.2)) drop-shadow(5px -5px 10px rgba(31, 33, 33, 0.2))

const ModalOverlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    background: #2527277D;
    backdrop-filter: blur(4px);
    z-index: 999;
`

const Modal = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: 14px 0px 28px;
  background: linear-gradient(${({theme}) => theme.colors.flatGlobal}, ${({theme}) => theme.colors.flatGlobal}) padding-box, ${({theme}) => theme.colors.strokeGradNorm} border-box;
  border: 6px solid transparent;
  border-image-source: ${({theme}) => theme.colors.strokeGradNorm};
  // border-image: ${({ theme }) => theme.colors.strokeGradNorm};
  border-radius: 24px;
  border-image-slice: 0;
`

const CloseWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-content: flex-end;
`

const CloseButton = styled.button`
  margin: 25px 25px 25px 0;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};
  cursor: pointer;
`

const GenericModal: React.FC<GenericModalProps> = ({
    width = 430,
    height = 480,
    isOpened = false,
    onClose,
    children,
    ...props
}) => {
    return isOpened ? (
        <>
                <ModalOverlay>
                    <Modal width={width} height={height} {...props}>
                        {onClose && (
                            <CloseWrapper>
                                <CloseButton onClick={onClose}>
                                    <CloseIcon />
                                </CloseButton>
                            </CloseWrapper>
                        )}
                        {children}
                    </Modal>
                </ModalOverlay>
        </>
    ) : (<></>)
}

export default GenericModal
