/* eslint-disable @typescript-eslint/no-unused-vars */
import useI18n from 'hooks/useI18n'
import React from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'toolkitUI/hooks'

import { Heading } from '../Heading'
import { PageHeaderProps } from './types'

// theme.colors.gradients.bubblegum
// linear - gradient(119.91deg,#041c3538 39.36 %,#2b7effa8 89.22 %)
// linear - gradient(119.91deg,#3480f6c4 36.36 %,#0dd8d0eb 84.22 %)
const HeaderWrapper = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${(props) => props.marginBottom ?? props.marginBottom};
  padding: 16px 0px;
  background: ${({ theme }) =>
    theme.isDark
      ? 'linear-gradient(119.91deg,#0549b5bf 39.36%,#1ba9b5e0 89.22%)'
      : 'linear-gradient(119.91deg,#2b7eff 36.36%,#20dfe0eb 84.22%)'};

  padding-left: 24px;
  padding-right: 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 32px 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const HeaderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const PageHeader: React.FC<PageHeaderProps> = ({ marginBottom, label, description }) => {
  const { isSm, isXs } = useMatchBreakpoints()
  const isMobile = isXs || isSm
  const TranslateString = useI18n()

  const titleSize =  isMobile ? 'lg' : 'xl'
  const descriptionSize = isMobile ? 'sm' : 'md'
  const textMargin = isMobile ? '14px' : '18px'
  // const titleSize = isXl ? 'xxl' : isXs ? 'lg' : 'xl'
  // const descriptionSize = isXl ? 'lg' : isXs ? 'sm' : 'md'
  // const textMargin = isXl ? '24px' : isXs ? '14px' : '18px'

  // theme.isDark ? "descriptionDarkColor" : "background"
  return (
    <HeaderWrapper marginBottom={marginBottom}>
      <HeaderContainer>
        <Heading as="h1" size={titleSize} color="headerTitle">
          {TranslateString(674, label)}
        </Heading>
        <Heading size={descriptionSize} color="headerSubtitle" mt={description ? textMargin : '0'}>
          {TranslateString(674, description)}
        </Heading>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default PageHeader
