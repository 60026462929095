import { ElevationShadows } from "./types";

export const lightShadows: ElevationShadows = {
    idleSoft: "-1px 1px 2px rgba(188, 189, 196, 0.2), 1px -1px 2px rgba(188, 189, 196, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(188, 189, 196, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(188, 189, 196, 0.5);",
    idleMedium: "-5px 5px 10px rgba(206, 208, 215, 0.2), 5px -5px 10px rgba(206, 208, 215, 0.2), -5px -5px 10px rgba(252, 254, 255, 0.9), 5px 5px 13px rgba(206, 208, 215, 0.9), inset 1px 1px 2px rgba(252, 254, 255, 0.3), inset -1px -1px 2px rgba(206, 208, 215, 0.5);",
    idleStrong: "-1px 1px 2px rgba(158, 159, 165, 0.2), 1px -1px 2px rgba(158, 159, 165, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(158, 159, 165, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(158, 159, 165, 0.5);",
    pressedSoft: "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(143, 144, 149, 0.5), inset -2px 2px 4px rgba(143, 144, 149, 0.2), inset 2px -2px 4px rgba(143, 144, 149, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 2px 2px 5px rgba(143, 144, 149, 0.9);",
    pressedMedium: "1px 1px 2px rgba(252, 254, 255, 0.3), -1px -1px 2px rgba(206, 208, 215, 0.5), inset -5px 5px 10px rgba(206, 208, 215, 0.2), inset 5px -5px 10px rgba(206, 208, 215, 0.2), inset -5px -5px 10px rgba(252, 254, 255, 0.9), inset 5px 5px 13px rgba(206, 208, 215, 0.9);",
    pressedStrong: "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(115, 116, 120, 0.5), inset -4px 4px 8px rgba(115, 116, 120, 0.2), inset 4px -4px 8px rgba(115, 116, 120, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.9), inset 4px 4px 10px rgba(115, 116, 120, 0.9);",
    popSoft: "-5px 5px 10px rgba(206, 208, 215, 0.2), 5px -5px 10px rgba(206, 208, 215, 0.2), -5px -5px 10px rgba(252, 254, 255, 0.9), 5px 5px 13px rgba(206, 208, 215, 0.9), inset 1px 1px 2px rgba(252, 254, 255, 0.3), inset -1px -1px 2px rgba(206, 208, 215, 0.5);",
    // popMedium: "-4px 4px 8px rgba(183, 185, 191, 0.2), 4px -4px 8px rgba(183, 185, 191, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.9), 4px 4px 10px rgba(183, 185, 191, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(183, 185, 191, 0.5);",
    popMedium: "4px 4px 10px 0px #B7B9BF1A, -4px -4px 8px 0px #FFFFFF80, 4px -4px 8px 0px #B7B9BF1A, -4px 4px 8px 0px #B7B9BF1A, -1px -1px 2px 0px #B7B9BF80 inset, 1px 1px 2px 0px #FFFFFF4D inset;",
    popStrong: "-1px 1px 2px rgba(142, 143, 148, 0.2), 1px -1px 2px rgba(142, 143, 148, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(142, 143, 148, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(142, 143, 148, 0.5);"
}

export const darkShadows: ElevationShadows = {
    idleSoft: "-5px 5px 10px rgba(33, 35, 35, 0.2), 5px -5px 10px rgba(33, 35, 35, 0.2), -5px -5px 10px rgba(41, 43, 43, 0.9), 5px 5px 13px rgba(33, 35, 35, 0.9), inset 1px 1px 2px rgba(41, 43, 43, 0.3), inset -1px -1px 2px rgba(33, 35, 35, 0.5);",
    idleMedium: "-2px 2px 4px rgba(29, 30, 30, 0.2), 2px -2px 4px rgba(29, 30, 30, 0.2), -2px -2px 4px rgba(45, 48, 48, 0.9), 2px 2px 5px rgba(29, 30, 30, 0.9), inset 1px 1px 2px rgba(45, 48, 48, 0.3), inset -1px -1px 2px rgba(29, 30, 30, 0.5);",
    idleStrong: "-2px 2px 4px rgba(19, 20, 20, 0.2), 2px -2px 4px rgba(19, 20, 20, 0.2), -2px -2px 4px rgba(55, 58, 58, 0.9), 2px 2px 5px rgba(19, 20, 20, 0.9), inset 1px 1px 2px rgba(55, 58, 58, 0.3), inset -1px -1px 2px rgba(19, 20, 20, 0.5);",
    pressedSoft: "inset -2px 2px 4px rgba(15, 16, 16, 0.2), inset 2px -2px 4px rgba(15, 16, 16, 0.2), inset -2px -2px 4px rgba(59, 62, 62, 0.9), inset 2px 2px 5px rgba(15, 16, 16, 0.9);",
    pressedMedium: "1px 1px 2px rgba(41, 43, 43, 0.3), -1px -1px 2px rgba(33, 35, 35, 0.5), inset -5px 5px 10px rgba(33, 35, 35, 0.2), inset 5px -5px 10px rgba(33, 35, 35, 0.2), inset -5px -5px 10px rgba(41, 43, 43, 0.9), inset 5px 5px 13px rgba(33, 35, 35, 0.9);",
    pressedStrong: "1px 1px 2px rgba(56, 59, 59, 0.3), -1px -1px 2px rgba(19, 20, 20, 0.5), inset -4px 4px 8px rgba(19, 20, 20, 0.2), inset 4px -4px 8px rgba(19, 20, 20, 0.2), inset -4px -4px 8px rgba(56, 59, 59, 0.9), inset 4px 4px 10px rgba(19, 20, 20, 0.9);",
    popSoft: "-5px 5px 10px rgba(31, 33, 33, 0.2), 5px -5px 10px rgba(31, 33, 33, 0.2), -5px -5px 10px rgba(43, 45, 45, 0.9), 5px 5px 13px rgba(31, 33, 33, 0.9), inset 1px 1px 2px rgba(43, 45, 45, 0.3), inset -1px -1px 2px rgba(31, 33, 33, 0.5);",
    // popMedium: "-1px 1px 2px rgba(27, 29, 29, 0.2), 1px -1px 2px rgba(27, 29, 29, 0.2), -1px -1px 2px rgba(47, 49, 49, 0.9), 1px 1px 3px rgba(27, 29, 29, 0.9), inset 1px 1px 2px rgba(47, 49, 49, 0.3), inset -1px -1px 2px rgba(27, 29, 29, 0.5);",
    popMedium: "1px 1px 3px 0px #1B1D1DE5, -1px -1px 2px 0px #2F3131E5, 1px -1px 2px 0px #1B1D1D33, -1px 1px 2px 0px #1B1D1D33, -1px -1px 2px 0px #1B1D1D80 inset, 1px 1px 2px 0px #2F31314D inset;",
    popStrong: "-2px 2px 4px rgba(17, 18, 18, 0.2), 2px -2px 4px rgba(17, 18, 18, 0.2), -2px -2px 4px rgba(57, 60, 60, 0.9), 2px 2px 5px rgba(17, 18, 18, 0.9), inset 1px 1px 2px rgba(57, 60, 60, 0.3), inset -1px -1px 2px rgba(17, 18, 18, 0.5);"
}