import React, { useCallback } from 'react'
import { useMoralis } from 'react-moralis'
import styled from 'styled-components'
import { Text } from 'designStandard/components/atoms/Text'
import { Button } from "designStandard/components/molecules/Button"
import { connectorLocalStorageKey } from './config'
import { Config } from './types'

const WalletButton = styled(Button)`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 44px);
  height: 56px;
  margin: 16px 22px 0;
  padding: 0 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border: none;

  &:hover:not(:active):not(:disabled) {
    border: none;
  }

  &:active {
    background: ${({ theme }) => theme.colors.flatOverlay};
    border: none;
  }
`

interface WalletCardProps {
  walletConfig: Config
  onClose: () => void
}

const WalletCard: React.FC<WalletCardProps> = ({ walletConfig, onClose }) => {
  const { authenticate } = useMoralis()
  const { name, icon: WalletIcon, connectorId } = walletConfig

  const handleEthereum = useCallback(async () => {
    const { ethereum } = window
    if (ethereum && ethereum.isMetaMask) {
      await authenticate();
      // window.location.reload()
    } else {
      window.location.href = "https://metamask.app.link/dapp/app.zap.org"
    }
  }, [authenticate])

  const connectHandler = useCallback(() => {
    if (name === 'Metamask') {
      if (window.ethereum) {
        handleEthereum();
      } else {
        window.addEventListener('ethereum#initialized', handleEthereum, {
          once: true,
        });
        setTimeout(handleEthereum, 3000);
      }
    } else {
      authenticate({
        provider: connectorId
      })
      window.localStorage.setItem(connectorLocalStorageKey, connectorId)
    }
    onClose()
  }, [authenticate, connectorId, handleEthereum, name, onClose])

  return (
    <WalletButton variant='secondary' onClick={connectHandler}>
      <Text font='h6BoldLarge'>{name}</Text>
      <WalletIcon />
    </WalletButton>
  )
}

export default WalletCard