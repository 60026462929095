import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredNew: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()

  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.5918 2.12767L13.8056 5.84201C13.9138 6.17795 14.1262 6.47098 14.4123 6.67884C14.6984 6.88671 15.0434 6.99868 15.3975 6.99861H19.3175C19.6708 6.9995 20.0149 7.1115 20.3006 7.31868C20.5864 7.52585 20.7991 7.81762 20.9087 8.15238C21.0182 8.48714 21.0189 8.84781 20.9106 9.18298C20.8024 9.51815 20.5907 9.8107 20.3058 10.019L17.1353 12.3189C16.848 12.5247 16.634 12.8163 16.5243 13.1514C16.4146 13.4865 16.4148 13.8478 16.525 14.1827L17.7389 17.897C17.8481 18.2326 17.848 18.594 17.7386 18.9295C17.6292 19.265 17.4161 19.5575 17.1298 19.765C16.8435 19.9726 16.4987 20.0846 16.1446 20.085C15.7906 20.0854 15.4455 19.9743 15.1587 19.7674L11.9882 17.4674C11.6991 17.2579 11.3507 17.145 10.9932 17.145C10.6357 17.145 10.2874 17.2579 9.99831 17.4674L6.84109 19.7939C6.55428 20.0007 6.20917 20.1118 5.85513 20.1114C5.50108 20.111 5.15623 19.999 4.86993 19.7915C4.58363 19.5839 4.37055 19.2915 4.26116 18.956C4.15177 18.6204 4.15167 18.2591 4.2609 17.9235L5.47472 14.2091C5.58493 13.8742 5.58519 13.513 5.47547 13.1778C5.36575 12.8427 5.15174 12.5511 4.86449 12.3454L1.71388 10.019C1.42895 9.8107 1.21727 9.51815 1.10901 9.18298C1.00076 8.84781 1.00144 8.48714 1.11098 8.15238C1.22051 7.81762 1.4333 7.52585 1.71902 7.31868C2.00474 7.1115 2.34881 6.9995 2.70217 6.99861H6.62219C6.97627 6.99868 7.32122 6.88671 7.60732 6.67884C7.89342 6.47098 8.10588 6.17795 8.21407 5.84201L9.4279 2.12767C9.54053 1.79874 9.7536 1.51317 10.0373 1.31095C10.321 1.10873 10.661 1 11.0098 1C11.3586 1 11.6987 1.10873 11.9824 1.31095C12.2661 1.51317 12.4791 1.79874 12.5918 2.12767Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M17.4836 3.88547L16.3759 7.63285C16.2763 7.97158 16.2868 8.33303 16.406 8.66544C16.5251 8.99786 16.7468 9.28418 17.0392 9.48339L20.2761 11.6908C20.5667 11.891 20.7865 12.1775 20.9042 12.5094C21.0219 12.8414 21.0314 13.2018 20.9314 13.5395C20.8314 13.8771 20.6271 14.1747 20.3474 14.3899C20.0678 14.605 19.7271 14.7267 19.374 14.7377L15.454 14.8434C15.1 14.8544 14.7586 14.9766 14.4787 15.1927C14.1987 15.4088 13.9944 15.7075 13.8952 16.0462L12.7743 19.7936C12.6765 20.1332 12.4729 20.4331 12.1928 20.6498C11.9128 20.8665 11.5708 20.9889 11.2163 20.9992C10.8619 21.0095 10.5134 20.9072 10.2211 20.7071C9.92884 20.507 9.708 20.2195 9.59052 19.8862L8.26395 16.2049C8.14431 15.8733 7.92298 15.5876 7.63131 15.3883C7.33965 15.189 6.99246 15.0863 6.63891 15.0945L2.7189 15.2003C2.36548 15.2088 2.01837 15.106 1.7271 14.9063C1.43584 14.7067 1.21528 14.4206 1.09689 14.0886C0.978506 13.7567 0.968344 13.396 1.06785 13.058C1.16735 12.72 1.37143 12.4219 1.65099 12.2063L4.75517 9.82708C5.03589 9.61199 5.24146 9.31423 5.34267 8.97609C5.44388 8.63796 5.43558 8.27665 5.31896 7.94348L3.99239 4.2622C3.87537 3.93004 3.86655 3.56957 3.96717 3.23212C4.06779 2.89468 4.27273 2.59747 4.55279 2.38287C4.83284 2.16827 5.17373 2.04723 5.52687 2.03696C5.88001 2.0267 6.22738 2.12776 6.51951 2.32573L9.74971 4.53317C10.0432 4.7381 10.3951 4.84358 10.7534 4.83406C11.1117 4.82454 11.4574 4.70051 11.7396 4.48029L14.8438 2.09441C15.124 1.87745 15.466 1.7546 15.8207 1.7435C16.1754 1.7324 16.5245 1.83363 16.8178 2.03265C17.1112 2.23167 17.3336 2.51821 17.4533 2.85112C17.5729 3.18403 17.5835 3.54617 17.4836 3.88547V3.88547Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M9.12887 9.33154V11.7373H8.73751L7.6033 10.0982V11.7373H7.16553V9.33154H7.55686L8.6911 10.9706V9.33154H9.12887Z" fill="#F5F5F8"/>
      <path d="M9.85254 11.7373V9.33154H11.4245V9.69504H10.2903V10.3559H11.3383V10.7194H10.2903V11.3804H11.4312V11.7439L9.85254 11.7373Z" fill="#F5F5F8"/>
      <path d="M12.6184 11.7373L11.9551 9.33154H12.426L12.8638 11.0962L13.3281 9.33154H13.7526L14.2169 11.1028L14.648 9.33154H15.1189L14.4557 11.7373H14.0245L13.547 10.0519L13.0628 11.7373H12.6184Z" fill="#F5F5F8"/>
    </Svg>
  )
}

export default ColoredNew
