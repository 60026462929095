import styled from 'styled-components'
import { GridBox  } from './GridBox'

export const GridLayout = styled(GridBox)<{
  width?: string | '100vw', minWidth?: string | '296px', maxWidth?: string | '100vw',
  
  height?: string, minHeight?: string | '538px', maxHeight?: string,
  align?: "start" | "center" | "end", 
  justify?: "start" | "center" | "end" | "space-between" | "space-around" | "space-evenly",
  gridGap?: string | '0px',
  padding?: string | '32px',
  pr?, pl?, pt?, pb?
  mr?, ml?, mt?, mb?
}>`
  justify-content: center;
  & > * {
    min-width: ${props => props.minWidth};
    max-width: ${props => props.maxWidth};
    min-height: ${props => props.minHeight};
    max-height: ${props => props.maxHeight};
    width: ${props => props.width ?? '100%'};
    height: ${props => props.height ?? '100%'};
    align-self: ${props => props.align ?? 'center'};
    justify-content: ${props => props.justify ?? 'start'};
    padding-right: ${props => props.pr ?? '0px'} 
    padding-bottom: ${props => props.pb ?? '32px'} 
    padding-left: ${props => props.pl ?? '0px'};
    padding-top: ${props => props.pt ?? '32px'};
    margin-top: ${props => props.mt ?? '0px'};
    margin-left: ${props => props.ml ?? '0px'};
    margin-right: ${props => props.mr ?? '0px'};
    margin-bottom: ${props => props.mb ?? '0px'};
  }
`

export default GridLayout