import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { InputProps, scales } from "./types";

import {
	SearchIcon,
	NotificationIcon,
	MoreIcon,
	TwitterIcon,
	LinkedinIcon,
	InstagramIcon,
	FacebookIcon,
	HomeIcon,
	WalletIcon,
	ExploreIcon,
	ProfileIcon,
	EthereumSmallIcon,
	CreateIcon,
	BNBSmallIcon,
	ZapIcon
} from "../Svg";

interface StyledInputProps extends InputProps {
  theme: DefaultTheme;
}

/**
 * Priority: Warning --> Success
 */

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
  switch (scale) {
    case scales.SM:
      return "32px";
    case scales.LG:
      return "48px";
    case scales.MD:
    default:
      return "40px";
  }
};

// const getBorder = (isFilled : string, theme: any) => {
// 	if(isFilled === "outlined") {
// 		return `1px solid ${theme.colors.strokeFlatNorm}`;
// 	}
// 	return `0`;
// }

const getBackground = (isFilled : string, theme: any) => {
	if(isFilled === "outlined") {
		return `transparent`;
	}
	return `${theme.colors.flatSearchBox}`;
}

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	// margin: 3px;
	width: 100%;
`
const IconWrapper = styled.div`
	position: absolute;
	right: 5px;
	height: 100%;
	display: grid;
	align-items: center;
`

const Input = styled.input<InputProps>`
  background: ${({ theme, isFilled }) => getBackground(isFilled, theme)};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-style: inherit;
  font-weight: normal;
  height: ${getHeight};
  outline: 0;
  padding: 0 8px;
  text-align: left;
  width: 100%;

  &:active {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
	
  &:hover {
	color: ${({ theme }) => theme.colors.textPrimary};
	border: 2px solid ${({ theme }) => theme.colors.strokeFlatNorm};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.textOnDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
	color: ${({ theme }) => theme.colors.textPrimary};
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

const InputField: React.FC<InputProps> = ({...props}) => {

	const { icon } = props;

	const getIcon = () => {
		switch (icon) {
			case "search":
				return <SearchIcon />
			case "notification":
				return <NotificationIcon />
			case "more":
				return <MoreIcon />
			case "twitter":
				return <TwitterIcon />
			case "linkedin":
				return <LinkedinIcon />
			case "instagram":
				return <InstagramIcon />
			case "facebook":
				return <FacebookIcon />
			case "home":
				return <HomeIcon />
			case "wallet":
				return <WalletIcon />
			case "explore":
				return <ExploreIcon />
			case "profile":
				return <ProfileIcon />
			case "create":
				return <CreateIcon />
			case "eth":
				return <EthereumSmallIcon />
			case "bnb":
				return <BNBSmallIcon />
			case "zap":
				return <ZapIcon style={{height: '100%', maxHeight: '24px'}}/>
			default:
				return <></>
		}
	}

	return (
		<InputWrapper>
			<Input {...props} />
			<IconWrapper>{getIcon()}</IconWrapper>
		</InputWrapper>
	)
}

InputField.defaultProps = {
  scale: scales.MD,
  isFilled: "filled",
};

export default InputField;
