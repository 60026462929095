import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps>
  = (props) => {
  return (
  <Svg viewBox="0 0 50 50" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30pt" height="30.000001pt" viewBox="0 0 30 30.000001" version="1.2">
    <defs>
    <clipPath id="clip1">
      <path d="M 0.484375 0 L 29.515625 0 L 29.515625 29.03125 L 0.484375 29.03125 Z M 0.484375 0 "/>
    </clipPath>
    </defs>
    <g id="surface1">
    <g clipPath="url(#clip1)" clipRule="nonzero">
    <path stroke="none" fillRule="nonzero" fill="rgb(16.859436%,49.409485%,100%)" fillOpacity="1" d="M 15 0 C 6.996094 0 0.484375 6.511719 0.484375 14.515625 C 0.484375 22.519531 6.996094 29.03125 15 29.03125 C 23.003906 29.03125 29.515625 22.519531 29.515625 14.515625 C 29.515625 6.511719 23.003906 0 15 0 Z M 15 25.402344 C 8.996094 25.402344 4.113281 20.519531 4.113281 14.515625 C 4.113281 8.511719 8.996094 3.628906 15 3.628906 C 21.003906 3.628906 25.886719 8.511719 25.886719 14.515625 C 25.886719 20.519531 21.003906 25.402344 15 25.402344 Z M 15 25.402344 "/>
    </g>
      <path stroke="none" fillRule="nonzero" fill="rgb(16.859436%,49.409485%,100%)" fillOpacity="1" d="M 16.28125 7.457031 C 15.601562 6.777344 14.398438 6.777344 13.71875 7.457031 L 9.871094 11.304688 C 9.160156 12.015625 9.160156 13.164062 9.871094 13.871094 C 10.578125 14.582031 11.726562 14.582031 12.433594 13.871094 L 13.183594 13.121094 L 13.183594 20.296875 C 13.183594 21.292969 14.003906 22.109375 15 22.109375 C 15.996094 22.109375 16.816406 21.292969 16.816406 20.296875 L 16.816406 13.121094 L 17.566406 13.871094 C 17.917969 14.226562 18.382812 14.402344 18.847656 14.402344 C 19.3125 14.402344 19.777344 14.226562 20.128906 13.871094 C 20.839844 13.164062 20.839844 12.015625 20.128906 11.304688 Z M 16.28125 7.457031 " />
    </g>
    </svg>
  </Svg>
  );
};

export default Icon;

