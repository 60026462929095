import React from 'react';
import styled from "styled-components";

const NavbarContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  z-index: 100;
`

const NavbarContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({children, ...props}) => (
  <NavbarContainerWrapper {...props}>
    {children}
  </NavbarContainerWrapper>
)

export default NavbarContainer;
