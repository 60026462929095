import styled from 'styled-components'
import { SwitchProps, HandleProps, InputProps, ScaleKeys, scales } from './types'

const scaleKeyValues = {
  sm: {
    handleWidth: '20px',
    handleTop: '5px',
    checkedLeft: 'calc(100% - 24px)',
    toggleHeight: '32px',
    toggleWidth: '64px',
  },
  md: {
    handleWidth: '30px',
    handleTop: '8px',
    checkedLeft: 'calc(100% - 36px)',
    toggleHeight: '48px',
    toggleWidth: '96px',
  },
  lg: {
    handleWidth: '40px',
    handleTop: '12px',
    checkedLeft: 'calc(100% - 48px)',
    toggleHeight: '64px',
    toggleWidth: '128px',
  },
}
const getScale =
  (property: ScaleKeys) =>
  ({ scale = scales.LG }: SwitchProps) => {
    return scaleKeyValues[scale][property]
  }

export const Handle = styled.div<HandleProps>`
  background: ${({ theme, notChecked }) => notChecked ? theme.colors.gradStroke : theme.colors.primaryBlue};
  border-radius: 50%;
  cursor: pointer;
  height: ${getScale('handleWidth')};
  left: ${getScale('handleTop')};
  top: ${getScale('handleTop')};
  position: absolute;
  transition: left 200ms ease-in;
  width: ${getScale('handleWidth')};
  z-index: 1;
`

export const Input = styled.input<InputProps>`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: ${getScale('checkedLeft')};
  }
`

const StyledSwitch = styled.div<SwitchProps>`
  align-items: center;
  background-color: ${({ theme, notChecked }) => !notChecked ? theme.colors.flatGlobal : theme.colors.primaryBlue};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 100px;
  border: ${({ theme }) => theme.colors.switchBorder} transparent;
  background: 
      linear-gradient(${({ theme, notChecked }) => !notChecked ? theme.colors.flatGlobal : theme.colors.primaryBlue},${({ theme, notChecked }) => !notChecked ? theme.colors.flatGlobal : theme.colors.primaryBlue}) padding-box,
      ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  cursor: pointer;
  height: ${getScale('toggleHeight')};
  position: relative;
  transition: background-color 200ms;
  width: ${getScale('toggleWidth')};
  border-image-slice: 0;
`

export default StyledSwitch
