import { createReducer } from '@reduxjs/toolkit'
import { getArtists } from './actions'
import { ZapArtistState } from './types'

const initialState: ZapArtistState = {
    artists: {},
}

export default createReducer<ZapArtistState>(initialState, (builder) => {
    builder
        .addCase(getArtists, (state, { payload: { artists } }) => {
            const toSet: { [key: string]: any } = {}
            artists.forEach((artist) => {
                toSet[artist.address] = artist
            })
            return {
                ...state,
                artists: toSet,
            }
        })
})