import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = (props) => {
  const theme = useTheme()

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M21.5865 2.66699H10.4132C5.55984 2.66699 2.6665 5.56033 2.6665 10.4137V21.5737C2.6665 26.4403 5.55984 29.3337 10.4132 29.3337H21.5732C26.4265 29.3337 29.3198 26.4403 29.3198 21.587V10.4137C29.3332 5.56033 26.4398 2.66699 21.5865 2.66699ZM20.4798 19.067C20.8665 19.4537 20.8665 20.0937 20.4798 20.4803C20.2798 20.6803 20.0265 20.7737 19.7732 20.7737C19.5198 20.7737 19.2665 20.6803 19.0665 20.4803L15.9998 17.4137L12.9332 20.4803C12.7332 20.6803 12.4798 20.7737 12.2265 20.7737C11.9732 20.7737 11.7198 20.6803 11.5198 20.4803C11.3339 20.2921 11.2296 20.0382 11.2296 19.7737C11.2296 19.5091 11.3339 19.2552 11.5198 19.067L14.5865 16.0003L11.5198 12.9337C11.3339 12.7455 11.2296 12.4916 11.2296 12.227C11.2296 11.9624 11.3339 11.7085 11.5198 11.5203C11.9065 11.1337 12.5465 11.1337 12.9332 11.5203L15.9998 14.587L19.0665 11.5203C19.4532 11.1337 20.0932 11.1337 20.4798 11.5203C20.8665 11.907 20.8665 12.547 20.4798 12.9337L17.4132 16.0003L20.4798 19.067Z" fill={theme.colors.gradIconColor1} />
    </Svg>
  )
}

export default Icon