import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <path d="M53 41.1061C53 47.6793 47.6689 53 41.1061 53H11.8939C5.33105 53 0 47.6689 0 41.1061V11.8939C0 5.33105 5.33105 0 11.8939 0H41.1061C47.6689 0 53 5.33105 53 11.8939V41.1061Z" fill="#0A81FE"/>
        <path d="M39.6562 13.8824L12.0383 24.6376C11.148 24.9896 11.1998 26.2732 12.1211 26.5423L19.1394 28.6126L21.7584 36.9249C22.0275 37.7945 23.1351 38.0533 23.7666 37.4011L27.4 33.6953L34.5219 38.9228C35.3914 39.5646 36.6336 39.0884 36.851 38.0326L41.5609 15.4869C41.799 14.3896 40.7121 13.4683 39.6562 13.8824ZM36.1988 18.8822L23.3732 30.2275C23.249 30.3414 23.1662 30.4966 23.1455 30.6622L22.6486 35.0513C22.6279 35.1962 22.4312 35.2169 22.3898 35.072L20.3609 28.5195C20.2678 28.2193 20.392 27.8984 20.6611 27.7328L35.8054 18.3335C36.1471 18.1369 36.5094 18.613 36.1988 18.8822Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
