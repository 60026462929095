import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface HeartProps extends SvgProps {
  isColor?: boolean
}

const HeartStroke: React.FC<HeartProps> = (props) => {
  // const { isColor } = props
  const theme = useTheme();
  const color1 = theme.colors.heartFill;
  const color2 = theme.colors.heartStroke;
  const fillColor = theme.colors.flatGlobal

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M10.725 17.2583C10.4417 17.3583 9.975 17.3583 9.69167 17.2583C7.275 16.4333 1.875 12.9917 1.875 7.15833C1.875 4.58333 3.95 2.5 6.50833 2.5C8.025 2.5 9.36667 3.23333 10.2083 4.36667C10.6365 3.78823 11.1941 3.3181 11.8367 2.99395C12.4792 2.6698 13.1887 2.50063 13.9083 2.5C16.4667 2.5 18.5417 4.58333 18.5417 7.15833C18.5417 12.9917 13.1417 16.4333 10.725 17.2583Z" stroke="url(#paint0_linear_4405_32228)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill={fillColor}/>
      <defs>
        <linearGradient id="paint0_linear_4405_32228" x1="10.2083" y1="2.5" x2="10.2083" y2="17.3333" gradientUnits="userSpaceOnUse">
		  <stop stopColor={color1}/>
		  <stop offset="1" stopColor={color2}/>
		</linearGradient>
	  </defs>
    </Svg>
  );
};

export default HeartStroke;