import React, { FC, cloneElement, createContext, isValidElement, useState, ReactNode } from 'react'
import { HandlerProps } from './types'

interface ModalsContext {
  onShow: (node: ReactNode) => void
  onClose: HandlerProps
}

export const Context = createContext<ModalsContext>({
  onShow: () => null,
  onClose: () => null,
})

const ModalContext: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalNode, setModalNode] = useState<ReactNode>()

  const openHandler = (node: ReactNode) => {
    setModalNode(node)
    setIsOpen(true)
  }

  const closeHandler = () => {
    setModalNode(undefined)
    setIsOpen(false)
  }

  return (
    <Context.Provider
      value={{
        onShow: openHandler,
        onClose: closeHandler,
      }}
    >
      {isOpen &&
        isValidElement(modalNode) &&
        cloneElement(modalNode, {
          onClose: closeHandler,
          isOpened: isOpen,
        })}
      {children}
    </Context.Provider>
  )
}

export default ModalContext
