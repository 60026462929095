import { createReducer } from '@reduxjs/toolkit'
import { getTransactions, updateOne, clear } from './actions'

export interface ZapTransactionsState {
  // readonly transactionsList: { [key: string]: { [key: string]: any } }
  transactionsList: { [key: string]: any }
}

const initialState: ZapTransactionsState = {
  transactionsList: {},
}

export default createReducer<ZapTransactionsState>(initialState, (builder) =>
  builder
    .addCase(getTransactions, (state, { payload: { transactions } }) => {
      const toSet: { [key: string]: any } = {}
      transactions.forEach((e) => {
        toSet[e.txHash] = e
      })
      return {
        ...state,
        transactionsList: toSet,
      }
    })
    .addCase(updateOne, (state, { payload: { one } }) => {
      // console.log('State', JSON.stringify(state.transactionsList))
      return {
        ...state,
        transactionsList: {...state.transactionsList, [one.txHash]: one},
      }
    })
    .addCase(clear, (state) => {
      return {
        ...state,
        transactionsList: {},
      }
    }),
)
