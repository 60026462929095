import React from "react";
import Svg from "../Svg";


const Icon: React.FC<any> = (props) => {

  const { fill, size } = props

	const _fill = fill === "Light" ? '#007AFF' : fill === "Dark" ? '#8C8E8F' : 'none';
	const _size = size === "Large" ? {width: "12px", height: "10px"} : size === "Medium" ? {width: "10px", height: "8px"} : {width: "8px", height: "6px"}

	const _props = {...props, fill: _fill, color:""}

  return (
    <Svg viewBox="0 0 8 6" {..._size} {..._props}>
			<path d="M3.15806 4.26307L6.80616 0.614563L7.36775 1.17575L3.15806 5.38544L0.632324 2.8597L1.19351 2.29852L3.15806 4.26307Z"/>
    </Svg>
  );
};

export default Icon;