import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Fortmatic: React.FC<SvgProps> = (props) => (
  <Svg width="25" height="25" viewBox="0 0 36 37" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.9399 0H26.9099H35.8799V9.19982H26.9099H17.9399H8.96996V18.3996V18.9895V27.5459V27.5995V36.7457H0V27.5995V27.5459V18.9895V18.3996V9.19982V0H8.96996H17.9399ZM26.9105 27.5487H17.9928V18.4025H35.8752V27.8705C35.8752 30.2242 34.9639 32.4815 33.3417 34.1463C31.7195 35.8111 29.5191 36.7471 27.2242 36.7486H26.9105V27.5487Z" fill="#6851FF" />
  </Svg>
)

export default Fortmatic