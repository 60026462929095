import React from 'react'
import styled from 'styled-components'
import { Text } from 'designStandard/components/atoms'
import { useMatchBreakpoints } from 'designStandard/hooks'

interface RowProps {
  data: { [key: string]: any }
  size?: string
}

const RowContainer = styled.tr<{ length?: number, size?: string, isMobile?: boolean }>`
  display: grid;
  grid-template-columns: ${({ length, size }) => size || `repeat(${length}, 1fr)`};
  padding: 34px;
  padding-left: ${({ isMobile }) => isMobile ? '16px' : null};
  padding-right: ${({ isMobile }) => isMobile ? '16px' : null};
  margin: 34px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  border-radius: 16px;

  & td {
    align-self: center;
  }
`

const Data = styled.td`
  pading-right: 16px;
`

/** @params:
 * @data - Object of the dataset EX : { id: 1, name: 'John'}
 * @size <OPTIONAL> - String value of column size for the table in grid format. Should match table headers EX: '1fr 2fr'
 * */

const Row: React.FC<RowProps> = ({ data, size }) => {
  const {isXs, isSm} = useMatchBreakpoints()
  const isMobile = isXs || isSm
  const keys = Object.keys(data)

  return (
    <>
      <RowContainer length={keys.length} size={size} isMobile={isMobile}>
        {keys.map((item) => {
          return (
            <Data key={data[item]}>
              <Text font="bodyRegularSmall">{data[item]}</Text>
            </Data>
          )
        })}
      </RowContainer>
    </>
  )
}

export default Row
