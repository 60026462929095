import React from "react";
import Svg from "../Svg";

const Icon: React.FC<any> = ({size, color1, color2, style}) => {
    const updatedWidth = parseInt(size)*6/24
    const updatedHeight = parseInt(size)*4/24
  return (
    <Svg viewBox="0 0 6 4" width={updatedWidth} height={updatedHeight} style={style}>
      <path d="M3 0.5L6 3.5H0L3 0.5Z" fill="url(#up)"/>
        <defs>
        <linearGradient id="up" x1="9.82969e-08" y1="-0.0625" x2="4.43337" y2="6.51248" gradientUnits="userSpaceOnUse">
        <stop stopColor={color1}/>
        <stop offset="1" stopColor={color2} stopOpacity="0.8"/>
        </linearGradient>
        </defs>
    </Svg>
  );
};

export default Icon;
