import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const Money: React.FC<SvgProps> = (props) => {
  const {color} = props
  const theme = useTheme()

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M8 11.4c0 .77.6 1.4 1.33 1.4h1.5c.64 0 1.16-.55 1.16-1.22 0-.73-.32-.99-.79-1.16l-2.4-.84c-.48-.17-.8-.43-.8-1.16 0-.67.52-1.22 1.16-1.22h1.5c.74.01 1.34.63 1.34 1.4M10 12.85v.74M10 6.41v.78" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.99 17.98A7.99 7.99 0 1 0 9.99 2a7.99 7.99 0 0 0 0 15.98ZM12.98 19.88c.9 1.27 2.37 2.1 4.05 2.1 2.73 0 4.95-2.22 4.95-4.95 0-1.66-.82-3.13-2.07-4.03" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Money