import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Twitter: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.5839 6.39122C22.7886 6.74299 21.9453 6.97401 21.0818 7.07664C21.9919 6.5323 22.6731 5.67561 22.9984 4.66622C22.1443 5.17456 21.2078 5.53081 20.2318 5.72352C19.5762 5.02204 18.7071 4.55681 17.7598 4.40017C16.8126 4.24352 15.84 4.40424 14.9935 4.85732C14.147 5.31041 13.4738 6.03048 13.0788 6.90559C12.6837 7.7807 12.5888 8.76183 12.8089 9.69643C11.0767 9.60961 9.38212 9.15948 7.83519 8.37525C6.28827 7.59102 4.92357 6.49023 3.82969 5.14435C3.44249 5.8094 3.23902 6.56542 3.2401 7.33497C3.2401 8.84539 4.00885 10.1798 5.1776 10.961C4.48595 10.9392 3.80951 10.7525 3.20469 10.4162V10.4704C3.2049 11.4763 3.55299 12.4512 4.18995 13.2298C4.8269 14.0084 5.71352 14.5428 6.69948 14.7423C6.05741 14.9163 5.38417 14.9419 4.73073 14.8173C5.00872 15.6831 5.55053 16.4404 6.2803 16.983C7.01008 17.5256 7.89128 17.8264 8.80052 17.8433C7.89685 18.553 6.86216 19.0777 5.7556 19.3872C4.64905 19.6968 3.49232 19.7852 2.35156 19.6475C4.34291 20.9281 6.66104 21.608 9.02865 21.6058C17.0422 21.6058 21.4245 14.9673 21.4245 9.20997C21.4245 9.02247 21.4193 8.83289 21.4109 8.64747C22.2639 8.03098 23.0001 7.26729 23.5849 6.39227L23.5839 6.39122Z" fill={color ?? theme.colors.gradIconColor1}/>
    </Svg>
  );
};

export default Twitter;