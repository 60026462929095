import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <g clipPath="url(#clip0)">
        <path d="M47.5237 0.976562H6.33172C3.60175 0.976562 1.38867 3.18964 1.38867 5.91961V47.1116C1.38867 49.8416 3.60175 52.0547 6.33172 52.0547H47.5237C50.2537 52.0547 52.4668 49.8416 52.4668 47.1116V5.91961C52.4668 3.18964 50.2537 0.976562 47.5237 0.976562Z" fill="#0A81FE"/>
        <path d="M47.5237 0.976562H6.33172C5.02074 0.976563 3.76346 1.49735 2.83646 2.42435C1.90946 3.35135 1.38867 4.60863 1.38867 5.91961V14.158C1.38867 18.9649 3.29821 23.575 6.69721 26.974C10.0962 30.373 14.7063 32.2825 19.5132 32.2825H34.3423C39.1492 32.2825 43.7592 30.373 47.1582 26.974C50.5573 23.575 52.4668 18.9649 52.4668 14.158V5.91961C52.4668 4.60863 51.946 3.35135 51.019 2.42435C50.092 1.49735 48.8347 0.976563 47.5237 0.976562Z" fill="#0A81FE"/>
        <path d="M47.5237 0.976562H6.33172C5.02074 0.976563 3.76346 1.49735 2.83646 2.42435C1.90946 3.35135 1.38867 4.60863 1.38867 5.91961V9.21497C1.38867 7.90399 1.90946 6.64671 2.83646 5.71971C3.76346 4.79271 5.02074 4.27192 6.33172 4.27192H47.5237C48.8347 4.27192 50.092 4.79271 51.019 5.71971C51.946 6.64671 52.4668 7.90399 52.4668 9.21497V5.91961C52.4668 4.60863 51.946 3.35135 51.019 2.42435C50.092 1.49735 48.8347 0.976563 47.5237 0.976562Z" fill="#0A81FE"/>
        <path d="M47.5237 48.7595H6.33172C5.02074 48.7595 3.76346 48.2387 2.83646 47.3117C1.90946 46.3847 1.38867 45.1274 1.38867 43.8164V47.1118C1.38867 48.4227 1.90946 49.68 2.83646 50.607C3.76346 51.534 5.02074 52.0548 6.33172 52.0548H47.5237C48.8347 52.0548 50.092 51.534 51.019 50.607C51.946 49.68 52.4668 48.4227 52.4668 47.1118V43.8164C52.4668 45.1274 51.946 46.3847 51.019 47.3117C50.092 48.2387 48.8347 48.7595 47.5237 48.7595Z" fill="#0A81FE"/>
        <path d="M30.2231 42.9927L29.3992 38.8735C33.083 38.7352 36.5932 37.2726 39.2853 34.7543C38.9385 34.8449 32.5298 37.2258 26.9277 37.2258C21.3256 37.2258 14.9169 34.8449 14.5701 34.7543C17.2622 37.2726 20.7724 38.7352 24.4562 38.8735L23.6323 42.9927C19.1096 42.8016 14.64 41.943 10.3685 40.4446C9.39324 40.0836 8.55742 39.4229 7.98105 38.5573C7.40469 37.6917 7.11741 36.6658 7.16047 35.6267C7.52274 28.5599 8.65902 21.5541 10.5489 14.735C10.7735 13.9178 11.2044 13.1721 11.8002 12.5695C12.3961 11.9669 13.1368 11.5276 13.9514 11.2938C17.3922 10.4677 20.9177 10.0466 24.4562 10.0391C21.0391 10.8239 17.9039 12.5341 15.3939 14.9821C15.7408 14.8915 21.3256 12.504 26.9277 12.5106C30.89 12.675 34.7967 13.5026 38.4854 14.959C35.9687 12.5121 32.8239 10.8092 29.3992 10.0391C32.9376 10.0456 36.4631 10.4655 39.904 11.2905C40.7186 11.5243 41.4593 11.9636 42.0552 12.5662C42.651 13.1688 43.0819 13.9145 43.3065 14.7317C45.1966 21.5518 46.3329 28.5588 46.6949 35.6267C46.738 36.6658 46.4507 37.6917 45.8744 38.5573C45.298 39.4229 44.4622 40.0836 43.4869 40.4446C39.2154 41.943 34.7458 42.8016 30.2231 42.9927Z" fill="#FDFEF9"/>
        <path d="M18.6895 31.4591C20.9645 31.4591 22.8087 29.6149 22.8087 27.3399C22.8087 25.0649 20.9645 23.2207 18.6895 23.2207C16.4145 23.2207 14.5703 25.0649 14.5703 27.3399C14.5703 29.6149 16.4145 31.4591 18.6895 31.4591Z" fill="#0A81FE"/>
        <path d="M35.1661 31.4591C37.4411 31.4591 39.2853 29.6149 39.2853 27.3399C39.2853 25.0649 37.4411 23.2207 35.1661 23.2207C32.8911 23.2207 31.0469 25.0649 31.0469 27.3399C31.0469 29.6149 32.8911 31.4591 35.1661 31.4591Z" fill="#0A81FE"/>
        <path d="M35.1661 24.8682C36.1157 24.868 37.0362 25.1959 37.7718 25.7964C38.5074 26.3969 39.013 27.2331 39.2029 28.1635C39.3244 27.5653 39.3114 26.9475 39.1648 26.3549C39.0183 25.7623 38.7418 25.2096 38.3555 24.737C37.9691 24.2643 37.4826 23.8834 36.931 23.6219C36.3794 23.3603 35.7765 23.2246 35.1661 23.2246C34.5556 23.2246 33.9528 23.3603 33.4012 23.6219C32.8496 23.8834 32.363 24.2643 31.9767 24.737C31.5904 25.2096 31.3139 25.7623 31.1673 26.3549C31.0208 26.9475 31.0078 27.5653 31.1293 28.1635C31.3192 27.2331 31.8247 26.3969 32.5604 25.7964C33.296 25.1959 34.2165 24.868 35.1661 24.8682Z" fill="#0A81FE"/>
        <path d="M18.6895 24.8682C19.6391 24.868 20.5596 25.1959 21.2952 25.7964C22.0309 26.3969 22.5364 27.2331 22.7263 28.1635C22.8478 27.5653 22.8348 26.9475 22.6882 26.3549C22.5417 25.7623 22.2652 25.2096 21.8789 24.737C21.4926 24.2643 21.006 23.8834 20.4544 23.6219C19.9028 23.3603 19.3 23.2246 18.6895 23.2246C18.079 23.2246 17.4762 23.3603 16.9246 23.6219C16.373 23.8834 15.8865 24.2643 15.5001 24.737C15.1138 25.2096 14.8373 25.7623 14.6908 26.3549C14.5442 26.9475 14.5312 27.5653 14.6527 28.1635C14.8426 27.2331 15.3482 26.3969 16.0838 25.7964C16.8194 25.1959 17.7399 24.868 18.6895 24.8682Z" fill="#0A81FE"/>
        <path d="M36.1697 13.0288C33.9239 12.7342 31.6639 12.5609 29.3994 12.5098C29.6194 12.5551 30.0494 12.6531 30.6203 12.8236C33.3114 13.2493 35.9485 13.965 38.4856 14.9582C37.7713 14.2488 36.9965 13.6032 36.1697 13.0288Z" fill="#0A81FE"/>
        <path d="M10.3685 40.4435C9.56132 40.1424 8.84735 39.6347 8.29796 38.9711C7.74858 38.3075 7.38304 37.5113 7.2379 36.6621C7.20824 37.1375 7.18105 37.6145 7.15551 38.0972C7.11281 39.1369 7.4008 40.1633 7.97811 41.0289C8.55541 41.8946 9.3923 42.5549 10.3685 42.9151C14.64 44.4135 19.1096 45.2721 23.6323 45.4632L24.4562 41.344C24.4562 41.344 24.2741 41.344 23.966 41.3218L23.6323 42.9917C19.1096 42.8006 14.64 41.942 10.3685 40.4435Z" fill="#0A81FE"/>
        <path d="M39.2855 34.7539C38.9387 34.8445 32.53 37.2254 26.9279 37.2254C21.3258 37.2254 14.9171 34.8445 14.5703 34.7539C17.2624 37.2722 20.7726 38.7348 24.4564 38.8731L24.3229 39.5412C25.1878 39.6399 26.0574 39.6919 26.9279 39.697C27.7984 39.6919 28.668 39.6399 29.5329 39.5412L29.3994 38.8731C33.0832 38.7348 36.5934 37.2722 39.2855 34.7539Z" fill="#0A81FE"/>
        <path d="M23.3214 12.8022C23.847 12.6465 24.2474 12.5551 24.4558 12.5098C22.2129 12.56 19.9744 12.7302 17.7497 13.0197C16.9085 13.6035 16.1201 14.2598 15.3936 14.9813C17.9547 13.9859 20.6112 13.2557 23.3214 12.8022Z" fill="#0A81FE"/>
        <path d="M43.4871 40.4435C39.2156 41.942 34.746 42.8006 30.2233 42.9917L29.8937 41.3218C29.5815 41.344 29.3994 41.344 29.3994 41.344L30.2233 45.4632C34.746 45.2721 39.2156 44.4135 43.4871 42.9151C44.4624 42.5541 45.2982 41.8934 45.8745 41.0278C46.4509 40.1622 46.7382 39.1363 46.6951 38.0972C46.6737 37.6145 46.6465 37.1375 46.6169 36.6621C46.4718 37.5112 46.1064 38.3073 45.5571 38.9709C45.0079 39.6345 44.2941 40.1423 43.4871 40.4435Z" fill="#0A81FE"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="52.7258" height="52.7258" fill="white" transform="translate(0.564453 0.152344)"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
