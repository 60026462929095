import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Sports: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()
  const fill1 = color || theme.colors.flatOverlay

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_36851)">
      <path d="M10.1719 21.25C15.2201 21.25 19.3125 17.1576 19.3125 12.1094C19.3125 7.06115 15.2201 2.96875 10.1719 2.96875C5.12365 2.96875 1.03125 7.06115 1.03125 12.1094C1.03125 17.1576 5.12365 21.25 10.1719 21.25Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M10.1719 2.96143V21.2505" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M4.57031 4.8833C4.57031 4.8833 8.39844 8.46143 4.57031 18.9458" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M15.947 5.08594C15.947 5.08594 12.1111 8.66406 15.947 19.1484" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M1.03125 12.1094C1.03125 12.1094 10.9297 7.46099 19.3203 12.1094" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M6.74763 15.2582C9.20857 12.9144 16.3883 7.59409 24.5679 15.2582C24.8161 15.5134 24.955 15.8553 24.955 16.2113C24.955 16.5673 24.8161 16.9092 24.5679 17.1644C22.0758 19.6488 14.7633 25.391 6.70857 17.1644C6.4674 16.9032 6.33676 16.5588 6.34404 16.2034C6.35132 15.848 6.49597 15.5092 6.74763 15.2582V15.2582Z" fill={fill1} stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M9.90625 19.7583V12.7271" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M21.2969 19.8823V12.8511" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M12.4844 16.3667H18.7109" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M15.6016 15.2422V17.4922" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M13.6016 15.1094V17.3672" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M17.4453 15.2422V17.4922" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      </g>
      <defs>
      <clipPath id="clip0_1678_36851">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
      </defs>
    </Svg>
  )
}

export default Sports
