import {
  MetamaskIcon,
  WalletConnectIcon,
  CoinbaseIcon,
  FortmaticIcon,
  PortisIcon,
} from 'designStandard/components/atoms/Svg'
import { Config, ConnectorNames } from './types'

const connectors: Config[] = [
  {
    name: "Metamask",
    icon: MetamaskIcon,
    connectorId: ConnectorNames.Injected,
  },
  {
    name: "WalletConnect",
    icon: WalletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    name: 'Coinbase Wallet',
    icon: CoinbaseIcon,
    connectorId: ConnectorNames.WalletConnect
  },
  {
    name: 'Fortmatic',
    icon: FortmaticIcon,
    connectorId: ConnectorNames.WalletConnect
  },
  {
    name: 'Portis',
    icon: PortisIcon,
    connectorId: ConnectorNames.WalletConnect
  }
]

export default connectors

export const connectorLocalStorageKey = 'connectorId'