import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Collectibles: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()
  const lightFill = color ?? theme.colors.gradIconColor2
  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_36958)">
      <path d="M17.6016 1.66406H8.08594C7.5466 1.66406 7.10938 2.10128 7.10938 2.64062V6.25781C7.10938 6.79715 7.5466 7.23438 8.08594 7.23438H17.6016C18.1409 7.23438 18.5781 6.79715 18.5781 6.25781V2.64062C18.5781 2.10128 18.1409 1.66406 17.6016 1.66406Z" fill={theme.isDark ? "none" : lightFill} stroke="#E5E7EF" strokeMiterlimit="10"/>
      <path d="M18.2734 4.0625H7.72656C6.99737 4.0625 6.40625 4.65362 6.40625 5.38281V11.5781C6.40625 12.3073 6.99737 12.8984 7.72656 12.8984H18.2734C19.0026 12.8984 19.5938 12.3073 19.5938 11.5781V5.38281C19.5938 4.65362 19.0026 4.0625 18.2734 4.0625Z" fill={theme.isDark ? "none" : lightFill} stroke="#E5E7EF" strokeMiterlimit="10"/>
      <path d="M8.72006 12.1559L10.5872 8.37462C10.651 8.23346 10.7527 8.1128 10.8811 8.02609C11.0094 7.93938 11.1593 7.89003 11.3141 7.88354C11.4688 7.87706 11.6223 7.9137 11.7575 7.98938C11.8926 8.06506 12.0041 8.17679 12.0794 8.31212L13.2044 10.3902C13.2856 10.5443 13.4128 10.6692 13.5684 10.7475C13.7239 10.8257 13.9 10.8535 14.0721 10.8268C14.2442 10.8001 14.4037 10.7204 14.5282 10.5987C14.6528 10.477 14.7362 10.3195 14.7669 10.1481V10.1481C14.7893 9.97181 14.8677 9.80743 14.9907 9.67918C15.1136 9.55093 15.2745 9.46562 15.4497 9.43585C15.6248 9.40607 15.8049 9.43341 15.9633 9.51383C16.1217 9.59424 16.2501 9.72347 16.3294 9.88243L17.9622 12.1481C18.2904 12.7027 17.0247 12.4215 16.3997 12.4215H10.0404C9.43881 12.4293 8.47006 12.7106 8.72006 12.1559Z" fill="#E5E7EF"/>
      <path d="M22.4531 12.4297H3.54688C2.34738 12.4297 1.375 13.4021 1.375 14.6016V21.1094C1.375 22.3089 2.34738 23.2812 3.54688 23.2812H22.4531C23.6526 23.2812 24.625 22.3089 24.625 21.1094V14.6016C24.625 13.4021 23.6526 12.4297 22.4531 12.4297Z" fill={theme.isDark ? "none" : lightFill} stroke="#E5E7EF" strokeMiterlimit="10"/>
      <path d="M15.3828 15.0625H10.6094C9.83704 15.0625 9.21094 15.6869 9.21094 16.457C9.21094 17.2272 9.83704 17.8516 10.6094 17.8516H15.3828C16.1551 17.8516 16.7812 17.2272 16.7812 16.457C16.7812 15.6869 16.1551 15.0625 15.3828 15.0625Z" fill="#E5E7EF"/>
      </g>
      <defs>
      <clipPath id="clip0_1678_36958">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
      </defs>
    </Svg>
  )
}

export default Collectibles
