import styled from "styled-components"
import { space, typography as baseTypography, border, color as baseColor } from "styled-system"
import { TextProps } from "./types"
import { typography } from "../../../theme/typographyFonts"

const Text = styled.div<TextProps>`
  font-family: ${({ font }) => typography[font]?.fontFamily};
  font-style: ${({ font }) => typography[font]?.fontStyle};
  font-size: ${({ font }) => typography[font]?.fontSize};
  font-weight: ${({ bold, font }) => bold ? '600' : typography[font]?.fontWeight};
  line-height: ${({ font }) => typography[font]?.lineHeight};
  color: ${({ color }) => color};
  letter-spacing: ${({ font }) => typography[font]?.letterSpacing || 'inherit'};
  text-transform: ${({ font }) => typography[font]?.textTransform || 'inherit'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  word-break: ${({ breakWords }) => breakWords && 'break-word'};
  ${space}
  ${baseTypography}
  ${border}
  ${baseColor}
`

Text.defaultProps = {
  color: "textPrimary",
	font: "bodyRegularSmall"
}

export default Text
