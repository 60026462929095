import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface CoingeckoProps extends SvgProps {
  isBW?: boolean;
}

const Coingecko: React.FC<CoingeckoProps> = (props) => {
  const { isBW } = props 
  const theme = useTheme();
  const bgfill = isBW ? "none" : "#F9E988"
  const geckofill = isBW ? theme.colors.gradIconColor2 : "#8DC63F"
  const stroke = "#535252"
  
  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.4999 12.9449C25.5108 15.4171 24.7884 17.8371 23.4239 19.8987C22.0595 21.9604 20.1144 23.5711 17.8345 24.5273C15.5546 25.4834 13.0424 25.7421 10.6156 25.2705C8.1887 24.7989 5.95619 23.6182 4.20033 21.8778C2.44447 20.1374 1.24413 17.9154 0.751094 15.4928C0.258062 13.0702 0.494481 10.5558 1.43046 8.26761C2.36643 5.97938 3.95992 4.02008 6.00943 2.63748C8.05893 1.25488 10.4724 0.511075 12.9446 0.500123V0.500123C14.5862 0.492851 16.213 0.808977 17.7324 1.43045C19.2518 2.05193 20.6338 2.96658 21.7997 4.12218C22.9656 5.27779 23.8924 6.65172 24.5273 8.16552C25.1622 9.67932 25.4926 11.3033 25.4999 12.9449Z" fill={bgfill} stroke={geckofill}/>
      <path d="M24.5587 12.9491C24.57 15.2357 23.9029 17.4743 22.6418 19.3818C21.3807 21.2893 19.5822 22.7799 17.4739 23.6652C15.3655 24.5505 13.042 24.7906 10.7971 24.3552C8.55228 23.9199 6.48697 22.8285 4.8624 21.2193C3.23783 19.6101 2.12699 17.5552 1.67035 15.3146C1.21372 13.074 1.43182 10.7483 2.29706 8.63161C3.1623 6.51496 4.63582 4.70244 6.53124 3.4233C8.42667 2.14415 10.6589 1.45584 12.9455 1.44542V1.44542C16.0107 1.43194 18.9558 2.6361 21.1335 4.79322C23.3111 6.95034 24.5432 9.88389 24.5587 12.9491V12.9491Z" stroke={bgfill} fill={bgfill}/>
      <path d="M13.2118 2.18963C13.9467 2.05919 14.6989 2.05919 15.4338 2.18963C16.1757 2.30727 16.893 2.54757 17.5561 2.90068C18.2155 3.263 18.7852 3.75485 19.3523 4.2159C19.9193 4.67695 20.4836 5.14887 21.0207 5.66517C21.5691 6.17339 22.0476 6.75211 22.4437 7.38619C22.8515 8.01529 23.1858 8.68908 23.4401 9.39434C23.9229 10.8128 24.0905 12.3409 23.8495 13.7983H23.778C23.5361 12.3536 23.2082 10.9768 22.691 9.66699C22.4482 9.00861 22.1626 8.36684 21.8359 7.74579C21.4953 7.12128 21.1197 6.51645 20.7109 5.93419C20.2954 5.34768 19.7862 4.83356 19.2037 4.41246C18.6167 3.99398 17.9419 3.72314 17.2888 3.4659C16.6358 3.20865 15.9881 2.94506 15.307 2.74941C14.6258 2.55376 13.9338 2.40702 13.2109 2.26481L13.2118 2.18963Z" fill="white"/>
      <path d="M18.8678 8.86871C18.029 8.62596 17.1594 8.28176 16.279 7.93393C16.2283 7.71292 16.0326 7.43756 15.6368 7.1015C15.0616 6.60241 13.981 6.61509 13.0471 6.83611C12.0163 6.59335 10.9982 6.5073 10.0208 6.74553C2.02717 8.94752 6.55978 14.3198 3.625 19.7202C4.04257 20.606 8.54348 25.7745 15.0562 24.3877C15.0562 24.3877 12.8288 19.0354 17.8551 16.4665C21.9321 14.3751 24.8777 10.5055 18.8678 8.86871Z" fill={geckofill}/>
      <path d="M19.852 12.3842C19.8525 12.4801 19.8246 12.5739 19.7718 12.6539C19.719 12.7339 19.6437 12.7964 19.5554 12.8336C19.467 12.8708 19.3696 12.8809 19.2755 12.8627C19.1814 12.8446 19.0948 12.7989 19.0267 12.7315C18.9585 12.6641 18.9119 12.578 18.8927 12.4841C18.8734 12.3902 18.8825 12.2928 18.9187 12.204C18.9548 12.1153 19.0165 12.0392 19.0959 11.9855C19.1753 11.9318 19.2688 11.9029 19.3647 11.9023C19.4931 11.9019 19.6166 11.9523 19.7079 12.0426C19.7992 12.1329 19.8511 12.2558 19.852 12.3842Z" fill="white"/>
      <path d="M13.0469 6.83268C13.6293 6.87435 15.7353 7.55732 16.2788 7.93051C15.8259 6.6171 14.3014 6.44228 13.0469 6.83268Z" fill="#009345"/>
      <path d="M13.596 10.1545C13.5962 10.5967 13.4653 11.0289 13.2198 11.3967C12.9743 11.7644 12.6253 12.0511 12.2168 12.2204C11.8084 12.3898 11.359 12.4343 10.9253 12.3482C10.4916 12.2621 10.0932 12.0493 9.78041 11.7368C9.46764 11.4243 9.25457 11.0261 9.16813 10.5924C9.0817 10.1588 9.12579 9.70933 9.29482 9.30078C9.46386 8.89222 9.75025 8.54297 10.1178 8.29718C10.4853 8.05139 10.9175 7.9201 11.3596 7.91992V7.91992C11.9522 7.91992 12.5205 8.1552 12.9397 8.57405C13.3589 8.9929 13.5946 9.56104 13.5951 10.1536L13.596 10.1545Z" fill="white"/>
      <path d="M12.934 10.174C12.934 10.485 12.8418 10.7891 12.669 11.0476C12.4962 11.3062 12.2506 11.5078 11.9633 11.6268C11.676 11.7458 11.3598 11.777 11.0548 11.7163C10.7497 11.6556 10.4695 11.5058 10.2496 11.2859C10.0297 11.066 9.87995 10.7858 9.81928 10.4808C9.7586 10.1758 9.78974 9.8596 9.90876 9.57227C10.0278 9.28494 10.2293 9.03935 10.4879 8.86657C10.7465 8.69379 11.0505 8.60156 11.3615 8.60156C11.7786 8.60156 12.1785 8.76723 12.4734 9.06213C12.7683 9.35702 12.934 9.75698 12.934 10.174Z" fill={stroke}/>
      <path d="M21.6652 13.3695C19.8536 14.6458 17.7938 15.6141 14.8717 15.6141C13.504 15.6141 13.2268 14.1648 12.3228 14.8731C11.8563 15.2409 10.2114 16.0633 8.90614 16.0008C7.60089 15.9383 5.48494 15.1739 4.89527 12.3867C4.66157 15.1739 4.54201 17.2255 3.49219 19.5778C5.57552 22.9139 10.5429 25.4872 15.052 24.3785C14.571 20.9954 17.5321 17.682 19.1933 15.9891C19.8273 15.3469 21.0321 14.2989 21.6652 13.3704V13.3695Z" fill={geckofill}/>
      <path d="M21.595 13.4529C21.0325 13.9656 20.3631 14.3451 19.682 14.6802C18.9924 15.0073 18.2736 15.269 17.5352 15.4619C16.7961 15.6531 16.0252 15.7971 15.2445 15.7264C14.4637 15.6558 13.6656 15.3904 13.1484 14.8052L13.1729 14.7772C13.807 15.1884 14.5388 15.3333 15.2671 15.3542C16.0053 15.3768 16.7436 15.3161 17.4682 15.173C18.2001 15.0196 18.916 14.7982 19.6068 14.5118C20.2979 14.2264 20.9818 13.8958 21.5705 13.4248L21.595 13.4529Z" fill={stroke}/>
      </Svg>
  );
};

export default Coingecko;