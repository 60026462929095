import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Verified: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="54" height="51" viewBox="0 0 54 51" fill="none" {...props}>
			<rect x="10" y="10" width="30.4918" height="30.4918" fill="white"/>
      <path d="M52.5196 24.1211L48.7791 20.2549C48.1358 19.5899 47.8353 18.6654 47.9647 17.7492L48.7171 12.4236C48.9121 11.0436 47.9771 9.75858 46.6046 9.51608L41.3065 8.58691C40.3951 8.42706 39.6084 7.85555 39.1747 7.03814L36.6546 2.28858C36.0046 1.06108 34.4921 0.568582 33.2421 1.18108L28.4091 3.54255C27.578 3.94863 26.6061 3.94863 25.775 3.54255L20.9421 1.18108C19.6921 0.568582 18.1796 1.06108 17.5271 2.29108L15.0069 7.04274C14.5732 7.86038 13.7864 8.43209 12.8748 8.59197L7.57707 9.52108C6.20457 9.76108 5.26957 11.0461 5.46457 12.4261L6.21696 17.7517C6.34639 18.6679 6.04587 19.5924 5.40255 20.2574L1.66207 24.1236C0.69207 25.1261 0.69207 26.7136 1.66207 27.7161L5.40255 31.5823C6.04587 32.2472 6.34639 33.1718 6.21696 34.0879L5.46457 39.4136C5.26957 40.7936 6.20457 42.0786 7.57707 42.3211L12.8751 43.2503C13.7866 43.4101 14.5732 43.9816 15.0069 44.799L17.5271 49.5486C18.1796 50.7786 19.6921 51.2711 20.9446 50.6586L25.7745 48.2957C26.6059 47.8889 27.5784 47.8887 28.4099 48.295L33.2421 50.6561C34.4946 51.2686 36.0046 50.7761 36.6596 49.5461L39.1797 44.7965C39.6134 43.9791 40.4001 43.4076 41.3115 43.2478L46.6096 42.3186C47.9821 42.0786 48.9171 40.7911 48.7221 39.4111L47.9697 34.0854C47.8403 33.1693 48.1408 32.2447 48.7841 31.5798L52.5246 27.7136C53.4871 26.7136 53.4871 25.1236 52.5196 24.1211ZM26.7134 34.8323C25.5418 36.0038 23.6423 36.0038 22.4708 34.8323L17.0921 29.4536C16.1159 28.4774 16.1159 26.8947 17.0921 25.9186V25.9186C18.0682 24.9424 19.6509 24.9424 20.6271 25.9186L22.4708 27.7623C23.6423 28.9338 25.5418 28.9338 26.7134 27.7623L36.0571 18.4186C37.0332 17.4424 38.6159 17.4424 39.5921 18.4186V18.4186C40.5682 19.3947 40.5682 20.9774 39.5921 21.9536L26.7134 34.8323Z" fill="#0A84FF"/>
    </Svg>
  );
};

export default Verified;