import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = (props) => {
  const theme = useTheme()
  const opacity = theme.isDark ? '0.8' : '0.65';

  return (
    <Svg width="22" height="21" viewBox="0 0 22 21" fill="none" {...props}>
      <path d="M15.875 5.25L5.375 15.75" stroke="url(#paint0_linear_1890:1893)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.375 5.25L15.875 15.75" stroke="url(#paint1_linear_1890:1893)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_1890:1893" x1="15.35" y1="15.6532" x2="7.69229" y2="9.51459" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1} />
		      <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
        <linearGradient id="paint1_linear_1890:1893" x1="15.35" y1="15.6532" x2="7.69229" y2="9.51459" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1} />
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
