import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredVirtualWorld: React.FC<SvgProps> = (props) => {
  // const { color, fill } = props
  // const theme = useTheme()
  // const stroke = color || theme.colors.gradIconColor2
  // const fills = fill || "none"

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.38432 22.395V16.224C4.38234 15.8765 4.26447 15.5397 4.04954 15.2673C2.87414 13.821 2.21566 12.0212 2.17924 10.1553C2.09191 1.39175 12.9208 -1.63165 18.6773 3.50961C18.9553 3.76157 19.1314 4.10721 19.1722 4.48089L20.242 14.6028C20.2623 14.7698 20.309 14.9325 20.3802 15.0847L21.108 16.6476C21.2218 16.8894 21.2703 17.1571 21.2486 17.4237C21.2269 17.6903 21.1357 17.9465 20.9843 18.1666L20.1619 19.3497C19.9863 19.6106 19.8925 19.9184 19.8927 20.2333C19.8927 21.2192 19.6743 23.4466 17.1927 22.9208C17.1007 22.9132 17.0082 22.9132 16.9161 22.9208C15.5381 22.9405 14.2076 22.4154 13.2121 21.4589C12.2167 20.5023 11.6363 19.1912 11.5963 17.8087" stroke="#252727" strokeMiterlimit="10" fill="none"/>
      <path d="M13.7575 7.98657H1.40754C1.18246 7.98657 1 8.16967 1 8.39553V11.0538C1 11.2797 1.18246 11.4628 1.40754 11.4628H13.7575C13.9825 11.4628 14.165 11.2797 14.165 11.0538V8.39553C14.165 8.16967 13.9825 7.98657 13.7575 7.98657Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M15.1985 6.56982H17.1489V12.8723H15.1985C14.9264 12.8723 14.6654 12.7638 14.4729 12.5707C14.2805 12.3775 14.1724 12.1156 14.1724 11.8425V7.60684C14.1714 7.471 14.1973 7.33632 14.2484 7.21055C14.2995 7.08477 14.375 6.97039 14.4703 6.87399C14.5657 6.7776 14.6792 6.7011 14.8042 6.6489C14.9291 6.59669 15.0632 6.56982 15.1985 6.56982V6.56982Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M20.1545 3.5022H19.9944C18.4229 3.5022 17.1489 4.78061 17.1489 6.35763V13.0836C17.1489 14.6607 18.4229 15.9391 19.9944 15.9391H20.1545C21.7261 15.9391 23 14.6607 23 13.0836V6.35763C23 4.78061 21.7261 3.5022 20.1545 3.5022Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M8.36496 13.2739C10.2781 13.2739 11.829 11.6816 11.829 9.71741C11.829 7.7532 10.2781 6.16089 8.36496 6.16089C6.4518 6.16089 4.90088 7.7532 4.90088 9.71741C4.90088 11.6816 6.4518 13.2739 8.36496 13.2739Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
    </Svg>
  )
}

export default ColoredVirtualWorld
