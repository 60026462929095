import React from 'react'
import { useTheme } from "styled-components"
import {Svg, SvgProps} from 'designStandard/components/atoms/Svg'

const Icon: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme();

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M10 19L14.9394 14.0606C15.5227 13.4773 16.4773 13.4773 17.0606 14.0606L22 19" stroke={color ?? theme.colors.gradIconColor1} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="transparent"/>
</Svg>
  )
}

export default Icon
