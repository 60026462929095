import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state/index'
import { Team } from 'config/constants/types'
import { getWeb3NoAccount } from 'utils/web3'
// import { Toast, toastTypes } from 'toolkitUI'
import { Toast , toastTypes } from 'designStandard/components/molecules/Toast'
import {
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  scan as scanToast,
  setBlock,
} from './actions'
import { State, Farm, Transaction, Pool, ProfileState, TeamsState, AchievementState, PriceState } from './types'

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const web3 = getWeb3NoAccount()
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch])
}

// Farms

export const useFarms = (): Farm[] => {
  // eslint-disable-next-line no-console
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useTransactions = (): Transaction[] => {
  // eslint-disable-next-line no-console
  const transactions = useSelector((state: State) => state.transactions.data)
  return transactions
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (): Pool[] => {

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// // Toasts
// export const useToast = () => {
//   const dispatch = useAppDispatch()
//   const helpers = useMemo(() => {
//     const push = (toast: Toast) => dispatch(pushToast(toast))

//     return {
//       toastError: (title: string, description?: string) => {
//         return push({ id: kebabCase(title), type: ztoastTypes.DANGER, title, description })
//       },
//       toastInfo: (title: string, description?: string) => {
//         return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
//       },
//       toastSuccess: (title: string, description?: string) => {
//         return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
//       },
//       toastWarning: (title: string, description?: string) => {
//         return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
//       },
//       push,
//       remove: (id: string) => dispatch(removeToast(id)),
//       clear: () => dispatch(clearToast()),
//     }
//   }, [dispatch])

//   return helpers
// }


const toDateId = (id: string, sep?: string | '_') => {
  return [id, Date.now().toString()].join(sep)
}

/**
 * Toasts 
 * @redesign 0.0.1v
 * Basic toast actions same as above, with new ones
 */
export const useToast = () => {
  const dispatch = useAppDispatch()
  return useMemo(() => {
    /** @toaster new useToast */
    // Includes dynamically customizable methods in addition to previous ones

    const push = (toast: Toast) => dispatch(pushToast(toast))
    const scan = (toast: Toast) => dispatch(scanToast(toast))

     // Below method calls instantly trigger next available toast in each of their stack.
    return {
      /** @params:  
       * @title - Visible toast payload. @description - documenting this particular toastError instance. */
      toastError: (title: string, description?: string) => { 
        return push({ id: kebabCase(toDateId(title)), type: 'error', title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(toDateId(title)), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(toDateId(title)), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(toDateId(title)), type: toastTypes.WARNING, title, description })
      },
      toastProcessing: (type: 'info' | 'warning', payload?: string, id?: string | 'zap-processing') => {
        return push({ id: kebabCase(toDateId(id)), type: toastTypes[type], title: payload })
        },
        toastWallet: (type: 'info' | 'warning', payload?: string, id?: string | 'zap-processing') => {
        return push({ id: kebabCase(toDateId(id)), type: toastTypes[type], title: payload })
        },
        toastUser: (type: 'info' | 'warning', payload?: string, id?: string | 'zap-processing') => {
        return push({ id: kebabCase(toDateId(id)), type: toastTypes[type], title: payload })
        },
        toastMarket: (type: 'info' | 'warning' | 'success' | 'error', payload?: string, id?: string | 'zap-processing') => {
        return push({ id: kebabCase(toDateId(id)), type: toastTypes[type], title: payload })
        },
        toastTransaction: (type: 'info' | 'warning' | 'success' | 'error', payload?: string, id?: string | 'zap-processing') => {
        return push({ id: kebabCase(toDateId(id)), type, title: payload})
        },
        toastButton: (payload?: Toast) => {
          return scan(payload)
        },
      push,
      scan,
      clear: () => dispatch(clearToast()),
      remove: (id: string) => dispatch(removeToast(id)),
    }
  }, [dispatch])

}

// Profile

export const useFetchProfile = () => {

  // useEffect(() => {
  //   dispatch<any>(fetchProfile(account))
  // }, [account, dispatch])
}

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile)
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading }
}

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id])

  // useEffect(() => {
  //   dispatch<any>(fetchTeam(id))
  // }, [id, dispatch])

  return team
}

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams)

  // useEffect(() => {
  //   dispatch<any>(fetchTeams())
  // }, [dispatch])

  return { teams: data, isInitialized, isLoading }
}

// Achievements

export const useFetchAchievements = () => {

  // useEffect(() => {
  //   if (account) {
  //     dispatch<any>(fetchAchievements(account))
  //   }
  // }, [account, dispatch])
}

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data)
  return achievements
}

// Prices
export const useFetchPriceList = () => {

  // useEffect(() => {
  //   dispatch(fetchPrices())
  // }, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}

export const useGetApiPrice = (token: string) => {
  const prices = useGetApiPrices()

  if (!prices) {
    return null
  }

  return prices[token.toLowerCase()]
}

export const usePriceCakeBusd = (): BigNumber => {
  const ZERO = new BigNumber(0)
  const cakeBnbFarm = useFarmFromPid(1)
  const bnbBusdFarm = useFarmFromPid(2)

  const bnbBusdPrice = bnbBusdFarm.tokenPriceVsQuote ? new BigNumber(1).div(bnbBusdFarm.tokenPriceVsQuote) : ZERO
  const cakeBusdPrice = cakeBnbFarm.tokenPriceVsQuote ? bnbBusdPrice.times(cakeBnbFarm.tokenPriceVsQuote) : ZERO

  return cakeBusdPrice
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
