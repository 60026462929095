
// import { getZapMiner, getZapAggregator } from 'utils/contractHelpers'
// import Moralis from 'moralis'
import handleMoralisError from './useMoralisError'
import ZapDisputeEvent from '../entities/zapDisputeEvent'

export default async function useZapMiningEventsList(Moralis): Promise<ZapDisputeEvent[]> {
    const retVal: ZapDisputeEvent[] = []
    // Moralis.initialize("RNrYqlQiX38ISiNHty0DAqU9kNbydscmQNzAOACs");
    // Moralis.serverURL = "https://i32izegtzlgg.usemoralis.com:2053/server";
    try {
        const eventOb = Moralis.Object.extend("ZapPythiaDisputes")
        const query = new Moralis.Query(eventOb)
        query.descending("DisputedDate")
        query.limit(100)
        // const results = await query.find()
    
        const results = await query.find()
        for (let i = 0; i < results.length; i++) {
            const item = results[i]
            const disputedEvent = new ZapDisputeEvent()
            disputedEvent.id = String(item.attributes.DisputeID)
            disputedEvent.active = item.attributes.Ended
            disputedEvent.disputedValue = item.attributes.DisputedValue
            disputedEvent.disputedPair = item.attributes.DisputedPair
            disputedEvent.timestamp = item.attributes.DisputedDate
            disputedEvent.votes = item.attributes.Votes
            disputedEvent.submissionAddress = item.attributes.SubmissionAddress
            retVal.push(disputedEvent)
        }
    } catch (error) {
        handleMoralisError(error)
    }

    return retVal
}