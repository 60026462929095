import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const WalletConnect: React.FC<SvgProps> = (props) => (
  <Svg width="25" height="25" viewBox="0 0 388 238" fill="none" {...props}>
    <path d="M79.3586 46.4205C142.575 -15.4735 245.069 -15.4735 308.285 46.4205L315.893 53.8695C319.054 56.9642 319.054 61.9817 315.893 65.0764L289.867 90.5581C288.287 92.1055 285.724 92.1055 284.144 90.5581L273.674 80.3073C229.573 37.1286 158.071 37.1286 113.969 80.3073L102.757 91.2851C101.177 92.8324 98.6144 92.8324 97.034 91.2851L71.0079 65.8034C67.8471 62.7087 67.8471 57.6912 71.0079 54.5965L79.3586 46.4205ZM362.109 99.1193L385.272 121.798C388.433 124.893 388.433 129.91 385.272 133.005L280.828 235.267C277.667 238.361 272.542 238.361 269.382 235.267C269.381 235.267 269.381 235.267 269.381 235.267L195.253 162.689C194.463 161.915 193.182 161.915 192.391 162.689C192.391 162.689 192.391 162.689 192.391 162.689L118.265 235.267C115.104 238.361 109.979 238.361 106.818 235.267C106.818 235.267 106.818 235.267 106.818 235.267L2.37061 133.004C-0.790203 129.909 -0.790203 124.891 2.37061 121.797L25.5339 99.118C28.6947 96.0233 33.8194 96.0233 36.9802 99.118L111.11 171.697C111.9 172.471 113.181 172.471 113.971 171.697C113.971 171.697 113.971 171.697 113.971 171.697L188.097 99.118C191.258 96.0232 196.383 96.0231 199.544 99.1177C199.544 99.1178 199.544 99.1178 199.544 99.1179L273.673 171.697C274.463 172.471 275.744 172.471 276.535 171.697L350.663 99.1193C353.824 96.0246 358.948 96.0246 362.109 99.1193Z" fill="#3B99FC" />
  </Svg>
)

export default WalletConnect