import React, { FC, useState, useEffect, useCallback, useRef } from "react"
import { useMoralis } from 'react-moralis'
import { useSelector } from 'react-redux'
import { useLocation, Link, useHistory } from 'react-router-dom'
import styled from "styled-components"
import { Link as ExternalLink } from "designStandard/components/atoms/Link"
import { Logo } from "designStandard/components/atoms/Logo"
import {
  SearchIcon as BaseSearchIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookIcon,
  SideMenuIcon,
  CloseIcon,
  SunIcon,
  MoonIcon,
} from "designStandard/components/atoms/Svg"
import useTheme from "hooks/useTheme"
import { LogoutCard } from "pages/LogoutWallet"
import { Searchbox as BaseSearchbox } from "designStandard/components/molecules/Searchbox"
import { SearchResults } from "pages/SearchResults"
import { AppState } from 'state'
import { ZapNFT } from 'state/types'
import hideMenuOnOutsideClicked from 'utils/hideMenuOnOutsideClicked'
import Connector from './components/Connector'
import NavbarsNFT from './components/NavbarsNFT'
import MenuModal from "./components/NavbarMenu"
import { IconMap, menuItems } from './components/constants'
import Accordion from './components/Accordion'

const Flex = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: 16px;
  align-items: center;

  ${({theme}) => theme.mediaQueries.sm } {
    grid-gap: 8px;
  }
`
const HeaderWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.flatGlobal};
`

const HeaderCenteredWrapper = styled(Flex)<{ isMobile: boolean }>`
  height: ${({ isMobile }) => !isMobile ? '96px' : '72px'};
  margin: 0 auto;
  // box-shadow: ${({ theme }) => theme.shadows.idleMedium};

  .desktop-menu-modal {
    position: absolute;
    top: 0;
    right: 16px;
  }
  max-width: 1440px;
  @media (max-width: 1507px) {
    width: 1144px;
  }
  @media (max-width: 1208px) {
    width: 852px;
  }
  @media (max-width: 915px) {
    width: 560px;
  }
  @media (max-width: 623px) {
    width: auto;
    padding: 0 16px;
  }
`

const LinkWrapper = styled(Link)`
  margin-right: 8px;
`

const Dropdown = styled.div<{isMobile?: boolean}>`
    position: absolute;
    top: 0;
    left: ${({ isMobile }) => isMobile ? '0' : 'none'};
    right: ${({ isMobile }) => isMobile ? 'none' : '0'};
    width: ${({ isMobile }) => isMobile ? '100%' : 'fit-content' };
    padding: ${({ isMobile }) => isMobile ? '24px 16px' : '32px'};
    background: ${({ theme }) => theme.colors.flatGlobal};
    z-index: 100;

    .close-icon {
      position: absolute;
      right: 16px;
      top: 32px;
      height: 40px;
    }
  `

const AccountViewWrapper = styled.div`
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 16px;
  margin: 80px 0 32px 0;
`

const Searchbox = styled(BaseSearchbox)`
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  // width: 435px;

  // &:hover  {
  //   padding: 12px 15px;
  // }

  input {
    width: 100%; // 367px;
    max-width: 367px;
    padding: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
  }

`

const SearchIcon = styled(BaseSearchIcon)`
  cursor: pointer;
`

const IconWrapper = styled.div<{isMobile: boolean}>`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  padding: 8px;
  cursor: pointer;
  box-shadow: ${({ theme, isMobile }) => !isMobile ? theme.shadows.idleSoft : 'none'};
  border-radius: 16px;
  width: 48px;
  height: 48px;
  
  @media(min-width: 375px) {
    margin-left: 16px;
  }

  .menu-toggle {
    width: 100%;
    min-width: 32px;
    max-width: 32px;
  }
`

const Divider = styled.div`
  height: 9px;
  background: ${({ theme }) => theme.colors.dividerMorphBody};
`

const SocialWrapper = styled(Flex)`
  display: grid;
  grid-auto-flow: column;
  height: 64px;
  justify-items: center;
  justify-content: center;
  grid-gap: 32px;
`

const SocialWrap = styled(ExternalLink)<{ mr: number }>`
    margin-right: ${({ mr }) => `${mr}px`};
    opacity: 0.65;

  ${({theme}) => theme.mediaQueries.xs || theme.mediaQueries.sm } {
    margin-right: 0;
  }
`

const AccountMenu = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`
const LinkLabel = styled.div<{ isPushed?: boolean }>`
  color: ${({ theme }) => /* (isPushed ? */ theme.colors.textPrimary /* : "transparent") */};
  transition: color 0.4s;
  flex-grow: 1;
`

const NavItems = styled.div<{ isMobile?: boolean; size?: number }>`
  display: grid;
  grid-auto-flow: rows;
  grid-column-start: 1;
  grid-gap: 24px 0;
  padding: 24px 12px;
  background: ${({ theme }) => theme.colors.flatGlobal};

  > * a {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 16px;
  margin-right: 12px;
`

const MenuEntry = styled.div<{ secondary?: boolean; isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 64px;
  padding: ${({ secondary }) => (secondary ? '0 32px' : '0 16px')};
  font-size: ${({ secondary }) => (secondary ? '14px' : '16px')};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};
  box-shadow: ${({ isActive, theme }) => (isActive ? `inset 4px 0px 0px ${theme.colors.primary}` : 'none')};

  a {
    display: flex;
    font-size: 16px;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.flatOverlay};
  }

  // Safari fix
  flex-shrink: 0;
`


interface HeaderProps {
  profilePic?: string
  account?: string
  chainId?: number
  logout?: () => void
  isMobile: boolean
  isDropdown: boolean
  setIsDropdown: (param: any) => void
}

const HeaderNFT: FC<HeaderProps> = ({ account, chainId, profilePic, logout, isMobile, isDropdown, setIsDropdown }) => {
  const { isDark, toggleTheme, theme } = useTheme()
  const { pathname } = useLocation()
  const { Moralis, isAuthenticating } = useMoralis()
  const { nftList } = useSelector<AppState, AppState['zapNfts']>((state) => state.zapNfts)
  const wrapperRef = useRef(null)
  const menuRef = useRef(null)

  const [network, setNetwork] = useState('')
  const [query, setQuery] = useState('')
  const [enableSearch, setEnableSearch] = useState(false)
  const [allNfts, setAllNfts] = useState<ZapNFT[]>([])
  const [users, setUsers] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [isMenu, setIsMenu] = useState(false)
  const [selectedAccordion, setSelectedAccordion] = useState('')

  const [isSmallWindow, setSmallWindow] = useState(false)
  const [withText, setWithText] = useState(false)

  const screenWidth = window.innerWidth

  useEffect(() => {
    const swSet = screenWidth < 1209 && !isMobile
    const wtSet = swSet || isMobile
    setSmallWindow(swSet)
    setWithText(wtSet)
  }, [screenWidth, isMobile])

  useEffect(() => {
    if (chainId !== undefined) {
      switch (chainId) {
        case 1:
          setNetwork('ETH')
          break
        case 4:
          setNetwork('Rinkeby')
          break
        case 42:
          setNetwork('Kovan')
          break
        case 56:
          if (isMobile) {
            setNetwork('BSC-M')
          } else {
            setNetwork('BSC-Main')
          }
          break
        case 97:
          if (isMobile) {
            setNetwork('BSC-T')
          } else {
            setNetwork('BSC-Test')
          }
          break
        default:
          setNetwork('Not Supported')
      }
    } else if (pathname.includes('/labs')) {
      setNetwork('from ETH')
    } else if (pathname.includes('/pricefeed') || pathname.includes('/miningevents')) {
      if (isMobile) {
        setNetwork('from BSC-T')
      } else {
        setNetwork('from BSC-Test')
      }
    } else {
      setNetwork('')
    }
  }, [pathname, isMobile, chainId])

  useEffect(() => {
    const zapNfts = nftList && Object.values(nftList).filter(
      (nft) =>
        nft.ownerAddress !== '0x0000000000000000000000000000000000000000' &&
        nft.listed &&
        nft.chainId === (chainId === undefined ? 4 : chainId),
    )
    setAllNfts(zapNfts)
  }, [chainId, nftList])

  useEffect(() => {
    const getUsers = async () => {
      const usersArray = []
      const nftUsers = Moralis.Object.extend('NftUser')
      const userQuery = new Moralis.Query(nftUsers)
      const result = await userQuery.find()

      result.forEach((element) => usersArray.push(element?.attributes))
      setUsers(usersArray)
    }
    getUsers()
  }, [Moralis.Object, Moralis.Query])

  useEffect(() => {
    if (query || enableSearch) setIsVisible(true)
    else {
      setQuery('')
      setIsVisible(false)
    }
  }, [enableSearch, query])

  useEffect(() => {
    if (!isVisible) setEnableSearch(false)
  }, [isVisible])

  const changeHandler = useCallback((input: string) => {
    setQuery(input)
  }, [])

  const searchHandler = useCallback(() => {
    setEnableSearch(!enableSearch)
  }, [enableSearch])

  const dropdownHandler = useCallback(() => {
    setIsDropdown(true)
  }, [setIsDropdown])

  const backHandler = useCallback(() => {
    setIsDropdown(false)
  }, [setIsDropdown])

  const history = useHistory()
  // const navigateToActivity = useCallback(() => {
  //     history.push('/nft/activitypage')
  // }, [history])

  hideMenuOnOutsideClicked(wrapperRef, setIsVisible)
  hideMenuOnOutsideClicked(menuRef, setIsMenu)

  const isMid = window.innerWidth < 916

  const closeHandler = useCallback((title: string) => {
    setSelectedAccordion(title)
  }, [])

  return (
    <>
      <HeaderWrapper>
        <HeaderCenteredWrapper isMobile={isMobile}>
          <LinkWrapper to='/nft'>
            <Logo withText={!withText} size={48} />
          </LinkWrapper>
          {isMid ? (
            <Flex style={{ gridGap: 0 }}>
              <IconWrapper style={{ padding: '8px 0' }} onClick={searchHandler} isMobile={isMid}>
                <SearchIcon width={24} height={24} isMobile={isMid} />
              </IconWrapper>
              <IconWrapper style={{ padding: '8px 0' }} onClick={toggleTheme} isMobile={isMid}>
                {isDark ? <SunIcon width={24} height={24} /> : <MoonIcon width={24} height={24} />}
              </IconWrapper>
              <IconWrapper style={{ padding: '8px 0' }} onClick={dropdownHandler} isMobile={isMid}><SideMenuIcon width={24} height={24} /></IconWrapper>
            </Flex>
          ) : !isSmallWindow ? (
            <>
              <Searchbox
                type="filled"
                state="active"
                placeholder="Search for NFTs, Collections, Artists & Users"
                changeHandler={changeHandler}
              />
              <NavbarsNFT isMobile={isMobile} account={account} dropdownHandler={backHandler} />
              <AccountMenu isMobile={isMobile}>
                <Connector
                  isAuthenticating={isAuthenticating}
                  isMobile={isMobile}
                  account={account}
                  chainId={chainId}
                  img={profilePic}
                  network={network}
                  logout={logout}
                />
                <IconWrapper onClick={() => setIsMenu(!isMenu)} ref={menuRef} isMobile={isMobile}>
                  {isMenu ? <CloseIcon className='menu-toggle'/> : <SideMenuIcon className='menu-toggle'/>}
                </IconWrapper> 
              </AccountMenu>
            </>
          ) : (
            <>
              <NavbarsNFT isMobile={isMobile} account={account} dropdownHandler={backHandler} />
              <IconWrapper isMobile={isMobile} style={{ padding: '8px 0', background: theme.colors.flatOverlay, marginLeft: 0 }} onClick={searchHandler}>
                <SearchIcon width={24} height={24} isMobile={isMobile} />
              </IconWrapper>
              <AccountMenu isMobile={isMobile}>
                <Connector
                  isAuthenticating={isAuthenticating}
                  isMobile={isMobile}
                  account={account}
                  chainId={chainId}
                  img={profilePic}
                  network={network}
                  logout={logout}
                />
                <IconWrapper onClick={() => setIsMenu(!isMenu)} ref={menuRef} isMobile={isMobile}>
                  {isMenu ? <CloseIcon className='menu-toggle'/> : <SideMenuIcon className='menu-toggle'/>}
                </IconWrapper> 
              </AccountMenu>
            </>
          )}
        </HeaderCenteredWrapper>
      </HeaderWrapper>
      <div className='desktop-menu-modal' ref={menuRef}>
        {isMenu && (
        <MenuModal isMobile={isMobile} onClose={() => setIsMenu(false)} account={account} history={history}/>
        )}
      </div>
      <div ref={wrapperRef}>
        {isVisible ? (
          <SearchResults
            nfts={allNfts}
            users={users}
            query={query}
            isMobile={isMobile}
          />
        ) : (
          <></>
        )}
      </div>
      {isDropdown && isMid && (
        <Dropdown isMobile={isMid}>
            <CloseIcon className='close-icon' onClick={backHandler} />
            <AccountViewWrapper >
            {!account ? (
              <Connector
                isAuthenticating={isAuthenticating}
                isMobile={isMid}
                account={account}
                chainId={chainId}
                img={profilePic}
                network={network}
                logout={logout}
                closeDropdown={backHandler}
                />
                ) : (
                  <LogoutCard
                  account={account}
                  chainId={chainId}
                  img={profilePic}
                  network={network}
                  logout={logout}
                  isMobile={isMid}
                  onClose={()=>null}
                  />
                  )}
              </AccountViewWrapper>
            {/* <Divider /> */}
            <NavItems isMobile={isMid}>
          {menuItems.map((entry) => {
            const index = entry.icon
            const Icon = entry.icon ? IconMap[index] : null
            if (entry.items) {
              const itemsMatchIndex = entry.items.findIndex((item) => item.href === window.location.pathname)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0
              return (
                <Accordion
                  key={entry.label}
                  className={`menu-tab-${entry.label}`}
                  icon={Icon}
                  label={entry.label}
                  // initialOpenState={initialOpenState}
                  isActive={entry.items.some((item) => item.href === window.location.pathname)}
                  onOpen={closeHandler}
                  isOpened={entry.label === selectedAccordion}
                >
                  {entry.items.map((item) => {
                    const subIndex = item.icon
                    const subIconElement = item.icon ? IconMap[subIndex] : null
                    return (
                      <MenuEntry key={item.href} secondary isActive={false} /* onClick={handleClick} */>
                        <ExternalLink href={item.href}>
                          <IconContainer>{subIconElement}</IconContainer>
                          <LinkLabel>{item.label}</LinkLabel>
                        </ExternalLink>
                      </MenuEntry>
                    )
                  })}
                </Accordion>
              )
            }
            return (
              <MenuEntry isActive={window.location.pathname.includes(entry.href)} /* className={calloutClass} */>
                <ExternalLink href={entry.href} /* onClick={handleClick} */>
                  <IconContainer>{Icon}</IconContainer>
                  {entry.label}
                </ExternalLink>
              </MenuEntry>
            )
          })}
        </NavItems>
            <Divider />
            <SocialWrapper>
              {/* <Logo withText size={24} /> */}
              {/* <Flex> */}
                <SocialWrap href="https://twitter.com/ZapProtocol" mr={27}>
                  <TwitterIcon  />
                </SocialWrap>
                <SocialWrap href="https://www.linkedin.com/company/zapprotocol" external mr={27}>
                  <LinkedinIcon />
                </SocialWrap>
                <SocialWrap href="https://facebook.com/ZapProtocol/" external mr={0}>
                  <FacebookIcon />
                </SocialWrap>
              {/* </Flex> */}
            </SocialWrapper>
        </Dropdown>
      )}
    </>
  )
}

export default HeaderNFT