import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredUtilities: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()

  return (
    <Svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.29574 3.04495L5.06297 2.42114L7.09931 3.805L6.54718 4.25673L12.1543 11.2263L12.771 10.7387L21.0814 19.2498C21.7984 20.1676 21.024 22.0319 20.1062 22.7704C19.1884 23.5089 17.2883 23.8101 16.5211 22.8923L9.9746 12.9614L10.5841 12.4739L4.96975 5.49718L4.41045 5.94891L3.49268 3.66877L4.29574 3.04495Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M21.0815 9.34033C21.738 8.68723 22.1822 7.85132 22.3562 6.94182C22.5301 6.03232 22.4258 5.0915 22.0567 4.24224L18.6078 7.69115L16.1556 5.23892L19.583 1.81153C18.7354 1.46179 17.8029 1.37229 16.9043 1.55444C16.0056 1.73659 15.1816 2.18214 14.5371 2.83431C13.8926 3.48649 13.4569 4.31576 13.2854 5.21647C13.1139 6.11719 13.2145 7.04856 13.5743 7.89192L1.84365 19.0417C1.60015 19.2281 1.39934 19.4644 1.25463 19.7347C1.10992 20.005 1.02466 20.3032 1.00461 20.6092C0.984547 20.9151 1.03017 21.2219 1.13835 21.5088C1.24653 21.7957 1.4148 22.0562 1.63187 22.2727C1.84895 22.4893 2.10983 22.6569 2.397 22.7644C2.68417 22.8719 2.99099 22.9168 3.29692 22.896C3.60284 22.8752 3.9008 22.7893 4.1708 22.6439C4.44079 22.4986 4.67656 22.2972 4.86235 22.0532L15.8257 10.2438C16.6876 10.6552 17.6559 10.7893 18.5972 10.6275C19.5385 10.4657 20.4065 10.016 21.0815 9.34033V9.34033Z" fill="#3480F6" stroke="#252727" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default ColoredUtilities
