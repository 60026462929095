import { createBrowserHistory } from 'history'

// Manually create the history object so we can access outside the Router e.g. in modals
const history = createBrowserHistory()

history.listen(() => {
  window.scrollTo(0, 0)
})

export default history
