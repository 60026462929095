/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'designStandard/components/atoms/Text'
import { Link } from 'designStandard/components/atoms/Link'
import { 
  SunIcon, 
  MoonIcon, 
  FacebookIcon, 
  InstagramIcon, 
  LinkedinIcon, 
  TwitterIcon,  
  EthDark 
  } from 'designStandard/components/atoms/Svg'
import BinanceOriginal from 'designStandard/components/atoms/Svg/Icons/BinanceOriginal'
import { Divider } from 'designStandard/components/atoms/Divider'
import { GridBox } from 'designStandard/components/atoms/GridLayout'
import { Button } from 'designStandard/components/molecules/Button'
import useTheme from "hooks/useTheme"
import { IconMap, menuItems } from './constants'
import Accordion from './Accordion'


interface NavbarMenuProps {
  account?: string | null;
  history?: any;
  // network?: string;
  // profilePic?: string | null;
  isMobile: boolean
  // onLogout?: () => void;
  onClose?: () => void
}

export const NavbarMenu: React.FC<NavbarMenuProps> = ({ isMobile, onClose = () => null, account, history }) => {
  const [selectedAccordion, setSelectedAccordion] = useState('')
  const { isDark, toggleTheme } = useTheme()


  const mintHandler = useCallback(() => {
    if (account) {
      history.push('/nft/create')
      onClose()
    }
  }, [account, history, onClose])

  const handleRedirect = (ref) => {
    window.location.href=ref
  }

  const closeHandler = useCallback((title: string) => {
    setSelectedAccordion(title)
  }, [])

  return (
    <ModalWrapper isMobile={isMobile} width={279}>
      <Modal>
        <MenuHeader>
          <GridBox columns="auto auto" gridGap="8px" justify='space-between' mb="40px" mr="1px" >
            <Text font="dropdownItemSemiBold" color="textPrimary">{`${'Get ZAP'}`}</Text>
            <ButtonContainer>
              <ButtonWrapper id="1" className="get-zap-btn" onClick={() => handleRedirect('https://app.uniswap.org/#/swap?outputCurrency=0x6781a0f84c7e9e846dcb84a9a5bd49333067b104')}>
                <ButtonETH>
                  <EthDark />
                </ButtonETH>
              </ButtonWrapper>
              <ButtonDividerWrapper>
                <ButtonDivider />
              </ButtonDividerWrapper>
              <ButtonWrapper id="2" className="get-zap-btn" onClick={() => handleRedirect("https://pancakeswap.finance/info/token/0xc5326b32e8baef125acd68f8bc646fd646104f1c")}>
                <ButtonBSC>
                  <BinanceOriginal height={22} />
                </ButtonBSC>
              </ButtonWrapper>
            </ButtonContainer>
          </GridBox>
          <GridBox columns="auto auto" gridGap="8px" justify='space-between' mb="15px" pr="5px">
            <Text font="dropdownItemSemiBold" color="textPrimary">{`${'Switch Theme'}`}</Text>
            {isDark ? 
            <ButtonWrapper onClick={toggleTheme}>
              <SunIcon width={30} height={30} />
            </ButtonWrapper> 
            : 
            <ButtonWrapper onClick={toggleTheme} >
              <MoonIcon width={30} height={30} />
            </ButtonWrapper>}
          </GridBox>
        </MenuHeader>
        <DividerWrapper>
          <Divider />
        </DividerWrapper> 
        <NavItems isMobile={isMobile}>
          {menuItems.map((entry) => {
            const index = entry.icon
            const Icon = entry.icon ? IconMap[index] : null
            if (entry.items) {
              const itemsMatchIndex = entry.items.findIndex((item) => item.href === window.location.pathname)
              const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0
              return (
                <Accordion
                  key={entry.label}
                  icon={null}
                  label={entry.label}
                  // initialOpenState={initialOpenState}
                  isActive={entry.items.some((item) => item.href === window.location.pathname)}
                  onOpen={closeHandler}
                  isOpened={entry.label === selectedAccordion}
                >
                  {entry.items.map((item) => {
                    const subIndex = item.icon
                    const subIconElement = item.icon ? IconMap[subIndex] : null
                    return (
                      <MenuEntry key={item.href} secondary isActive={false}/* onClick={handleClick} */>
                        <Link font="dropdownItemSemiBold" ml={-4} href={item.href}>
                          <IconWrapper>{subIconElement}</IconWrapper>
                          <LinkLabel>{item.label}</LinkLabel>
                        </Link>
                      </MenuEntry>
                    )
                  })}
                </Accordion>
              )
            }
            return (
              <MenuEntry isActive={window.location.pathname.includes(entry.href)} /* className={calloutClass} */>
                <Link href={entry.href} padding="10px" font='dropdownItemSemiBold' /* onClick={handleClick} */>
                  {/* <IconWrapper>{Icon}</IconWrapper> */}
                  {entry.label}
                </Link>
              </MenuEntry>
            )
          })}
        </NavItems>
        <DividerWrapper>
          <Divider />
        </DividerWrapper> 
        <GridBox pt="11px" columns="repeat(4, 48px)" colSpan="1 / span 4" gridGap="8px" justify="center">
          <Link href="https://twitter.com/ZapProtocol">
            <TwitterIcon color='searchIconInactiveStroke'/>
          </Link>
          <Link href="https://www.linkedin.com/company/zapprotocol">
            <LinkedinIcon color='searchIconInactiveStroke' />
          </Link>
          <Link href="https://www.instagram.com/zapprotocol">
            <InstagramIcon color='searchIconInactiveStroke'/>
          </Link>
          <Link href="https://facebook.com/ZapProtocol/">
            <FacebookIcon color='searchIconInactiveStroke'/>
          </Link>
        </GridBox>
      </Modal>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div<{ isMobile?: boolean; width?: number }>`
  position: absolute;
  margin-top: 8px;
  right: 8px;
  background: linear-gradient(${({ theme }) => theme.colors.flatGlobal}, ${({ theme }) => theme.colors.flatGlobal})
      padding-box,
    ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  border: 2px solid transparent;
  border-image-source: ${({ theme }) => theme.colors.strokeGradNorm};
  // border-image: ${({ theme }) => theme.colors.strokeGradNorm};
  border-radius: 16px;
  border-image-slice: 0;
  width: 100%;
  max-width: ${({ width }) => width ?? '279'}px;
  z-index: 100;
  display: grid;
`
const Modal = styled.div`
  width: 100%;
  grid-gap: 16px 0;
  margin-top: 16px;
  margin-bottom: 11px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 16px;
  width: 102px;
  height: 40px;
  align-content: center;
  align-items: center;

`
const ButtonETH = styled.div`
  &:hover:not(:active):not(:disabled){
    border-radius: 16px 0 0 16px;
  }   
  &:active {
    border-radius: 16px 0 0 16px;
  }
`

const ButtonBSC = styled.div`
  border-radius: 0 16px 16px 0;
  &:hover:not(:active):not(:disabled){
    border-radius: 0 16px 16px 0;
  }   
  &:active {
    border-radius: 0 16px 16px 0;
  }
`

const ButtonDividerWrapper=styled.div`
position: absolute;
right: 65px;
height: 2px;
width: 20px;
transform: rotate(90deg);
background: #252727;
`

const ButtonDivider = styled(Divider)`
  width: 20px;
  rotate: 90deg;
  height: 2px;
`

/** Modal inner contents */
/** If size is 0, make a full page menu */
const MenuHeader = styled.div`
margin: 10px 24px;
  // display: grid;
  // grid-template-rows: repeat(2, 1fr);
  // grid-column: 1 / span 3;
  // width: 100%;
  // justify-content: space-evenly;
  
  // .get-zap {
  //   grid-row: 1;
  //   grid-column: 1 / span 3;
  // }
  // .get-zap-btn {
  //   grid-row: 2;
  //   }

  // }
  // .get-zap-btn[id='1'] {
  //   grid-column: 1;
  // }
  // .get-zap-btn[id='2'] {
  //   grid-column: 3;
  // }
`

const LinkLabel = styled.div<{ isPushed?: boolean }>`
  color: ${({ theme }) => /* (isPushed ? */ theme.colors.textPrimary /* : "transparent") */};
  transition: color 0.4s;
  flex-grow: 1;
  margin: 8px;
`

const NavItems = styled.div<{ isMobile?: boolean; size?: number }>`
  display: grid;
  grid-auto-flow: rows;
  grid-column-start: 1;
  grid-gap: 8px 0;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.flatGlobal};

  > * a {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 16px;
  margin-right: 8px;
`

const MenuEntry = styled.div<{ secondary?: boolean; isActive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 64px;
  padding: ${({ secondary }) => (secondary ? '0 45px' : '0 4px')};
  font-size: ${({ secondary }) => (secondary ? '16px' : '16px')};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};
  box-shadow: ${({ isActive, theme }) => (isActive ? `inset 4px 0px 0px ${theme.colors.primary}` : 'none')};

  a {
    display: flex;
    font-size: 14px;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.flatOverlay};
  }

  // Safari fix
  flex-shrink: 0;
`

const DividerWrapper = styled.div`
  // height: 27px;
  // display: flex;
  // flex-direction: column;
  // grid-column: 1 / span 3;
  // min-width: 100%;
  // background: linear-gradient(180deg, rgba(60, 60, 60, 0) 0%, rgba(81, 81, 81, 0.18) 100%);
  // padding: 0 0 8px;
  position: absolute;
  right: 14px;
  height: 2px;
  width: 250px;
  background: ${({theme}) => theme.colors.gradStroke};
  transform: rotate(-0.03deg);  
`

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 8px;
  align-items: start;
  justify-content: center;
  justify-items: space-between;
  cursor: pointer;
  // padding: 8px;

  button {
    background: ${({ theme }) => theme.colors.flatOverlay};
    // border-radius: 16px;
    border: none;
    box-shadow: none;
    width: 0;
    height: 40px;
    &:hover:not(:active):not(:disabled) {
      border: none;
    }

    svg {
      // margin-right: 5px;
    }
  }
`

const MintButton = styled(Button)`
  width: calc(100% - 16px);
  margin: 8px;
`

export default NavbarMenu
