import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import Logo from '../Svg/Icons/LogoWhite'
import { LoadingSpinnerProps } from "./types";

const ZapLoadingLogo = styled(Logo)`
  height: 100px;
  width: 60px;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 1000;
  #white-logo1, #white-logo2 {
    animation: fill-outline 5s linear infinite;
  }
  @keyframes fill-outline {
    0% { stroke-dashoffset: 1000; }
    50% { stroke-dashoffset: 0; }
    100% { stroke-dashoffset: -1000; }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 150px;
    width: 90px;
  }
`

const Spinner: React.FC<LoadingSpinnerProps> = ({ isOpened }) => (
  <Modal
    center
    open={isOpened}
    onClose={() => console.log('modal closed')}
    closeOnOverlayClick={false}
    showCloseIcon={false}
  >
    <ZapLoadingLogo />
  </Modal>
)

export default Spinner;