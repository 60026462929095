import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import TokenIcon from "./TokenIcon";
import ZapTokenIcon from './ZapToken';

export interface TokenPairIconProps {
  tokenLeft: string;
  tokenRight: string;
  type?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const LeftIcon = styled.div`
  z-index: 1;
`

const RightIcon = styled.div`
  margin-left: -16px;
`

const CurrencyIcon = styled.div<{ src: string }>`
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.src})`};
  border-radius: 50%;
`

const XCDIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: lightgrey;
  border-radius: 50%;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
`

const Icon: React.FC<TokenPairIconProps> = ({ tokenLeft, tokenRight, type }) => {
  const imgURL = `https://wise.com/public-resources/assets/flags/rectangle/${tokenLeft.toLowerCase()}.png`;

  const [flag, setFlag] = useState(false);

  const handleOnCompleted = useCallback(() => setFlag(true), []);
  const handleIconError = useCallback(() => setFlag(false), []);

  return (
    <Container>
      <LeftIcon>
        {flag
          ? (tokenLeft.toLowerCase() === 'zap'
            ? <ZapTokenIcon />
            : <TokenIcon
                name={tokenLeft.toLowerCase()}
                onCompleted={handleOnCompleted}
                onError={handleIconError}
                width='32px'
                height='32px'
                viewBox='0 0 32 32'
              />
            )
          : (tokenLeft.toLowerCase() === 'xcd'
            ? <XCDIcon>XCD</XCDIcon>
            : type === 'crypto'
              ? <TokenIcon
                  name={tokenRight.toLowerCase()}
                  onCompleted={handleOnCompleted}
                  onError={handleIconError}
                  width='32px'
                  height='32px'
                  viewBox='0 0 32 32'
                />
              : <CurrencyIcon src={imgURL}/>
          )
        }
      </LeftIcon>
      <RightIcon>
        <TokenIcon
          name={tokenRight.toLowerCase()}
          onCompleted={handleOnCompleted}
          onError={handleIconError}
          isRight
          width='32px'
          height='32px'
          viewBox='0 0 32 32'
        />
      </RightIcon>
    </Container>

  )
}

export default Icon