import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const VirtualWorld: React.FC<SvgProps> = (props) => {
  const { color, fill } = props
  const theme = useTheme()
  const stroke = color || theme.colors.gradIconColor2
  const fills = fill || "none"

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_36931)">
      <path d="M4.82088 23.9373V17.3123C4.82248 16.9399 4.69554 16.5785 4.4615 16.2889C3.20515 14.7391 2.50137 12.8147 2.4615 10.8201C2.36775 1.46073 13.9928 -1.76583 20.1646 3.73417C20.4666 3.99865 20.6589 4.36647 20.7037 4.76542L21.8521 15.5935C21.8739 15.7747 21.924 15.9514 22.0006 16.117L22.7818 17.7889C22.9041 18.0462 22.9562 18.3313 22.9329 18.6153C22.9096 18.8993 22.8116 19.1721 22.649 19.406L21.7662 20.6795C21.5792 20.9477 21.4784 21.2666 21.4771 21.5935C21.4771 22.6482 21.2349 25.031 18.5787 24.4685C18.4799 24.4609 18.3806 24.4609 18.2818 24.4685C16.8018 24.4917 15.3724 23.9306 14.3033 22.9069C13.2343 21.8832 12.6118 20.4794 12.5709 18.9998" fill={fills} stroke={stroke} strokeMiterlimit="10"/>
      <path d="M14.8906 8.49219H1.63281C1.39119 8.49219 1.19531 8.68806 1.19531 8.92969V11.7734C1.19531 12.0151 1.39119 12.2109 1.63281 12.2109H14.8906C15.1323 12.2109 15.3281 12.0151 15.3281 11.7734V8.92969C15.3281 8.68806 15.1323 8.49219 14.8906 8.49219Z" fill={fills} stroke={stroke} strokeMiterlimit="10"/>
      <path d="M16.4297 6.98438H18.5235V13.7266H16.4297C16.1389 13.7224 15.8616 13.6035 15.6581 13.3957C15.4547 13.1878 15.3417 12.908 15.3438 12.6172V8.08594C15.3437 7.79647 15.4577 7.51863 15.6609 7.31249C15.8641 7.10635 16.1403 6.98848 16.4297 6.98438Z" fill={fills} stroke={stroke} strokeMiterlimit="10"/>
      <path d="M21.75 3.69531H21.5781C19.8911 3.69531 18.5234 5.06294 18.5234 6.75V13.9453C18.5234 15.6324 19.8911 17 21.5781 17H21.75C23.4371 17 24.8047 15.6324 24.8047 13.9453V6.75C24.8047 5.06294 23.4371 3.69531 21.75 3.69531Z" fill={fills} stroke={stroke} strokeMiterlimit="10"/>
      <path d="M9.10156 14.1562C11.1554 14.1562 12.8203 12.4528 12.8203 10.3516C12.8203 8.25029 11.1554 6.54688 9.10156 6.54688C7.04775 6.54688 5.38281 8.25029 5.38281 10.3516C5.38281 12.4528 7.04775 14.1562 9.10156 14.1562Z" fill={fills} stroke={stroke} strokeMiterlimit="10"/>
      </g>
      <defs>
      <clipPath id="clip0_1678_36931">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
      </defs>
    </Svg>
  )
}

export default VirtualWorld
