import React from "react";
import styled, { useTheme } from "styled-components";
import { DisclosureButtonProps } from "./types";
import { UpIcon, DownIcon, BackwardIcon, ForwardIcon } from "../../atoms/Svg";

const IconButton = styled.button<{size: number}> `
    display: grid;
    justify-content: center;
    align-content: center;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: 
     linear-gradient(${({ theme }) => theme.colors.flatCheckBox},${({ theme }) => theme.colors.flatCheckBox}) padding-box,
     ${({ theme }) => theme.colors.strokeGradNorm} border-box;
    border: 1px solid transparent;
    border-radius:100px;
    cursor: pointer;
    &:disabled {
        background: ${({ theme }) => theme.colors.flatOverlay};
    }
    &:hover:not(:disabled):not(:active) {
        box-shadow: ${({ theme }) => theme.shadows.idleSoft};
    }
    &:active:not(:disabled) {
        box-shadow: ${({ theme }) => theme.shadows.pressedMedium};
    }
    &:focus:not(:disabled):not(:active):not(:hover) {
        background: 
            linear-gradient(${({ theme }) => theme.colors.flatCheckBox},${({ theme }) => theme.colors.flatCheckBox}) padding-box,
            ${({ theme }) => theme.isDark ? 'linear-gradient(107.54deg, #ECEFF7 0.41%, #EBEBEC 100.35%)' : 'linear-gradient(103.62deg, #464646 7.23%, #222424 96.5%)'} border-box;
    }
`


const DisclosureButton: React.FC<DisclosureButtonProps> = ({size, arrow, onClick, disabled}) => {
    const theme = useTheme();
    const color1 = theme.colors.gradIconColor1
    const color2 = theme.colors.gradIconColor2
  return (
    <>
        {
            arrow === 'Top' ? 
            <IconButton size={size} onClick={onClick} disabled={disabled}>
                <UpIcon size={size} color1={color1} color2={color2} />
            </IconButton> : 
            arrow === 'Down' ? 
            <IconButton size={size} onClick={onClick} disabled={disabled}>
                <DownIcon size={size} color1={color1} color2={color2} />
            </IconButton> :
            arrow === 'Left' ?
            <IconButton size={size} onClick={onClick} disabled={disabled}>
                <BackwardIcon size={size} color1={color1} color2={color2} />
            </IconButton> :
            arrow === 'Right' ?
            <IconButton size={size} onClick={onClick} disabled={disabled}>
                <ForwardIcon size={size} color1={color1} color2={color2} />
            </IconButton> :
            <></>
        }
    </>
  );
};
export default DisclosureButton;