import { useState, useEffect } from 'react'
import { getRegistryContract } from 'utils/contractHelpers'
import { ethers } from 'ethers'
import { useAppDispatch } from 'state'
import { updateUserKey, updateUserTitle } from 'state/user/actions'
import useWeb3 from './useWeb3'

const useProviderTitle = () => {
  const [title, setTitle] = useState('')
  const [pKey, setPKey] = useState('')

  const { web3, chainId, account } = useWeb3()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const registry = getRegistryContract(web3, chainId)
        const res = await registry.methods.getProviderTitle(account).call()
        const key = await registry.methods.getPublicKey(account).call()
        // console.log(res, key)
        setTitle(ethers.utils.parseBytes32String(res))
        setPKey(key)
      } catch (error) {
        console.log(error)
      }
    }

    if (account) {
      fetchTitle().then(() => {
        dispatch<any>(updateUserTitle({ userTitle: title }))
        dispatch<any>(updateUserKey({ userKey: pKey }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, web3, chainId, title, pKey])

  return title
}

export default useProviderTitle
