import React from "react";
import Svg from "../../Svg";
import { SvgProps } from "../../types";

const Icon: React.FC<SvgProps>
  = (props) => {
  return (
  <Svg viewBox="0 0 446.4 446.4" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Layer 1" viewBox="0 0 446.4 446.4">
       
        <g fill="#149f49" fillRule="evenodd">
            <path
                d="m249.84 197.5c-7.78-1.23-15.77-.8-23.62-.87-11.66 0-23.32.15-34.92-.07q-.21 31.65 0 63.29c14-.15 27.94-.07 41.91-.07 7.41 0 15 .28 22.1-2.38a30.07 30.07 0 0 0 19.23-21.82c1.65-8.35.79-17.49-4-24.69-4.54-7.2-12.49-11.74-20.7-13.39z"
            />
            <path
                fill="#73c692"
                      d="m223.2 0c-123.27 0-223.2 99.93-223.2 223.2s99.93 223.2 223.2 223.2 223.2-99.93 223.2-223.2-99.93-223.2-223.2-223.2zm-93 262.3c11.88.21 23.25-7.35 28.15-18.08a33.23 33.23 0 0 0 2.88-16.05c.5-10.66-4.68-21.53-13.75-27.36-5-3.6-11.16-5-17.21-5.4-.07-8.43 0-16.78-.07-25.13 8.71.36 17.2-3.67 23-10.08a31.86 31.86 0 0 0 8-23c-1-10.22-7-20.09-16.27-24.77-4.47-2.59-9.58-3.53-14.69-3.74-.15-9.22 0-18.36-.07-27.51 39.67-.07 79.34-.14 119.08 0 15.77 1 31.76 6.56 43.49 17.43a50.33 50.33 0 0 1 15.12 27.79c1.73 12.74.58 26.64-7.13 37.37-4.89 7.63-12.38 13.17-20.37 17.35 12.38 4.75 23.47 13.46 29.66 25.34 8.5 16.28 8.28 36.87-.86 52.78-6.27 11.16-16.78 19.58-28.51 24.48s-24.77 6.62-37.44 6.48h-113c-.03-9.33.04-18.62-.03-27.9zm186 102.88c-25.27.29-50.61.08-76 .15-36.65-.07-73.29.14-109.94-.15-.22-9.93-.22-19.94 0-29.95 38.3-.29 76.61 0 115-.07 23.62.07 47.31-.22 70.92.07.19 10.01.19 20.02-.03 29.95z" />
                  <path d="m229.18 169.2c6.26-.14 12.6.58 18.64-1.37 9.94-2.81 18.87-11.09 20.45-21.6 1.44-8.64-.21-18.21-6.26-24.84-6.41-7.13-16.35-10.58-25.78-10.22h-45.07c.07 19.37.07 38.66 0 58z" />
              </g >
              <path d="m243.22 290.16c12.67.14 25.7-1.44 37.44-6.48s22.24-13.32 28.51-24.48c9.14-15.91 9.36-36.5.86-52.78-6.19-11.88-17.28-20.59-29.66-25.34 8-4.18 15.48-9.72 20.37-17.35 7.71-10.73 8.86-24.63 7.13-37.37a50.33 50.33 0 0 0 -15.12-27.79c-11.75-10.87-27.75-16.42-43.49-17.43-39.74-.14-79.41-.07-119.08 0 .07 9.15-.08 18.29.07 27.51 5.11.21 10.22 1.15 14.69 3.74 9.28 4.68 15.26 14.55 16.27 24.77a31.86 31.86 0 0 1 -8 23c-5.84 6.41-14.33 10.44-23 10.08.07 8.35 0 16.7.07 25.13 6.05.43 12.24 1.8 17.21 5.4 9.07 5.83 14.25 16.7 13.75 27.36a33.23 33.23 0 0 1 -2.88 16.05c-4.9 10.73-16.27 18.29-28.15 18.08.07 9.28 0 18.57 0 27.86zm31.32-54.58a30.07 30.07 0 0 1 -19.23 21.82c-7.05 2.66-14.69 2.38-22.1 2.38-14 0-27.94-.08-41.91.07q-.21-31.65 0-63.29c11.6.22 23.26.07 34.92.07 7.85.07 15.84-.36 23.62.87 8.21 1.65 16.13 6.19 20.66 13.39 4.83 7.2 5.69 16.34 4.04 24.69zm-83.38-124.41h45.07c9.43-.36 19.37 3.09 25.78 10.22 6.05 6.63 7.7 16.2 6.26 24.84-1.58 10.51-10.51 18.79-20.45 21.6-6 1.95-12.38 1.23-18.64 1.37h-38c.05-19.37.05-38.66-.02-58.03z"
                  fill="#fff" />
              <path d="m245.23 335.16c-38.37.07-76.68-.22-115 .07-.22 10-.22 20 0 29.95 36.65.29 73.29.08 109.94.15 25.35-.07 50.69.14 76-.15.22-9.93.22-19.94 0-29.95-23.63-.29-47.32 0-70.94-.07z" />
     </svg>
  </Svg>
  );
};

export default Icon;
