import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Header, HeaderNFT, Footer } from 'designStandard/components/organisms'
import { useMatchBreakpoints } from 'designStandard/hooks'
import { baseText } from 'designStandard/theme/typographyFonts'

const Page = styled.div<{ mobilePadding?: string }>`
  padding: 32px;
  background: ${({ theme }) => theme.colors.flatGlobal};
  margin: auto;
  @media (max-width: 575px) {
    padding: ${({ mobilePadding }) => mobilePadding ?? '0px'};
  }
`

const Full = styled.div`
  height: 100%;
  * {
    ${baseText}
  }
`

// const Banner = styled.div`
//   width: 100%;
//   text-align: center;
//   padding: 12px 4px;
//   color: ${({ theme }) => theme.colors.textPrimary};
// `

interface Page {
  profilePic: string
  account: string
  chainId: number
  isDark: boolean
  handleSubscribe: (email: string) => void
  toggleTheme: () => void
  logout: () => void
}

const PageWrapper: React.FC<Page> = ({
  profilePic,
  account,
  chainId,
  isDark,
  handleSubscribe,
  toggleTheme,
  logout,
  children,
}) => {
  const { isSm, isXs, isMd, isLg } = useMatchBreakpoints()
  const isMobile = window.innerWidth < 768 || isSm || isXs
  const isTablet = (window.innerWidth >= 768 && window.innerWidth < 968) || isMd || isLg
  const [isSideMenu, setSideMenu] = useState(false)
  const location = useLocation()

  return (
    <Full>
      {location.pathname.includes('/nft') ? (
        <HeaderNFT
          profilePic={profilePic}
          account={account}
          chainId={chainId}
          logout={logout}
          isMobile={isMobile}
          // isTablet={isTablet}
          isDropdown={isSideMenu}
          setIsDropdown={setSideMenu}
        />
      ) : (
        <Header
          profilePic={profilePic}
          account={account}
          chainId={chainId}
          logout={logout}
          isMobile={isMobile}
          isTablet={isTablet}
          isDropdown={isSideMenu}
          setIsDropdown={setSideMenu}
        />
      )}

      {!isSideMenu && (
        <Page mobilePadding={!location.pathname.includes('votewatcher') ? '8px' : '0px'}>{children}</Page>
      )}
      {!location.pathname.includes('votewatcher') && (
        <Footer toggleTheme={toggleTheme} isDark={isDark} handleSubscribe={handleSubscribe} isMobile={isMobile} />
      )}
    </Full>
  )
}

export default PageWrapper
