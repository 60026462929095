/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useMatchBreakpoints from 'toolkitUI/hooks/useMatchBreakpoints'
import Text from '../Text/Text'

const HeaderOutline = styled.tr<{ fromTop: string; current: boolean; isDispute: boolean; isMobiled: boolean }>`
  transition: opacity 0.2s ease-in;
  background: ${({ theme }) => theme.colors.tableHeader};
  border-bottom: ${({ theme }) => `3px solid ${theme.colors.borderColor}`};
  box-shadow: #3c6ebd57 0px 3px 8px -2px;
  border-radius: 16px 16px 0px 0px;
  position: ${(props) => (props.current ? null : 'sticky')};
  width: 100%;
  height: 60px;
  padding-left: ${(props) => (props.isDispute ? (props.isMobiled ? '16px' : '32px') : '16px')};
  top: ${(props) => props.fromTop};
  align-items: center;
  z-index: 99;
`

const variants = {
  SM: 'sm',
  LG: 'lg',
}

export interface TableHeaderRowProps {
  labels?: any
  actionColumnName?: string
  actionButtonScale?: string
  scrollingUp?: boolean
  isDispute?: boolean
}

const TableHeaderRow: React.FunctionComponent<TableHeaderRowProps> = (props) => {
  const { t } = useTranslation()
  const { isXl, isXs, isSm  } = useMatchBreakpoints()
  const { scrollingUp, labels, actionColumnName, actionButtonScale, isDispute } = props
  const isMobile = isSm || isXs
  const isMobiled = !isXl
  const actionButtonSize = actionButtonScale === variants.LG ? '100%' : '50%'

  return (
    <>
      <HeaderOutline
        current={labels.length === 2}
        fromTop={scrollingUp ? (isMobile ? '15%' : '8%') : '0'}
        isDispute
        isMobiled={isMobiled}
      >
        {labels.map((col, index: number) => {
          return col.label.length > 0 || actionColumnName === col.name ? (
            !(isMobiled && index === 3 && isDispute) ? (
              <th
                style={{
                  display: 'flex',
                  width: `${actionColumnName === col.name ? actionButtonSize : '100%'}`,
                  justifyContent: `${isMobile ? 'flex-start' : 'space-between'}`,
                  // paddingLeft: "32px", // `${isMobile ? "28px" : "16px"}`,
                }}
                key={col.label}
              >
                <Text
                  textAlign="center"
                  fontWeight="550"
                  color="label"
                  textTransform="lowercase"
                  fontSize={!isXl && labels.length > 3 ? '13px' : '16px'}
                >
                  { t(col.label) }
                </Text>
              </th>
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        })}
      </HeaderOutline>
    </>
  )
}

export default TableHeaderRow
