import React from 'react'
import { useTheme } from "styled-components"
import {Svg} from 'designStandard/components/atoms/Svg'

const Icon: React.FC = () => {
  const theme = useTheme()

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15 7.10079C15.1042 12.805 11.7017 15.2316 9.77252 15.8041C9.64473 15.8421 9.50864 15.8421 9.38085 15.8041C7.48335 15.2416 4.17835 12.8866 4.16669 7.38496C4.17812 6.90953 4.44551 6.47737 4.86585 6.25496C7.80252 4.59663 9.21502 4.16663 9.57419 4.16663C9.93335 4.16663 11.4575 4.62413 14.5892 6.41663C14.8371 6.55635 14.9932 6.81627 15 7.10079Z" opacity="0.65" fill="transparent" stroke={theme.colors.gradIconColor1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.08337 10.0124L8.75004 11.6791L12.0834 8.33911" opacity="0.65" stroke={theme.colors.gradIconColor1} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="transparent"/>
</Svg>


  )
}

export default Icon