import React from "react";
import styled from "styled-components";
import { Link } from "../../atoms/Link";
import { 
	TwitterIcon,
	// LinkedinIcon,
	// InstagramIcon,
	// FacebookIcon,
	CoinMarketCapIcon,
	CoingeckoIcon
} from "../../atoms/Svg";

const Wrapper = styled.div`
	display: grid;
	// grid-template-columns: repeat(6, 20px);
	grid-template-columns: repeat(3, 20px);
	grid-gap: 20px;
`
const SocialLinks: React.FC<any> = ({ ...props }) => {
  return (
		<Wrapper {...props}>
			<Link external href="https://twitter.com/ZapProtocol"><TwitterIcon/></Link>
			{/* <Link external href="https://linkedin.com"><LinkedinIcon/></Link>
			<Link external href="https://instagram.com"><InstagramIcon/></Link>
			<Link external href="https://facebook.com"><FacebookIcon/></Link> */}
			<Link external href="https://coinmarketcap.com/currencies/zap/"><CoinMarketCapIcon/></Link>
			<Link external href="https://www.coingecko.com/en/coins/zap/"><CoingeckoIcon/></Link>
		</Wrapper>
	)
};


export default SocialLinks;