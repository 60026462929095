import React from "react";
import Svg from "../Svg";

const Icon: React.FC<any> = ({size, color1, color2, style}) => {
    const updatedHeight = parseInt(size)*6/24
    const updatedWidth = parseInt(size)*4/24
  return (
    <Svg viewBox="0 0 4 6" width={updatedWidth} height={updatedHeight} style={style}>
        <path d="M3.5 3L0.5 6L0.5 0L3.5 3Z" fill="url(#forward)"/>
        <defs>
        <linearGradient id="forward" x1="4.0625" y1="9.82969e-08" x2="-2.51248" y2="4.43337" gradientUnits="userSpaceOnUse">
        <stop stopColor={color1}/>
        <stop offset="1" stopColor={color2} stopOpacity="0.8"/>
        </linearGradient>
        </defs>
    </Svg>
  );
};

export default Icon;
