import React from "react";
import styled from "styled-components";
import { Text } from "../Text";
import { LinkProps } from "./types";

const StyledLink = styled(Text)<LinkProps>`
	cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
	color: ${({ theme }) => theme.colors.textSecondary};
  &:hover {
    text-decoration: none;
  }
`;

const Link: React.FC<LinkProps> = ({ ...props }) => {

	const { external } =  props;

  return (
		<>
		{ external ?
			<StyledLink target="_blank" as="a" {...props} />:
			<StyledLink as="a" {...props} />
		}
		</>
	)
};

Link.defaultProps = {
  color: "textSecondary",
	font: "bodyRegularSmall",
	external: false
};

export default Link;