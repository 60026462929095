import React from "react";
import styled from "styled-components";
import { PropertyCardProps } from "./types";
import { Text } from "../../atoms/Text"


const PropertyCardWrapper = styled.div<{ size: number }>`
    display: grid;
    justify-content: center;
    align-content: center;
    min-width: ${(props) => props.size}px;
    // max-width: 100%;
    height: ${(props) => props.size}px;
    background: 
        linear-gradient(${({ theme }) => theme.colors.flatGlobal},${({ theme }) => theme.colors.flatGlobal}) padding-box,
        ${({ theme }) => theme.colors.strokeGradNorm} border-box;
    border: 1px solid transparent;
    border-image-source: ${({ theme }) => theme.colors.strokeGradNorm};
    border-radius:  5.67px;
    box-shadow: ${({ theme }) => theme.colors.propertyCardBoxShadow};
    border-image-slice: 0;
    overflow: hidden;
`

const PlaceholderText = styled.p`
    font-family: Inter;
    font-style: italic;
    font-weight: normal;
    font-size: 9.26px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #737478;
`

const PropertyCard : React.FC<PropertyCardProps> = ({ size, attrKey, attrValue  }) => {
    if(attrValue) {
        return (
        <>
        <PropertyCardWrapper size={size}>
            <Text font="bodySemiBoldSmall" textAlign='center'>{attrKey}</Text>
            <PlaceholderText >{attrValue}</PlaceholderText>
        </PropertyCardWrapper>
        </>
        )
    }
    return null
}

export default PropertyCard;