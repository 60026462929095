import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredCollectibles: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()
  // const lightFill = color ?? theme.colors.gradIconColor2
  return (
    <Svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.3544 1H7.35033C6.83998 1 6.42627 1.41372 6.42627 1.92406V5.34677C6.42627 5.85711 6.83998 6.27082 7.35033 6.27082H16.3544C16.8647 6.27082 17.2784 5.85711 17.2784 5.34677V1.92406C17.2784 1.41372 16.8647 1 16.3544 1Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M16.9899 3.26929H7.01007C6.32009 3.26929 5.76074 3.82863 5.76074 4.51862V10.3808C5.76074 11.0708 6.32009 11.6302 7.01007 11.6302H16.9899C17.6799 11.6302 18.2392 11.0708 18.2392 10.3808V4.51862C18.2392 3.82863 17.6799 3.26929 16.9899 3.26929Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M8.48082 10.0781L9.71539 7.35029C9.77521 7.21684 9.87116 7.10279 9.99243 7.02105C10.1137 6.9393 10.2554 6.89312 10.4016 6.88774C10.5477 6.88235 10.6924 6.91797 10.8194 6.99057C10.9463 7.06316 11.0505 7.16985 11.1199 7.29853L12.1919 9.26493C12.2654 9.41493 12.385 9.53748 12.5332 9.6147C12.6813 9.69193 12.8503 9.71977 13.0154 9.69418C13.1805 9.66859 13.3331 9.59089 13.4509 9.47244C13.5688 9.35399 13.6456 9.201 13.6703 9.03577V9.03577C13.6915 8.869 13.7657 8.71343 13.882 8.59208C13.9984 8.47073 14.1506 8.39001 14.3164 8.36183C14.4821 8.33366 14.6525 8.35953 14.8024 8.43563C14.9523 8.51172 15.0738 8.63399 15.1488 8.78441L15.8881 9.99678C15.9582 10.1168 15.9955 10.2531 15.9962 10.3921C15.9969 10.5311 15.961 10.6677 15.892 10.7884C15.8231 10.9091 15.7235 11.0094 15.6034 11.0794C15.4833 11.1494 15.347 11.1865 15.208 11.187H9.24966C9.1138 11.1968 8.97771 11.1712 8.85482 11.1124C8.73192 11.0536 8.62643 10.9639 8.54881 10.8519C8.4712 10.74 8.42411 10.6097 8.41219 10.474C8.40026 10.3383 8.42391 10.2019 8.48082 10.0781V10.0781Z" fill="#252727"/>
      <path d="M20.9449 11.1941H3.05511C1.9201 11.1941 1 12.1142 1 13.2492V19.4071C1 20.5421 1.9201 21.4622 3.05511 21.4622H20.9449C22.0799 21.4622 23 20.5421 23 19.4071V13.2492C23 12.1142 22.0799 11.1941 20.9449 11.1941Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M14.2546 13.6855H9.73781C9.007 13.6855 8.41455 14.2763 8.41455 15.0051C8.41455 15.7339 9.007 16.3247 9.73781 16.3247H14.2546C14.9854 16.3247 15.5779 15.7339 15.5779 15.0051C15.5779 14.2763 14.9854 13.6855 14.2546 13.6855Z" fill="#252727"/>
    </Svg>
  )
}

export default ColoredCollectibles
