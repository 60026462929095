import React from "react";
import styled from "styled-components";
import { IconButton as baseIcon } from "../IconButton";
import { ExpandableButtonProps } from './types'
import { ArrowUpIcon, ArrowDownIcon } from '../../atoms/Svg'

const IconButton = styled(baseIcon)`
	color: ${({ theme }) => theme.colors.textPrimary};
	& svg {
		color: ${({ theme }) => theme.colors.textPrimary};
	}
`


const ExpandableButton: React.FC<ExpandableButtonProps> = ({ ...props }) => {

	const { expanded, children, onClick } = props

	return (
		<IconButton variant="secondary" onClick={onClick} endIcon={expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}>{children}</IconButton>
	)
}

ExpandableButton.defaultProps = {
  expanded: false,
};

export default ExpandableButton;
