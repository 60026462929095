import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Website: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
	  <rect width="9.15894" height="14.2387" rx="4.57947" fill="none" transform="matrix(0.718765 0.695253 -0.718765 0.695253 17.7363 0)" stroke="#5E5E5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <rect width="9.32294" height="14.2387" rx="4.66147" fill="none" transform="matrix(0.718765 0.695253 -0.718765 0.695253 10.4512 7.02441)" stroke="#5E5E5F" strokeWidth="2"/>
    </Svg>
  );
};

export default Website;