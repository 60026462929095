import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35368 1.88708C6.35368 1.51375 6.00951 1.22792 5.64784 1.30958C4.99451 1.46125 4.37618 1.71792 3.82784 2.06792C3.51868 2.26625 3.47201 2.70959 3.73451 2.97209C3.92118 3.15875 4.21868 3.20542 4.44034 3.06542C4.88951 2.77959 5.38534 2.56958 5.92201 2.45292C6.17868 2.39458 6.35368 2.15542 6.35368 1.88708ZM2.91201 3.80042C2.64368 3.53792 2.20618 3.57875 2.00784 3.89375C1.65784 4.44209 1.40118 5.06042 1.24951 5.71375C1.16784 6.07542 1.44784 6.41959 1.82118 6.41959C2.08368 6.41959 2.32868 6.24459 2.38118 5.98792C2.49784 5.45709 2.71368 4.95542 2.99368 4.51209C3.14534 4.28459 3.09868 3.98709 2.91201 3.80042ZM1.24368 8.29208C1.16201 7.93041 1.44784 7.58625 1.82118 7.58625C2.08951 7.58625 2.32868 7.76125 2.38118 8.02375C2.49784 8.56041 2.70784 9.05625 2.99368 9.49958C3.13951 9.72125 3.09284 10.0187 2.90618 10.2054C2.64368 10.4679 2.20034 10.4271 2.00201 10.1121C1.65201 9.55791 1.39534 8.94541 1.24368 8.29208ZM3.82785 11.9321C4.38201 12.2821 4.99451 12.5387 5.64785 12.6904C6.00951 12.7721 6.35368 12.4862 6.35368 12.1187C6.35368 11.8562 6.17868 11.6112 5.92201 11.5587C5.39118 11.4421 4.88951 11.2262 4.44618 10.9462C4.21868 10.8062 3.92701 10.8471 3.74035 11.0396C3.47201 11.2962 3.51285 11.7337 3.82785 11.9321ZM8.26118 12.6846C10.8453 12.0837 12.7703 9.76208 12.7703 7.00291C12.7703 4.24375 10.8453 1.92208 8.26118 1.32125C7.89951 1.23375 7.54951 1.51958 7.54951 1.89291C7.54951 2.16125 7.73034 2.40041 7.98701 2.45875C10.0578 2.93708 11.6037 4.79208 11.6037 7.00291C11.6037 9.21375 10.0578 11.0687 7.98701 11.5471C7.73034 11.6112 7.54951 11.8446 7.54951 12.1129C7.54951 12.4862 7.89951 12.7721 8.26118 12.6846Z"
      />
      <path
        strokeWidth="0.5"
        d="M7.13307 3.60175C7.14595 3.57788 7.16 3.56881 7.17119 3.56435C7.18471 3.55896 7.2031 3.55714 7.22306 3.56218C7.24302 3.56722 7.25834 3.57755 7.26768 3.58872C7.27542 3.59796 7.28347 3.61262 7.28347 3.63975V5.44083C7.28347 5.76116 7.54314 6.02083 7.86347 6.02083H8.89975C8.95985 6.02083 8.99849 6.08464 8.97064 6.1379L7.06795 9.77701C7.05524 9.80132 7.04122 9.81053 7.03011 9.81508C7.01662 9.8206 6.99814 9.82259 6.97797 9.81764C6.95781 9.81268 6.94235 9.80235 6.93295 9.79121C6.92522 9.78204 6.91706 9.76737 6.91706 9.73994V7.97583C6.91706 7.65551 6.65738 7.39583 6.33706 7.39583H5.21973C5.15916 7.39583 5.12056 7.33114 5.14933 7.27784L7.13307 3.60175Z"
      />
    </Svg>
  )
}

export default Icon
