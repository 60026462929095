import React, { useState, useEffect, useCallback } from 'react'
import styled  from 'styled-components'
import { useMatchBreakpoints } from 'designStandard/hooks'
import { Button } from 'designStandard/components/molecules/Button'
import { ActivityPanelProps } from './types'
import { NotificationCard } from '../../molecules/NotificationCard'

const NotificationCardWrapper = styled.div<{isMobile: boolean}>`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  padding: ${({ isMobile }) => isMobile ? '29.52px 20px 0px 20px' : '29.52px 48px 0px 48px'};
`

const Row = styled.div`
  padding-top: 16.47px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  margin: 26px 0;
`

const ActivityPanel: React.FC<ActivityPanelProps> = ({data}) => {
    const numberOfViews = 8
    const {  isXs, isSm  } = useMatchBreakpoints()
    const isMobile = isSm || isXs
    const [isLoading, setIsLoading] = useState(true)
    const [countOfClicks, setCounfOfClicks] = useState(1)
    const [txs, setTxs] = useState([])
    useEffect(() => {
      if (data.length > 0) {
        setTxs(data.slice(0, numberOfViews * countOfClicks))
        setIsLoading(false)
      }
    }, [data, countOfClicks])

    const showHandler = useCallback(() => {
      setCounfOfClicks(countOfClicks + 1)
    }, [countOfClicks])

    return (
        <div>
            <NotificationCardWrapper isMobile={isMobile}>
                {
                    txs.map((row) => (
                        <Row>
                            <NotificationCard key={row.user} data={row} />
                        </Row>
                    ))
                }
            </NotificationCardWrapper>
            {!isLoading && data.length !== txs.length && (
            <ButtonWrapper>
                <Button scale='sm' variant='secondary' onClick={showHandler}>Load More</Button>
            </ButtonWrapper>
            )}
        </div>
)}

export default ActivityPanel