import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import hideMenuOnOutsideClicked from 'utils/hideMenuOnOutsideClicked'
import { SearchIcon as BaseSearchIcon, Close3Icon } from '../../atoms/Svg'
import { SearchboxProps, Type } from './types'

const SearchboxWrapper = styled.div<{ type: Type, state: string }>`
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  background: ${({ theme, type, state }) => type === 'filled' && state === "inactive" ? theme.colors.flatSearchBox : type==="filled" && state ==="active" ? theme.colors.flatOverlay :  'transparent'};
  box-shadow: ${({ theme }) => theme.shadows.popMedium};
  border-radius: 16px;
  // &:focus-within {
  //   border: 2px solid ${({ theme }) => theme.colors.flatOverlay};
  //   padding: 10px 14px;
  // }
  &:hover:not(:focus-within) {
    // border: 2px solid ${({ theme }) => theme.colors.searchBoxBorder};
    // padding: 10px 14px;
    input::placeholder {
      color: ${({ theme }) => theme.colors.textOnHover};
    }
  }
`

const SearchIcon = styled(BaseSearchIcon)`
  margin-right: 8px;
`

const IconContainer = styled.div`
  margin-right: -10px;
  position: relative;
  right: 10px;
`

const Input = styled.input<{ size: number }>`
  width: ${({ size }) => `${size - 96}px`};
  background: transparent;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  &:focus {
    color: ${({ theme }) => theme.colors.textPrimary};
    ::placeholder {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
`

const Searchbox: React.FC<SearchboxProps> = ({
  type = 'filled',
  state = 'inactive',
  size = 396,
  placeholder = 'Enter Password',
  changeHandler,
  ...props
}) => {
  const [query, setQuery] = useState('')
  const [activeState, setActiveState] = useState('idle')
  const [isVisible, setIsVisible] = useState(false)
  const [isClearable, setIsClearable] = useState(false)
  const wrapperRef = useRef(null)

  hideMenuOnOutsideClicked(wrapperRef, setIsVisible)

  const inputHandler = useCallback(
    (e) => {
      if (e.target.value === '') {
        setIsClearable(false)
      } else {
        setIsClearable(true)
      }
      setQuery(e.target.value)
      changeHandler(e.target.value)
    },
    [changeHandler],
  )

  useEffect(() => {
    if (isVisible) {
      setActiveState('active')
    } else {
      setActiveState('idle')
    }
  }, [isVisible])



  useEffect(() => {
    if (isVisible) {
      setActiveState('active')
    } else {
      setActiveState('idle')
    }
  }, [isVisible])

  const handleFocus = useCallback(() => {
    setIsVisible(true)
  }, [])

  const handleClear = useCallback(() => {
    setQuery('')
    setIsClearable(false)
    changeHandler('')
  }, [changeHandler])

  return (
    <SearchboxWrapper type={type} state={state} className="searchbox" {...props} onFocus={handleFocus} ref={wrapperRef}>
      <SearchIcon className="searchboxIcon" activeState={activeState} />
      <Input
        type="text"
        value={query}
        size={size}
        disabled={state !== 'active'}
        placeholder={placeholder}
        onChange={inputHandler}
      />
      {isClearable ? <IconContainer><Close3Icon onClick={handleClear} style={{ cursor: 'pointer' }} /></IconContainer> : <></>}
    </SearchboxWrapper>
  )
}

export default Searchbox
