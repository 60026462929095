import React from "react"
// import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

// export interface AllProps extends SvgProps {
//   size: number
// }

const SelectedMark: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.4585 9.99994L8.81683 12.3583L13.5418 7.6416" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </Svg>
  )
}

export default SelectedMark