import React from "react"
import { useTheme } from "styled-components"

const ArrowRight = () => {
  const theme = useTheme()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
      <path stroke={theme.colors.textPrimary} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M3.5 12h16.83" />
    </svg>
  )
}

export default ArrowRight