import { commify } from 'ethers/lib/utils'
// import Moralis from 'moralis'
import ZapPriceDetail from '../entities/zapPriceDetail'
import ZapPriceDetails from '../entities/zapPriceDetails'

export default async function useZapPriceDetailList(priceFeedList, Moralis): Promise<ZapPriceDetails[]> {

    const retVal: ZapPriceDetails[] = []
    
    const getPriceDetail = async(_id) : Promise<ZapPriceDetail[]> => {
        const _retVal : ZapPriceDetail[] = []
        // Moralis.initialize("RNrYqlQiX38ISiNHty0DAqU9kNbydscmQNzAOACs");
        // Moralis.serverURL = "https://i32izegtzlgg.usemoralis.com:2053/server";
      
        // const NODE_URL = "https://speedy-nodes-nyc.moralis.io/e14d2d027262a7f7a9f4c757/bsc/testnet"
        // const provider = new Web3.providers.HttpProvider(NODE_URL)
        // const MoralisWeb3 = new Web3(provider)
      
        const eventOb = Moralis.Object.extend("ZapPythiaEvents")
        const query = new Moralis.Query(eventOb)
        query.equalTo("RequestId", `${Number(_id)}`)
        query.equalTo("Status", "mined")
        query.descending("Timestamp")
        query.limit(50)
        const results = await query.find()
        for (let i = 0; i < results.length; i++) {
            const current = results[i].attributes

            const minedValue = commify((Number(current.MinedValue[0]) * 10**(-6)).toFixed(6))
            const _item = new ZapPriceDetail()
                    _item.id = current.RequestId[0]
                    _item.blockNumber = current.BlockNumber
                    _item.value = String(minedValue)
                    _item.name = current.RequestSymbols[0]
                    _item.timestamp = current.Timestamp
                    _item.tip = current.TotalTips
                    _item.minerSubmissions = current.MinerValues
           await  _retVal.push(_item)

        }
        return _retVal


    // try {
    //     return new Promise(resolve => {
    //         fetch(process.env.REACT_APP_SCAN_URL.concat(`/testnet/price/${Number(_id)}/50`))
    //             .then((response) => response.json())
    //             .then((data) => {
    //             const selectedData = Object(data).filter((item) => {
    //                 return item.id === `${Number(_id)}` && item
    //             })

    //             const _retVal : ZapPriceDetail[] = []
                
    //             selectedData.forEach((entry) => {
    //                 const Submissions = []
    //                 const _item = new ZapPriceDetail()
    //                 entry.minerSubmissions.forEach((item)=>{
    //                     const tempOb = {
    //                         miner: item.minerAddress,
    //                         values: [item.value]
    //                     }
    //                     Submissions.push(tempOb)
    //                 })
    //                 _item.id = entry.id
    //                 _item.blockNumber = entry.blockNumber
    //                 _item.value = commify(parseFloat(entry.value).toFixed(6))
    //                 _item.name = entry.name
    //                 _item.timestamp = entry.timestamp
    //                 _item.tip = entry.tip
    //                 _item.minerSubmissions = Submissions
    //                 _retVal.push(_item)
    //             })

    //             resolve(_retVal);
    //         })
    //     })
    // } catch (e) {
    //   console.error('error', e)
    //   return null
    // }
}

    await Promise.all(priceFeedList.map(async (e) => {
        const _priceDetailItem = await getPriceDetail(e.requestId)
        retVal.push({id: e.requestId, data:_priceDetailItem})
    }));

    return retVal;
}