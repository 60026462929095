import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ArrowDown: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M12.243 14.657L8 10.414L9.415 9L12.243 11.829L15.071 9L16.486 10.414L12.243 14.657Z" fill="url(#paint0_linear_1682:312)"/>
			<defs>
			<linearGradient id="paint0_linear_1682:312" x1="16.486" y1="15.7177" x2="7.51935" y2="5.74349" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1}/>
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
			</linearGradient>
			</defs>
    </Svg>
  );
};

export default ArrowDown;