import React from "react"
// import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

interface LogoIconProps extends SvgProps {
  size: number
}

const Icon: React.FC<LogoIconProps> = (props) => {
    const { size } = props
    const updatedWidth = size*18/40
    const updatedHeight = size*30/40
    // const theme = useTheme()
    return (
        <Svg width={updatedWidth} height={updatedHeight} viewBox="0 0 35 55" fill="none" {...props}>
            <path d="M34.483 21.6554C34.4836 21.9607 34.4194 22.2626 34.2948 22.5412L34.03 22.9835L15.3339 54.2339C15.0893 54.6427 14.7174 54.96 14.2752 55.1372C13.833 55.3144 13.3449 55.3417 12.8857 55.2149C12.4264 55.0881 12.0214 54.8143 11.7328 54.4353C11.4441 54.0563 11.2876 53.5931 11.2874 53.1167C11.2873 53.006 11.2957 52.8954 11.3126 52.7859L11.2874 52.7764L13.6847 32.1661H2.17787C1.78273 32.166 1.39507 32.0584 1.05639 31.8548C0.717714 31.6513 0.44077 31.3595 0.255202 31.0106C0.0696344 30.6618 -0.0175656 30.269 0.00293471 29.8744C0.0234351 29.4798 0.150864 29.0982 0.371582 28.7704L17.531 1.14949L17.6724 0.922931C17.6748 0.916674 17.6785 0.910957 17.6832 0.906145C17.9521 0.532144 18.3327 0.253083 18.7703 0.109093C19.2078 -0.0348975 19.6798 -0.036384 20.1183 0.104839C20.5567 0.246061 20.9391 0.522737 21.2103 0.895031C21.4816 1.26733 21.6278 1.71606 21.6279 2.1767L21.6171 2.39963L21.6158 2.40563L20.7564 19.4775H32.3063C32.5923 19.4776 32.8754 19.5339 33.1395 19.6434C33.4037 19.7529 33.6437 19.9133 33.8459 20.1155C34.048 20.3178 34.2083 20.5579 34.3176 20.8221C34.427 21.0863 34.4832 21.3695 34.483 21.6554Z" fill="url(#paint0_linear_4233_21773)"/>
            <defs>
                <linearGradient id="paint0_linear_4233_21773" x1="17.2415" y1="0" x2="17.2415" y2="55.2934" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3480F6"/>
                    <stop offset="1" stopColor="#4CBDFE"/>
                </linearGradient>
            </defs>
        </Svg>

        // !theme.isDark ? 
        // <Svg width={updatedWidth} height={updatedHeight} viewBox="0 0 18 30" fill="none" {...props}>
        //     <path d="M17.6088 12.0081C17.6091 12.1605 17.577 12.3112 17.5148 12.4503L17.3826 12.6712L8.04829 28.2734C7.92617 28.4775 7.74049 28.6359 7.5197 28.7244C7.29892 28.8129 7.05522 28.8265 6.82595 28.7632C6.59667 28.6999 6.39447 28.5632 6.25035 28.374C6.10622 28.1848 6.02812 27.9535 6.028 27.7157C6.02793 27.6604 6.03213 27.6052 6.04057 27.5505L6.028 27.5457L7.22486 17.2557H1.47991C1.28264 17.2556 1.08909 17.2019 0.919999 17.1003C0.750908 16.9987 0.612639 16.853 0.519992 16.6788C0.427344 16.5047 0.383808 16.3086 0.394043 16.1115C0.404278 15.9145 0.4679 15.724 0.578097 15.5604L9.14521 1.77019L9.21581 1.65708C9.21703 1.65395 9.21887 1.6511 9.22121 1.6487C9.35546 1.46197 9.54548 1.32264 9.76394 1.25076C9.9824 1.17887 10.218 1.17812 10.4369 1.24863C10.6558 1.31914 10.8467 1.45727 10.9822 1.64315C11.1176 1.82902 11.1906 2.05306 11.1906 2.28304L11.1852 2.39434L11.1846 2.39734L10.7555 10.9208H16.522C16.6648 10.9208 16.8061 10.9489 16.938 11.0036C17.0699 11.0582 17.1897 11.1383 17.2907 11.2393C17.3916 11.3403 17.4716 11.4601 17.5262 11.592C17.5808 11.724 17.6088 11.8653 17.6088 12.0081Z" fill="url(#paint0_linear_1154:879)" stroke="url(#paint1_linear_1154:879)" strokeWidth="0.75" strokeMiterlimit="10"/>
        //     <defs>
        //         <linearGradient id="paint0_linear_1154:879" x1="0.392577" y1="14.9994" x2="17.6088" y2="14.9994" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="#4479BD"/>
        //             <stop offset="1" stopColor="#63BDEB"/>
        //         </linearGradient>
        //         <linearGradient id="paint1_linear_1154:879" x1="0.392578" y1="1.66533" x2="22.1896" y2="5.96115" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="#ECEFF7"/>
        //             <stop offset="1" stopColor="#EBEBEC"/>
        //         </linearGradient>
        //     </defs>
        // </Svg> :
        // <Svg width={updatedWidth} height={updatedHeight} viewBox="0 0 18 30" fill="none" {...props}>
        //     <path d="M17.6088 12.0081C17.6091 12.1605 17.577 12.3112 17.5148 12.4503L17.3826 12.6712L8.04829 28.2734C7.92617 28.4775 7.74049 28.6359 7.5197 28.7244C7.29892 28.8129 7.05522 28.8265 6.82595 28.7632C6.59667 28.6999 6.39447 28.5632 6.25035 28.374C6.10622 28.1848 6.02812 27.9535 6.028 27.7157C6.02793 27.6604 6.03213 27.6052 6.04057 27.5505L6.028 27.5457L7.22486 17.2557H1.47991C1.28264 17.2556 1.08909 17.2019 0.919999 17.1003C0.750908 16.9987 0.612639 16.853 0.519992 16.6788C0.427344 16.5047 0.383808 16.3086 0.394043 16.1115C0.404278 15.9145 0.4679 15.724 0.578097 15.5604L9.14521 1.77019L9.21581 1.65708C9.21703 1.65395 9.21887 1.6511 9.22121 1.6487C9.35546 1.46197 9.54548 1.32264 9.76394 1.25076C9.9824 1.17887 10.218 1.17812 10.4369 1.24863C10.6558 1.31914 10.8467 1.45727 10.9822 1.64315C11.1176 1.82902 11.1906 2.05306 11.1906 2.28304L11.1852 2.39434L11.1846 2.39734L10.7555 10.9208H16.522C16.6648 10.9208 16.8061 10.9489 16.938 11.0036C17.0699 11.0582 17.1897 11.1383 17.2907 11.2393C17.3916 11.3403 17.4716 11.4601 17.5262 11.592C17.5808 11.724 17.6088 11.8653 17.6088 12.0081Z" fill="url(#paint0_linear_1154:281)" stroke="url(#paint1_linear_1154:281)" strokeWidth="0.75" strokeMiterlimit="10"/>
        //     <defs>
        //         <linearGradient id="paint0_linear_1154:281" x1="0.392577" y1="14.9994" x2="17.6088" y2="14.9994" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="#4479BD"/>
        //             <stop offset="1" stopColor="#63BDEB"/>
        //         </linearGradient>
        //         <linearGradient id="paint1_linear_1154:281" x1="13.5" y1="20.5" x2="-4.16666" y2="3.66666" gradientUnits="userSpaceOnUse">
        //             <stop/>
        //             <stop offset="0.71815" stopColor="#585858" stopOpacity="0"/>
        //         </linearGradient>
        //     </defs>
        // </Svg>
    )
}

export default Icon
