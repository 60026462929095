import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <g clipPath="url(#clip0)">
        <path d="M48.3554 0.152344H5.79031C2.98451 0.152344 0.709961 2.42689 0.709961 5.2327V47.7978C0.709961 50.6036 2.98451 52.8782 5.79031 52.8782H48.3554C51.1612 52.8782 53.4358 50.6036 53.4358 47.7978V5.2327C53.4358 2.42689 51.1612 0.152344 48.3554 0.152344Z" fill="#0A81FE"/>
        <path d="M20.1965 8.25781H33.9271C36.9479 8.25781 39.694 9.49357 41.6163 11.5532C43.5386 13.4755 44.7744 16.2216 44.7744 19.2424V33.7969C44.7744 36.8176 43.5386 39.4265 41.6163 41.4861C39.694 43.4084 36.9479 44.6441 33.9271 44.6441H20.1965C17.1757 44.6441 14.4296 43.4084 12.5073 41.4861C10.4477 39.4265 9.21191 36.8176 9.21191 33.7969V19.2424C9.21191 16.2216 10.4477 13.4755 12.5073 11.5532C14.4296 9.49357 17.1757 8.25781 20.1965 8.25781ZM33.9271 11.4159H20.1965C17.9995 11.4159 16.0773 12.2397 14.5669 13.6128C13.1938 15.1232 12.2327 17.0454 12.2327 19.2424V33.7969C12.2327 35.8565 13.1938 37.9161 14.5669 39.2892C16.0773 40.7995 17.9995 41.6234 20.1965 41.6234H33.9271C36.124 41.6234 38.0463 40.7995 39.4194 39.2892C40.9298 37.9161 41.7536 35.8565 41.7536 33.7969V19.2424C41.7536 17.0454 40.9298 15.1232 39.4194 13.6128C38.0463 12.2397 36.124 11.4159 33.9271 11.4159Z" fill="white"/>
        <path d="M27.0507 17.0566C29.6596 17.0566 31.9938 18.0178 33.6415 19.8028C35.2891 21.4505 36.3876 23.7847 36.3876 26.3935C36.3876 29.0023 35.2891 31.1992 33.6415 32.9842C31.9938 34.6319 29.6596 35.7304 27.0507 35.7304C24.4419 35.7304 22.1077 34.6319 20.46 32.9842C18.675 31.1992 17.7139 29.0023 17.7139 26.3935C17.7139 23.7847 18.675 21.4505 20.46 19.8028C22.1077 18.0178 24.4419 17.0566 27.0507 17.0566ZM31.4445 21.8624C30.3461 20.7639 28.6984 20.0774 27.0507 20.0774C25.2657 20.0774 23.7554 20.7639 22.5196 21.8624C21.4212 23.0981 20.7346 24.6085 20.7346 26.3935C20.7346 28.0412 21.4212 29.6889 22.5196 30.7873C23.7554 31.8858 25.2657 32.5723 27.0507 32.5723C28.6984 32.5723 30.3461 31.8858 31.4445 30.7873C32.543 29.6889 33.3668 28.0412 33.3668 26.3935C33.3668 24.6085 32.543 23.0981 31.4445 21.8624Z" fill="white"/>
        <path d="M37.1119 18.6829C38.3253 18.6829 39.3089 17.6993 39.3089 16.486C39.3089 15.2727 38.3253 14.2891 37.1119 14.2891C35.8986 14.2891 34.915 15.2727 34.915 16.486C34.915 17.6993 35.8986 18.6829 37.1119 18.6829Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="52.7258" height="52.7258" fill="white" transform="translate(0.709961 0.152344)"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
