import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Facebook: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.5437 22.375V15.1188H18.974L19.3375 12.2979H16.5437V10.4979C16.5437 9.68125 16.7708 9.12396 17.9417 9.12396H19.4354V6.60104C18.7123 6.52432 17.9855 6.48711 17.2583 6.48958C15.1052 6.48958 13.6312 7.80417 13.6312 10.2188V12.2979H11.1958V15.1188H13.6312V22.375H4.66667C4.3904 22.375 4.12545 22.2653 3.9301 22.0699C3.73475 21.8746 3.625 21.6096 3.625 21.3333V4.66667C3.625 4.3904 3.73475 4.12545 3.9301 3.9301C4.12545 3.73475 4.3904 3.625 4.66667 3.625H21.3333C21.6096 3.625 21.8746 3.73475 22.0699 3.9301C22.2653 4.12545 22.375 4.3904 22.375 4.66667V21.3333C22.375 21.6096 22.2653 21.8746 22.0699 22.0699C21.8746 22.2653 21.6096 22.375 21.3333 22.375H16.5437Z" fill={color ?? theme.colors.gradIconColor1}/>
    </Svg>
  );
};

export default Facebook;