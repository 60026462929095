import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CoinMarketCap: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
	  <path d="M20.5608 14.3803C20.3737 14.5093 20.1552 14.5854 19.9285 14.6006C19.7017 14.6158 19.475 14.5696 19.2723 14.4669C18.7964 14.198 18.54 13.5677 18.54 12.7087V10.076C18.54 8.81217 18.0394 7.91298 17.202 7.66887C15.7868 7.25481 14.73 8.9883 14.3221 9.64029L11.8192 13.6913V8.74728C11.7914 7.60707 11.4206 6.92418 10.7192 6.72024C10.2557 6.58429 9.56045 6.6399 8.88374 7.67196L3.28776 16.6546C2.54319 15.2301 2.15712 13.6455 2.163 12.0381C2.163 6.62446 6.48899 2.22121 11.8192 2.22121C17.1495 2.22121 21.4909 6.62446 21.4909 12.0381V12.0659C21.4909 12.0659 21.4909 12.0845 21.4909 12.0937C21.5435 13.1413 21.2036 13.9756 20.5639 14.3803H20.5608ZM23.6508 12.0412V11.9887C23.6076 5.39155 18.3175 0.0396729 11.8192 0.0396729C5.32097 0.0396729 0 5.42245 0 12.0381C0 18.6538 5.30243 24.0397 11.8192 24.0397C14.8081 24.0395 17.6833 22.8939 19.8532 20.8384C20.0639 20.64 20.1878 20.3666 20.1982 20.0774C20.2086 19.7882 20.1047 19.5065 19.9088 19.2934C19.8151 19.1898 19.7019 19.1057 19.5755 19.046C19.4492 18.9863 19.3123 18.9522 19.1728 18.9456C19.0332 18.9389 18.8937 18.96 18.7623 19.0075C18.6309 19.055 18.5102 19.128 18.4071 19.2224C17.4729 20.1086 16.3692 20.7968 15.1624 21.2458C13.9555 21.6949 12.6704 21.8954 11.3841 21.8354C10.0978 21.7753 8.83696 21.456 7.67718 20.8965C6.51739 20.3371 5.48262 19.549 4.63499 18.5797L9.67169 10.4839V14.2197C9.67169 16.015 10.3669 16.5959 10.9509 16.7658C11.535 16.9358 12.428 16.8184 13.3642 15.2981L16.1452 10.7959C16.2318 10.6507 16.3152 10.5271 16.3893 10.419V12.7087C16.3893 14.3865 17.063 15.7276 18.2433 16.3888C18.7801 16.6786 19.3849 16.8186 19.9943 16.7941C20.6038 16.7696 21.1954 16.5816 21.7072 16.2498C23.005 15.4062 23.7126 13.8798 23.6385 12.0412H23.6508Z"/>
	  <defs>
		<linearGradient id="paint0_linear_2:1579" x1="21" y1="24.375" x2="-6.45614" y2="4.01537" gradientUnits="userSpaceOnUse">
		  <stop stopColor={theme.colors.gradIconColor1}/>
		  <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
		</linearGradient>
	  </defs>
    </Svg>
  );
};

export default CoinMarketCap;