import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const More: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
	  <path fillRule="evenodd" clipRule="evenodd" d="M2.00012 12C2.00012 6.48 6.47012 2 12.0001 2C17.5201 2 22.0001 6.48 22.0001 12C22.0001 17.52 17.5201 22 12.0001 22C6.47012 22 2.00012 17.52 2.00012 12ZM7.52012 13.2C6.86012 13.2 6.32012 12.66 6.32012 12C6.32012 11.34 6.86012 10.801 7.52012 10.801C8.18012 10.801 8.71012 11.34 8.71012 12C8.71012 12.66 8.18012 13.2 7.52012 13.2ZM10.8001 12C10.8001 12.66 11.3401 13.2 12.0001 13.2C12.6601 13.2 13.1901 12.66 13.1901 12C13.1901 11.34 12.6601 10.801 12.0001 10.801C11.3401 10.801 10.8001 11.34 10.8001 12ZM15.2801 12C15.2801 12.66 15.8101 13.2 16.4801 13.2C17.1401 13.2 17.6701 12.66 17.6701 12C17.6701 11.34 17.1401 10.801 16.4801 10.801C15.8101 10.801 15.2801 11.34 15.2801 12Z" fill="url(#paint0_linear_2:1571)"/>
	  <defs>
		<linearGradient id="paint0_linear_2:1571" x1="22.0001" y1="25.75" x2="-8.5067" y2="3.12819" gradientUnits="userSpaceOnUse">
		  <stop stopColor={theme.colors.gradIconColor1} />
		  <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
		</linearGradient>
	  </defs>
    </Svg>
  );
};

export default More;