import React from "react";
import Svg from "../../Svg";
import { SvgProps } from "../../types";

const Icon: React.FC<SvgProps>
  = (props) => {
  return (
  <Svg viewBox="0 0 560 560.04" {...props}>
          <svg viewBox="0 0 560 560.04" xmlns="http://www.w3.org/2000/svg"><path d="m219.42 275.6-4.86-4.88a5.85 5.85 0 0 0 -10 4.13v69.72a5.85 5.85 0 0 0 5.87 5.84h69.73a5.83 5.83 0 0 0 4.12-10 5.85 5.85 0 0 1 0-8.25l74.18-74.38a5.84 5.84 0 0 0 0-8.26l-6.76-6.73a5.84 5.84 0 0 1 4.13-10h69.7a5.86 5.86 0 0 1 5.86 5.84v69.72a5.86 5.86 0 0 1 -10 4.13l-4.71-4.69a5.7 5.7 0 0 0 -8.24 0l-125 125a5.89 5.89 0 0 1 -8.27 0l-153-153a5.83 5.83 0 0 1 0-8.28l211.83-211.74a5.89 5.89 0 0 0 0-8.27l-49.86-49.8a5.87 5.87 0 0 0 -8.27 0l-274.17 274.2a5.87 5.87 0 0 0 0 8.27l274.17 274.17a5.87 5.87 0 0 0 8.27 0l274.16-274.17a5.85 5.85 0 0 0 0-8.25l-161.3-161.3a5.86 5.86 0 0 0 -8.26 0l-161 161a5.83 5.83 0 0 1 -8.28 0z" fill="#de5959" />
          </svg>
  </Svg>
  );
};

export default Icon;
