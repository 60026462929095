
import styled from 'styled-components'
import { ScopeButtonProps } from './types';

const ScopeButton = styled.button<ScopeButtonProps>`
    width: 48px;
    height: 24px;
    padding: 4px, 12px, 4px, 12px;
    align-items: center;
    background-color: #EAECF5;
    border-radius: 16px;
    box-shadow: 0px -1px 0px 0px rgba(14, 14, 44, 0.4) inset;
    cursor: pointer;
    font-color: #AAABAD
    font-family: inherit;
    font-size: 9.26px;
    font-weight: 600;
    justify-content: center
    border: 0px;

`

export default ScopeButton;