import { createReducer } from '@reduxjs/toolkit'
import { getNftFilter } from './actions'

const defaultFilter = {
	owned: false,
	created: false,
	liked: false,
	onsale: false,
	mybid: false,
	image: false,
	gif: false,
	video: false,
	text: false,
	audio: false
}

export interface NftFilter {
   NftFilterData: {
	owned: boolean;
	created: boolean;
	liked: boolean;
	onsale: boolean;
	mybid: boolean;
	image: boolean;
	gif: boolean;
	video: boolean;
	text: boolean;
	audio: boolean;
	}
}

const initialState: NftFilter = {
	NftFilterData: {...defaultFilter},
}

export default createReducer<NftFilter>(initialState, (builder) =>
  builder
    .addCase(getNftFilter, (state, { payload: { nftFilter } }) => {
      return {
        ...state,
        NftFilterData: {...nftFilter},
      }
    })
)
