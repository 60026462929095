import React from 'react';
import styled from "styled-components";
import { CollectionCardProps } from './types';
import { Text } from "../../atoms/Text";
import { AddIcon } from '../../atoms/Svg'

const CollectionCardContainer = styled.div<{ size: number, mr?: number }>`
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
    align-items: start;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    margin-right: ${(props) => props.mr}px;
    padding-top: 18px;
    background: 
        linear-gradient(${({ theme }) => theme.colors.flatGlobal},${({ theme }) => theme.colors.flatGlobal}) padding-box,
        ${({ theme }) => theme.colors.strokeGradNorm} border-box;
    border: 1px solid transparent;
    border-image-source: ${({ theme }) => theme.colors.strokeGradNorm};
    border-radius:  5.67px;
    border-image-slice: 0;
    box-shadow: ${({ theme }) => theme.shadows.idleSoft};
    cursor: pointer;
`
const Avatar = styled.img`
    display: grid;
    width: 32px;
    height: 32px;
    background: ${({ theme }) => theme.colors.textPrimary};
    border-radius: 50%;
`

const IconWrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
`

const TextWrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    margin-bottom: 6px;
    padding: 0 5px;
    
    .collection-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .token-abbr {
      text-align: center;
    }
`

const CollectionCard: React.FC<CollectionCardProps> = ({
    size,
    img,
    name,
    symbol,
    mr = 0,
    changeHandler,
    ...props
}) => {
    return (
        <CollectionCardContainer size={size} mr={mr} onClick={changeHandler} {...props}>
            {img ? (
                <Avatar src={img} alt="Avatar" />
            ) : (
                <IconWrapper>
                    <AddIcon size={38} />
                </IconWrapper>
            )}
            <TextWrapper>
                <Text className='collection-name' font="captionSemiBoldLarge" color="textPrimary" mb="5px" >
                    {name}
                </Text>
                <Text className='token-abbr' font="captionRegularSmall" color="textPrimary">
                    {symbol}
                </Text>  
            </TextWrapper>
        </CollectionCardContainer>
    )
}

export default CollectionCard;