import React from 'react'
import styled from 'styled-components'
import { NFTCategory, ZapNFT } from 'state/types'
import { Flex, Text } from 'toolkitUI'
import { DocumentViewer } from 'react-documents'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

const MediaContainer = styled.div`
  // width: 267px;
  // height: 267px;
  overflow: hidden;
  position: relative;

  .audio-player {
    width: inherit;
  }

.media-content {
    display: inline-grid;
    width: 100%;
    height: 100%;
  }
`

const NftImage = styled.img`/* 
  width: 100%;
  height: 100%; */
  object-fit: cover;/* 
  flex-shrink: 0;
  min-height: 100% */
  -webkit-user-drag: none;
`

const VideoWrapper = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
`

const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
`

const AudioWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const AudioThumbnail = styled.img`
  width: 100%;
  src: ${(p) => p.src};
  -webkit-user-drag: none;
`

const AudioPlayerWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;

  .rhap_container {
    background: transparent;
    border-radius: 7px;
    box-shadow: none;

    .rhap_time {
      color: ${({ theme }) => theme.colors.white};
      font-size: 16px;
    }

    .rhap_progress-filled {
      background: ${({ theme }) => theme.colors.audioPlayer};
    }

    .rhap_progress-indicator {
      width: 14px;
      height: 14px;
      margin-left: -5px;
      top: -5px;
      background: ${({ theme }) => theme.colors.white};
    }

    .rhap_download-progress {
      background: ${({ theme }) => theme.colors.white};
    }

    .rhap_controls-section {
      .rhap_additional-controls {
        flex: initial;

        .rhap_button-clear {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      .rhap_main-controls {
        button {
          color: ${({ theme }) => theme.colors.white};
        }
      }

      .rhap_volume-controls {
        flex: initial;
        margin-top: 4px;

        button {
          color: ${({ theme }) => theme.colors.white};
        }

        .rhap_volume-indicator {
          background: ${({ theme }) => theme.colors.white};
          opacity: 1;
        }

        .rhap_volume-bar-area {
          width: 30px;
        }
      }
    }
  }
`

const TextWrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`

const TextDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10000;
`

const PdfDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10000;
`

  interface MediaContentProps {
    nft?: ZapNFT
    onClick?: () => void
    isMobile: boolean
  }

const MediaContent: React.FC<MediaContentProps> = ({ nft, onClick, isMobile=false, ...props}) => {
  return (
    <>
      {
        typeof nft.category === undefined || nft.category === null ?
          (
            <Flex height="100px" background="pink" width="200px">
              <Text> something is wrong</Text>
            </Flex>
          ) :
          (
            nft.category === NFTCategory.IMAGE ? 
            (
              <MediaContainer onClick={onClick} {...props}>
                <NftImage  className="media-content" alt={nft.name} src={nft.contentURI} />
              </MediaContainer>
            )
            : nft.category === NFTCategory.GIF ? 
            (
              <MediaContainer onClick={onClick} {...props}>
                <NftImage  className="media-content" alt={nft.name} src={nft.contentURI} />
              </MediaContainer>
            )
            : nft.category === NFTCategory.VIDEO ?
            (
              <MediaContainer onClick={onClick} {...props}>
                <VideoWrapper>
                  <VideoPlayer autoPlay={!isMobile} playsInline muted loop>
                    <source src={nft.contentURI} type={nft.type} />
                  </VideoPlayer>
                </VideoWrapper>
              </MediaContainer>
            ) 
            : nft.category === NFTCategory.TEXT ?
            (
              <MediaContainer {...props}>
                <TextWrapper>
                  <TextDiv onClick={onClick} />
                  <DocumentViewer url={nft?.contentURI} viewer='url' />
                </TextWrapper>
              </MediaContainer>
            )
            : nft.category === NFTCategory.OTHER ?
            (
              <MediaContainer {...props}>
                <PdfDiv onClick={onClick} />
                <DocumentViewer url={nft?.contentURI} viewer='url' />
              </MediaContainer>
            ) 
            : nft.category === NFTCategory.AUDIO &&
            (
              <MediaContainer onClick={onClick} {...props}>
                <AudioWrapper>
                  <AudioThumbnail src="/images/audioZap.png" />
                  <AudioPlayerWrapper>
                    <AudioPlayer src={nft.contentURI} />
                  </AudioPlayerWrapper>
                </AudioWrapper>
              </MediaContainer>
            ))
      }
    </>
  )
}

export default MediaContent