/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Moralis from 'moralis'
import { Flex, Text } from 'toolkitUI'
import styled from 'styled-components'
import { usePopper } from 'react-popper'

Moralis.initialize(process.env.REACT_APP_MORALIS_APPLICATION_ID)
Moralis.serverURL = process.env.REACT_APP_MORALIS_SERVER_URL

interface GasPrice {
  fast: number
  average: number
  slow: number
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
`

const ListRowWrapper = styled(Flex)`
  padding: 8px 12px;
  width: 192px;
`

const PopoverContainer = styled.div<{ show: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: visibility 150ms linear, opacity 150ms linear;

  background: ${({ theme }) => theme.colors.invertedContrast};
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  box-shadow: -1px 0px 10px 7px rgb(0 0 0 / 4%);
  color: ${({ theme }) => theme.colors.textSubtle};
  border-radius: 8px;
`

const GasTextLable = styled(Text)`
  width: 80px;
`

const GasTextValue = styled(Text)`
  font-weight: bold;
`

export default function GasPrice() {
  const [gasPrice, setGasPrice] = useState<GasPrice>(null)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [-64, 8] } }],
  })
  const [show, setShow] = useState(false)

  useEffect(() => {
    const query = new Moralis.Query('GasFees')
    const setGas = (obj: Moralis.Attributes) => {
      if (!obj) {
        return
      }
      const { fast, slow, average } = obj.attributes
      setGasPrice({ fast, slow, average })
    }
    let subscription: Moralis.LiveQuerySubscription

    const createSubscription = async () => {
      subscription = await query.subscribe()
      subscription.on('update', (obj) => setGas(obj))
    query.find().then(([obj]) => setGas(obj))
    }
    createSubscription()

    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [])

  return (
    <Wrapper>
      {/* <ToggleWrapper ref={setReferenceElement} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        GAS
      </ToggleWrapper> */}
      <PopoverContainer show={show} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <ListRowWrapper flexDirection="column">
          <Flex flexDirection="row" pr={10} flexWrap="wrap">
            <GasTextLable>Fast: </GasTextLable>
            <GasTextValue ml={1}>{gasPrice?.fast || 0} gwei</GasTextValue>
          </Flex>
          <Flex flexDirection="row" pr={10} flexWrap="wrap">
            <GasTextLable>Average: </GasTextLable>
            <GasTextValue ml={1}>{gasPrice?.average || 0} gwei</GasTextValue>
          </Flex>
          <Flex flexDirection="row" pr={10} flexWrap="wrap">
            <GasTextLable>Slow: </GasTextLable>
            <GasTextValue ml={1}>{gasPrice?.slow || 0} gwei</GasTextValue>
          </Flex>
        </ListRowWrapper>
      </PopoverContainer>
    </Wrapper>
  )
}
