import React from 'react';
import styled from "styled-components";
import { LogoProps } from "./types";
import { LogoIcon } from '../Svg';


// Bolt Container
const LogoCard = styled.div<{size: number}>`
  display: grid;
  justify-content: center;
  align-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: 
      linear-gradient(${({ theme }) => theme.colors.flatGlobal},${({ theme }) => theme.colors.flatGlobal}) padding-box,
      ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  box-shadow: ${({ theme }) => theme.isDark? theme.shadows.idleSoft : theme.shadows.idleMedium};
  border-radius: ${({ size }) => `${size * 10 / 40}px`};
  border: .5px solid;
    border-image-source: linear-gradient(180deg, #373737 0%, #303030 100%);
  border-image-slice: 0;
`

// ZAP text
const TextWrapper1 = styled.span<{size: number, height: number}>`
	padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.height}px;
  background: ${({ theme }) => theme.colors.gradientBlue};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

// NFT or DEX text
const TextWrapper2 = styled.span<{size: number, height: number}>`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.height}px;
  background: ${({ theme }) => theme.colors.gradIconColor1};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const LogoWrapper = styled.div<{size: number, withText: boolean}>`
  display: grid;
  width: 100%; // ${(props) => props.size}px;
  grid-template-columns: ${(props) => props.withText ? 'repeat(3, fit-content(0))' : 'unset'};
  align-items: center;
  justify-items: center;
`

const Logo: React.FC<LogoProps> = ({ withText, text = "NFT", size }) => {
    const wrapperWidth = size * 110 / 40;
    const fontSize = size * 16 / 40;
    const fontHeight = size * 19 / 40;
    return (
        <LogoWrapper size={wrapperWidth} withText={withText}>
            <LogoCard size={size}>
                <LogoIcon size={size} />
            </LogoCard>
            {
                withText ?
                <>
                    <TextWrapper1 size={fontSize} height={fontHeight}>Zap</TextWrapper1>
                    <TextWrapper2 size={fontSize} height={fontHeight}>{text}</TextWrapper2>
                </> :
                null
            }
        </LogoWrapper>
    )
}

export default Logo;
