import React from 'react'
import { SecurityIcon, VoteWatcherSmIcon, CaptureIcon, BlockSearchIcon, Categories, ClockIcon, CreateIcon, DisputeIcon, DocsIcon, HardhatIcon, MarketIcon, MoneyIcon, ProfileIcon, TradeIcon, ZapCoinIcon } from 'designStandard/components/atoms/Svg'

export const IconMap = {
  'Blocks': <BlockSearchIcon width={20}/>,
  'Dispute': <DisputeIcon width={20}/>,
  'Docs': <DocsIcon width={16}/>,
  'Hardhat': <HardhatIcon width={18}/>,
  'Market': <MarketIcon width={18}/>,
  'Explore': <Categories width={20} />,
  'Clock': <ClockIcon width={18}/>,
  'Create': <CreateIcon width={18}/>,
  'Profile': <ProfileIcon width={22}/>,
  'Money': <MoneyIcon width={20}/>,
  'Trade': <TradeIcon width={20}/>,
  'VoteWatcher': <div style={{marginRight: '8px'}}><VoteWatcherSmIcon /></div>,
  'Capture': <CaptureIcon/>,
  'Security': <SecurityIcon />,
  'ZapCoin': <ZapCoinIcon width={22}/>,
}

export const menuItems = [
  {
    label: 'NFT Marketplace',
    // icon: 'Market',
    // href: '/nft',
    items: [
      {
        label: 'Explore',
        icon: 'Market',
        href: '/nft-marketplace',
      },
      {
        label: 'My Profile',
        icon: 'Profile',
        href: '/nft/profile'
      },
      {
        label: 'Activity',
        icon: 'Clock',
        href: '/nft/activitypage',
      },
      {
        label: 'Mint NFT',
        icon: 'Create',
        href: '/nft/create'
      }
    ],
  },
  {
    label: 'VoteWatcher',
    icon: 'VoteWatcher',
    href: '/votewatcher',
    initialOpenState: false,
    items: [
      {
        label: 'Capture',
        icon: 'Capture',
        href: '/votewatcher'
      },
      // {
      //   label: 'Admin Portal',
      //   icon: 'Security',
      //   href: '/votewatcher/admin'
      // }
      {
        label: 'NFT Collection BSC-Main',
        icon: 'Security',
        href: '/nft/collection/0x5f53325046c7a4b61c6acbf02b1124fae4dd6171'
      },
      {
        label: 'NFT Collection BSC-Test',
        icon: 'Security',
        href: '/nft/collection/0xc0e5f3c321fec03050fed7febde3f305e7a3b6b2'
      }

    ]
    },
  {
    label: 'Oracles',
    // icon: 'TradeIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Feeds',
        icon: 'Money',
        href: '/pricefeed',
      },
      {
        label: 'Events',
        icon: 'Hardhat',
        href: '/miningevents',
      },
      {
        label: 'Disputes',
        icon: 'Dispute',
        href: '/alldisputes'
      }
    ],
  },
  {
    label: 'ZAP Labs',
    // icon: 'TradeIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Bonding Curve Tokens',
        icon: 'Trade',
        href: '/labs/curvetokens',
      },
      {
        label: 'New Token Wizard',
        icon: 'ZapCoin',
        href: '/labs/newtoken',
      },
      {
        label: 'Block Explorer',
        icon: 'Blocks',
        href: '/labs/blockexplorer',
      },
    ],
  },/* 
  {
    label: 'DEX (coming soon)',
    icon: 'TradeIcon',
    href: '/dex'
  }, */
  {
    label: 'ZAP Docs',
    icon: 'Docs',
    href: '/docs',
  }
]

export const Icons = {

}

export default menuItems
