import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const CopyIcon: React.FC<SvgProps> = (props) => {
  // const {color} = props
  const theme = useTheme()

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path className="copy-icon" fill="transparent" stroke={theme.colors.gradIconColor2} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"/>
    <path className="copy-icon" fill="transparent" stroke={theme.colors.gradIconColor2} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M22 6.9v4.2c0 3.5-1.4 4.9-4.9 4.9H16v-3.1C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2h4.2C20.6 2 22 3.4 22 6.9z"/>
    </Svg>
  )
}

export default CopyIcon