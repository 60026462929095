import { ArtistType, NFTArtists } from 'state/zapArtists/types'

const getZapArtists = async (Moralis: any): Promise<ArtistType[]> => {
  const artists: ArtistType[] = []
  const moralisCollections = Moralis.Object.extend('NftUser')
  const collectionQuery = new Moralis.Query(moralisCollections)
  const collectionQueryRes = await collectionQuery.find()

  collectionQueryRes.forEach((element: NFTArtists) => artists.push(element?.attributes))
  return Promise.all(artists)
}

export default getZapArtists
