
import { Ask } from 'state/types'
import ZapNftDetails from 'entities/zapNftDetails';
// import Moralis from 'moralis'

export default async function useZapNfts(Moralis): Promise<ZapNftDetails[]> {
    // let nftRes: ZapNftDetails[] = []
    // const nftData = await getAllNfts()
    // Moralis.initialize(process.env.REACT_APP_MORALIS_APPLICATION_ID)
    // Moralis.serverURL = process.env.REACT_APP_MORALIS_SERVER_URL
    const nftClass = Moralis.Object.extend("ZapNFTs")
    const nftQuery = new Moralis.Query(nftClass)
    nftQuery.limit(10000)
    const nftData = await nftQuery.find()

    // for (let i = 0; i < nftData.length; i++){
    const nftRes = nftData.map(async(nft) => {
        const item = nft.attributes
        if (item.isHidden) return null
        const userClass = Moralis.Object.extend("NftUser")
        const userLook = new Moralis.Query(userClass)
        userLook.equalTo("address", item.creatorId.toLowerCase())
        const results = await userLook.find()
        const user = results[0]
        const newDate = String(item.created).replace("at ", "")
        const eachNft = new ZapNftDetails()
        
        const ask = {
            currency: item.AskingCurrency,
            symbol: item.AskSymbol,
            amount: item.price,
            // timestamp: item.AskTimestamp
        } as Ask

        eachNft.id = parseInt(item.tokenId + String(item.blockNumber) + String(item.ChainID))
        eachNft.title = item.title
        eachNft.artist = user?.attributes?.name || "Error with Artist Name"
        eachNft.category = item.category
        eachNft.nftCategory = item.nftCategory ?? []
        eachNft.contractAddress = item.contractAddress
        eachNft.description = item.description || "No Description"
        eachNft.isNewToken = Date.now() - Date.parse(newDate) < 604800000
        eachNft.content = item.content
        eachNft.latestBid = item.LatestBid
        eachNft.thumbnailUrl = item.thumbnailUrl
        eachNft.url = item.url
        eachNft.price = item.price
        eachNft.ownerAddress = item.ownerId
		eachNft.creatorAddress = item.creatorId
        eachNft.contentURI = item.contentURI
        eachNft.tokenId = item.tokenId
        eachNft.views = item.Views
        eachNft.likes = item.Likes
        eachNft.listType=item.ListType
        eachNft.listed=item.Listed
        eachNft.reservePrice=item.ReservePrice
        eachNft.endAuction=item.EndAuction
        eachNft.auctionCurrency=item.AuctionCurrency
        eachNft.auctionId=item.AuctionId
        eachNft.curator=item.Curator
        eachNft.currentAsk = ask
        eachNft.isListed = item.listed
        eachNft.isAuction = item.listType === 'auction'
        eachNft.auctionTokenOwner = item.AuctionOwner || item.ownerId
        eachNft.currentBids = item.currentBids ?? []
        eachNft.currentOffers = item.currentOffers ?? []
        eachNft.askingCurrency = item.AskingCurrency
        eachNft.chainId = item.ChainID
        eachNft.isFeatured = item.Featured
        eachNft.attributes = item.attributes ?? []
        eachNft.nftCategory = item.nftCategory ?? []
        eachNft.reported = item.Reported ?? []
        eachNft.blockNumber = item.blockNumber
        eachNft.created = String(item.created).replace("at ", "")
        // nftRes.push(eachNft)
        return eachNft;
    })
    // nftRes = Promise.all(aaa)
    return Promise.all(nftRes);
}