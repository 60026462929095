import React, { FC } from 'react'
import styled, { keyframes } from "styled-components"
import { useMatchBreakpoints } from 'designStandard/hooks'

const NFTCardWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.skeletonBg};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 24px;
`

const placeHolderShimmer = keyframes`

  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`

const NFT = styled.div<{ width: number, height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border-radius: 16px 16px 0 0;
`


const NFTBody = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  height: 44px;
  background: ${({ theme }) => theme.colors.skeletonCard};
`

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const PlaceHolder = styled.div`
  width: 90px;
  height: 24px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const FlatButton = styled.div`
  width: 98px;
  height: 28px;
  border-radius: 392.677px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const NFTFooter = styled.div`
  height: 30px;
  border-radius: 0 0 16px 16px;
`

interface SkeletonProps {
  size: number
}

const NFTCardSkeleton: FC<SkeletonProps> = ({ size }) => {
  const { isXs, isSm } = useMatchBreakpoints()
  const isMobile = isXs || isSm
  if (isMobile) size -= 16

  return (
    <NFTCardWrapper size={size}>
      <NFT width={size} height={size} />
      <NFTBody>
        <Avatar />
        <PlaceHolder />
        <FlatButton />
      </NFTBody>
      <NFTFooter />
    </NFTCardWrapper>
  )
}

export default NFTCardSkeleton