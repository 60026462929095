import { createReducer } from '@reduxjs/toolkit'
import { setProfile, clear} from './actions'

export interface TypeBio {
	content: string
	toggle: boolean
}

export interface SocialLink {
	url: string
	toggle: boolean
}

export interface SocialLinks {
	website?: SocialLink
	discord?: SocialLink
	twitter?: SocialLink
	instagram?: SocialLink
	youtube?: SocialLink
}

export interface ProfileState {
	id: any,
  name: string
  address: string
	bio?: TypeBio
	avatar?: string
	backDrop?: string
	socialLinks?: SocialLinks
}

const initialState: ProfileState = {
	id: '',
  name: '',
	address: '',
	bio: {
		content: '',
		toggle: false
	},
	avatar: '',
	backDrop: '',
	socialLinks: {
		website: {
			url: '',
			toggle: false
		},
		discord: {
			url: '',
			toggle: false
		},
		twitter: {
			url: '',
			toggle: false
		},
		instagram: {
			url: '',
			toggle: false
		},
		youtube: {
			url: '',
			toggle: false
		},
	}
}

export default createReducer<ProfileState>(initialState, (builder) =>
  builder
    .addCase(setProfile, (state, { payload: data }) => {
      return {
        ...state,
        ...data
      }
    })
    .addCase(clear, (state) => {
      return {
        ...state,
        ...initialState
      }
    })
)
