import React from "react";
import styled from "styled-components";
// import { CogIcon } from "../../../components/Svg";
// import IconButton from "../../../components/Button/IconButton";
import { MENU_ENTRY_HEIGHT } from "../config";
import { PanelProps, PushedProps } from "../types";
import ThemeSwitcher from "./ThemeSwitcher";
import MenuLang from "./MenuLang";
import SocialLinks from "./SocialLinks";

interface Props extends PanelProps, PushedProps {
  showMenu: boolean
}

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  background-color: ${({ theme }) => theme.nav.background};
  border-top: solid 2px rgba(133, 133, 133, 0.1);
`;

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 8px;
`;

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`;

const PanelFooter: React.FC<Props> = ({
  isPushed,
  showMenu,
  toggleTheme,
  isDark,
  langs,
}) => {
  return (
    (isPushed && showMenu) &&
    <Container>
      <SocialEntry>
        <SocialLinks />
      </SocialEntry>
      <SettingsEntry>
        <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
        <MenuLang langs={langs} />
      </SettingsEntry>
    </Container>
  );
};

export default PanelFooter;
