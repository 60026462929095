import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

interface InformationIconProps extends SvgProps {
  size: number
}

const InformationIcon: React.FC<InformationIconProps> = (props) => {
  const theme = useTheme()
  const { size } = props
  const width = size * 2 / 14
  const height = size * 6 / 14
  return (
    <Svg width={width} height={height} viewBox="0 0 2 6" fill="none" {...props}>
      <path d="M0.4928 6H1.50444V1.7075H0.4928V6ZM1.00142 1.09828C1.32279 1.09828 1.58549 0.852352 1.58549 0.550536C1.58549 0.245925 1.32279 0 1.00142 0C0.677243 0 0.414551 0.245925 0.414551 0.550536C0.414551 0.852352 0.677243 1.09828 1.00142 1.09828Z" fill="url(#paint0_linear_835:241)" />
      <defs>
        <linearGradient id="paint0_linear_835:241" x1="1.58549" y1="7.125" x2="-1.12592" y2="6.73262" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1} />
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity="0.8" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default InformationIcon
