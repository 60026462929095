import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const TradingCards: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()
  return (
    <Svg  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_36982)">
      <path d="M14.0229 1.14197L6.28336 2.41631C4.76773 2.66586 3.74137 4.09683 3.99092 5.61246L6.03315 18.0158C6.28271 19.5314 7.71367 20.5578 9.22931 20.3082L16.9688 19.0339C18.4845 18.7843 19.5108 17.3534 19.2613 15.8377L17.2191 3.43442C16.9695 1.91878 15.5385 0.892421 14.0229 1.14197Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M19.2578 6.76562H11.4141C9.87802 6.76562 8.63281 8.01083 8.63281 9.54687V22.1172C8.63281 23.6532 9.87802 24.8984 11.4141 24.8984H19.2578C20.7939 24.8984 22.0391 23.6532 22.0391 22.1172V9.54687C22.0391 8.01083 20.7939 6.76562 19.2578 6.76562Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_1678_36982">
          <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
      </defs>

    </Svg>
  )
}

export default TradingCards
