import { useEffect, useRef, useState } from 'react'
import { getNames, clear } from 'state/zaplabs/actions'
// import { updateOne as updateTransaction } from 'state/zapTransactions/actions'
import { AppState, useAppDispatch } from 'state'
import { useMoralis, useMoralisSubscription } from 'react-moralis'
import { getZapLabTokens } from 'hooks/useZapTokens'
import useZapTransactionsList from 'hooks/useZapTransactions'
import ZapNftDetails from 'entities/zapNftDetails'
import useZapPriceFeed from 'hooks/useZapPriceFeed'
import { getPriceFeed } from 'state/zapPriceFeed/actions'
import useWeb3 from 'hooks/useWeb3'
import useZapPriceDetail from 'hooks/useZapPriceDetail'
import { getPriceDetail } from 'state/zapPriceDetail/actions'
import useZapMiningEvents from 'hooks/useZapMiningEvents'
import { getMiningEvents } from 'state/zapMiningEvents/actions'
import useZapDisputeEvents from 'hooks/useZapDisputeEvents'
import useProviderTitle from 'hooks/useProviderTitle'
import { getDisputedEvents } from 'state/zapDisputeEvents/actions'
import { getTransactions } from 'state/zapTransactions/actions'
import ZapMiningEvent from 'entities/zapMiningEvent'
import { useSelector } from 'react-redux'
import blacklist from 'constants/blacklist.json'
import { getNfts, completeProcessing, updateOne as updateNFT } from 'state/zapNfts/actions'
import { setProfile } from 'state/nftProfile/actions'
import useZapNfts from 'hooks/useZapNfts'
import { Ask } from 'state/types'
import useZapCollections from 'hooks/useZapCollections'
import useZapArtists from 'hooks/useZapArtists'
import { CollectionType } from './zapCollections/types'
import { getCollections } from './zapCollections/actions'
import { getArtists } from './zapArtists/actions'
import { ArtistType } from './zapArtists/types'

export default function Initialize({ children }) {
  const miningEventsObject = useSelector<AppState, AppState['zapMiningEvents']['MiningEventsList']>(
    (state) => state.zapMiningEvents.MiningEventsList,
  )
  const currentMiningEvents = useSelector<AppState, AppState['zapMiningEvents']['CurrentMiningEventsList']>(
    (state) => state.zapMiningEvents.CurrentMiningEventsList,
  )
  const _user = useRef(null)
  const _zapNFTs = useSelector<AppState, AppState['zapNfts']>((state) => state.zapNfts)
  const { processingNFT } = _zapNFTs
  const dispatch = useAppDispatch()
  const { Moralis, user, logout, authenticate, isAuthenticated } = useMoralis()
  const account = user?.get('ethAddress')
  const { web3, chainId } = useWeb3()
  const [isMainnet, setIsMainnet] = useState(chainId === 1 || chainId === 56)

  const updateCreateNFT = async (nft) => {
    const item = nft.attributes
    if (item.contractAddress) {
      const userClass = Moralis.Object.extend('NftUser')
      const userLook = new Moralis.Query(userClass)
      userLook.equalTo('address', item.creatorId.toLowerCase())
      const results = await userLook.find()
      const userTemp = results[0]
      const newDate = String(item.created).replace('at ', '')
      const eachNft = new ZapNftDetails()
      const ask = {
        currency: item.AskingCurrency,
        symbol: item.AskSymbol,
        amount: item.price,
        // timestamp: item.AskTimestamp
      } as Ask
      eachNft.id = parseInt(item.tokenId + String(item.blockNumber) + String(item.ChainID))
      eachNft.title = item.title
      eachNft.artist = userTemp?.attributes?.name || 'Error getting artist'
      eachNft.category = item.category
      eachNft.contractAddress = item.contractAddress
      eachNft.description = item.description || 'No Description'
      eachNft.isNewToken = Date.now() - Date.parse(newDate) < 604800000
      eachNft.content = item.content
      eachNft.latestBid = item.LatestBid
      eachNft.thumbnailUrl = item.thumbnailUrl
      eachNft.url = item.url
      eachNft.price = item.price
      eachNft.ownerAddress = item.ownerId
      eachNft.creatorAddress = item.creatorId
      eachNft.contentURI = item.contentURI
      eachNft.tokenId = item.tokenId
      eachNft.views = item.Views
      eachNft.likes = item.Likes
      eachNft.listType = item.ListType
      eachNft.listed = item.Listed
      eachNft.reservePrice = item.ReservePrice
      eachNft.endAuction = item.EndAuction
      eachNft.auctionCurrency = item.AuctionCurrency
      eachNft.auctionId = item.AuctionId
      eachNft.curator = item.Curator
      eachNft.currentAsk = ask
      eachNft.isListed = item.listed
      eachNft.auctionTokenOwner = item.AuctionOwner || item.ownerId
      eachNft.isAuction = item.listType === 'auction'
      eachNft.currentBids = item.currentBids ?? []
      eachNft.currentOffers = item.currentOffers ?? []
      eachNft.askingCurrency = item.AskingCurrency
      eachNft.chainId = item.ChainID

      if (
        processingNFT?.title !== undefined &&
        eachNft.title === processingNFT?.title &&
        eachNft.chainId === processingNFT?.chainId &&
        eachNft.ownerAddress.toLowerCase() === account &&
        eachNft.creatorAddress.toLowerCase() === account
      ) {
        dispatch<any>(completeProcessing({ nft: eachNft.getObject() }))
        return
      }
      dispatch<any>(updateNFT({ nft: eachNft.getObject() }))
    }
  }

  useMoralisSubscription('ZapNFTs', (q) => q, [], {
    onCreate: (updatedNFT) => updateCreateNFT(updatedNFT),
    onUpdate: (updatedNFT) => updateCreateNFT(updatedNFT),
  })

  useProviderTitle()

  useEffect(() => {
    if (isAuthenticated) {
      const userClass = Moralis.Object.extend('NftUser')
      const userLook = new Moralis.Query(userClass)
      userLook.equalTo('User', user)
      userLook.find().then((value) => {
        if (value.length > 0) {
          const data = {
            id: value[0]?.id,
            name: value[0]?.get('name'),
            address: user?.get('ethAddress'),
            bio: value[0]?.get('bio'),
            avatar: value[0]?.get('profileImageRef'),
            backDrop: value[0]?.get('profileBackdropRef'),
            socialLinks: value[0]?.get('SocialLinks'),
          }
          dispatch<any>(setProfile(data))
        }
      })
    }
  }, [dispatch, isAuthenticated, user, Moralis.Object, Moralis.Query])

  useEffect(() => {
    dispatch<any>(clear())
    getZapLabTokens(web3, chainId, account).then((r) => {
      const toSet: { [key: string]: any }[] = []
      r.forEach((e) => {
        const o = e.getObject()
        const profanity =
          blacklist.words.some((word) => o.name.toLowerCase().includes(word)) ||
          blacklist.words.some((word) => o.providerTitle.toLowerCase().includes(word)) ||
          blacklist.words.some((word) => o.symbol.toLowerCase().includes(word))
        if (!profanity) toSet.push(o)
      })
      dispatch<any>(getNames({ chainId, names: toSet }))
    })
  }, [dispatch, web3, chainId, account])

  useEffect(() => {
    useZapTransactionsList(web3, chainId).then((r) => {
      let toSet: { [key: string]: any }[] = []
      toSet = r.map((e) => {
        const o = e.getObject()
        return o
      })
      dispatch<any>(getTransactions({ transactions: toSet }))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, web3, chainId])

  useEffect(() => {
    useZapDisputeEvents(Moralis).then((r) => {
      const toSet: { [key: string]: any }[] = []
      r.forEach((e) => {
        const o = e.getObject()
        toSet.push(o)
      })
      dispatch<any>(getDisputedEvents({ disputedEvents: toSet }))
    })
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    useZapMiningEvents(web3, chainId, Moralis).then((r) => {
      const toSet: { [key: string]: any }[] = []
      const toSetCurrent: { [key: string]: any }[] = []
      r.forEach((e) => {
        const o = e.getObject()
        if (o.status === 'mining...') {
          toSetCurrent.push(o)
        } else {
          toSet.push(o)
        }
      })
      dispatch<any>(getMiningEvents({ miningEvents: toSet, currentMiningEvents: toSetCurrent }))
    })
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    useZapPriceFeed().then((r) => {
      const toSet: { [key: string]: any }[] = []
      r.forEach((e) => {
        const o = e.getObject()
        toSet.push(o)
      })
      dispatch<any>(getPriceFeed({ priceFeed: toSet }))
      useZapPriceDetail(toSet, Moralis).then((rD) => {
        const toSetD: { [key: string]: any }[] = []
        rD.forEach((eD) => {
          const toSetDI: { [key: string]: any }[] = []
          eD.data.forEach((eDI) => {
            const oD = eDI.getObject()
            toSetDI.push(oD)
          })
          toSetD.push({ id: eD.id, data: toSetDI })
        })
        dispatch<any>(getPriceDetail({ priceDetail: toSetD }))
      })
    })
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    useZapNfts(Moralis)
      .then((r) => {
        const toSet: { [key: string]: any }[] = []
        r.forEach((e) => {
          try {
            const o = e.getObject()
            if (o.ownerAddress !== '0x0000000000000000000000000000000000000000') toSet.push(o)
          } catch (error) {
            console.log('This is a hidden NFT')
          }
        })
        dispatch<any>(getNfts({ nfts: toSet }))
      })
      .catch((error) => console.log(error))
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    useZapCollections(Moralis).then((collections: CollectionType[]) => {
      dispatch<any>(getCollections({ collections }))
    })
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    useZapArtists(Moralis).then((artists: ArtistType[]) => {
      dispatch<any>(getArtists({ artists }))
    })
  }, [dispatch, web3, chainId, Moralis])

  useEffect(() => {
    Moralis.Web3.onAccountsChanged(async (accounts) => {
      if (!accounts.length) {
        return
      }
      if (!_user.current) {
        return
      }
      await logout()
      await authenticate()
    })
    Moralis.Web3.onChainChanged(async (chain) => {
      if ((isMainnet && chain !== '1' && chain !== '56') || (!isMainnet && (chain === '1' || chain === '56'))) {
        await logout()
        setIsMainnet(!isMainnet)
      }
      window.location.reload()
    })
  }, [Moralis.Web3, authenticate, logout, isMainnet])

  useEffect(() => {
    _user.current = user
  }, [user])

  const updateArtistRedux = () => {
    useZapArtists(Moralis).then((artists: ArtistType[]) => {
      dispatch<any>(getArtists({ artists }))
    })
  }

  useMoralisSubscription('NftUser', (q) => q, [], {
    onCreate: () => updateArtistRedux(),
    onUpdate: () => updateArtistRedux(),
  })

  useMoralisSubscription('ZapPythiaEvents', (q) => q, [], {
    onCreate: (dataM) => miningStarted(dataM),
    onUpdate: (dataM) => updateMining(dataM),
  })

  const miningStarted = (dataM) => {
    const handledEvent = new ZapMiningEvent()

    const toSetCurrent: { [key: string]: any }[] = []
    const toSet: { [key: string]: any }[] = Object.values(miningEventsObject)

    handledEvent.id = dataM.attributes.Id
    handledEvent.rowtitle = dataM.attributes.rowtitle
    handledEvent.timestamp = dataM.attributes.Timestamp
    handledEvent.requestIds = dataM.attributes.RequestId
    handledEvent.minedValues = dataM.attributes.MinedValue
    handledEvent.totalTips = dataM.attributes.TotalTips
    handledEvent.block = dataM.attributes.BlockNumber
    handledEvent.requestSymbols = dataM.attributes.RequestSymbols
    handledEvent.inDisputeWindow = dataM.attributes.InDisputeWindow
    handledEvent.granPrices = dataM.attributes.GranPrices
    handledEvent.status = dataM.attributes.Status
    handledEvent.minerValues = dataM.attributes.MinerValues

    toSetCurrent.push(handledEvent)

    dispatch<any>(getMiningEvents({ miningEvents: toSet, currentMiningEvents: toSetCurrent }))
  }

  const updateMining = (dataM) => {
    const handledEvent = new ZapMiningEvent()

    let toSetCurrent: { [key: string]: any }[] = Object.values(miningEventsObject)
    const toSet: { [key: string]: any }[] = Object.values(miningEventsObject)

    handledEvent.id = dataM.attributes.Id
    handledEvent.rowtitle = dataM.attributes.rowtitle
    handledEvent.timestamp = dataM.attributes.Timestamp
    handledEvent.requestIds = dataM.attributes.RequestId
    handledEvent.minedValues = dataM.attributes.MinedValue
    handledEvent.totalTips = dataM.attributes.TotalTips
    handledEvent.block = dataM.attributes.BlockNumber
    handledEvent.requestSymbols = dataM.attributes.RequestSymbols
    handledEvent.inDisputeWindow = dataM.attributes.InDisputeWindow
    handledEvent.granPrices = dataM.attributes.GranPrices
    handledEvent.status = dataM.attributes.Status
    handledEvent.minerValues = dataM.attributes.MinerValues

    if (currentMiningEvents[handledEvent.id]) {
      if (handledEvent.status === 'mined') {
        toSet.unshift(handledEvent)
        toSetCurrent = []
      } else {
        toSetCurrent = [handledEvent]
      }
    } else {
      for (let i = 0; i < toSet.length; i++) {
        if (toSet[i].id === handledEvent.id) {
          toSet[i] = handledEvent
        }
      }
    }

    dispatch<any>(getMiningEvents({ miningEvents: toSet, currentMiningEvents: toSetCurrent }))
  }

  return children
}
