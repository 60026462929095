/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { useMoralis, useMoralisQuery } from 'react-moralis'
// import { useWeb3React } from '@web3-react/core'
import useWeb3 from 'hooks/useWeb3'
import { useToast } from 'state/hooks'

// import { useLocation } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import PageWrapper from 'designStandard/components/organisms/PageWrapper'


const Menu: React.FC = (props) => {

	const { toastSuccess, toastError } = useToast()

  const { isDark, toggleTheme } = useTheme()
  const { logout, user } = useMoralis()
  const account = user?.get('ethAddress')
  const { data } = useMoralisQuery('NftUser', (query) => query.equalTo('User', user).limit(2), [user], {
    live: true,
  })
  const userProfilePic = data[0]?.get('profileImageRef')
  const { chainId } = useWeb3()

  const userLogout = async () => {
    await logout()
  }

	const isEmailAddress = (str) => {
		const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    return str.match(pattern)    
	}

	const handleSubscribe = (emailaddr) => {
		if(isEmailAddress(emailaddr) === null) {
			toastError('Email validation failed.')
			return;
		}
		if(data.length === 0) {
			toastError('Please subscribe after login.')
			return;
		}
		console.log(emailaddr)
		data[0]?.save({email: emailaddr})
		toastSuccess('Successfully registered as a subscriber.')
	}

  return (
    // currentPathname.includes('/nft') 
    //   || currentPathname.includes('profile') 
    //   || currentPathname.includes('votewatcher') ?
    <PageWrapper
      profilePic={userProfilePic}
      account={account}
      chainId={chainId}
      logout={userLogout}
			isDark={isDark}
			handleSubscribe={handleSubscribe}
			toggleTheme={toggleTheme}
      {...props}
    />
    // : 
    // <UikitMenu
    //   profilePic={userProfilePic}
    //   providerTitle={providerTitle}
    //   links={showDocsLinks ? docsLinks : links()}
    //   account={account as string}
    //   chainId={chainId as number}
    //   login={userLogin}
    //   logout={userLogout}
    //   isDark={isDark}
    //   toggleTheme={toggleTheme}
    //   langs={languageList}
    //   // profile={profile}
    //   {...props}
    // />
  )
}

export default Menu
