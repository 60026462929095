import { FC } from 'react'
import { SvgProps } from 'designStandard/components/atoms/Svg'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export type Login = (connectorId: ConnectorNames) => void

export interface Config {
  name: string
  icon: FC<SvgProps>
  connectorId: any
}