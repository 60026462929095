import React from 'react'
import styled from "styled-components"
import { AddIcon, MinusIcon } from "../../atoms/Svg"
import { GradientButtonProps } from "./types"

const GradientContainer = styled.button<{ size: number }>`
  display: grid;
  justify-content: center;
  align-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.flatCheckBox};
  border: ${({ theme }) => `1px solid ${theme.colors.strokeFlatNorm}`};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`

const GradientButton: React.FC<GradientButtonProps> = ({ size, type, changeHandler }) => (
  <GradientContainer size={size} onClick={changeHandler}>
    {type === 'add' ? <AddIcon size={size} /> : <MinusIcon size={size} />}
  </GradientContainer>
)

export default GradientButton