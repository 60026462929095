import React from "react";
import Svg from "../../Svg";
import { SvgProps } from "../../types";

const Icon: React.FC<SvgProps>
  = (props) => {
  return (
  <Svg viewBox="0 0 104 104" {...props}>
<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.88616 67.5742C-0.00994697 53.0766 6.07957 36.9618 18.6209 24.4204C45.8909 -2.84956 102.682 0.972805 103.005 0.99477C102.85 1.21982 61.3333 61.4138 12.1356 67.0438C8.33265 67.479 4.57389 67.6501 0.88616 67.5742Z" fill="#03CA9B"/>
<path d="M6.64744 86.1101C8.05189 88.3498 9.71265 90.4472 11.6332 92.3678C28.4668 109.201 58.8878 106.073 79.5805 85.3801C106.928 58.0323 103.005 0.99477 103.005 0.99477C102.854 1.33274 73.0944 68.0427 25.7961 82.5935C19.3755 84.5688 12.9394 85.7194 6.64744 86.1101Z" fill="#03CA9B"/>
</svg>

  </Svg>
  );
};

export default Icon;
