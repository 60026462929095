import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <g clipPath="url(#clip0)">
        <path d="M53.1447 41.0457C53.1447 47.585 47.8413 52.8781 41.3123 52.8781H12.2514C5.72242 52.8781 0.418945 47.5747 0.418945 41.0457V11.9848C0.418945 5.45582 5.72242 0.152344 12.2514 0.152344H41.3123C47.8413 0.152344 53.1447 5.45582 53.1447 11.9848V41.0457Z" fill="#0A81FE"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.2101 17.1135C32.0957 17.0826 32.9813 17.1032 33.867 17.1032C33.9699 17.1032 34.0832 17.1032 34.2274 17.1032V12.5C33.7537 12.4485 33.2594 12.3867 32.7651 12.3661C31.8588 12.3249 30.9526 12.2837 30.0361 12.3043C28.6459 12.3352 27.338 12.675 26.1949 13.4886C24.8768 14.436 24.1559 15.7644 23.8882 17.3297C23.7749 17.9785 23.744 18.6479 23.7234 19.307C23.7028 20.3368 23.7234 21.3666 23.7234 22.4067V22.798H19.3262V27.9367H23.7028V40.8504H29.0475V27.947H33.4138C33.6404 26.2375 33.8567 24.5486 34.0832 22.7877C33.7331 22.7877 33.4138 22.7877 33.1049 22.7877C31.8588 22.798 29.0166 22.7877 29.0166 22.7877C29.0166 22.7877 29.0269 20.2441 29.0578 19.1319C29.099 17.6181 30.0052 17.1547 31.2101 17.1135Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="52.7258" height="52.7258" fill="white" transform="translate(0.418945 0.152344)"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
