import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CheckIcon } from '../../atoms/Svg'
import { CheckboxProps } from './types'

const getSize = ({ Size }: CheckboxProps) => {
  switch (Size) {
    case 'Small':
      return 14
    case 'Medium':
      return 16
    case 'Large':
      return 20
    default:
      return 14
  }
}

const getFontSize = ({ Size }: CheckboxProps) => {
  switch (Size) {
    case 'Small':
      return 10
    case 'Medium':
      return 12
    case 'Large':
      return 14
    default:
      return 10
  }
}

const getBackground = (State: string, theme: any) => {
  if (State === 'Disabled' || State === 'PreChecked') {
    return `linear-gradient(${theme.colors.flatGlobal},${theme.colors.flatGlobal}) padding-box,	${theme.colors.strokeGradNorm} border-box;`
  }
  if (State === 'Invalid') {
    return `linear-gradient(${theme.colors.flatCheckBox},${theme.colors.flatCheckBox}) padding-box,	${theme.colors.strokeGradError} border-box;`
  }
  return `linear-gradient(${theme.colors.flatCheckBox},${theme.colors.flatCheckBox}) padding-box,	${theme.colors.strokeGradNorm} border-box;`
}

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  font-size: ${getFontSize}px;
  height: fit-content;
  margin: 3px;
`
const Container = styled.div`
  border: 0.5px solid transparent;
  border-radius: 3px;
  display: flex;
  background: ${({ theme, State }) => getBackground(State, theme)};
  width: ${getSize}px;
  height: ${getSize}px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
`
const CheckBoxIcon = styled(CheckIcon)`
  margin: auto;
`
const ChildWrapper = styled.div`
  line-height: ${getSize}px;
  color: ${({ theme }) => theme.colors.textPrimary};
`
const CheckBox: React.FC<CheckboxProps> = ({ ...props }) => {
  const { isChecked, Size, State, children, onChange } = props

  const [_isChecked, setCheck] = useState(isChecked)
  const [_state] = useState(State ?? 'Default')
  // setState ^^^

  const handleClick = () => {
    if (State === 'Disabled' || State === 'PreChecked') {
      return false
    }
    setCheck(!_isChecked)
    return false
  }

  const getCheckIcon = () => {
    if (_state === 'PreChecked' && _isChecked) {
      return <CheckBoxIcon fill="Dark" size={Size} />
    }
    if (_state === 'Invalid' && !_isChecked) {
      return <CheckBoxIcon fill="none" size={Size} />
    }
    if (_state === 'Disabled' && !_isChecked) {
      return <CheckBoxIcon fill="none" size={Size} />
    }
    if (_state === 'Default' && _isChecked) {
      return <CheckBoxIcon fill="Light" size={Size} />
    }
    if (!_isChecked) {
      return <CheckBoxIcon fill="none" size={Size} />
    }
    if (_isChecked) {
      return <CheckBoxIcon fill="Light" size={Size} />
    }
    return <></>
  }

  useEffect(() => {
    if (onChange) {
      onChange(_isChecked)
    }
  }, [onChange, _isChecked])

  useEffect(() => {
    if (_state === 'PreChecked') {
      setCheck(true)
    }
  }, [_state])

  return (
    <Wrapper {...props}>
      <Container State={_state} Size={Size} onClick={handleClick}>
        {getCheckIcon()}
      </Container>
      <ChildWrapper Size={Size}>{children}</ChildWrapper>
    </Wrapper>
  )
}

export default CheckBox
