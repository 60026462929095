import React from 'react';
import styled from "styled-components";

const SidebarContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: ${({ theme }) => theme.colors.flatGlobal};
  z-index: 101;
  transition: all ease 0.2s;
`

const SidebarContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({children, ...props}) => (
  <SidebarContainerWrapper {...props}>
    {children}
  </SidebarContainerWrapper>
)

export default SidebarContainer;
