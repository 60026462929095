import React from 'react'
import styled from "styled-components"
import { CategoryCardProps } from "./types"
import { Text } from "../../atoms/Text"

const CategoryCardContainer = styled.div<CategoryCardProps>`
  width: ${({ size }) => `${size}px`};
  background: 
    linear-gradient(
      ${({ theme }) => theme.colors.flatGlobal},
      ${({ theme }) => theme.colors.flatGlobal}) padding-box,
    ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  border: 1px solid transparent;
  border-radius: 5.06px;
`

const Card = styled.div<CategoryCardProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-left: -1px;
  margin-top: -1px;
  background: ${({ theme }) => theme.colors.flatCard};
  border-radius: 5.06px;
`

const TextWrapper = styled.div`
  height: 30px;
  display: grid;
  justify-content: center;
  align-items: center;
`

const CategoryCard: React.FC<CategoryCardProps> = ({ size, children }) => (
  <CategoryCardContainer size={size}>
    <Card size={size} />
    <TextWrapper>
      <Text font="categoryPrimaryBold">
        {children}
      </Text>
    </TextWrapper>
  </CategoryCardContainer>
)

export default CategoryCard