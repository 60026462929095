import React, { FC, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import { Link }  from "designStandard/components/atoms/Link"
// import { Button } from "designStandard/components/molecules/Button"
import { Text } from "designStandard/components/atoms"
import useConnectWallet from "pages/ConnectWallet/useConnectWallet"

const NavbarWrapper = styled.div<{ isMobile }>`
  display: grid; 
  grid-template-columns: ${({ isMobile }) => !isMobile ? 'auto auto auto auto' : null};
  grid-template-rows: ${({ isMobile }) => isMobile ? '40px 40px 40px 40px' : null};
  align-items: center;
  grid-gap: 16px; // ${({isMobile}) => isMobile ? '16px' : '24px'};
  white-space: pre;

  @media(max-width: 374px) {
    grid-gap: 4px;
  }
  @media(max-width: 915px) {
    grid-gap: 8px;
  }
  @media(min-width: 1025px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`

const ProfileLink = styled(Link)<{ isMobile: boolean, isCurrent: boolean }>`
  color: ${({ theme, isCurrent }) => isCurrent ? theme.colors.textPrimary : theme.colors.textSecondary};
  opacity: 0.9;
  transition: all .1s ease;
  
  &:hover {
    transition: all .1s ease;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    // transition: 1s ease;
  }
  &:link:active {
    font-weight: 600;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    // font-size: 13.33px;
    line-height: 16px;
    // letter-spacing: -0.015em;
  }
    
`

const ProfileText = styled(Text)<{isCurrent: boolean}>`
  color: ${({ theme, isCurrent }) => isCurrent ? theme.colors.textPrimary : theme.colors.textSecondary};
  cursor: pointer;
  opacity: 0.9;
  transition: all ease .1s;
  
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    transition: all ease .1s;
  }
  
  &:link:active {
    font-weight: 600;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: 600;
    // font-size: 13.33px;
    line-height: 16px;
    // letter-spacing: -0.015em;
  }
`

interface NavbarProps {
  isMobile?: boolean
  account?: string
  dropdownHandler?: () => void
}

const Navbars: FC<NavbarProps> = ({ isMobile, account, dropdownHandler }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { onShowHandler } = useConnectWallet()

  const mintHandler = useCallback(() => {
    if (account) {
      history.push('/nft/create')
      dropdownHandler()
    } else {
      onShowHandler()
    }
  }, [account, dropdownHandler, history, onShowHandler])

  const profileHandler = useCallback(() => {
    if (account) {
      history.push('/nft/profile')
    } else {
      onShowHandler()
    }
  }, [account, history, onShowHandler])

  return (
    <NavbarWrapper isMobile={isMobile}>
      <ProfileLink
        isCurrent={pathname.includes('nft-marketplace')}
        href="/nft-marketplace"
        font={pathname === '/nft-marketplace' ? "bodyRegularExtraLargeSemiBold" : "bodyRegularExtraLargeBold"}
        isMobile={isMobile}
      >
        Explore
      </ProfileLink>
      <ProfileLink
        isCurrent={pathname.includes('nft/activitypage')}
        href="/nft/activitypage"
        font={pathname === '/nft/activitypage' ? "bodyRegularExtraLargeSemiBold" : "bodyRegularExtraLargeBold"}
        isMobile={isMobile}
      >
        Activity
      </ProfileLink>
      <ProfileText 
        isCurrent={pathname.includes('nft/profile')}
        onClick={profileHandler} 
        font="bodyRegularExtraLargeBold">
        My Profile
      </ProfileText>
      {/* <MintButton
        scale='sm'
        variant='secondary'
        isMobile={isMobile}
        onClick={mintHandler}
      >
        Mint NFT
      </MintButton> */}
      <ProfileText 
        isCurrent={pathname.includes('nft/create')}
        onClick={mintHandler} 
        font="bodyRegularExtraLargeBold">
        Mint NFT
      </ProfileText>
    </NavbarWrapper>
  )
}

export default Navbars