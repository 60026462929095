class ZapDisputeEvent {
    id?: string

    active?: boolean

    disputedValue?: string

    disputedPair?: string

    timestamp?: string

    submissionAddress?: string

    votes?: {address?: string, timestamp?: string, position?: boolean, weight?: string}[]

    getObject = (): { [key: string]: any } => {
        return {
            id: this.id,
            active: this.active,
            disputedValue: this.disputedValue,
            disputedPair: this.disputedPair,
            timestamp: this.timestamp,
            submissionAddress: this.submissionAddress,
            votes: this.votes,
        }
    }

}

export default ZapDisputeEvent