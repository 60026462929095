import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
        <path d="M48.3089 1.13086H6.12822C3.80829 1.13086 1.91016 3.02899 1.91016 5.34892V47.5296C1.91016 49.8495 3.80829 51.7476 6.12822 51.7476H48.3089C50.6288 51.7476 52.5269 49.8495 52.5269 47.5296V5.34892C52.5269 3.02899 50.6288 1.13086 48.3089 1.13086Z" fill="#0A81FE"/>
        <path d="M43.0359 17.1595C41.8759 17.6868 40.0832 18.3195 38.8178 18.5304C40.1887 17.6868 41.4541 15.7887 41.9814 14.3123C40.9268 14.945 39.7669 15.7887 38.6069 16.2105L37.7633 15.3668C36.6033 14.1014 35.4434 13.2578 33.5452 13.2578C29.9599 13.2578 27.2181 15.9996 27.2181 19.5849C27.2181 20.0067 27.2181 20.3231 27.3236 20.6394H27.2181C20.891 20.6394 16.673 19.2686 13.5094 15.3668C12.9822 16.3159 12.4549 17.3704 12.4549 18.5304C12.4549 20.7449 13.8258 22.643 15.6185 23.803C14.5639 23.803 13.2985 23.2757 12.4549 22.7485C12.4549 25.912 16.8839 29.4974 19.8365 30.1301C18.782 31.1846 14.9857 30.2355 14.5639 30.1301C15.4076 32.6609 18.0438 34.2427 20.891 34.3481C18.6766 36.0354 16.0403 36.9844 12.9822 36.9844C12.4549 36.9844 11.9276 36.9844 11.4004 36.879C14.2476 38.6717 18.2547 39.6207 21.9456 39.6207C33.8616 39.6207 39.8723 30.2355 39.8723 21.6939C39.8723 21.3776 39.8723 20.9558 39.8723 20.6394C41.1377 19.6904 42.1923 18.4249 43.0359 17.1595Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
