
export interface ToastAction {
    text: string;
    url: string;
}

export const types = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    INFO: "info",
}

export type Types = typeof types[keyof typeof types];
export interface Toast {
    id: string;
    type: Types;
    title: string;
    description?: string;
    action?: ToastAction;
}

export interface ToastContainerProps {
    toasts: Toast[];
    onRemove: (id: string) => void;
    stackSpacing?: number;
    ttl?: number;
    onClick?: (id: string) => void;
}


export interface ToastProps {
    toast: Toast;
    onRemove: ToastContainerProps["onRemove"];
    onClick: ToastContainerProps["onClick"];
    ttl?: number;
    style?: Partial<CSSStyleDeclaration>;
}
