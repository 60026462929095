import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const DomainNames: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_36896)">
      <path d="M13 24.6333C19.4246 24.6333 24.6328 19.4251 24.6328 13.0005C24.6328 6.57586 19.4246 1.36768 13 1.36768C6.57538 1.36768 1.36719 6.57586 1.36719 13.0005C1.36719 19.4251 6.57538 24.6333 13 24.6333Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M13.0859 24.6875C16.2573 24.6875 18.8281 19.4548 18.8281 13C18.8281 6.54517 16.2573 1.3125 13.0859 1.3125C9.91462 1.3125 7.34375 6.54517 7.34375 13C7.34375 19.4548 9.91462 24.6875 13.0859 24.6875Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M3.91406 5.73438C3.91406 5.73438 12.8516 11.125 22.1719 5.73438" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M3.41406 19.5864C6.37946 18.0256 9.68018 17.21 13.0313 17.21C16.3823 17.21 19.683 18.0256 22.6484 19.5864" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M1.36719 13H24.6328" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M19.7031 9.88281H6.30469C5.76535 9.88281 5.32812 10.32 5.32812 10.8594V14.7656C5.32812 15.305 5.76535 15.7422 6.30469 15.7422H19.7031C20.2425 15.7422 20.6797 15.305 20.6797 14.7656V10.8594C20.6797 10.32 20.2425 9.88281 19.7031 9.88281Z" fill="none" stroke={color ?? theme.colors.gradIconColor2}/>
      <path d="M9.35938 13.6797L8.86719 11.9531H9.28906L9.58594 13.1641L9.89844 11.9531H10.3125L10.6562 13.1563L10.9609 11.9531H11.375L10.8828 13.6797H10.4609L10.1406 12.5156L9.8125 13.6797H9.35938Z" fill="none" stroke={color ?? theme.colors.gradIconColor2}/>
      <path d="M12.2187 13.6797L11.7344 11.9531H12.1484L12.4531 13.1641L12.7656 11.9531H13.1719L13.4844 13.1563L13.7812 11.9531H14.1953L13.7812 13.6797H13.3594L13 12.5156L12.6797 13.6797H12.2187Z" fill="none" stroke={color ?? theme.colors.gradIconColor2}/>
      <path d="M15.1328 13.6797L14.6484 11.9531H15.0625L15.3437 13.1641L15.6562 11.9531H16.125L16.4297 13.1563L16.7266 11.9531H17.1406L16.6484 13.6797H16.2266L15.9062 12.5156L15.5781 13.6797H15.1328Z" fill="none" stroke={color ?? theme.colors.gradIconColor2}/>
      </g>
      <defs>
      <clipPath id="clip0_1678_36896">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
      </defs>
    </Svg>
  )
}

export default DomainNames
