import { useEffect } from 'react'
import { useMoralis } from "react-moralis"

const useEnableWeb3 = () => {
    const { enableWeb3, isWeb3Enabled, user } = useMoralis()

    useEffect(() => {
        if (user && !isWeb3Enabled) enableWeb3()
    }, [enableWeb3, user, isWeb3Enabled])
}

export default useEnableWeb3