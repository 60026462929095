import React from 'react'
import styled from 'styled-components'
import { Text } from 'designStandard/components/atoms'
import { useMatchBreakpoints } from 'designStandard/hooks'
import Row from './Row'

const Container = styled.div`
  filter: ${({ theme }) => theme.shadows.popMedium};
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 16px;
  min-width: 280px;
`

const TableBody = styled.tbody`
  width: 100%;

  // & tr {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;

  //   td {
  //     font-size: 16px;
  //     vertical-align: left;
  //   }
  //   th {
  //     vertical-align: left;
  //   }
  // }
`

const TableHeaderContainer = styled.thead<{ isMobile?: boolean}>`
  color: ${({ theme }) => theme.colors.priceCardSecondary};
  display: grid;
  padding: ${({ isMobile }) => isMobile ? '0px 16px' : '0 34px'};
  margin: 34px;
`

const TableHeader = styled.tr<{ length?: number; size?: string }>`
  // color: ${({ theme }) => theme.colors.priceCardSecondary};
  display: grid;
  // padding: 0 34px;
  grid-template-columns: ${({ length, size }) => size || `repeat(${length}, 1fr)`};
  // margin: 34px;
`

const TableContainer = styled.table`
  width: 100%;
  height: 100%;
`

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.flatGlobal};
`

interface TableProps {
  headers: string[]
  rows: { [key: string]: any }[]
  emptyStatement?: string
  size?: string
}

/** @params:
 * @headers - Array of string values of the header tabs. EX: ['Id', 'Name']
 * @rows - Array of Objects of each data entry. EX: [{ id: 1, name: 'test' }] 
 * @emptyStatement <OPTIONAL> - String value of the empty state message to be displayed if no data. EX: 'No data found'
 * @size <OPTIONAL> - String value of column size for the table in grid format. EX: '1fr 2fr'
 * */

const Table: React.FC<TableProps> = ({ headers, rows, emptyStatement = 'No Data', size }) => {
  const {isXs, isSm} = useMatchBreakpoints()
  const isMobile = isXs || isSm

  return (
    <>
      <Container>
        <TableContainer>
          <TableBody>
            <TableHeaderContainer  isMobile={isMobile}>
              <TableHeader length={headers.length} size={size}>
                {headers.map((header) => {
                  return (
                    <th key={header}>
                      <Text font="h6BoldSmall">{header}</Text>
                    </th>
                  )
                })}
              </TableHeader>
            </TableHeaderContainer>
            <Divider />
            {rows.length > 0 ? (
              rows.map((row) => {
                return <Row key={Math.floor(Math.random() * 1000000)} data={row} size={size} />
              })
            ) : (
              <Text font="h6BoldSmall" textAlign="center" style={{ padding: '34px' }}>
                {emptyStatement}
              </Text>
            )}
          </TableBody>
        </TableContainer>
      </Container>
    </>
  )
}

export default Table
