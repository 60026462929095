// import { Toast } from 'toolkitUI'
import BigNumber from 'bignumber.js'
import { CampaignType, FarmConfig, NFT, Nft, PoolConfig, Team, Token } from 'config/constants/types'
import { Toast } from 'designStandard/components/molecules/Toast'

export type TranslatableText =
  | string
  | {
      id: number
      fallback: string
      data?: {
        [key: string]: string | number
      }
    }

export enum NFTCategory {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  TEXT = 'text',
  GIF = 'gif',
  OTHER = 'other'
}
    
export enum NFTAction {
  BID = 'bid',
  ASK = 'ask',
  SHARE = 'share',
  TRANSFER = 'transfer',
  BURN = 'burn',
  OFFER = 'offer',
  LIKE = 'like',
  ACCEPTOFFER = 'acceptOffer',
  ACCEPTBID = 'acceptBid',
  CANCELBID = 'cancelBid',
  CANCELOFFER = 'cancelOffer',
  BUY = 'buy',
  COPYURL = 'copy',
  CANCELAUCTION= 'cancelAuction',
  REPORT = 'report',
}

export enum MarketType {
  Auction = "auction",
  Sale = "sale"
}

export const marketType = {
  auction: MarketType.Auction,
  sale: MarketType.Sale,
  initial: undefined
}

export interface NFTAttributes {
  traitType?: string
  traitValue?: string
}

export interface ZapNFT extends NFT {
  price?: number
  title?: string
  isNewToken?: boolean
  timestamp?: string
  isOwned?: boolean
  category?: string
  nftCategory?: string[]
  artist?: string
  owner?: string
  url?: string
  tags?: Array<string>
  thumbnailUrl?: string
  description?: string
  contractAddress?: string
  ownerAddress?: string
  creatorAddress?: string
  id: number
  blockchain?: string
  type?: string
  content?: string
  mimeType?: string
  name?: string
  creatorName?: string
  share?: number
  currentAsk?: Ask
  latestBid?: string // Latest Bid
  tokenId?:string
  views?: string[]
  likes?: string[]
  listType?: string
  listed?:boolean
  reservePrice?:string
  endAuction?:number
  auctionCurrency?:string,
  auctionId?:string,
  curator?:string
  isListed?: boolean
  isAuction?: boolean
  currentBids?: LatestBid[]
  currentOffers?: LatestBid[]
  auctionTokenOwner?: string
  askingCurrency? : string
  chainId? : number
  isFeatured?: boolean
  attributes?: {[key: string]: any }[]
  reported?: string[]
  blockNumber?: number
  created?: string
}

export interface NftTransaction {
  id?: string
  tokenId?: string
  event?: NFTAction
  gasFee?: number
  actionPrice?: number
  currency?: string
  timestamp?: string
  isCompleted?: boolean
}

export interface RaiseToken extends Token {
  price: number
  value: number
  isNewToken: boolean
  bondedSupply: number
  marketCap: number
  providerTitle: string
  providerAddress: string
  brokerAddress: string
  name: string
  curve: Array<number>
  projectLink?: string
  totalBound: string
  dotLimit: string
  spotPrice: string
  markdown: string
  json: string
  userBound: string
  zapBound: string
  isToken: boolean
  symbol: string
  tokenAddress: string
  isTDFOwner: boolean
}

export interface Ask {
  id?: number
  amount?: number
  currency?: string
  symbol?: string
  timestamp?: string
  blockNumber?: number
}

export interface AuctionItem {
  id?: number
  auctionId?: string
  currency?: string
  tokenId?: string
  amount?: number
}
export interface UserType {
  id: string
  profileImageRef?: string
  name?: string
  bio?: string
  website?: string
}

export type ZapObject = {
  contentOb: "contentOb",
  userOb: "userOb"
}

export interface ZapUser {
  id?: string
  username?: string
  address?: string
  email?: string
  phone?: string
  inSession?: boolean
  joinedOn?: string
  profile?: ZapProfile
  rank?: number
}

export interface ZapProfile {
  id?: string
  userId?: string
  profileUrl?: string
  coverPhotoUrl?: string
  bio?: string
  followers?: Array<string>
  collections?: Array<any>
  social?: {[key:string]: SocialProps}
}

export interface SocialProps {
  name?: string
  url?: string
  toggle?:boolean
}

export interface Currency {
  id?: number
  symbol?: string
}

export interface LatestBid {
  id?: number
  amount?: BigNumber
  currency?: string
  time?: Date
  from: string
  isWinner?: boolean
  to?: string
  blockNumber?: string
  expiration?: string
}

export interface NftTag {
  id?: number
  label?: string
  description?: string
}

export interface Farm extends FarmConfig {
  tokenAmount?: BigNumber
  quoteTokenAmount?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  userData?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}
export interface Transaction {
  txHash: string
  block: number
  timestamp: string
  status: number
  from: string
  to: string
  transactionFee: string
  gasInfo: string
  action: string
  txAction: string
  tokenTransfer: string
  tokenAmount?: BigNumber
  quoteTokenAmount?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  actionPanelToggled?: boolean
  userData?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}

export interface Pool extends PoolConfig {
  totalStaked?: BigNumber
  startBlock?: number
  endBlock?: number
  userData?: {
    allowance: BigNumber
    stakingTokenBalance: BigNumber
    stakedBalance: BigNumber
    pendingReward: BigNumber
  }
}

export interface Profile {
  userId: number
  points: number
  teamId: number
  nftAddress: string
  tokenId: number
  isActive: boolean
  username: string
  nft?: Nft
  team: Team
  hasRegistered: boolean
}

// Slices states

export interface ToastsState {
  data: Toast[]
}

export interface FarmsState {
  data: Farm[]
}

export interface PoolsState {
  data: Pool[]
}

export interface ProfileState {
  isInitialized: boolean
  isLoading: boolean
  hasRegistered: boolean
  data: Profile
}

export interface TransactionState {
  data: Transaction[]
}

export type TeamResponse = {
  0: string
  1: string
  2: string
  3: string
  4: boolean
}

export type TeamsById = {
  [key: string]: Team
}

export interface TeamsState {
  isInitialized: boolean
  isLoading: boolean
  data: TeamsById
}

export interface Achievement {
  id: string
  type: CampaignType
  address: string
  title: TranslatableText
  description?: TranslatableText
  badge: string
  points: number
}

export interface AchievementState {
  data: Achievement[]
}

// API Price State
export interface PriceApiList {
  /* eslint-disable camelcase */
  [key: string]: {
    name: string
    symbol: string
    price: string
    price_BNB: string
  }
}

export interface PriceApiListThunk {
  /* eslint-disable camelcase */
  [key: string]: number
}

export interface PriceApiResponse {
  /* eslint-disable camelcase */
  updated_at: string
  data: PriceApiList
}

export interface PriceApiThunk {
  /* eslint-disable camelcase */
  updated_at: string
  data: PriceApiListThunk
}

export interface PriceState {
  isLoading: boolean
  lastUpdated: string
  data: PriceApiListThunk
}

// Block

export interface BlockState {
  currentBlock: number
  initialBlock: number
}

// Global state

export interface State {
  farms: FarmsState
  // toasts: ToastsState
  toasts: ToastsState
  prices: PriceState
  pools: PoolsState
  profile: ProfileState
  teams: TeamsState
  achievements: AchievementState
  block: BlockState
  transactions: TransactionState
}