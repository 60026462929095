class ZapPriceDetail {
    id?: string

    blockNumber?: any

    name?: string

    timestamp?: string

    tip?: string

    value?: string

    minerSubmissions: any

    getObject = (): { [key: string]: any } => {
        return {
            id: this.id,
            blockNumber: this.blockNumber,
            name: this.name,
            timestamp: this.timestamp,
            tip: this.tip,
            value: this.value,
            minerSubmissions: this.minerSubmissions
        }
    }
  }
  
  export default ZapPriceDetail
