class ZapPriceFeed {
    id?: string

    block?: any

    rank?: string
  
    requestId?: string
      
    symbol?: string
    
    timestamp?: string
  
    tip?: number

    value?: string

    gdp? : string
  
    getObject = (): { [key: string]: any } => {
        return {
            id: this.id,
            block: this.block,
            rank: this.rank,
            requestId: this.requestId,
            symbol: this.symbol,
            timestamp: this.timestamp,
            tip: this.tip,
            value: this.value,
            gdp: this.gdp
        }
    }
  }
  
  export default ZapPriceFeed