/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from "styled-components"
import { useMoralis, useMoralisQuery } from 'react-moralis'
import { AppState } from 'state/index'
import useWeb3 from 'hooks/useWeb3'
import { useMatchBreakpoints } from 'designStandard/hooks'

import { Text } from '../../atoms/Text'

const NFTCardWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.flatGlobal};
  box-shadow: ${({ theme }) => theme.shadows.popMedium};
  border-radius: 16px;
  cursor: pointer;
  z-index: 69;
`

const NFT = styled.div<{ width: number, height: number }>`
	display: grid;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin-top: -1px;
  margin-left: -1px;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  position: relative;
	background: ${({ theme }) => theme.colors.flatCard};
	border: 1px dashed #7e7e7e;
	.media-content {
    width: 30%;
    height: 30%;
		margin: auto auto 10px auto;
  }
	span::before {
		content: "Processing";
		animation: animate infinite 1s;
	}

	@keyframes animate {
		25% {
				content: "Processing.";
		}

		50% {
				content: "Processing..";
		}

		75% {
				content: "Processing...";
		}
	}
`

const NFTTitle = styled(Text)`
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover span{
    visibility: visible;
  }
  span::before {
    content: "";
    position: absolute;
    top: -20%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`

const Tooltip = styled.span`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: 150%;
  left: 50%;
  margin-left: -60px;
`

const NFTInfoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 44px;
  padding: 8px;
  cursor: pointer;
  z-index: 70;
`

const UserWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`

const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`

const UserInfo = styled.div`
  margin: auto 0 auto 8px;
  width: auto;
  position: relative;
  cursor: pointer;
  max-width: 105px;
`

const FooterWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 0px 0px 16px 16px;
  box-shadow: inherit;
`

const FooterLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`

const ProcessingText = styled.span`
	width: 100px;
	height: auto;
	margin: auto;
	color: #138AF2;
	font-size: 20px;
`

const NFTCardProcessing: React.FC<any> = ({ noNft, size }) => {
  const { isXs, isSm } = useMatchBreakpoints()
  const history = useHistory()
  const isMobile = isXs || isSm
  const { web3 } = useWeb3()
  const { isAuthenticated, user, Moralis } = useMoralis()
  
  const account = user?.get('ethAddress')

	const { fetch, data, isLoading } = useMoralisQuery('NftUser', (query) => query.equalTo('User', user).limit(2), [user])

	const c = data[0]?.attributes

	const zapNFTs = useSelector<AppState, AppState['zapNfts']>((state) => state.zapNfts)
  const { processingNFT } = zapNFTs

  const handleProfile = (param: string) => () => {
    history.push(`/nft/profile/${param}`)
  }

  return (
		processingNFT.title === undefined ? noNft ? <>{noNft}</> : <></> :
    <NFTCardWrapper size={size}>
      <NFT width={size} height={size} >
				<ProcessingText color="primaryDark" />
			</NFT>
      <NFTInfoWrapper>
        <UserWrapper>
          <Avatar src={data[0]?.get('profileImageRef') ?? '/zappy_profile.png'} alt="Avatar" onClick={handleProfile(data[0]?.get('name'))} />
          <UserInfo>
            <Text font="cardSecondaryMedium" color="textSecondary" opacity="0.8">
              {data[0]?.get('name')}
            </Text>
            <NFTTitle font="nftCardTitle" >
              { processingNFT?.title }
              <Tooltip>{ processingNFT?.title }</Tooltip>
            </NFTTitle>
          </UserInfo>
        </UserWrapper>
      </NFTInfoWrapper>
      <FooterWrapper>
        <FooterLeft>
          <Text font="cardSecondarySmall" color="textSecondary" mr="8px" opacity="0.8">
            Reserve
          </Text>
          <Text font="cardSecondaryBold" color="textPrimaryCard" ml="4px">
            -
          </Text>
        </FooterLeft>
      </FooterWrapper>
    </NFTCardWrapper>
  )
}

export default NFTCardProcessing