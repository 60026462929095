import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredArt: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()
  // const lightFill = color ?? theme.colors.gradIconColor2

  return (
    <Svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M25.0251 23.506C25.5794 23.0021 28.855 19.9382 29.3186 16.8542C29.3807 16.3573 29.3354 15.853 29.1857 15.3751C29.0361 14.8973 28.7856 14.4571 28.4511 14.0845C28.1167 13.7118 27.7061 13.4153 27.2472 13.215C26.7883 13.0147 26.2917 12.9153 25.7911 12.9235C24.2591 12.9235 22.2434 12.6313 22.0519 10.9582C22.0519 10.9582 21.5379 9.08361 24.9646 7.60206C25.5917 7.32875 26.1269 6.88095 26.5066 6.312C27.5649 4.72967 28.7441 1.69602 23.5335 0.26486C23.3451 0.213387 23.153 0.17634 22.959 0.15401C18.954 -0.293916 14.9002 0.238732 11.1469 1.70609C5.09976 4.17534 0.695423 10.928 0.0604734 17.3279C-0.453534 22.7098 2.32815 27.3561 7.67987 28.7066C10.7269 29.4422 13.9137 29.3639 16.9209 28.4795C19.9281 27.595 22.6499 25.9355 24.8134 23.6673L25.0251 23.506Z" fill="#252727"/>
      <path d="M31.5864 29.22L22.939 20.0787C22.7994 19.9282 22.6302 19.8081 22.442 19.726C22.2539 19.6439 22.0508 19.6016 21.8455 19.6016C21.6402 19.6016 21.4371 19.6439 21.2489 19.726C21.0608 19.8081 20.8916 19.9282 20.7519 20.0787V20.0787C20.615 20.2236 20.5086 20.3946 20.4389 20.5813C20.3692 20.7681 20.3376 20.967 20.3461 21.1662C20.3545 21.3654 20.4028 21.5608 20.4881 21.741C20.5734 21.9212 20.694 22.0825 20.8426 22.2154L30.508 30.9233C30.508 30.9233 31.7678 32.435 31.9895 30.4092C32.0157 30.1935 31.993 29.9746 31.9233 29.7687C31.8535 29.5629 31.7384 29.3754 31.5864 29.22V29.22Z" fill="#3480F6" stroke="#1E1E1E" strokeMiterlimit="10"/>
      <path d="M17.1439 12.1172C17.1439 12.1172 15.0778 19.918 19.7945 20.8654C19.7945 20.8654 18.9681 18.0635 21.5482 19.0412C21.5482 19.0412 21.3467 15.433 19.1193 14.405C19.1193 14.405 16.6904 14.1027 17.1439 12.1172Z" fill="#79CEFF"/>
      <path d="M11.9534 25.6225C13.2948 25.6225 14.3823 24.5351 14.3823 23.1936C14.3823 21.8521 13.2948 20.7646 11.9534 20.7646C10.6119 20.7646 9.52441 21.8521 9.52441 23.1936C9.52441 24.5351 10.6119 25.6225 11.9534 25.6225Z" fill="#3480F6"/>
      <path d="M5.34202 21.7827C6.68349 21.7827 7.77096 20.6952 7.77096 19.3537C7.77096 18.0123 6.68349 16.9248 5.34202 16.9248C4.00056 16.9248 2.91309 18.0123 2.91309 19.3537C2.91309 20.6952 4.00056 21.7827 5.34202 21.7827Z" fill="#4CBDFE"/>
      <path d="M7.08519 13.8716C8.42665 13.8716 9.51412 12.7841 9.51412 11.4426C9.51412 10.1011 8.42665 9.01367 7.08519 9.01367C5.74372 9.01367 4.65625 10.1011 4.65625 11.4426C4.65625 12.7841 5.74372 13.8716 7.08519 13.8716Z" fill="#3480F6"/>
      <path d="M12.6389 9.01314C13.9804 9.01314 15.0678 7.92568 15.0678 6.58421C15.0678 5.24275 13.9804 4.15527 12.6389 4.15527C11.2974 4.15527 10.21 5.24275 10.21 6.58421C10.21 7.92568 11.2974 9.01314 12.6389 9.01314Z" fill="#4CBDFE"/>
      <path d="M20.0867 6.57368C21.4281 6.57368 22.5156 5.48622 22.5156 4.14476C22.5156 2.8033 21.4281 1.71582 20.0867 1.71582C18.7452 1.71582 17.6577 2.8033 17.6577 4.14476C17.6577 5.48622 18.7452 6.57368 20.0867 6.57368Z" fill="#3480F6"/>
    </Svg>
  )
}

export default ColoredArt
