const getCurrencyFromSymbol = (symbol: string) => {
    const symbolLowerCase = symbol.toLowerCase()
    switch (symbolLowerCase) {
      case '0xc778417E063141139Fce010982780140Aa0cD5Ab'.toLowerCase():
        return 'WETH'
      case '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase():
        return 'WETH'
      case '0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea'.toLowerCase():
        return 'DAI'
      case '0x09d8AF358636D9BCC9a3e177B66EB30381a4b1a8'.toLowerCase():
        return 'ZAP'
      case '0xc5326b32e8baef125acd68f8bc646fd646104f1c'.toLowerCase():
        return 'ZAP'
      case '0x6781a0f84c7e9e846dcb84a9a5bd49333067b104'.toLowerCase():
        return 'ZAP'
      case '0x5877451904f0484cc49DAFdfb8f9b33C8C31Ee2F'.toLowerCase():
        return 'ZAP'
      case '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b'.toLowerCase():
        return 'USDC'
      case '0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02'.toLowerCase():
        return 'USDT'
      case '0xbF7A7169562078c96f0eC1A8aFD6aE50f12e5A99'.toLowerCase():
        return 'BAT'
      case '0xae13d989dac2f0debff460ac112a837c89baa7cd'.toLowerCase():
        return 'WBNB'
      case '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase():
        return 'WBNB'
      default:
        return 'ZAP'
    }
}
  
export default getCurrencyFromSymbol