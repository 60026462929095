import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import styled from "styled-components"
import { useMoralis } from 'react-moralis'
import useWeb3 from 'hooks/useWeb3'
import { useMatchBreakpoints } from 'designStandard/hooks'
import {
  EthereumIcon,
  BNBIcon,
  // ZapIcon,
  HeartIcon, 
  HeartFillIcon,
  LogoIcon
} from 'designStandard/components/atoms/Svg'
import { PriceInfo, IconButton } from 'designStandard/components/molecules'
import createTimer from 'utils/createTimer'
import getCurrencyFromSymbol from 'utils/getCurrencyFromSymbol'
import { NFTCardProps } from "./types"
import { Text } from '../../atoms/Text'
import MediaContent from './MediaContent'

const NFTCardWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.flatGlobal};
  box-shadow: ${({ theme }) => theme.shadows.popMedium};
  border-radius: 16px;
  cursor: pointer;
  z-index: 69;
`

const NFT = styled(MediaContent)<{ width: number, height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin-top: -1px;
  margin-left: -1px;
  background: ${({ theme }) => theme.colors.flatCard};
  border-radius: 16px 16px 0 0;
`

const NFTTitle = styled(Text)`
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover span{
    visibility: visible;
  }
  span::before {
    content: "";
    position: absolute;
    top: -20%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`

const Tooltip = styled.span`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: 150%;
  left: 50%;
  margin-left: -60px;
`

const NFTInfoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 8px;
  cursor: pointer;
  z-index: 70;
`
const NFTStats = styled.div`
  display: grid;
  grid-auto-flow: column;
`

const UserWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`

const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`

const UserInfo = styled.div`
  margin: auto 0 auto 8px;
  width: auto;
  position: relative;
  cursor: pointer;
  max-width: 105px;
`

// const NFTPriceWrapper = styled.div`
//   display: grid;
//   grid-auto-flow: column;
//   justify-content: space-between;
//   align-items: center;
//   width: 100px;
//   height: 48px;
//   padding: 8px;
//   background: ${({ theme }) => theme.colors.flatGlobal};
//   box-shadow: ${({ theme }) => theme.shadows.idleMedium};
//   border-radius: 500px;
// `

// const TokenIconWrapper = styled.div`
//   display: grid;
//   justify-content: center;
//   align-items: center;
//   width: 32px;
//   height: 32px;
//   margin-right: 10px;
//   border-radius: 50%;
//   background: ${({ theme }) => theme.colors.flatOverlay};
// `

const FooterWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  height: 30px;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 0px 0px 16px 16px;
  box-shadow: inherit;
`

const FooterLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`
const FooterRight = styled.div`
  display: grid;
  grid-template-columns: 51px 36px;
  align-items: center;
  gap: 2px;
`

const LikeButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 28px;
  height: 28px;
  background: 
    linear-gradient(${({ theme }) => theme.colors.flatGlobal},${({ theme }) => theme.colors.flatGlobal}) padding-box,
    ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  border: 0.4px solid transparent;
  border-radius: 16px;
  align-items: center;
  justify-content: center;

  .like-icon-button {
    background: transparent;
    border: none;
    box-shadow: none;
  }
`

// const PriceText = styled(Text)`
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   font-size: 12px;
//   text-align: end;
// `

const NFTCard: React.FC<NFTCardProps> = ({ onCardSelect, nft, size }) => {
  const { isXs, isSm } = useMatchBreakpoints()
  const history = useHistory()
  const isMobile = isXs || isSm
  // if (isMobile) size -= 16

  const { web3 } = useWeb3()
  const { isAuthenticated, user, Moralis } = useMoralis()
  // const { onPresentConnectModal } = useWalletModal(authenticate, logout)
  const account = user?.get('ethAddress')
  
  const [isLikedEnabled, setEnableIsLiked] = useState(true)
  const [isLiked, setIsLiked] = useState(account ? nft.likes.includes(account.toLowerCase()) : false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPrice, setShowPrice] = useState(false)
  const [price, setPrice] = useState({ currency: "N/A", amount: 0 })
  // const toast = useToast()
  const [avatar, setAvatar] = useState()
  const endDate = nft.endAuction ? +nft.endAuction : 0
  const [timeState, updateTimeState] = useState(createTimer(Math.floor(endDate - new Date().getTime() / 1000)))

  const onNoAccount = () => {
    // onPresentConnectModal()
  }
  
  const getAvatar = async () => {
    const userOb = Moralis.Object.extend("NftUser")
    const cQuery = new Moralis.Query(userOb)
    cQuery.equalTo("name", nft.artist)
    const cRes = await cQuery.find()
    const c = cRes[0]?.attributes

    setAvatar(c?.profileImageRef)
  }

  const handleShowDetails = (id: number) => {
    if (onCardSelect) onCardSelect(id)
  }

  const handleProfile = (param: string) => () => {
    history.push(`/nft/profile/${param}`)
  }

  const getLikes = async (accountT, nftT, liked) => {
    if (isLikedEnabled && nftT.contractAddress !== "") {
      setEnableIsLiked(false)
      const viewsOb = Moralis.Object.extend("ZapNFTs")
      const nftQuery = new Moralis.Query(viewsOb)

      nftQuery.equalTo("contractAddress", nftT.contractAddress)
      nftQuery.equalTo("tokenId", nftT.tokenId)

      const results = await nftQuery.find()
      const foundNft = results[0]
      const likes = foundNft.attributes.Likes

      if (liked) {
        if (!user?.get('username')) {
          // toast.toastWarning("Please complete setting up your profile to save liked items.")
          return
        }
        likes.push(accountT.toLowerCase())
        foundNft.set("Likes", likes)
      }
      else {
        const newLikes = likes.filter((item) => item !== accountT.toLowerCase())
        foundNft.set("Likes", newLikes)
      }

      await foundNft.save()
      setIsLiked(liked)
      setEnableIsLiked(true)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLikeAction = (liked?: boolean) => {
    if (!isAuthenticated) {
      onNoAccount()
      return
    }
    getLikes(account, nft, liked)
  }

  const setPriceState = async (localNFT) => {
    if (localNFT.listType === "sale") {
      const symbol = getCurrencyFromSymbol(nft.currentAsk.currency)
      let newPrice
      try {
        newPrice = nft.price ? parseFloat(web3.utils.fromWei(String(nft.price))) : 0
      } catch (error) {
        let weiBid = nft.price ? `${nft.price}000000000000000000` : "0";
        if (String(nft.price).includes(".")) {
          const bidSplit = String(nft.price).split(".")
          weiBid = bidSplit[0] + bidSplit[1]
          for (let i = 0; i < 18 - bidSplit[1].length; i++) {
            weiBid += "0"
          }
        }
        newPrice = nft.price ? parseFloat(web3.utils.fromWei(String(weiBid))) : 0
      }
      const priceOB = { currency: symbol, amount: newPrice }
      setPrice(priceOB)
      setShowPrice(true)
    }
    else if (localNFT.listType === "auction") {
      const symbol = getCurrencyFromSymbol(nft.auctionCurrency)

      const newPrice = nft.latestBid ?
        parseFloat(web3.utils.fromWei(String(nft.latestBid))) :
        nft.reservePrice ? parseFloat(web3.utils.fromWei(String(nft.reservePrice))) :
          0
      
      const priceOB = { currency: symbol, amount: newPrice }
      setPrice(priceOB)
      setShowPrice(true)
    }
    else {
      setShowPrice(false)
    }
  }

  useEffect(() => {
    const timeHandler = setInterval(() => {
      if (timeState.remainTime <= 0) return
      updateTimeState(createTimer(timeState.remainTime - 1))
    }, 1000);

    return () => {
      clearInterval(timeHandler);
    };
  })

  useEffect(() => {
    if (nft.contractAddress !== "") {
      getAvatar()
      setPriceState(nft)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft])

  useEffect(() => {
    setEnableIsLiked(account ?? false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nft.likes.length])

  const TokenIcon1 = price.currency === 'WETH' ? 
  (
    <EthereumIcon
      width={15}
      height={15}
    />
  ) : 
  price.currency === 'WBNB' ? 
  (
    <BNBIcon
      width={15}
      height={15}
    />
  ) :
  (
    <LogoIcon
      width={15}
      height={15} 
      size={15} 
    />
  )

  const TokenIcon2 = price.currency === 'WETH' ? 
  (
    <EthereumIcon
      width={10}
      height={10}
    />
  ) : 
  price.currency === 'WBNB' ? 
  (
    <BNBIcon
      width={10}
      height={10}
    />
  ) :
  (
    <LogoIcon
      width={10}
      height={10}
      size={10}
    />
  )

  // const likeButton = (
  //   <Flex flexDirection="row" width="100%" alignItems="center" justifyContent="flex-start">
  //     {isLiked ? <LikeIcon fontSize="18px" /> : <StyledLikeOffIcon fontSize="18px" style={{ opacity: "0.5" }} />}
  //     <Text ml="4px" fontSize="14px" color="contrast"> {nft.likes ? nft.likes.length : 0}</Text>
  //   </Flex>
  // )

  // const bidTimer = timeState.hours > 0 ?
  //             +timeState.hours === 1 ?
  //               `an hour left`
  //               :
  //               `${+timeState.hours} hours left`
  //             :
  //             timeState.minutes >= 0 ?
  //               +timeState.minutes === 1 ?
  //                 `a minute left`
  //                 :
  //                 +timeState.minutes !== 0
  //                   ? `${+timeState.minutes} minutes left`
  //                   : ''
  //               :
  //               `--`
  const bidTimer = timeState.remainTime > 0 ? `${+timeState.hours < 10 ? `0${+timeState.hours}` : +timeState.hours}:${+timeState.minutes < 10 ? `0${+timeState.minutes}` : timeState.minutes}:${+timeState.seconds < 10 ? `0${+timeState.seconds}` : +timeState.seconds}` : `Ended`

  return (
    <NFTCardWrapper size={size}>
      <NFT width={size} height={size} nft={nft} onClick={() => handleShowDetails(nft.id)} isMobile={isMobile} />
      {/* <MediaContent nft={nft} onClick={handleShowDetails(nft.id)} isMobile={isMobile}/> */}
      <NFTInfoWrapper>
        <UserWrapper>
          <Avatar src={avatar ?? '/zappy_profile.png'} alt="Avatar" onClick={handleProfile(nft.artist)} />
          <UserInfo>
            <Text font="cardSecondaryNormal" color="textSecondary" >
              {nft.artist}
            </Text>
            <NFTTitle font="nftCardTitle" >
              { nft.title }
              <Tooltip>{ nft.title }</Tooltip>
            </NFTTitle>
          </UserInfo>
        </UserWrapper>
        <NFTStats>
        <PriceInfo icon={TokenIcon1} value={price} size={size}/>
        <LikeButtonWrapper className="like-button-wrapper">
          <IconButton
            className="like-icon-button"
            onClick={() => handleLikeAction(!isLiked)}
            disabled={!account || !isAuthenticated}
            variant="primary"
            scale="sm"
            style={{ padding: 0, width: 28, height: 28 }}
            endIcon={isLiked ? <HeartFillIcon/> : <HeartIcon/>}
          />
        </LikeButtonWrapper>
        </NFTStats>
        {/* <NFTPriceWrapper>
          <TokenIconWrapper>
            {TokenIcon1}
          </TokenIconWrapper>
          <PriceText font="bodyRegularLargeBold">
          { Number(price.amount) === 0 || price.amount === null || price.amount === undefined ? "--" : parseFloat(Number(price.amount).toPrecision(4))}
          </PriceText>
        </NFTPriceWrapper> */}
      </NFTInfoWrapper>
      <FooterWrapper>
        <FooterLeft>
          <Text font="cardSecondarySmall" color="textSecondary" mr="8px" opacity="0.8">
            Reserve
          </Text>
          {TokenIcon2}
          <Text font="cardSecondarySmallBold" color="textPrimary" ml="4px">
            {nft && nft.reservePrice ? web3.utils.fromWei(String(nft?.reservePrice)) : ' - '}
          </Text>
        </FooterLeft>
        {nft.endAuction && nft.endAuction > 0 ?
          <FooterRight>
            {/* {
              (timeState.hours >= 0 && timeState.minutes !== 0) && 
              <Text font="cardSecondarySmall" color="textSecondary" opacity="0.8">
                Auction Time
              </Text>
            } */}
            <Text font="cardSecondarySmall" color="textSecondary" opacity="0.8">
              Auction Time
            </Text>
            <Text font="cardSecondarySmallBold" color="primaryBlue">
              {bidTimer}
            </Text>
          </FooterRight>
          : <></>
        }
      </FooterWrapper>
    </NFTCardWrapper>
  )
}

export default NFTCard