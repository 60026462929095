import styled from 'styled-components'

 export const GridBox = styled.div<{
  align?: "start" | "center" | "end", 
  justify?: "start" | "center" | "end" | "space-between" | "space-around" | "space-evenly" | "normal",
  rows?: string | number, columns?: string | number,
  width?: number | string, height?: number | string,
  maxWidth?: number | string, maxHeight?: number | string,
  colSpan?: string,
  rowSpan?: string,
  gridGap?: string,
  padding?: string,
  margin?: string,
  pr?, pl?, pt?, pb?,
  mr?, ml?, mt?, mb?,
  variant?: 'primary' | 'secondary' | string,
  }>`
  display: grid;
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  width: ${props => props.width ?? ((props.align || props.justify) && '100%')};
  grid-template-rows: ${props => props.rows && 
    typeof props.rows === typeof '' ? props.rows :
    props.rows > 0 ? `repeat(${props.rows}, 1fr)` : 'none'};
  grid-template-columns: ${props => props.columns && 
    typeof props.columns === typeof '' ? props.columns :
    props.columns > 0 ? `repeat(${props.columns}, 1fr)` : 'none'};
  grid-row: ${props => props.rowSpan && props.rowSpan};
  grid-column: ${props => props.colSpan && props.colSpan};
  grid-gap: ${props => props.gridGap ?? '0px'};
  justify-items: ${props => props.justify ?? 'center'};
  justify-content: ${props => props.justify ?? 'flex-start'};
  align-items: ${props => props.align ?? 'center'};
  padding: ${props => props.padding ?? '0px'};
  margin: ${props => props.margin ?? '0px'};
  padding-top: ${props => props.pt ?? '0px'};
  padding-bottom: ${props => props.pb ?? '0px'};
  padding-left: ${props => props.pl ?? '0px'};
  padding-right: ${props => props.pr ?? '0px'};
  margin-top: ${props => props.mt ?? "auto"};
  margin-bottom: ${props => props.mb ?? "auto"};
  margin-left: ${props => props.ml ?? "auto"};
  margin-right: ${props => props.mr ?? "auto"};
  border-radius: 16px;
  background: ${({variant, theme}) => 
    variant === 'primary' ? theme.colors.flatOverlay : 
    variant === 'secondary' ? theme.colors.flatGlobal :
    variant };
`

export default GridBox
