class ZapTransaction {
  txHash?: string

  block?: number

  timestamp?: string

  status?: string
  
  from?: string

  to?: string

  dataEncoded?: any

  value?: number

  transactionFee?: string

  gasInfo?: string

  txAction?: string
  
  tokenTransfer?: string

  zap?: any

  action?: string

  getObject = (): { [key: string]: any } => {
    return {
      value: this.value,
      txHash: this.txHash,
      dataEncoded: this.dataEncoded,
      zap: this.zap,
      block: this.block,
      timestamp: this.timestamp,
      status: this.status,
      from: this.from,
      to: this.to,
      transactionFee: this.transactionFee,
      gasInfo: this.gasInfo,
      txAction: this.txAction,
      tokenTransfer: this.tokenTransfer,
      action: this.action
    }
  }

  // getUID = (): string => {
  //   return this.providerAddress + this.name
  // }
}

export default ZapTransaction
