import { SpaceProps } from "styled-system";

export type RadioTheme = {
  handleBackground: string;
};

export const scales = {
	XS: "xs",
  SM: "sm",
  MD: "md",
	LG: "lg",
} as const;

export type Scales = typeof scales[keyof typeof scales];

export interface RadioProps extends SpaceProps {
  scale?: Scales;
	state?: string;
	checked?: boolean;
}
