import React, { useMemo, useState } from 'react'
import styled from "styled-components"
import useTheme from '../../../../hooks/useTheme'
import { SideMenuProps } from "./types"
import SidebarContainer from './SidebarContainer'
import { Text } from '../../atoms/Text'
import { Link } from '../../atoms/Link'
import Divider from '../../atoms/Divider/Divider'
import Switch from '../../molecules/Switch/Switch'
import { Button } from '../../molecules/Button'
import { menuItems } from "./const";

const MenuWrapper = styled(SidebarContainer)<{size: number}>`
  display: grid;
  grid-template-rows: repeat(6, 50px) 1fr;
  align-items: center;
  padding-top: 20px;
  transform: translateX(-${(props) => props.size}px);
`

const ItemWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  padding: 0px 20px;
`

const ModeWrapper = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

const DividerWrapper = styled.div`
  align-self: end;
`

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-self: end;
  align-items: center;
  justify-items: center;
  padding: 20px;
`

// const CreateButton = styled.div`
//   display: grid;
//   justify-content: center;
//   align-content: center;
//   width: 100px;
//   height: 48px;
//   padding: 8px;
//   background: ${({ theme }) => theme.colors.flatGlobal};
//   border: 1px solid ${({ theme }) => theme.colors.strokeFlatNorm};
//   box-shadow: ${({ theme }) => theme.shadows.popSoft};
//   border-radius: 500px;
//   cursor: pointer;
// `

// const WalletButton = styled.button`
//   width: 177px;
//   height: 48px;
//   display: grid;
//   grid-template-columns: 40px 1fr;
//   align-items: center;
//   justify-items: center;
//   background: ${({ theme }) => theme.colors.gradBtnNorm};
//   border: 1px solid ${({ theme }) => theme.colors.strokeFlatNorm};
//   border-radius: 500px;
//   box-shadow: ${({ theme }) => theme.shadows.popSoft};
//   padding: 6px;
//   cursor: pointer;
// `

// const WalletButtonInvt = styled.div`
//   width: 32px;
//   height: 32px;
//   border-radius: 100px;
//   background: ${({ theme }) => theme.colors.gradBtnInvert};
// `

const SideMenu: React.FC<SideMenuProps> = ({ size }) => {
    const {theme, toggleTheme} = useTheme()
    const items = useMemo(() => menuItems, []);
    const [notChecked, setNotChecked] = useState(false)
    const toggle = () => {
        toggleTheme()
        setNotChecked(notChecked)
    }
    return (
        <MenuWrapper size={size}>
            {
                items.map((item) => (
                    <ItemWrapper key={item.label}>
                        {
                            item.label === 'Mode' ?
                            <ModeWrapper>
                                <Link href={item.path} font="bodySemiBoldLarge" color="textPrimary">
                                    {item.label}
                                </Link>
                                <Switch onChange={toggle} scale="sm" />
                            </ModeWrapper> :
                            <Link href={item.path} font="bodySemiBoldLarge" color="textPrimary">
                                {item.label}
                            </Link>
                        }
                        {
                            item.label === 'Blog' ?
                            null :
                            <Divider type="dotted" width="100%" />
                        }
                    </ItemWrapper>
                ))
            }
            <DividerWrapper>
                <Divider type="morph" width="100%" />
            </DividerWrapper>
            <ButtonWrapper>
                <Button variant="secondary" scale="sm">
                  <Text font="buttonSmall" color={theme.colors.textPrimary}>Create</Text>
                </Button>
                <Button variant="primary" scale="sm">
                  <Text font="buttonSmall" color="#E1E1E1">Connect Wallet</Text>
                </Button>
            </ButtonWrapper>
        </MenuWrapper>
    )
}

export default SideMenu