import React from "react"
import { useTheme } from "styled-components";
import Svg from "../Svg"
import { SvgProps } from "../types"

const ExpandIcon: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
      <path d="M9 2H12.6L8.6 6L10 7.4L14 3.4V7H16V0H9V2ZM6 8.6L2 12.6V9H0V16H7V14H3.4L7.4 10L6 8.6Z" fill={theme.colors.textPrimary}/>
    </Svg>
  )
}

export default ExpandIcon