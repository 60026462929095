export default {
  /**
   * Zap Contracts
   */

  registry: {
    1: '0xC7Ab7FFc4FC2f3C75FfB621f574d4b9c861330f0',
    42: '0x26BC483E8f4E868B031b29973232c188B941a3D8',
    31337: '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853',
    97: '0x7C8785ce85DBf561D47625F4d4F38cE7d4BCdbfF',
  },
  bondage: {
    1: '0x188f79B0a8EdC10aD53285c47c3fEAa0D2716e83',
    42: '0x6164d3A0644324155cd2ad5CDDe5e01c073b79f1',
    31337: '0x8a791620dd6260079bf849dc5567adc3f2fdc318',
    97: '0x3A145A62AB24779E5494CB50fbECdC774Bb7D4cC',
  },
  currentCost: {
    1: '0xdE775430f4e9F0DF7887d6c7C3ce63b257300fCA',
    42: '0x80d8e38D1b496EcB9c7afCBd194d34275736eC72',
    31337: '0xcf7ed3acca5a467e9e704c703e8d87f634fb0fc9',
    97: '0x11934a8728072D3841FF35cCaDbeD1518b6588Ae',
  },
  tokenFactory: {
    1: '0xe13fef4c8e75c12f9706e8bdf28fe847ce99cb42',
    42: '0xeC97E4896cF9f067a9dD428760316024EA0cfc12',
    31337: '0x36c02da8a0983159322a80ffe9f24b1acff8b570',
    97: '0x63f80ECc19902D6016d378B72B46288fE1AE04E1',
  },
  zapMiner: {
    97: '0xEc507495e11b8778b7b23D9fE01A8203cE6e96B6',
    // 97: '0xDe0151BC25867D8Bf163f60cffce7E44eC2da8d6',
    // 97: '0x6b757bd87A06128864B2f912b54f6D513543FB13',
    // 97: '0xbb1ebb02c6fd085b29f51a4817aa82030f17a3d6'
  },
  zapAggregator: {
    97: '0xD2d710c0fA58dc1d1433d0D175FFCFc77eBdC61F',
  },

  /* 
  ZapVault deployed to: 0xb5D7Ad0908a6C124ddB9075cc5D8b3D01C12e35f ZapVault Owner: 0x84438cEc6c5E7d2D80a77d6d144E272D1f18e666 

ZapMarket deployed to: 0x53813994415203448cdF5b2113d82b9D0058A23C ZapMarket Owner: 0x84438cEc6c5E7d2D80a77d6d144E272D1f18e666 

Platform fee set for ZapMarket AuctionHouse deployed to: 0x4efdfA627e71E2891EF05a523EE1e619E55Deea4 

zapMediaImplementation: 0x2477D4C59b87E6b791CC63EaeFBd28276186B1D3 zapMediaImplementation Owner:0x0000000000000000000000000000000000000000

MediaFactory set to ZapMarket MediaFactory deployed to: 0xceED738456A64C55640bf211c659D05B108D7E93 

MediaFactory Owner: 0x84438cEc6c5E7d2D80a77d6d144E272D1f18e666 ZapMedia deployed to: 0x795Ef08D74295f773b6C6D197fC5c6eFD5610B31 
  */
  zapMedia: {
    // 97: '0x8BE3aA056762AE868465fF9BeCe74bE27Aa62099',
    // 97: '0xA7737097C55cfC67Dc6914A5672A2160CCA1e9f1',
    // 97: '0xcA8408E092e27E7CFd4a1D0002a7f242Ed38836C',
    // 97: '0x86132AE2716B7858efc9A78A80E8389142150eF7',
    // 97: '0xEc08ee84c4cE7233320bAF56ac83f397878cD546', // current app.zap
    // 4: '0xfE896Fc2589C894b74Ee9823Fa1Cb2b082311403'
    // 4: '0x83FF7c5B4e548383AD76331E1A7c206357623544' // current app.zap
    // 97: "0x5B5Fe597864f5E6c23cf451a54D662c772B66AaE", 
    // 4: "0xF0D67b54918a5591010bB893cbde2476230d248d"
    97: "0x314D0A56B2bd8229a18A3B9f0875E4fE7A963375", 
    4: "0xa6b70C1Ad5D443d6C53C0A563f56bF0AF7F582D4",
    56: "0x795Ef08D74295f773b6C6D197fC5c6eFD5610B31",
    1: "0xBBd7B3fcA15a10BA76811117Ba83Da259691362d"
  },
  zapMarket: {
    // 97: '0x0eB7542506d12b66D7B9Ece36fc39C6319c7d7bA',
    // 97: '0x92309e3c12f13C306CEF3BDF82d98E5974Bf3Ad0',
    // 97: '0xB3224aF74ae98D8B348cFEa1DD2D6C8e8CDD0543',
    // 97: '0x68971aB68c58705ECe5Bd3A6b156AD60Bd373a4A', // current app.zap
    // 4: '0xd023FD4E84B57aC2bC318A5234214bB5E6f4970D' // current app.zap
    // 97: "0xB5CD67D3Df2D1D9Cc630ed21c0Ba735fb06eCA2B",
    // 4: "0x464a05959b8cc44a1C624D47CaCA7983Ef68F19E"
    97: "0x1630800181A705aeBd645066Ee2c91e5CD37D1cB",
    4: "0x61d099A2799eE012173eceA89ca9FDcA10d02C1c",
    56: "0x53813994415203448cdF5b2113d82b9D0058A23C",
    1: "0x8E85743faA25b305967609E2a5E4FA32Ea092cc4"
  },
  zapAuctionHouse: {
    // 97: '0x209893e1512718776c52536FeB163265523c0385',
    // 97: '0xDB1e7b9Bd423ba240D05063A71e9f2269be4a5Fa',
    // 97: '0xBaa8550Ea92AD77AC88B7d1E7510619Ef92Daa97',
    // 97: '0x22cDd04751ed3700f2d712db17Ae6f76E3E7cDF8', // current app.zap
    // 4: '0xA6192853b42448B332E7157967F7B0aA8abF67EC' // current app.zap
    // 97: "0x55bA55DE8A0ECccE115B5Ade4dD572740B5bdd42",
    // 4: "0xf1a8E3241C0FA9D298AdB9944b4C8Fb4Eca9D326"
    97: "0x798a9CDd42ed45735d0704B350Ee6D86e4B1abdF",
    4: "0x1580946938a30e1F010c64854ee77D345887A535",
    56: "0x4efdfA627e71E2891EF05a523EE1e619E55Deea4",
    1: "0xceED738456A64C55640bf211c659D05B108D7E93"
  },
  zapMediaFactory: {
    // 97: "0x8b57303D32E3Ca3d22481BEE02F109A7B500833d",
    // 4: "0xaF805fD539eae9f2aC2b313F5E908C1a005F1992",
    97: "0x82f8AcCac4Cb415bcA6fD7066fc9063035A904F0",
    4: "0x3a8f450C7844A8e8AbeFc7a0A7F37e8beC28c77C",
    56: "0xceED738456A64C55640bf211c659D05B108D7E93",
    1: "0xeC57c2f02Bc13aB37611e0E4c9B4Bf80f5511E5B"
  },
  zap: {
    97: '0x06c5D9D89d2BB914e1D8E58BB9713c6B3E5B66cF',
  },


  /**
   * Pancakeswap Contracts
   */

  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
}
