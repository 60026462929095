import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const Market: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()
  // const opacity = theme.isDark ? '0.8' : '0.65'
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M3.01 11.22v4.49C3.01 20.2 4.81 22 9.3 22h5.39c4.49 0 6.29-1.8 6.29-6.29v-4.49" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" fill="transparent" strokeLinecap="round" strokeLinejoin="round" {...props}/>
    <path d="M12 12c1.83 0 3.18-1.49 3-3.32L14.34 2H9.67L9 8.68C8.82 10.51 10.17 12 12 12Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.31 12c2.02 0 3.5-1.64 3.3-3.65l-.28-2.75C20.97 3 19.97 2 17.35 2H14.3l.7 7.01c.17 1.65 1.66 2.99 3.31 2.99ZM5.64 12c1.65 0 3.14-1.34 3.3-2.99l.22-2.21.48-4.8H6.59C3.97 2 2.97 3 2.61 5.6l-.27 2.75C2.14 10.36 3.62 12 5.64 12ZM12 17c-1.67 0-2.5.83-2.5 2.5V22h5v-2.5c0-1.67-.83-2.5-2.5-2.5Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Market