import React from 'react'
import {Svg, SvgProps} from 'designStandard/components/atoms/Svg'
import { useTheme } from 'styled-components'

const Icon: React.FC<SvgProps> = (props) => {
  const {color} = props
  const theme = useTheme()

  return (<Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M12 19a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.25 13.75v.93c0 .35-.18.68-.49.86L11 16" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9 22h6c4.02 0 4.74-1.61 4.95-3.57l.75-6C20.97 9.99 20.27 8 16 8H8c-4.27 0-4.97 1.99-4.7 4.43l.75 6C4.26 20.39 4.98 22 9 22ZM7.5 7.67V6.7c0-2.25 1.81-4.46 4.06-4.67a4.5 4.5 0 0 1 4.94 4.48v1.38" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
  )
}

export default Icon