import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps as BaseSvgProps } from "../types"

interface SvgProps extends BaseSvgProps {
  fillColor1?: string
  strokeColor1?: string
  fillColor2?: string
  strokeColor2?: string
}

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  fillColor1="gradIconColor1",
  strokeColor1="gradIconColor2",
  fillColor2="ethereumIconFill2",
  strokeColor2="ethereumIconStroke2",
  ...props
}) => {
  const theme = useTheme()
  return (
    <Svg width={width} height={height} viewBox="0 0 6 8" fill="none" {...props}>
      <path d="M2.47973 5.46225V0.186884L2.51768 0.0606573L4.98497 4.0662L2.51373 5.49545L2.47973 5.46225Z" fill="url(#paint0_linear_1151:542)" stroke="url(#paint1_linear_1151:542)" strokeWidth="0.0493113"/>
      <path d="M2.48472 5.48246L0.034287 4.06523L2.48472 0.0870393V2.9579V5.48246Z" fill="url(#paint2_linear_1151:542)" stroke="url(#paint3_linear_1151:542)" strokeWidth="0.0493113"/>
      <path d="M2.50317 7.90745V6.03643L2.5255 6.00979L4.93224 4.61938L2.51712 7.94732L2.50317 7.90745Z" fill="url(#paint4_linear_1151:542)" stroke="url(#paint5_linear_1151:542)" strokeWidth="0.0493113"/>
      <path d="M0.088501 4.61939L2.48521 6.00484V7.92393L0.088501 4.61939Z" fill="url(#paint6_linear_1151:542)" stroke="url(#paint7_linear_1151:542)" strokeWidth="0.0493113"/>
      <path d="M2.53442 2.99596L4.9649 4.07687L2.53442 5.48253V2.99596Z" fill="url(#paint8_linear_1151:542)" stroke="url(#paint9_linear_1151:542)" strokeWidth="0.0493113"/>
      <path d="M2.48517 2.99596V5.48253L0.054703 4.07687L2.48517 2.99596Z" fill="url(#paint10_linear_1151:542)" stroke="url(#paint11_linear_1151:542)" strokeWidth="0.0493113"/>
      <defs>
        <linearGradient id="paint0_linear_1151:542" x1="5.01926" y1="6.56213" x2="-0.400774" y2="4.6969" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1151:542" x1="4.89105" y1="5.47522" x2="2.19272" y2="4.47138" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1151:542" x1="2.50937" y1="6.56117" x2="-2.81868" y2="4.76678" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1151:542" x1="2.38391" y1="5.47426" x2="-0.270397" y2="4.5079" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1151:542" x1="5.02028" y1="8.64866" x2="0.386472" y2="6.12437" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1151:542" x1="4.89319" y1="7.96804" x2="2.63234" y2="6.63662" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint6_linear_1151:542" x1="2.50986" y1="8.64864" x2="-2.09155" y2="6.17392" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint7_linear_1151:542" x1="2.38439" y1="7.96802" x2="0.137707" y2="6.66179" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint8_linear_1151:542" x1="5.01911" y1="6.00663" x2="1.12995" y2="3.18775" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint9_linear_1151:542" x1="4.89364" y1="5.5016" x2="3.03106" y2="4.04219" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint10_linear_1151:542" x1="2.50983" y1="6.00663" x2="-1.37933" y2="3.18776" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor1]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor1]} stopOpacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint11_linear_1151:542" x1="2.38436" y1="5.5016" x2="0.521777" y2="4.04219" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors[fillColor2]}/>
          <stop offset="1" stopColor={theme.colors[strokeColor2]} stopOpacity="0.8"/>
        </linearGradient>
      </defs>
      </Svg>
  )
}

export default Icon