import React, { ReactNode, useCallback } from "react";
// import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DownArrow, UpArrow } from "designStandard/components/atoms/Svg";
import Text from "../../../atoms/Text/Text"

const MENU_ENTRY_HEIGHT = 64;

interface Props {
  label: string;
  icon: React.ReactElement;
  className?: string;
  children: ReactNode;
  isActive?: boolean;
  onOpen: (title: string) => void;
  isOpened: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // Safari fix
  flex-shrink: 0;
`;

const AccordionContent = styled.div<{ isOpen: boolean; maxHeight: number }>`
  max-height: ${({ isOpen, maxHeight }) => (isOpen ? `${maxHeight}px` : 0)};
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  border-color: ${({ isOpen}) => (isOpen ? "rgba(133, 133, 133, 0.1)" : "transparent")};
  border-style: solid;
  border-width: 1px 0;
`;

const Accordion: React.FC<Props> = ({
  label,
  icon,
  children,
  className,
  isActive,
  onOpen,
  isOpened
}) => {
  // const { t } = useTranslation()

  const changeHandler = useCallback(() => {
    const status = isOpened ? '' : label;
    onOpen(status);
  }, [label, onOpen, isOpened]);

  return (
    <Container>
      <MenuEntry onClick={changeHandler} className={className} isActive={isActive}>
        {icon}
        <LinkLabel /* isPushed={isPushed} */ >
          <Text font="dropdownItemSemiBold">{label}</Text></LinkLabel>
        {isOpened ? <UpArrow /> : <DownArrow />}
      </MenuEntry>
      <AccordionContent
        isOpen={isOpened}
        maxHeight={React.Children.count(children) * MENU_ENTRY_HEIGHT}
      >
        {children}
      </AccordionContent>
    </Container>
  );
};


const LinkLabel = styled.div<{ isPushed?: boolean }>`
  color: ${({ theme }) => /* (isPushed ?  */theme.colors.textPrimary /* : "transparent") */};
  transition: color 0.4s;
  flex-grow: 1;
  margin: 0 -4px;
`;

// const IconContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   align-items: center;
//   width: 16px;
//   margin-right: 8px;
// `

const MenuEntry = styled.div<{ secondary?: boolean, isActive: boolean}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 64px;
  padding: ${({ secondary }) => (secondary ? "0 32px" : "0 16px")};
  // font-size: ${({ secondary }) => (secondary ? "14px" : "16px")};
  background-color: ${({ secondary, theme }) => (secondary ? theme.colors.background : "transparent")};
  color: ${({ theme }) => theme.colors.textPrimary};
  box-shadow: ${({ isActive, theme }) => (isActive ? `inset 4px 0px 0px ${theme.colors.primary}` : "none")};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.flatOverlay};
  }

  // Safari fix
  flex-shrink: 0;

`;

export default Accordion