import React from 'react'
import { useMoralis } from 'react-moralis'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import { connectorLocalStorageKey } from './config'
import { Config } from './types'

interface Props {
  walletConfig: Config
  // login: Login;
  onDismiss: () => void
  mb: string
}

const WalletCard: React.FC<Props> = ({ walletConfig, onDismiss, mb }) => {
  const { title, icon: Icon } = walletConfig
  const { Moralis, user, authenticate } = useMoralis()

  function handleEthereum() {
    const { ethereum } = window
    if (ethereum && ethereum.isMetaMask) {
      console.log('Ethereum successfully detected!')
      authenticate()
      // Access the decentralized web!
    } else {
      console.log('Please install MetaMask!')
      window.location.href = 'https://metamask.app.link/dapp/app.zap.org'
    }
  }

  return (
    <Button
      width="100%"
      variant="tertiary"
      onClick={async () => {
        if (title === 'Metamask') {
          if (window.ethereum) {
            handleEthereum()
          } else {
            window.addEventListener('ethereum#initialized', handleEthereum, {
              once: true,
            })

            // If the event is not dispatched by the end of the timeout,
            // the user probably doesn't have MetaMask installed.
            setTimeout(handleEthereum, 3000) // 3 seconds
          }
        } else {
          authenticate({ provider: 'walletconnect' })
        }
        // if (!isWeb3Enabled) { enableWeb3() }
        const account = user?.get('ethAddress')
        if (account) {
          const newName = `${account.slice(0, 5)}...${account.slice(account.length - 6)}`
          const UserOB = Moralis.Object.extend('NftUser')
          const query = new Moralis.Query(UserOB)
          query.equalTo('address', account)
          const results = await query.find()
          if (results?.length === 0) {
            console.log('new profile created')
            const newUser = new UserOB()
            newUser.set('User', user)
            newUser.set('name', newName)
            newUser.set('address', account)
            newUser.save()
          }
        }
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
        onDismiss()
      }}
      style={{ justifyContent: 'space-between' }}
      mb={mb}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Text bold color="primary" mr="16px">
        {title}
      </Text>
      <Icon width="32px" />
    </Button>
  )
}

export default WalletCard
