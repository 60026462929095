import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const Hardhat: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M30 18C30 12.9844 26.92 8.6925 22.5506 6.89875L20 12V5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H13C12.7348 4 12.4804 4.10536 12.2929 4.29289C12.1054 4.48043 12 4.73478 12 5V12L9.44938 6.89875C5.08 8.6925 2 12.9844 2 18V22H30V18ZM31 24H1C0.734784 24 0.48043 24.1054 0.292893 24.2929C0.105357 24.4804 0 24.7348 0 25V27C0 27.2652 0.105357 27.5196 0.292893 27.7071C0.48043 27.8946 0.734784 28 1 28H31C31.2652 28 31.5196 27.8946 31.7071 27.7071C31.8946 27.5196 32 27.2652 32 27V25C32 24.7348 31.8946 24.4804 31.7071 24.2929C31.5196 24.1054 31.2652 24 31 24Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Hardhat