import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Edit: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
			<path d="M28 29.3335H4C3.45333 29.3335 3 28.8801 3 28.3335C3 27.7868 3.45333 27.3335 4 27.3335H28C28.5467 27.3335 29 27.7868 29 28.3335C29 28.8801 28.5467 29.3335 28 29.3335ZM25.36 4.64281C22.7733 2.05615 20.24 1.98948 17.5867 4.64281L15.9733 6.25615C15.84 6.38948 15.7867 6.60281 15.84 6.78948C16.3499 8.53932 17.2929 10.1323 18.5817 11.4211C19.8705 12.7099 21.4635 13.6529 23.2133 14.1628C23.3078 14.1938 23.4091 14.1977 23.5057 14.1741C23.6023 14.1505 23.6904 14.1005 23.76 14.0295L25.36 12.4161C26.68 11.1095 27.32 9.84281 27.32 8.56281C27.3333 7.24281 26.6933 5.96281 25.36 4.64281V4.64281ZM20.8133 15.3735C20.4267 15.1868 20.0533 15.0001 19.6933 14.7868C19.4009 14.6126 19.1162 14.4258 18.84 14.2268C18.6133 14.0801 18.3467 13.8668 18.0933 13.6535C18.0117 13.594 17.9359 13.527 17.8667 13.4535C17.4267 13.0801 16.9333 12.6001 16.4933 12.0668C16.4533 12.0401 16.3867 11.9468 16.2933 11.8268C16.16 11.6668 15.9333 11.4001 15.7333 11.0935C15.5433 10.8427 15.3696 10.5799 15.2133 10.3068C15 9.94681 14.8133 9.58681 14.6267 9.21348C14.5989 9.15377 14.5718 9.09377 14.5453 9.03348C14.348 8.58948 13.768 8.46015 13.4253 8.80281L5.78667 16.4415C5.61333 16.6148 5.45333 16.9481 5.41333 17.1748L4.69333 22.2815C4.56 23.1881 4.81333 24.0415 5.37333 24.6148C5.85333 25.0815 6.52 25.3348 7.24 25.3348C7.4 25.3348 7.56 25.3215 7.72 25.2948L12.84 24.5748C13.08 24.5348 13.4133 24.3748 13.5733 24.2015L21.2027 16.5735C21.5493 16.2268 21.4173 15.6335 20.968 15.4401C20.9164 15.418 20.8648 15.3958 20.8133 15.3735V15.3735Z" fill="url(#paint0_linear_290_13360)"/>
			<defs>
			<linearGradient id="paint0_linear_290_13360" x1="29" y1="34.3314" x2="-11.3546" y2="5.1433" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1} />
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
			</linearGradient>
			</defs>
    </Svg>
  );
};

export default Edit;