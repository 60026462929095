import React from 'react';
import styled from "styled-components";
import { InfoCardProps } from "./types";

const InfoCardWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: 32px;
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 0px 0px 16px 16px;
`

const InfoCard: React.FC<InfoCardProps> = ({ size }) => (
  <InfoCardWrapper size={size} />
)

export default InfoCard;
