import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredTradingCards: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()
  return (
    <Svg  width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5293 1.41072L3.49442 2.56902C2.11679 2.79585 1.18388 4.09652 1.41071 5.47415L3.26699 16.7481C3.49382 18.1257 4.79449 19.0586 6.17212 18.8318L13.207 17.6735C14.5846 17.4466 15.5175 16.146 15.2907 14.7683L13.4344 3.49442C13.2076 2.1168 11.9069 1.18389 10.5293 1.41072Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M15.2952 6.51831H8.1657C6.76952 6.51831 5.6377 7.65013 5.6377 9.04631V20.472C5.6377 21.8682 6.76952 23.0001 8.1657 23.0001H15.2952C16.6914 23.0001 17.8232 21.8682 17.8232 20.472V9.04631C17.8232 7.65013 16.6914 6.51831 15.2952 6.51831Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
    </Svg>
  )
}

export default ColoredTradingCards
