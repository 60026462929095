// import { request } from 'graphql-request'
// import { getMiningEvents } from 'state/zapMiningEvents/actions'
// import { miningEventsG } from 'views/MinerEvents/constants/constants'
import Web3 from 'web3'
// import Moralis from 'moralis'
import { getZapMiner, getZapAggregator } from 'utils/contractHelpers'
import handleMoralisError from './useMoralisError'
import ZapMiningEvent from '../entities/zapMiningEvent'

export default async function useZapMiningEventsList(web3: Web3, chainId: number, Moralis: any): Promise<ZapMiningEvent[]> {
  // console.log(chainId)
  // const client = chainId === 42 ? kovanMiningClient : mainMiningClient // FIRST CLIENT SHOULD BE THE MAIN BLOCK CLIENT
  // const miningEventsG = await client.request(MININGEVENTS);
  const chainIdSet = chainId === undefined ? '97' : String(chainId)
  const retVal: ZapMiningEvent[] = []

  // Moralis.initialize("RNrYqlQiX38ISiNHty0DAqU9kNbydscmQNzAOACs");
  // Moralis.serverURL = "https://i32izegtzlgg.usemoralis.com:2053/server";

  const NODE_URL = "https://speedy-nodes-nyc.moralis.io/e14d2d027262a7f7a9f4c757/bsc/testnet"
  const provider = new Web3.providers.HttpProvider(NODE_URL)
  const MoralisWeb3 = new Web3(provider)

  const eventOb = Moralis.Object.extend("ZapPythiaEvents")
  const queryCurrent = new Moralis.Query(eventOb)
  queryCurrent.equalTo("BlockNumber", 0)
  try {
    const currentRes = await queryCurrent.find()
    if(currentRes.length > 0){
      const currentEvent = new ZapMiningEvent()
      currentEvent.id = currentRes[0].attributes.Id
      currentEvent.rowtitle = currentRes[0].attributes.rowtitle
      currentEvent.timestamp = currentRes[0].attributes.Timestamp
      currentEvent.requestIds = currentRes[0].attributes.RequestId
      currentEvent.minedValues = currentRes[0].attributes.MinedValue
      currentEvent.totalTips = currentRes[0].attributes.TotalTips
      currentEvent.block = currentRes[0].attributes.BlockNumber
      currentEvent.requestSymbols = currentRes[0].attributes.RequestSymbols
      currentEvent.inDisputeWindow = currentRes[0].attributes.InDisputeWindow
      currentEvent.granPrices = currentRes[0].attributes.GranPrices
      currentEvent.status = currentRes[0].attributes.Status
      currentEvent.minerValues = currentRes[0].attributes.MinerValues
    
      retVal.push(currentEvent)
    }
  } catch (err) {
    handleMoralisError(err)
  }

  const minedQuery = new Moralis.Query(eventOb)
  minedQuery.descending("BlockNumber");
  minedQuery.limit(100)

  try {
    const results = await minedQuery.find()
    for (let i = 0; i < results.length; i++) {
      const minedEvent = new ZapMiningEvent()
      minedEvent.id = results[i].attributes.Id
      minedEvent.rowtitle = results[i].attributes.rowtitle
      minedEvent.timestamp = results[i].attributes.Timestamp
      minedEvent.requestIds = results[i].attributes.RequestId
      minedEvent.minedValues = results[i].attributes.MinedValue
      minedEvent.totalTips = results[i].attributes.TotalTips
      minedEvent.block = results[i].attributes.BlockNumber
      minedEvent.requestSymbols = results[i].attributes.RequestSymbols
      minedEvent.inDisputeWindow = results[i].attributes.InDisputeWindow
      minedEvent.granPrices = results[i].attributes.GranPrices
      minedEvent.status = results[i].attributes.Status
      minedEvent.minerValues = results[i].attributes.MinerValues

      retVal.push(minedEvent)
    }
  } catch (err) {
    handleMoralisError(err)
  }

  if (retVal.length === 0) {
      const all = chainId === undefined ? await getMiningEventsViaLogs(MoralisWeb3, chainIdSet) : await getMiningEventsViaLogs(web3, chainIdSet)
      const events: { [key: string]: any } = Object.values(all)
      events.sort((a, b) => {
        return b.timestamp - a.timestamp
    })
    events.forEach((e) => {
      const miningEvent = new ZapMiningEvent()
      miningEvent.id = e.id
      miningEvent.rowtitle = e.rowtitle
      miningEvent.timestamp = e.timestamp
      miningEvent.requestIds = e.requestIds
      miningEvent.minedValues = e.minedValues
      miningEvent.totalTips = e.totalTips
      miningEvent.block = e.blockNumber
      miningEvent.requestSymbols = e.requestSymbols
      miningEvent.inDisputeWindow = e.inDisputeWindow
      miningEvent.granPrices = e.granPrices
      miningEvent.status = e.status
      miningEvent.minerValues = e.minerValues

      retVal.push(miningEvent)
    })
  }
  return retVal
}

export const getMiningEventsViaLogs = async (web3: Web3, chainIdSet) => {
  
  const retVal = {}
  try {
    // Get symbols matching the request IDs
    const aggregator = getZapAggregator(web3, chainIdSet)
    const zapMaster = getZapMiner(web3, chainIdSet)
    // console.log(web3, zapMaster, aggregator)
    const allIds = await aggregator.methods.dataIDsAll().call()
    // Get values submitted by the miners
    const nonces = await getNonceSubmittedEvents(web3, chainIdSet)

    // Get the values picked as the official value
    const values = await getNewValueEvents(web3, chainIdSet)

    // Create objects based off of the declared official values
    const valuesArr = Object.values(values)
    // console.log(valuesArr)
    for (let i = 0; i < valuesArr.length; i += 1) {
      const curr = valuesArr[i]
      const requestIds = [curr.requestId]
      const requestSymbols = [allIds[curr.requestId-1][1]]
      const minedValues = [curr.minedValue]
      const granPrices = [curr.minedValue / parseFloat(allIds[curr.requestId-1].granularity)]
      const inDisputeWindow = await zapMaster.methods.isInDispute(curr.requestId, curr.timestamp).call()
      retVal[curr.id] = {
        id: curr.id,
        timestamp: curr.timestamp,
        requestIds,
        minedValues,
        totalTips: curr.totalTips,
        blockNumber: curr.blockNumber,
        requestSymbols,
        inDisputeWindow,
        granPrices,
        status: 'mined',
        minerValues: [],
      }
      
    }
    // console.log(retVal)
    // Add submitted values to objects
    const noncesArr = Object.values(nonces)
    for (let i = 0; i < noncesArr.length; i += 1) {
      const curr = noncesArr[i]
      try {
        // retVal[curr.id].minerValues[curr.miner] = curr
        retVal[curr.id].minerValues.push({id: curr.id, miner: curr.miner, values: [curr.value]})
      } catch (e) {
        // No declared official values because not all participating miners submitted
        console.error(e)
        retVal[curr.id] = {
          id: "",
          timestamp: "",
          requestIds: [curr.requestId],
          minedValues: [],
          totalTips: [],
          blockNumber: "",
          requestSymbols: [allIds[curr.requestId-1][1]],
          inDisputeWindow: false,
          granPrices: [],
          minerValues: [{id: curr.id, miner: curr.miner, values: [curr.value]}],
          status: 'mining...',
        }
      }
    }
  } catch (e) {
    console.error('ISSUE PARSING MINING EVENTS VIA LOGS \n', e)
  }
  return retVal
}

export const getNonceSubmittedEvents = async (web3: Web3, chainIdSet) => {
  try {
    const zapMiner = getZapMiner(web3, chainIdSet)
    const hash = web3.eth.abi.encodeEventSignature('NonceSubmitted(address,string,uint256,uint256,bytes32)')
    const events = await zapMiner.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest',
      topics: [hash],
    })
    const parsed = []
    // const types = ['address', 'string', 'uint256', 'uint256', 'bytes32']
    const names = ['miner', 'nonce', 'requestId', 'value', 'id']
    const inputs = [
      {
        type: 'address',
        name: '_miner',
        indexed: true,
      },
      {
        type: 'string',
        name: '_nonce',
        indexed: false,
      },
      {
        type: 'uint256',
        name: '_requestId',
        indexed: true,
      },
      {
        type: 'uint256',
        indexed: false,
        name: '_value',
      },
      {
        type: 'bytes32',
        name: '_currentChallenge',
        indexed: false,
      },
    ]
    for (let i = 0; i < events.length; i += 1) {
      const args = web3.eth.abi.decodeLog(inputs, events[i].raw.data, [
        events[i].raw.topics[1],
        events[i].raw.topics[2],
      ])
      const toAdd = {}
      for (let j = 0; j < parseInt(args.__length__, 10); j += 1) {
        toAdd[names[j]] = args[j]
      }
      //   const block = await web3.eth.getBlock(events[i].blockNumber)
      parsed.push({
        ...toAdd,
        //   timestamp: block.timestamp
      })
    }
    return parsed
  } catch (e) {
    console.error('ISSUE PARSING NONCESUBMITTED EVENTS \n', e)
  }
  return []
}

export const getNewValueEvents = async (web3: Web3, chainIdSet) => {
  try {
    const zapMiner = getZapMiner(web3, chainIdSet)
    const hash = web3.eth.abi.encodeEventSignature('NewValue(uint256,uint256,uint256,uint256,bytes32)')
    const events = await zapMiner.getPastEvents('allEvents', {
      fromBlock: 0,
      toBlock: 'latest',
      topics: [hash],
    })
    const parsed = []
    // const types = ['address', 'string', 'uint256', 'uint256', 'bytes32']
    const names = ['requestId', 'timestamp', 'minedValue', 'totalTips', 'id']
    const inputs = [
      {
        indexed: true,
        internalType: 'uint256',
        name: '_requestId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_time',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_value',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_totalTips',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: '_currentChallenge',
        type: 'bytes32',
      },
    ]
    for (let i = 0; i < events.length; i += 1) {
      const args = web3.eth.abi.decodeLog(inputs, events[i].raw.data, [events[i].raw.topics[1]])
      const toAdd = {}
      for (let j = 0; j < parseInt(args.__length__, 10); j += 1) {
        toAdd[names[j]] = args[j]
      }
      const block = await web3.eth.getBlock(events[i].blockNumber)
      parsed.push({
        ...toAdd,
          blockNumber: block.number
      })
    }
    return parsed
  } catch (e) {
    console.error('ISSUE PARSING NEWVALUE EVENTS \n', e)
  }
  return []
}
