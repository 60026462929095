import React from "react"
// import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M16.3886 2.34326L16.2012 2.96669V21.057L16.3886 21.2401L24.971 16.2764L16.3886 2.34326Z" fill="url(#paint0_linear_4723_23766)"/>
      <path d="M16.3871 2.34143L7.80469 16.2746L16.3871 21.2383V12.4578V2.34143Z" fill="url(#paint1_linear_4723_23766)"/>
      <path d="M16.3888 22.8305L16.2832 22.9566V29.4007L16.3888 29.7026L24.9763 17.8694L16.3888 22.8305Z" fill="url(#paint2_linear_4723_23766)"/>
      <path d="M16.3871 29.7026V22.8305L7.80469 17.8694L16.3871 29.7026Z" fill="url(#paint3_linear_4723_23766)"/>
      <path d="M16.3867 21.2401L24.969 16.2765L16.3867 12.4597V21.2401Z" fill="url(#paint4_linear_4723_23766)"/>
      <path d="M7.80469 16.2763L16.387 21.2398V12.4595L7.80469 16.2763Z" fill="url(#paint5_linear_4723_23766)"/>
      <defs>
      <linearGradient id="paint0_linear_4723_23766" x1="20.5861" y1="2.34326" x2="20.5861" y2="21.2401" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4723_23766" x1="12.0959" y1="2.34143" x2="12.0959" y2="21.2383" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      <linearGradient id="paint2_linear_4723_23766" x1="20.6298" y1="17.8694" x2="20.6298" y2="29.7026" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      <linearGradient id="paint3_linear_4723_23766" x1="12.0959" y1="17.8694" x2="12.0959" y2="29.7026" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      <linearGradient id="paint4_linear_4723_23766" x1="20.6779" y1="12.4597" x2="20.6779" y2="21.2401" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      <linearGradient id="paint5_linear_4723_23766" x1="12.0958" y1="12.4595" x2="12.0958" y2="21.2398" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3480F6"/>
      <stop offset="1" stopColor="#4CBDFE"/>
      </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon