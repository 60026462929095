import React from "react";
import styled from 'styled-components'
import Svg from "../Svg";
import { SvgProps } from "../types";
import getThemeValue from "../../../util/getThemeValue";

const LogoSvg = styled(Svg)<SvgProps>`
  stroke: ${({ theme, color }) => getThemeValue(`colors.${color}`, color)(theme)};
`

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <LogoSvg viewBox="0 0 400 600" {...props}>
        {/* <defs>
              <style>.a0db3501-448b-4a54-8a39-4a18925f7ede{isolation:isolate;}.b9603697-d49a-4e93-9b7e-3582a0eb7464{fill:#f2f2f2;}</style>
            </defs> */}
        <g id="adeb9b68-d761-443e-9633-e48b88c6eeb4" data-name="Layer 2" strokeWidth="10">
          <g id="ed0cbde5-8dd5-4847-a422-1275ad559fb7" data-name="Layer 1-2" sketch-type="MSLayerGroup" transform="translate(1.000000, 1.000000)" opacity="0.9">
            <path id="white-logo1" d="M162 373C162 373 139 558.78 139 583.683C139 608.586 165.833 616.057 180.523 593.644C195.214 571.232 370.7 276.544 370.7 276.544C382.326 255.791 368.208 245 354.09 245H234" stroke="#1187fe"/>
            <path id="white-logo2" d="M234 245C234 245 257 59.22 257 34.3168C257 9.41366 230.167 1.94274 215.477 24.3556C200.786 46.7685 25.3002 341.456 25.3002 341.456C13.6737 362.209 27.7917 373 41.9096 373L162 373" stroke="#1187fe"/>
            <line x1="162" y1="373" x2="234" y2="245" strokeWidth="10"/>
          </g>
        </g>
    </LogoSvg>
  );
};

export default Icon;
