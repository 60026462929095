import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps as BaseSvgProps } from "../types"

interface SvgProps extends BaseSvgProps {
  fillColor1?: string
  strokeColor1?: string
  fillColor2?: string
  strokeColor2?: string
}

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  fillColor1="ethereumIconFill1",
  strokeColor1="ethereumIconStroke1",
  fillColor2="ethereumIconFill2",
  strokeColor2="ethereumIconStroke2",
  ...props
}) => {
  const theme = useTheme()
  return (
    <Svg width={width} height={height} viewBox="0 0 64 64" fill="none" {...props}>
      <g clipPath="url(#clip0_3060_3475)">
        <path d="M19.5707 26.8933L32 14.464L44.4352 26.8987L51.6672 19.6667L32 0L12.3392 19.6608L19.5709 26.8928L19.5707 26.8933ZM0 32L7.23226 24.7667L14.464 31.9985L7.23174 39.2307L0 32ZM19.5707 37.1075L32 49.536L44.4349 37.1016L51.6708 44.3297L51.6672 44.3336L32 64L12.3392 44.3392L12.329 44.329L19.5715 37.1067L19.5707 37.1075ZM49.536 32.0031L56.7683 24.7708L64 32.0026L56.768 39.2348L49.536 32.0031Z" fill="url(#paint0_linear_3060_3475)" stroke="url(#paint1_linear_3060_3475)" strokeWidth="0.75" strokeMiterlimit="10"/>
        <path d="M39.3349 31.9963H39.338L32 24.6577L26.5761 30.0801L25.953 30.7035L24.6679 31.9888L24.6577 31.9988L24.6679 32.0093L32 39.3425L39.3385 32.004L39.3421 31.9999L39.3354 31.9963" fill="url(#paint2_linear_3060_3475)"/>
        <path d="M39.3349 31.9963H39.338L32 24.6577L26.5761 30.0801L25.953 30.7035L24.6679 31.9888L24.6577 31.9988L24.6679 32.0093L32 39.3425L39.3385 32.004L39.3421 31.9999L39.3354 31.9963" stroke="url(#paint3_linear_3060_3475)" strokeWidth="0.75" strokeMiterlimit="10"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_3060_3475" x1="-5.93447e-06" y1="32" x2="64" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor={theme.colors[fillColor1]} />
            <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint1_linear_3060_3475" x1="-1.14305e-06" y1="1.08738" x2="76.533" y2="25.2735" gradientUnits="userSpaceOnUse">
            <stop stopColor={theme.colors[fillColor2]} />
            <stop offset="1" stopColor={theme.colors[strokeColor2]} />
        </linearGradient>
        <linearGradient id="paint2_linear_3060_3475" x1="24.6577" y1="32.0001" x2="39.3421" y2="32.0001" gradientUnits="userSpaceOnUse">
            <stop stopColor={theme.colors[fillColor1]} />
            <stop offset="1" stopColor={theme.colors[strokeColor1]} />
        </linearGradient>
        <linearGradient id="paint3_linear_3060_3475" x1="24.6577" y1="24.9072" x2="42.2178" y2="30.4564" gradientUnits="userSpaceOnUse">
            <stop stopColor={theme.colors[fillColor2]} />
            <stop offset="1" stopColor={theme.colors[strokeColor2]} />
        </linearGradient>
        <clipPath id="clip0_3060_3475">
            <rect width="64.0003" height="64" fill="white"/>
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon