import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

const rotateTime = 8000

const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`

const Container = styled.div<{ size?: number }>`
  display: grid;
  grid-auto-rows: auto;
  width: ${(size) => (size ? '' : '100%')};
  padding: 24px 0;
  overflow-x: hidden;
`

const ImageContainer = styled.div<{ isMobile?: boolean }>`
  position: relative;
  height: ${({ isMobile }) => isMobile ? 'auto' : 'min(36vw, 1450px / 3)'};
  max-width: 100%;
`

const Card = styled.div<{ pos: string }>`
  cursor: pointer;
  position: absolute;
  width: min(36vw, 1450px/3);
  left: 50%;
  height: min(36vw, 1450px/3);
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 6%;
  background: ${({ theme }) => theme.colors.skeletonBg};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 6%;
  transition: 1s ease;
  ${(pos) => posMap(pos)}
`

const posMap = ({ pos }) => {
  if (pos === 'left') {
    return `
    left: calc(50% - min(36vw, 1450px/3) - min(5vw, 150px));
    margin: 32px 0px 32px 0px;
    opacity: 0.8;
    z-index: 1;
    transform: scale(0.65);
    `
  }
  if (pos === 'right') {
    return `
    left: calc(50% + min(5vw, 150px));
    margin: 32px 0px 32px 0px;
    opacity: 0.8;
    z-index: 1;
    transform: scale(0.65);
    // width: 37%;
    
    // max-height: 300px;
    `
  }
  if (pos === 'offLeft') {
    return `
    left: -25%;
    margin: 32px 0px 32px 0px;
    opacity: 0;
    z-index: 0;
    width: 20%;
    transform: scale(0.1);
    `
  }
  if (pos === 'offRight') {
    return `
    left: 125%;
    margin: 32px 0px 32px 0px;
    opacity: 0;
    z-index: 0;
    width: 20%;
    transform: scale(0.1);
    `
  }
  if (pos === 'center') {
    return `
      border: 2px solid transparent;
    `
  }
  return `
    top: -100%;
    opacity: 0;
    }
  `
}

const MobileCard = styled.div <{ height: number }>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.colors.skeletonBg};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 6%;
`

const ArtistCard = styled.div`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  height: 52px;
  padding: 10px 16px 10px 12px;
  width: 142px;
  background: ${({ theme }) => theme.colors.skeletonCard};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 24px;
  margin: 12px auto;
`

const SkeletonAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  border-radius: 50%;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const SkeletonRect = styled.div`
  width: 60px;
  height: 24px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  border-radius: 100px;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const Featured = ({ width, isMobile }) => {
  const [id, setId] = useState(0)
  const scrollNfts = [...Array(5).keys()]
  const size = width - 16

  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      if (id === scrollNfts.length - 1) setId(0)
      else setId(id + 1)
    }, rotateTime)
    return () => {
      clearTimeout(scrollTimer)
    }
  }, [id, scrollNfts.length])

  return (
    <Container>
      <ImageContainer isMobile={isMobile}>
        {!isMobile ? scrollNfts.map((nft, index) => {
          let pos = ''
          if (index === id) {
            pos = 'center'
          } else {
            // right edge case 1
            if (id === scrollNfts.length - 1) {
              if (index === 0) pos = 'right'
              else if (index === 1) pos = 'offRight'
            }
            // right edge case 2
            else if (id === scrollNfts.length - 2) {
              if (index === scrollNfts.length - 1) pos = 'right'
              if (index === 0) pos = 'offRight'
            }
            if (index === id + 1) pos = 'right'
            else if (index === id + 2) pos = 'offRight'
            // left edge case 1
            if (id === 0) {
              if (index === scrollNfts.length - 1) pos = 'left'
              else if (index === scrollNfts.length - 2) pos = 'offLeft'
            }
            // left edge case 2
            else if (id === 1) {
              if (index === 0) pos = 'left'
              else if (index === scrollNfts.length - 1) pos = 'offLeft'
            }
            if (index === id - 1) pos = 'left'
            else if (index === id - 2) pos = 'offLeft'
          }

          return (
            <Card pos={pos} />
          )
        }) : (
          <MobileCard height={size} />
        )}
      </ImageContainer>

      <ArtistCard>
        <SkeletonAvatar />
        <SkeletonRect />
      </ArtistCard>
    </Container>
  )
}

export default Featured
