// import { padStart } from "lodash";

export const truncatedTimer = (remainTime: number) => {
   
    if (remainTime <= 0) {
        return createTimer(remainTime)
    }
    const split = []
        Object.values(createTimer(remainTime)).forEach((v, i) => (i > 0 && split.push(`:${v}`.replace(':0', ''))))
    return {
        remainTime,
        days: split[0].replace('0', ''),
        hours: split[1].replace('0', ''),
        minutes: split[2].replace('0', ''),
        seconds: '',
    }

}

const createTimer = (remainTime: number) => {
    if (remainTime <= 0) {
        return {
            remainTime: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }
    }
    const days = Math.floor(remainTime / (60 * 60 * 24));
    let delta = remainTime - days * 60 * 60 * 24;

    const hours = Math.floor(delta / (60 * 60)) % 24;
    delta -= hours * 60 * 60;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = delta % 60;

    return {
        remainTime,
        days: days < 10 ? `0${days}` : `${days}`,
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`
    }
}

export default createTimer
