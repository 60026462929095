import Cookies from 'js-cookie';
import React, { useState, useCallback } from "react";
import i18next from 'i18next';
import styled from "styled-components";
import { SvgProps } from "../../../components/Svg";
import Text from "../../../components/Text/Text";
import Flex from "../../../components/Box/Flex";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import * as IconModule from "../icons";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };
const { LanguageIcon } = Icons;

interface Props {
  langs: any;
}

const LangBtn = styled(Text)`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

const MenuLang: React.FC<Props> = ({ langs }) => {
  const [currentLang, setCurrentLang] = useState(Cookies.get('i18next') || '')

  const changeLang = useCallback((lang) => {
    setCurrentLang(lang.code)
    i18next.changeLanguage(lang.code)
  }, [])

    const LangIcon = (
        <Flex>
            <LanguageIcon width="24px" /><Text>{currentLang.toUpperCase()}</Text>
        </Flex>
        );

    if (langs.length) {
            return (
              <Dropdown position="top" target={LangIcon}>
                {langs.map((lang) => (
                  <LangBtn key={lang.code} aria-label={lang.code} color="textSubtle" onClick={() => changeLang(lang)}>
                    {lang.language}
                  </LangBtn>
                ))}
              </Dropdown>
            );
    }

    return (
  <Button variant="text">
    {/* alignItems center is a Safari fix */}
    <Flex alignItems="center">
      {LangIcon}
    </Flex>
  </Button>
    );
};

export default React.memo(MenuLang);
