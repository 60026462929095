import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 53 53" {...props}>
      <path d="M47.4534 1.05469H5.27275C2.95282 1.05469 1.05469 2.95282 1.05469 5.27275V47.4534C1.05469 49.7733 2.95282 51.6715 5.27275 51.6715H47.4534C49.7733 51.6715 51.6715 49.7733 51.6715 47.4534V5.27275C51.6715 2.95282 49.7733 1.05469 47.4534 1.05469Z" fill="#0A81FE"/>
      <path d="M22.4609 21.8281H27.9444V24.5699C28.6826 23.0935 30.6862 21.8281 33.4279 21.8281C39.2277 21.8281 39.8605 25.0971 39.8605 30.0534V40.0713H34.377V30.897C34.377 28.7879 34.5879 26.3625 31.6352 26.3625C27.9444 26.3625 27.9444 28.577 27.9444 30.897V40.0713H22.4609V21.8281Z" fill="white"/>
      <path d="M18.8761 21.8281H13.3926V40.0713H18.8761V21.8281Z" fill="white"/>
      <path d="M16.1343 18.9814C17.8814 18.9814 19.2978 17.565 19.2978 15.8178C19.2978 14.0707 17.8814 12.6543 16.1343 12.6543C14.3871 12.6543 12.9707 14.0707 12.9707 15.8178C12.9707 17.565 14.3871 18.9814 16.1343 18.9814Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
