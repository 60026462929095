import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Discord: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();
  
  return (
    <Svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props}>
      <g clipPath="url(#clip0_1678_36723)">
      <path d="M21.1636 1.72413C19.5701 0.993002 17.8614 0.45434 16.0749 0.145825C16.0424 0.139871 16.0099 0.154751 15.9931 0.184512C15.7733 0.575361 15.5299 1.08526 15.3595 1.48603C13.4379 1.19835 11.5262 1.19835 9.64408 1.48603C9.47359 1.07635 9.22134 0.575361 9.0006 0.184512C8.98384 0.155744 8.95134 0.140864 8.9188 0.145825C7.13324 0.453354 5.42454 0.992016 3.83014 1.72413C3.81634 1.73008 3.80451 1.74001 3.79666 1.7529C0.555616 6.59494 -0.33224 11.318 0.103313 15.9824C0.105283 16.0053 0.118094 16.0271 0.135831 16.041C2.27418 17.6113 4.34553 18.5647 6.37842 19.1966C6.41095 19.2065 6.44542 19.1946 6.46613 19.1678C6.94701 18.5111 7.37567 17.8187 7.7432 17.0905C7.76489 17.0479 7.74419 16.9973 7.69986 16.9804C7.01993 16.7225 6.3725 16.408 5.74972 16.0509C5.70046 16.0221 5.69651 15.9517 5.74183 15.9179C5.87289 15.8197 6.00398 15.7175 6.12912 15.6144C6.15176 15.5955 6.18331 15.5916 6.20993 15.6035C10.3013 17.4715 14.7308 17.4715 18.7739 15.6035C18.8005 15.5906 18.8321 15.5946 18.8557 15.6134C18.9809 15.7166 19.1119 15.8197 19.244 15.9179C19.2893 15.9517 19.2863 16.0221 19.2371 16.0509C18.6143 16.4149 17.9669 16.7225 17.2859 16.9794C17.2416 16.9963 17.2219 17.0479 17.2436 17.0905C17.619 17.8177 18.0477 18.5101 18.5197 19.1668C18.5394 19.1946 18.5749 19.2065 18.6074 19.1966C20.6501 18.5647 22.7215 17.6113 24.8598 16.041C24.8786 16.0271 24.8904 16.0062 24.8924 15.9834C25.4136 10.5908 24.0193 5.90649 21.1961 1.75388C21.1892 1.74001 21.1774 1.73008 21.1636 1.72413ZM8.35419 13.1423C7.12239 13.1423 6.10743 12.0114 6.10743 10.6225C6.10743 9.23371 7.10271 8.10283 8.35419 8.10283C9.61549 8.10283 10.6206 9.24364 10.6009 10.6225C10.6009 12.0114 9.60563 13.1423 8.35419 13.1423ZM16.6612 13.1423C15.4294 13.1423 14.4145 12.0114 14.4145 10.6225C14.4145 9.23371 15.4097 8.10283 16.6612 8.10283C17.9225 8.10283 18.9276 9.24364 18.908 10.6225C18.908 12.0114 17.9225 13.1423 16.6612 13.1423Z" fill={color ?? theme.colors.gradIconColor1}/>
      </g>
      <defs>
      <clipPath id="clip0_1678_36723">
      <rect width="25" height="19.3662" fill="white"/>
      </clipPath>
      </defs>
  </Svg>
  );
};

export default Discord;