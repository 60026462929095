import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1700px;
  margin: auto;
`

interface WrapperProps {
  className?: string
}

const ContentWrapper: React.FC<WrapperProps> = ({className, children}) => {
  return <Wrapper className={className}>{children}</Wrapper>;
}

export default ContentWrapper