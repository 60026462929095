import React from 'react'
import { useTheme } from "styled-components"
import {Svg, SvgProps} from 'designStandard/components/atoms/Svg'

const Icon: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme();

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22 13L17.0606 17.9394C16.4773 18.5227 15.5227 18.5227 14.9394 17.9394L10 13" stroke={color ?? theme.colors.gradIconColor1} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill='transparent'/>
</Svg>
  )
}

export default Icon
