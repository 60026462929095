class ZapMiningEvent {
  id?: string

  rowtitle?: string
    
  timestamp?: string
  
  requestIds?: string[]

  minedValues?: string[]

  totalTips?: string

  block?: string

  requestSymbols?: string[]

  inDisputeWindow?: boolean

  granPrices?: number[]

  status?: string

  minerValues?: { __typename: string; id: string; miner: string; values: string[]; }[]

  getObject = (): { [key: string]: any } => {
      return {
          id: this.id,
          rowtitle: this.rowtitle,
          timestamp: this.timestamp,
          requestIds: this.requestIds,
          minedValues: this.minedValues,
          totalTips: this.totalTips,
          block: this.block,
          requestSymbols: this.requestSymbols,
          inDisputeWindow: this.inDisputeWindow,
          granPrices: this.granPrices,
          status: this.status,
          minerValues: this.minerValues,
      }
  }
}


export default ZapMiningEvent