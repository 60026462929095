import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Youtube2: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="16" viewBox="0 0 24 16" fill="none" {...props}>
      <path d="M15 6.10352e-05H5C2 6.10352e-05 0 2.00006 0 5.00006V11.0001C0 14.0001 2 16.0001 5 16.0001H15C18 16.0001 20 14.0001 20 11.0001V5.00006C20 2.00006 18 6.10352e-05 15 6.10352e-05ZM11.89 9.03006L9.42 10.5101C8.42 11.1101 7.6 10.6501 7.6 9.48006V6.51006C7.6 5.34006 8.42 4.88006 9.42 5.48006L11.89 6.96006C12.84 7.54006 12.84 8.46006 11.89 9.03006Z" fill={color ?? theme.colors.gradIconColor1}/>
      <defs>
        <linearGradient id="paint0_linear_2:1573" x1="22.1631" y1="23.3695" x2="-4.14424" y2="-0.62347" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1}/>
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Youtube2;