import React from 'react'
import { useTheme } from "styled-components"
import {Svg, SvgProps} from 'designStandard/components/atoms/Svg'

const Icon: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme();

  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.55133 14.5086L9.54 9.33326L11.8947 13.4113C10.7611 14.2295 9.39804 14.6688 8 14.6666C7.50267 14.6666 7.018 14.6119 6.55133 14.5086ZM5.26 14.0799C4.40347 13.6927 3.63823 13.1291 3.01424 12.4261C2.39024 11.7231 1.92152 10.8964 1.63867 9.99993H7.61533L5.26 14.0793V14.0799ZM1.36667 8.66659C1.27126 7.73143 1.37543 6.78667 1.67227 5.89475C1.96912 5.00283 2.45189 4.18407 3.08867 3.49259L6.07467 8.66659H1.36667ZM4.106 2.58859C5.23941 1.77051 6.60219 1.33119 8 1.33326C8.49733 1.33326 8.982 1.38793 9.44867 1.49126L6.46 6.66659L4.106 2.58859V2.58859ZM10.74 1.91993C11.5965 2.30718 12.3618 2.87071 12.9858 3.57374C13.6098 4.27677 14.0785 5.10348 14.3613 5.99993H8.38467L10.74 1.92059V1.91993ZM14.6333 7.33326C14.7287 8.26842 14.6246 9.21319 14.3277 10.1051C14.0309 10.997 13.5481 11.8158 12.9113 12.5073L9.92533 7.33326H14.6347H14.6333Z" opacity="0.65" fill={color ?? theme.colors.gradIconColor1}/>
      </Svg>
  )
}

export default Icon
