import React from 'react'
import styled from 'styled-components'
import { DividerProps } from './types'


const Container = styled.div<{ type?: string, width?: string }>`
    width: ${({width})=> width};
    border-top: ${({type})=> type==="morph" ? null : type==="underline" ? null : `rgba(0, 0, 0, 0.5) .05px ${type}`};
    background: ${({theme, type}) => type ==="solid" ? null : type==="dashed" ? null : type==="underline" ? theme.colors.gradBtnNorm : null};
    height: ${({type}) => type === "morph" ? '1px' : type==="underline" ? '.2px' :  null};
`

const MorphContainer = styled.div`
    background: ${({theme})=>theme.colors.dividerMorphBody};
    // border-top: 1px solid rgba(0,0,0,0);
    height: 10px;
`

const Underline = styled.div`
    background: ${({theme})=>theme.colors.radialGradient};
    height: 2px;
`
const Divider: React.FC<DividerProps> = ({ type, width }) => {
    return (
            <>
                <Container type={type} width={width}>
                    {type === "morph"? <MorphContainer /> : null}
                    {type === "underline"? <Underline /> : null}
                </Container>
            </>
    )
}

export default Divider

