import React from "react"
import Svg from "../Svg"
import { SvgProps} from "../types"

const Icon: React.FC<SvgProps> = ({
  width,
  height,
  ...props
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
        <path d="M11.5397 14.3974L16.3899 9.54731L21.2427 14.3999L24.0649 11.5777L16.3899 3.90247L8.71755 11.5752L11.5397 14.3974ZM3.90234 16.3898L6.72467 13.5675L9.5468 16.3897L6.7245 19.212L3.90234 16.3898ZM11.5397 18.383L16.3899 23.2332L21.2425 18.3807L24.0663 21.2014L24.0649 21.2029L16.3899 28.878L8.7173 21.2055L8.71331 21.2015L11.5397 18.383ZM23.2335 16.3914L26.0558 13.5691L28.878 16.3912L26.0556 19.2135L23.2335 16.3914Z" fill="url(#paint0_linear_4723_23762)"/>
        <path d="M19.2532 16.3887H19.2544L16.3906 13.5249L14.2742 15.6413H14.274L14.0309 15.8846L13.5294 16.3862L13.5254 16.3901L13.5294 16.3942L16.3906 19.2555L19.2544 16.3917L19.2558 16.3901L19.2532 16.3887" fill="url(#paint1_linear_4723_23762)"/>
        <defs>
        <linearGradient id="paint0_linear_4723_23762" x1="16.3901" y1="3.90247" x2="16.3901" y2="28.878" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3480F6"/>
        <stop offset="1" stopColor="#4CBDFE"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4723_23762" x1="16.3906" y1="13.5249" x2="16.3906" y2="19.2555" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3480F6"/>
        <stop offset="1" stopColor="#4CBDFE"/>
        </linearGradient>
        </defs>
    </Svg>
  )
}

export default Icon