import { gql } from 'graphql-request'

export const PROVIDER = gql`
query {
  providers {
           id
           title
           owner {
             id
           }
           endpoints {
             id
             oracleTitle
             broker
             curve
             dotsIssued
             dotLimit
             spotPrice
             zapBound
             isToken
             symbol
             tokenAdd
           }
         }
  }
  `;

export const TOKENS = gql`
query {
    endpoints(where: {isToken: true}) {
        id
        symbol
        provider {
            id
            title
        }
        endpointStr
        broker
        zapBound
        tokenAdd
        curve
        dotsIssued
        dotLimit
        spotPrice
        timestamp
        isToken
        symbolBytes
        endpointBytes
    }
}
`;

export const TOKEN = () => {
    return gql`
        endpoints(where: {isToken: true}) {
            id
            symbol
            provider {
                id
                title
            }
            endpointStr
            zapBound
            tokenAdd
            curve
            dotsIssued
            dotLimit
            spotPrice
            timestamp
        }
    `
}

export const USERBOUND = (id) => {
    return gql`
        query {
            user (id: "${id.toString()}") {
              id
              tdfsOwned{
                id
                title
              }
              userBound {
                endpoint {
                  id
                  endpointStr
                  isToken
                  tokenAdd
                }
                bounded
              }
            }
        }
    `
} 
  
export const TRANSACTIONS = gql`
query {
    events (first: 1000) {
        id
        txnHash
        block
        to
        from
        transactionFee
        timestamp
        status
        action
        data {
            id
            name
            numDots
            endpoint
            numZap
            holder
        }
        txAction
        tokenTransfer
        gasPrice
        gasLimit
        gasUsed
        value
    }
}
`;
 
export const ZAP_MEDIA_UNBURNED = gql`
    {
        medias (where: {burnedAtTimeStamp: null}) {
            id,
            owner {
              id
            },
            creator {
              id
            },
            contentURI,
            metadataURI,
            createdAtTimestamp,
            contractAddress,
            currentAsk {
              id,
              amount,
              createdAtTimestamp,
              currency {
                id,
                symbol
              }
            }
            currentBids {
              id,
              amount,
              currency {
                id,
                symbol
              }
              
            }
        }
    }
`

export const ZAP_CREATIONS_BY_USER = gql`
  {
    users {
      creations (where: {burnedAtTimeStamp: null}) {
        id
        burnedAtBlockNumber
      }
    }
  }
`;


/**
 * Returns gql query to retrieve specific Zora post
 * @param {Number} id post infromation to retrieve
 * @returns {gql} query with template string embedded
 */
export const ZAP_MEDIA_BY_ID = (id) => {
  return gql`
  {
    media(id:"${id.toString()}") {
      id,
      owner {
        id
      },
      creator {
        id
      },
      contentURI,
      metadataURI,
      createdAtTimestamp,
      contractAddress,
      currentAsk {
        id,
        amount,
        createdAtTimestamp,
        currency {
          id,
          symbol
        }
      }
      currentBids {
        id,
        amount,
        currency {
          id
        }
        
      }
    }
  }
  `;
};

/**
 * Returns gql query to retrieve all Zora posts by owner
 * @param {String} owner address
 * @returns {gql} query with template string embedded
 */
export const ZAP_MEDIA_BY_OWNER = (owner) => {
  return gql`
    {
      medias(where: { owner: "${owner}" }) {
        id
        owner {
          id
        }
        creator {
          id
        }
        contentURI
        metadataURI
        createdAtTimestamp
      }
    }
  `;
};

/**
 * TODO
 * @param {Number} id asset transfer information  to retrieve
 * @returns {gql} query with ...
 */
 export const ZAP_BIDS_BY_ID = (id) => {
  return gql`
    {   
        bids(where: {media: "${id}"}) {
            id
            amount
            currency {
            id
            symbol
            decimals
            }
            amount
            bidder {
            id
            }
            media {
            id
            metadataURI
            }
            createdAtTimestamp
        }
    }`;
};