import React from 'react'
import {Svg, SvgProps} from 'designStandard/components/atoms/Svg'

const Icon: React.FC<SvgProps> = () => {

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9803 3L15.8059 3.59251V20.7842L15.9803 20.9583L23.9604 16.2412L15.9803 3Z" fill="#343434"/>
<path d="M15.9803 3L8 16.2412L15.9803 20.9583V12.6139V3Z" fill="#8C8C8C"/>
<path d="M15.9804 22.4685L15.8821 22.5884V28.7124L15.9804 28.9994L23.9653 17.7539L15.9804 22.4685Z" fill="#3C3C3B"/>
<path d="M15.9803 28.9994V22.4685L8 17.7539L15.9803 28.9994Z" fill="#8C8C8C"/>
<path d="M15.9802 20.9577L23.9603 16.2406L15.9802 12.6133V20.9577Z" fill="#141414"/>
<path d="M8 16.2406L15.9803 20.9577V12.6133L8 16.2406Z" fill="#393939"/>
</Svg>
  )
}

export default Icon
