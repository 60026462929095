import { createAction } from '@reduxjs/toolkit'

export const getNftFilter = createAction<{ nftFilter: {
	owned: boolean;
	created: boolean;
	liked: boolean;
	onsale: boolean;
	mybid: boolean;
	image: boolean;
	gif: boolean;
	video: boolean;
	text: boolean;
	audio: boolean;
} }>('nftfilter/get_nft_filter')
export const clear = createAction('nftfilter/clear')