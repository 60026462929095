import React from 'react'
import useModal from '../../designStandard/components/organisms/GenericModal/useModal'
import ConnectWallet from './ConnectWallet'

interface ReturnType {
  onShowHandler: () => void
  onClose: () => void
}

const useConnectWallet = (): ReturnType => {
  const [ onShowHandler, onClose ] = useModal(<ConnectWallet />)

  return {
    onShowHandler,
    onClose
  }
}

export default useConnectWallet