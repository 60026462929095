import React, { FC, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'designStandard/components/atoms/Link'
import { Text as BaseText } from 'designStandard/components/atoms/Text'
import { Searchbox as BaseSearchbox } from 'designStandard/components/molecules/Searchbox'
import { Button as BaseButton } from 'designStandard/components/molecules/Button'
import { ZapNFT } from 'state/types'
import { MainProps } from './types'

const SearchContainer = styled.div<{ notFound: boolean; isMobile: boolean; isTablet: boolean }>`
  position: absolute;
  top: 75px;
  left: ${({ isMobile }) => (isMobile ? '16px' : '142px')};
  width: ${({ isMobile, isTablet }) => (isMobile ? 'calc(100% - 32px)' : isTablet ? 'calc(100% - 284px)' : '720px')};
  height: ${({ notFound }) => (notFound ? '300px' : 'auto')};
  min-height: auto;
  padding: ${({ isMobile }) => (isMobile ? '10px 0' : '20px 0')};
  background: ${({ theme }) => theme.colors.flatOverlay};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.idleMedium};
  z-index: 100;
  overflow-y: scroll;
  max-height: 530px;
`

const Searchbox = styled(BaseSearchbox)<{ isMobile: boolean }>`
  width: calc(100% - 22px);
  height: 48px;
  margin: 0 11px;
  padding: 12px 16px;

  input {
    // width: ${({ isMobile }) => (isMobile ? 'calc(100% - 71px)' : '301px')};
    width: 100%;
    padding: 0;
    font-size: 13.33px;
    line-height: 24px;
    letter-spacing: 0.03em;
    ::placeholder {
      letter-spacing: ${({ isMobile }) => (isMobile ? '-0.048em' : '0.03em')};
    }
  }
`

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 35px;
`

const Text = styled(BaseText)`
  text-transform: capitalize;
`

const Wrapper = styled.div`
  padding: 0 35px;
`

const Button = styled(BaseButton)`
  width: 100%;
  margin-top: 50px;
  padding: 16px 32px;
  border-radius: 500px;
`

interface FilterByQuery {
  filteredNfts: any[]
  name: string
}

// const highlightSearchResult = (searched: string, query: string) => {
//   const re = new RegExp(query, "g");
//   const newText = searched.replace(re, `<mark>${query}</mark>`);
//   return newText
// }

const SearchResults: FC<MainProps> = ({ nfts, users, query, isMobile }) => {
  const [searchKey, setSearchKey] = useState('')
  const [notFound, setNotFound] = useState(false)
  const [isMore, setIsMore] = useState(false)
  const [isAll, setIsAll] = useState(false)
  const [filteredByTitle, setFilteredByTitle] = useState<FilterByQuery>({
    filteredNfts: [],
    name: 'title',
  })
  const [filteredByArtist, setFilteredByArtist] = useState<FilterByQuery>({
    filteredNfts: [],
    name: 'artist',
  })
  const [filteredByTags, setFilteredByTags] = useState<FilterByQuery>({
    filteredNfts: [],
    name: 'tags',
  })
  const [filteredByAccounts, setFilteredByAccounts] = useState<FilterByQuery>({
    filteredNfts: [],
    name: 'users',
  })

  const filterNfts = useCallback(
    (nftTokens: ZapNFT[], nftUsers: any[]) => {
      const lowercaseQuery = searchKey.toLowerCase()
      const filterByTitle: ZapNFT[] = []
      const filterByArtist: ZapNFT[] = []
      const filterByTags: ZapNFT[] = []
      let filterByAccounts: any[] = []

      if (searchKey) {
        // Search Users by query
        filterByAccounts = nftUsers.filter(
          (user) =>
            user.name?.toLowerCase().includes(lowercaseQuery) || user.address?.toLowerCase().includes(lowercaseQuery),
        )
        // Search Artist, Title, and Tags by query
        nftTokens.forEach((token) => {
          if (token.title?.toLowerCase().includes(lowercaseQuery)) filterByTitle.push(token)
          if (token.artist?.toLowerCase().includes(lowercaseQuery)) filterByArtist.push(token)
          token.tags?.forEach((tag) => {
            if (tag.toLowerCase().includes(lowercaseQuery)) filterByTags.push(token)
          })
        })
      }

      if (
        filterByTitle.length === 0 &&
        filterByArtist.length === 0 &&
        filterByTags.length === 0 &&
        filterByAccounts.length === 0 &&
        searchKey
      ) {
        setNotFound(true)
      } else {
        setNotFound(false)
      }

      // Remove duplicated items
      const rmByArtist = Array.from(new Set(filterByArtist.map((a) => a.artist))).map((artist) => {
        return filterByArtist.find((a) => a.artist === artist)
      })
      setFilteredByTitle({
        filteredNfts: filterByTitle,
        name: 'title',
      })
      setFilteredByArtist({
        filteredNfts: rmByArtist,
        name: 'artist',
      })
      setFilteredByTags({
        filteredNfts: filterByTags,
        name: 'tags',
      })
      setFilteredByAccounts({
        filteredNfts: filterByAccounts,
        name: 'users',
      })
    },
    [searchKey],
  )

  useEffect(() => {
    setSearchKey(query)
  }, [query])

  useEffect(() => {
    filterNfts(nfts, users)
  }, [filterNfts, nfts, users])

  useEffect(() => {
    if (
      filteredByAccounts.filteredNfts.length > 5 ||
      filteredByArtist.filteredNfts.length > 5 ||
      filteredByTitle.filteredNfts.length > 5 ||
      filteredByTags.filteredNfts.length > 5
    )
      setIsMore(true)
    else setIsMore(false)
  }, [filteredByAccounts, filteredByArtist, filteredByTags, filteredByTitle])

  const changeHandler = useCallback((input: string) => {
    setSearchKey(input)
  }, [])

  const allViewHandler = useCallback(() => {
    setIsAll(true)
    setIsMore(false)
  }, [])

  const screenWidth = window.innerWidth
  const isSmallWindow = screenWidth < 1209 && !isMobile

  return (
    <SearchContainer notFound={notFound} isMobile={isMobile} isTablet={isSmallWindow}>
      {(isMobile || isSmallWindow) && (
        <Searchbox
          isMobile={isMobile}
          type="filled"
          state="active"
          placeholder="Search for Nfts, Collections, Users & Artists"
          changeHandler={changeHandler}
        />
      )}
      {notFound ? (
        <NotFoundWrapper>
          <Text font="buttonLarge">No results found</Text>
          <Text font="captionRegularSmall" textAlign="center">
            Don’t be shy. Give it <br />
            another shot.
          </Text>
        </NotFoundWrapper>
      ) : (
        <Wrapper>
          {[filteredByAccounts, filteredByArtist, filteredByTitle, filteredByTags].map(
            (element: FilterByQuery, id) =>
              element.filteredNfts.length > 0 && (
                <div key={element.name}>
                  <Text font="bodySemiBoldLarge" py="13px">
                    {element.name}
                  </Text>
                  {isAll
                    ? element.filteredNfts.map((item) => (
                        <Link
                          key={id === 0 ? item.name : item.id}
                          href={
                            id === 0
                              ? `/nft/profile/${item?.name}`
                              : id === 1
                              ? `/nft/profile/${item[element.name]}`
                              : `/nft/asset/${item.id}`
                          }
                        >
                          <BaseText font="cardRegularNormal" pb="10px">
                            {id === 0 ? item.name : item[element.name]}
                            {/* {highlightSearchResult(item[element.name], searchKey.toLowerCase())} */}
                          </BaseText>
                        </Link>
                      ))
                    : element.filteredNfts.slice(0, 4).map((item) => (
                        <Link
                          key={id === 0 ? item.name : item.id}
                          href={
                            id === 0
                              ? `/nft/profile/${item?.name}`
                              : id === 1
                              ? `/nft/profile/${item[element.name]}`
                              : `/nft/asset/${item.id}`
                          }
                        >
                          <BaseText font="cardRegularNormal" pb="10px">
                            {id === 0 ? item.name : item[element.name]}
                            {/* {highlightSearchResult(item[element.name], searchKey.toLowerCase())} */}
                          </BaseText>
                        </Link>
                      ))}
                </div>
              ),
          )}
          {isMore && <Button onClick={allViewHandler}>View All Results</Button>}
        </Wrapper>
      )}
    </SearchContainer>
  )
}

export default SearchResults
