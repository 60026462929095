import { Ask, LatestBid, NftTag } from "state/types";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ZapNftDetails {
    id?: number;

    title?: string;

    price?: number;

    isNewToken?: boolean;

    timestamp?: string;

    isOwned?: boolean;
    
    content?: string;

    category?: string;

    nftCategory?: string[];

    artist?: string;

    owner?: string;

    url?: string;

    tags?: NftTag[];

    thumbnailUrl?: string;
    
    description?: string;

    contractAddress?: string;

	creatorAddress?: string;

    ownerAddress?: string;

    blockchain?: string;

    type?: string;

    mimeType?: string;

    share?: number;

    currentAsk?: Ask;

    latestBid?: LatestBid;

    contentURI?: string;

    tokenId?: string;

    views?: string[]

    likes?: string[]

    listType?: string

    listed?: boolean

    reservePrice?: string

    endAuction?: number

    auctionCurrency?: string

    auctionId?: string 

    curator?:string

    isAuction?: boolean;

    isListed?: boolean

    currentBids?: LatestBid[];

    currentOffers?: LatestBid[];

    auctionTokenOwner?: string

    askingCurrency?: string

    chainId?: number

    isFeatured?: boolean

    attributes?: {[key: string]: any }[]

    reported?: string[]

    blockNumber?: number

    created?: string


    getObject = (): { [key: string]: any } => {
        return {
            id: this.id.toString(),
            title: this.title,
            price: this.price,
            isNewToken: this.isNewToken,
            timestamp: this.timestamp,
            isOwned: this.isOwned,
            category: this.category,
            nftCategory: this.nftCategory,
            artist: this.artist,
            owner: this.owner,
            url: this.url,
            tags: this.tags,
            thumbnailUrl: this.thumbnailUrl,
            description: this.description,
            contractAddress: this.contractAddress,
            ownerAddress: this.ownerAddress,
			creatorAddress: this.creatorAddress,
            blockchain: this.blockchain,
            type: this.type,
            mimeType: this.mimeType,
            share: this.share,
            currentAsk: this.currentAsk,
            latestBid: this.latestBid,
            contentURI: this.contentURI,
            tokenId: this.tokenId,
            views: this.views,
            likes: this.likes,
            listType: this.listType,
            listed: this.listed,
            reservePrice: this.reservePrice,
            endAuction: this.endAuction,
            auctionCurrency: this.auctionCurrency,
            auctionId: this.auctionId,
            curator: this.curator,
            currentBids: this.currentBids,
            currentOffers: this.currentOffers,
            auctionTokenOwner: this.auctionTokenOwner, 
            askingCurrency: this.askingCurrency,
            chainId: this.chainId,
            isFeatured: this.isFeatured,
            attributes: this.attributes,
            reported: this.reported,
            blockNumber: this.blockNumber,
            created: this.created
        }
    }
}

export default ZapNftDetails