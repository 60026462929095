import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CarouselProps } from './types'
import { Text } from '../../atoms/Text'

import 'swiper/swiper.min.css'

const CarouselContainer = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.carouselFill};
  border-radius: 16px;
`

const SwiperWrapper = styled.div`
  & .swiper-slide {
    display: grid;
    justify-content: center;
  }
`

const NFTCard = styled.img<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 16px;
`

const CarouselFooter = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  margin-right: 16px;
  background: transparent;
`

const ProgressBarWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
`

const ProgressBar = styled.div<{ sliderId: number, currentId: number }>`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background: ${({ theme, sliderId, currentId }) =>
    sliderId === currentId
      ? theme.colors.primaryReverseBlue
      : theme.colors.carouselDeselected
  };
  border-radius: 50%;
`

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
`

const Carousel: React.FC<CarouselProps> = ({ size, nfts, avatar, username }) => {
  const [currentId, setCurrentId] = useState(0)

  return (
    <CarouselContainer size={size}>
      <SwiperWrapper>
        <Swiper
          className="carouselSwiper"
          onSlideChange={(e) => setCurrentId(e.realIndex)}
        >
          {
            nfts.map((nft) => (
              <SwiperSlide key={nft.id} >
                <NFTCard src={nft.img} size={size} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </SwiperWrapper>
      <CarouselFooter>
        <ProgressBarWrapper>
          {nfts.map((nft: any, id: number) => (
            <ProgressBar key={nft.id} sliderId={id} currentId={currentId} />
          ))}
        </ProgressBarWrapper>
        <Avatar src={avatar} />
        <Text font="captionSemiBoldLargeCap" color="textReversePrimary">
          {username}
        </Text>
      </CarouselFooter>
    </CarouselContainer>
  )
}

export default Carousel