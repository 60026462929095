export const filterData = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Images',
    value: 'image',
  },
  {
    label: 'GIFs',
    value: 'gif',
  },
  {
    label: 'Videos',
    value: 'video',
  },
  {
    label: "Text",
    value: 'text',
  },
  {
    label: "Audio",
    value: 'audio',
  }
]

export const sortData = [
  {
    label: 'Recent',
    value: 'timestamp',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Price',
    value: 'price',
  }
]

export const truncateAddr = (addr) => {
  return addr.slice(0, 6).concat('...').concat(addr.slice(addr.length  - 4));
};
