import React from "react"
// import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

// export interface AllProps extends SvgProps {
//   size: number
// }

const ColoredAll: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="11.875" y="3.125" width="5" height="5" rx="2.5" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <rect x="3.125" y="3.125" width="5" height="5" rx="2.5" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <rect x="3.125" y="11.875" width="5" height="5" rx="2.5" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <rect x="11.875" y="11.875" width="5" height="5" rx="2.5" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
    </Svg>
  )
}

export default ColoredAll