import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Wallet: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme();

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.3359 12H9.33594H17.3359ZM29.3359 14.6267V17.3733C29.3359 18.1067 28.7493 18.7067 28.0026 18.7333H25.3893C23.9493 18.7333 22.6293 17.68 22.5093 16.24C22.4293 15.4 22.7493 14.6133 23.3093 14.0667C23.8026 13.56 24.4826 13.2667 25.2293 13.2667H28.0026C28.7493 13.2933 29.3359 13.8933 29.3359 14.6267Z"  fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.3041 14.067C22.7441 14.6137 22.4241 15.4003 22.5041 16.2403C22.6241 17.6803 23.9441 18.7337 25.3841 18.7337H27.9974V20.667C27.9974 24.667 25.3307 27.3337 21.3307 27.3337H9.33073C5.33073 27.3337 2.66406 24.667 2.66406 20.667V11.3337C2.66406 7.70699 4.85073 5.17366 8.25073 4.74699C8.5974 4.69366 8.9574 4.66699 9.33073 4.66699H21.3307C21.6774 4.66699 22.0107 4.68033 22.3307 4.73366C25.7707 5.13366 27.9974 7.68033 27.9974 11.3337V13.267H25.2241C24.4774 13.267 23.7974 13.5603 23.3041 14.067V14.067Z"  fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Wallet;