import React, { useState } from "react";
import StyledSwitch, { Input, Handle } from "./StyledSwitch";
import { SwitchProps, scales } from "./types";

const Switch: React.FC<SwitchProps> = ({ notChecked, scale = scales.MD, ...props }) => {
  const [ checked, setChecked ] = useState(!notChecked)
  const toggle = (e) => {
    props.onChange(e);
    setChecked(!checked)
  }
  return (
    <StyledSwitch notChecked={!checked} scale={scale}>
      <Input scale={scale} {...props} type="checkbox" onChange={toggle}/>
      <Handle scale={scale} notChecked={!checked}/>
    </StyledSwitch>
  );
};

Switch.defaultProps = {
  scale: scales.MD,
};

export default Switch;