import { SpaceProps } from "styled-system";

export const scales = {
  SM: "sm",
  MD: "md",
  LG: "lg",
} as const;

export type Scales = typeof scales[keyof typeof scales];

export interface InputProps extends SpaceProps {
  type?: string;
  name?: string;
	value?: any;
	disabled?: boolean;
  scale?: Scales;
  isFilled?: string;
	icon?: string;
	placeholder?: string;
  onChange?: (value: any) => void;
}
