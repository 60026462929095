import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Utilities: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1678_37000)">
      <path d="M4.71094 2.63281L5.55469 1.95312L7.77344 3.46094L7.16406 3.95313L13.2813 11.5469L13.9453 11.0156L23 20.2891C23.7812 21.2891 22.9375 23.3203 21.9375 24.125C20.9375 24.9297 18.8672 25.2578 18.0312 24.2578L10.9297 13.4375L11.5937 12.9063L5.48438 5.3125L4.875 5.79688L3.86719 3.3125L4.71094 2.63281Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      <path d="M23.0052 9.49214C23.7175 8.7803 24.199 7.87052 24.3872 6.8812C24.5753 5.89189 24.4612 4.86886 24.0598 3.94526L20.3098 7.69526L17.6302 5.02339L21.3645 1.2812C20.4398 0.899922 19.4223 0.802956 18.4422 1.00268C17.462 1.20241 16.5637 1.68977 15.8618 2.40251C15.16 3.11525 14.6866 4.02101 14.502 5.00412C14.3174 5.98722 14.43 7.00304 14.8255 7.92182L2.05984 20.0312C1.79454 20.2343 1.57572 20.4917 1.41805 20.7862C1.26038 21.0808 1.1675 21.4057 1.14565 21.739C1.12379 22.0724 1.17346 22.4066 1.29133 22.7192C1.4092 23.0318 1.59254 23.3156 1.82907 23.5516C2.06559 23.7876 2.34982 23.9702 2.66272 24.0873C2.97561 24.2045 3.30992 24.2534 3.64325 24.2307C3.97658 24.2081 4.30121 24.1144 4.59539 23.956C4.88956 23.7977 5.14647 23.5782 5.34891 23.3124L17.2708 10.4765C18.2111 10.9254 19.2674 11.0718 20.2944 10.8956C21.3213 10.7193 22.2684 10.229 23.0052 9.49214V9.49214Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeMiterlimit="10"/>
      </g>
      <defs>
      <clipPath id="clip0_1678_37000">
      <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
      </defs>
    </Svg>
  )
}

export default Utilities
