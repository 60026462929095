import React from 'react'
import styled from 'styled-components'
import { Text } from '../../atoms/Text'
import { Link } from '../../atoms/Link'
import { Logo } from '../../atoms/Logo'
// import { Input } from '../../atoms/InputField'
// import { Button } from '../../molecules/Button'
import { SocialLinks } from '../../molecules/SocialLinks'
import { Divider } from '../../atoms/Divider'

const Container = styled.div<{ size?: number }>`
  width: 100%;
  background: ${({ theme }) => theme.colors.flatGlobal};
  display: grid;
  grid-template-rows: repeat(4, fit-content(0));
`

// const StyledInput = styled(Input)`
//   border: 1px solid ${({ theme }) => theme.colors.strokeFlatNorm};
//   border-radius: 500px;
//   height: 48px;
//   width: 100%;
// `

// const StyledButton = styled(Button)`
//   border-radius: 500px;
//   color: #e1e1e1;
//   padding: 16px 32px;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 16px;
//   letter-spacing: 0.01em;
//   width: 110px;
//   height: 52px;
// `
// const SubscribeControlWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   position: relative;
//   @media (min-width: 730px) {
//     min-width: 400px;
//   }
//   min-width: 300px;
//   width: 100%;
// `
// const HeadWrapper = styled.div`
//   padding: 30px 64px;
//   display: grid;
//   grid-template-rows: repeat(2, fit-content(0));
//   grid-gap: 20px;
// `

// const SubscribeWrapper = styled.div`
//   display: grid;
//   grid-template-columns: unset;
//   grid-template-rows: repeat(2, fit-content(0));
//   align-items: center;
//   justify-items: center;
//   grid-gap: 20px;
//   @media (min-width: 730px) {
//     grid-template-columns: repeat(2, fit-content(0));
//     grid-template-rows: unset;
//   }
// `

const LinksWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  padding: 30px ${({ isMobile }) => `${isMobile ? '16px': '96px 0'}`} ;
  grid-template-columns: ${({ isMobile }) => `${isMobile ? 'auto auto' :'repeat(4, auto)'}`};
  justify-content: space-between;
  justify-items: ${({ isMobile }) => `${isMobile ? 'start': 'center'}`};
`
const SubLinksWrapper = styled.div`
  display: grid;
  grid-template-rows: 21px 14px 14px 14px;
  grid-gap: 12px;
  justify-content: space-around;
  justify-items: start;
  align-items: start;
  padding: 16px;
`

const IconWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 14px;
  justify-items: center;
  justify-content: center;
  align-content: center;
  padding: 16px;
  margin: ${({ isMobile }) => `${isMobile ? '40px 0 0': '0'}`};
  svg {
    height: 20px;
  }
`

// const SubIconWrapper = styled.div`
//   display: grid;
//   grid-auto-flow: column;
//   grid-gap: 20px;
// `

// const ThemeSwitchWrapper = styled.div`
//   align-items: center;
//   cursor: pointer;
//   svg {
//     height: 20px;
//   }
// `

// const FootWrapper = styled.div<{ isMobile: boolean }>`
//   display: flex;
//   flex-direction: row;
//   padding: 30px ${({ isMobile }) => `${isMobile ? '16px': '64px'}`};
//   max-width: 1022px;
//   width: 100%;
// `

const LogoWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  // width: 120px;
`

// const FootWrapper = styled.div<{ isMobile: boolean }>`
//   display: flex;
//   flex-direction: row;
//   padding: 30px ${({ isMobile }) => `${isMobile ? '16px': '64px'}`};
//   max-width: 1022px;
//   width: 100%;
// `

// width: 120px;


const BottomBarWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  // grid-template-columns: 120px auto;
  grid-auto-flow: ${({ isMobile }) => `${isMobile ? 'row' : 'column'}`};
  justify-content: ${({ isMobile }) => `${isMobile ? 'center' : 'space-between'}`};
  justify-items: center;
  grid-gap: 16px;
  padding: 24px ${({ isMobile }) => `${isMobile ? '16px': '96px'}`};
  color: ${({ theme }) => theme.colors.textSecondary};
`

// const SubscribeTitleText = styled(Text)`
//   width: '240px';
// `
// const SubscribeDescText = styled(Text)`
//   width: 100%;
// `

const links = {
  resources: [
    // { name: 'Whitepaper', attrs: { href: '/' } },
    { name: 'Blog', attrs: { href: 'https://medium.com/the-zap-project' } },
    { name: 'Docs', attrs: { href: 'https://app.zap.org/docs/overview' } },
    { name: 'Featured Artist Request', attrs: { href: 'https://forms.gle/9Sq9AWaPnT7NJouWA' } },
  ],
  products: [
    { name: 'ZapOracle', attrs: { href: '/pricefeed' } },
    { name: 'Marketplace', attrs: { href: '/nft-marketplace' } },
    { name: 'ZapDEX', attrs: { href: '/dex' } },
  ],
  support: [{ name: 'FAQs', attrs: { href: 'https://app.zap.org/docs/overview' } }],
}

const Footer: React.FC<any> = ({ isMobile, ...props }) => {
  // const [text, setText] = useState('')

  // const onSubscribe = () => {
  //   handleSubscribe(text)
  // }

  // const handleInput = (event) => {
  //   setText(event.target.value)
  // }

  return (
    <Container {...props}>
      { /* <HeadWrapper>
        <SubscribeTitleText font="h6BoldLarge">Subscribe for updates.</SubscribeTitleText> 
        <SubscribeWrapper>
          <SubscribeDescText>
            Zap Protocol provides critical infrastructure built to power the next wave of decentralized applications.
            Users can swap tokens, earn rewards by validating oracle data feeds, and raise funds on customizable bonding
            curves on our platform.
          </SubscribeDescText>
          <SubscribeControlWrapper>
						<StyledInput value={text} onChange={handleInput} placeholder="airdrop alerts are mail exclusive" />
						<StyledButton onClick={onSubscribe}> I&apos;m in </StyledButton>
					</SubscribeControlWrapper> 
        </SubscribeWrapper>
      </HeadWrapper> */}

      <Divider type='morph' width='100%'/>
      {isMobile ? 
      <>
        <IconWrapper isMobile={isMobile}>
          <LogoWrapper>
            <Link href="/">
              <Logo withText text="Protocol" size={24} />
            </Link>
          </LogoWrapper>
          <SocialLinks />
        </IconWrapper>
        <LinksWrapper isMobile={isMobile}>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Resources</Text>
          {links.resources.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Products</Text>
          {links.products.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Support</Text>
          {links.support.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
        </LinksWrapper> </>
        : 

      <LinksWrapper isMobile={isMobile}>
        <IconWrapper isMobile={isMobile}>
          <LogoWrapper>
            <Link href="/">
              <Logo withText text="Protocol" size={24} />
            </Link>
          </LogoWrapper>
          <SocialLinks />
          {/* <ThemeSwitchWrapper onClick={toggleTheme}>
          {isDark ? <SunIcon width={24} height={24} /> : <MoonIcon width={24} height={24} />}
          </ThemeSwitchWrapper>   */}
        </IconWrapper>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Resources</Text>
          {links.resources.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Products</Text>
          {links.products.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
        <SubLinksWrapper>
          <Text font="footerHeaderLink" mb={2}>Support</Text>
          {links.support.map((item) => (
            <Link key={item.name} {...item.attrs}>
              <Text font="footerMainLinks" color='textSecondary'>{item.name}</Text>
            </Link>
          ))}
        </SubLinksWrapper>
      </LinksWrapper>
      }
      { !isMobile ? 
      <BottomBarWrapper isMobile={isMobile}>
        <Text font="footerBottomLink" color='textPlaceHolder'>
        © 2022, All Rights Reserved
        </Text>
        <Link href="https://v1.zap.org/terms.pdf" >
          <Text font="footerBottomLink" color='textSecondary'>
            Terms & Conditions
          </Text>
        </Link>
        <Link href="https://app.zap.org/docs/privacypolicy">
          <Text font="footerBottomLink" color='textSecondary'>
            Privacy Policy
          </Text>
        </Link>
        </BottomBarWrapper>
        :
        <BottomBarWrapper isMobile={isMobile}>
        <Link href="https://v1.zap.org/terms.pdf" >
          <Text font="footerBottomLink" color='textSecondary'>
            Terms & Conditions
          </Text>
        </Link>
        <Link href="https://app.zap.org/docs/privacypolicy">
          <Text font="footerBottomLink" color='textSecondary'>
            Privacy Policy
          </Text>
        </Link>
        <Text font="footerBottomLink" color='textPlaceHolder'>
        © 2022, All Rights Reserved
        </Text>
        </BottomBarWrapper>
        }
    </Container>
  )
}

export default Footer
