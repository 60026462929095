import React from 'react';
import styled from "styled-components";
import { NFTCardProps } from "./types";

const NFTCardWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: 509px;
  background: 
    linear-gradient(
      ${({ theme }) => theme.colors.flatGlobal},
      ${({ theme }) => theme.colors.flatGlobal}) padding-box,
    ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  border: 1px solid transparent;
  border-radius: 16px;
`

const NFTCard: React.FC<NFTCardProps> = ({ size, children }) => (
  <NFTCardWrapper size={size}>
    {children}
  </NFTCardWrapper>
)

export default NFTCard;
