import { createReducer } from '@reduxjs/toolkit'
import { getNfts, updateOne, updateProcessing, completeProcessing, clear, updateArtWorkSelected, deleteOne } from './actions'
import { ThumbFile } from '../../views/NFTWizard/contexts/types'

export interface ZapNftState {
  // readonly transactionsList: { [key: string]: { [key: string]: any } }
  nftList: { [key: string]: any }
  artworkFile: ThumbFile
	processingNFT: any
}

const initialState: ZapNftState = {
  nftList: {},
  artworkFile: {
    uploadUrl: '',
    hash: '',
    thumbnail: '',
    thumbnailUrl: '',
    ...new File([], ''),
  },
	processingNFT: {}
}

export default createReducer<ZapNftState>(initialState, (builder) =>
  builder
    .addCase(getNfts, (state, { payload: { nfts } }) => {
      const toSet: { [key: string]: any } = {}
      nfts.forEach((nft) => {
        toSet[nft.id] = nft
      })
      return {
        ...state,
        nftList: toSet,
      }
    })
    .addCase(updateOne, (state, { payload: { nft } }) => {
      const toSet: { [key: string]: any } = state.nftList
      const tempOb = {...toSet}
      tempOb[nft.id] = nft
      return {
        ...state,
        nftList: tempOb,
      }
    })
		.addCase(updateProcessing, (state, { payload: { processing } }) => {
      return {
        ...state,
        processingNFT: processing,
      }
    })
		.addCase(completeProcessing, (state, { payload: { nft } }) => {
      const toSet: { [key: string]: any } = state.nftList
      const tempOb = {...toSet}
      tempOb[nft.id] = nft
      return {
        ...state,
				processingNFT: {},
        nftList: tempOb,
      }
    })
    .addCase(clear, (state) => {
      return {
        ...state,
        nftList: {},
      }
    })
    .addCase(updateArtWorkSelected, (state, { payload: { artwork } }) => {
      return {
        ...state,
        artworkFile: artwork,
      }
    })
    .addCase(deleteOne, (state, { payload: { id } }) => {
      const toSet: { [key: string]: any } = state.nftList
      const tempOb = {...toSet}
      delete tempOb[id]
      return {
       ...state,
        nftList: tempOb,
      }
    }),
)
