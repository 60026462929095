import React from 'react';
import styled from "styled-components";
import { AvatarProps } from "./types";

const AvatarWrapper = styled.div<{ size: number, src: string }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-image: ${({ src }) => `URL(${src})`};
  background-size: cover;
  border-radius: ${({ size }) => `${size}px`};
`


const Avatar: React.FC<AvatarProps> = ({ size, src, ...props }) => (
  <AvatarWrapper size={size} src={src} {...props} />
)

export default Avatar;
