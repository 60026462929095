import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import { useLocation, Link } from 'react-router-dom'
import { Tag } from 'toolkitUI'
import CircleProfileAvatar from 'components/ProfileAvatar'
import { AppState } from 'state'
import { useSelector } from 'react-redux'
// import { Footer } from 'designStandard/components/organisms'
import useSidebarContext from 'hooks/useSidebarContext'
import Overlay from '../../components/Overlay/Overlay'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import Panel from './components/Panel'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'
import MenuButton from './components/MenuButton'
import { HamburgerCloseIcon, HamburgerIcon } from './icons'

const Wrapper = styled.div`
  // position: relative;
  width: 100%;
`

const StyledNav = styled.nav<{ showMenu: boolean; isPushed: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.nav.background};
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  opacity: ${({ showMenu }) => showMenu ? '1' : '0.3'};
  transition: all ease 0.2s;

  &:hover {
    opacity: 1;
    transition: opacity ease 0.2s;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean; isMobile: boolean }>`
  flex-grow: 1;
  margin-top: 64px;
  // transition: margin-top 0.2s;
  max-width: 100%;
  margin-left: ${({ isPushed, isMobile }) => `${!isMobile ? (isPushed ? SIDEBAR_WIDTH_FULL : SIDEBAR_WIDTH_REDUCED) : (isPushed ? 0 : SIDEBAR_WIDTH_REDUCED)}px`};

`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const StyledProviderTitle = styled(Link)`
  align-self: center;
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  display: none;

  ${({ theme }) => theme.mediaQueries.md} {
    display: inline;
  }
`

const ReportTag = styled(Tag)`
border-color: ${({ theme }) => theme.colors.failure};
color: ${({ theme }) => theme.colors.failure};
  &:hover {
    cursor: pointer;
  }
`

const Menu: React.FC<NavProps> = ({
  profilePic,
  providerTitle,
  account,
  chainId,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  links,
  children,
}) => {
  const { toggleSidebar } = useSidebarContext()
  const { isXl, isXs, isSm } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const [admin, setAdmin] = useState(false)
  const refPrevOffset = useRef(window.pageYOffset)
  const [network, setNetwork] = useState('')
  const { pathname } = useLocation()
  const user = useSelector<AppState, AppState['user']>((state) => state.user)

  useEffect(() => {
    if (chainId !== undefined) {
      switch (chainId) {
        case 1:
          setNetwork('ETH')
          break
        case 4:
          setNetwork('Rinkeby')
          break
        case 42:
          setNetwork('Kovan')
          break
        case 56:
          if (isXs || isSm) {
            setNetwork('BSC-M')
          } else {
            setNetwork('BSC-Main')
          }
          break
        case 97:
          if (isXs || isSm) {
            setNetwork('BSC-T')
          } else {
            setNetwork('BSC-Test')
          }
          break
        default:
          const windowThere = !!window.ethereum
          setNetwork(`${windowThere}`)
      }
    } else if (pathname.includes('/labs')) {
      setNetwork('from ETH')
    } else if (pathname.includes('/pricefeed') || pathname.includes('/miningevents')) {
      if (isXs || isSm) {
        setNetwork('from BSC-T')
      } else {
        setNetwork('from BSC-Test')
      }
    } else {
      setNetwork('')
    }
  }, [pathname, account, chainId, isSm, isXs])

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setIsPushed(false)
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  useEffect(() => {
    if (user.isAdmin) setAdmin(true)
  },[user])



  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Token Raise')

  const networkTagVar = chainId === undefined ? 'textDisabled' : network === 'Not Supported' ? 'failure' : 'primary'

  const showTag = (chainId !== undefined || network.length > 0) && account

  useEffect(() => {
    toggleSidebar(isPushed)
  }, [isPushed, toggleSidebar])

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu} isPushed={isPushed}>
        <Flex
          width={isMobile ? "40px" : "33%"} 
        >
          <MenuButton
            aria-label="Toggle menu"
            onClick={() => setIsPushed((prevState: boolean) => !prevState)}
            width="33%"
          >
            {isPushed ? (
              <HamburgerCloseIcon width="24px" color="textSubtle" />
            ) : (
              <HamburgerIcon width="24px" color="textSubtle" />
            )}
          </MenuButton>
        </Flex>
        <Flex
          width="100%"
          justifyContent={isMobile ? "left" : "center"} 
        >
          <Logo
            isPushed={isPushed}
            togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
            isDark={isDark}
            href={homeLink?.href ?? '/'}
          />
        </Flex>
        <Flex alignItems="center" width="33%" justifyContent="right">
          {admin? <a href="/admin"><ReportTag variant={networkTagVar} outline mr="8px"> Reports </ReportTag></a> : null}
          {showTag && (
            <Tag variant={networkTagVar} outline mr="8px">
              {network}
            </Tag>
          )}
          <UserBlock account={account} login={login} logout={logout} providerTitle={providerTitle} />
          {!isXs && !isSm && account && <StyledProviderTitle to="/nft/profile/"> <CircleProfileAvatar height='28px' src={profilePic}/></StyledProviderTitle>}
          {/* {profile && <Avatar profile={profile} />} */}
        </Flex>
      </StyledNav>
      <BodyWrapper>
        <Panel
          isPushed={isPushed}
          isMobile={isMobile}
          showMenu={showMenu}
          isDark={isDark}
          toggleTheme={toggleTheme}
          langs={langs}
          pushNav={setIsPushed}
          links={links}
        />
        <Inner isPushed={isPushed} showMenu={showMenu} isMobile={isMobile}>
          {children}
        </Inner>
        <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
      </BodyWrapper>
      {/* <Footer /> */}
    </Wrapper>
  )
}

export default Menu
