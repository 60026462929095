import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Website2: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M0.0498047 11.0001H5.5268C5.69792 14.167 6.70735 17.2315 8.4518 19.8801C6.26674 19.5369 4.257 18.4793 2.73682 16.8726C1.21664 15.2659 0.271722 13.2008 0.0498047 11.0001ZM0.0498047 9.00012C0.271722 6.79942 1.21664 4.73429 2.73682 3.12763C4.257 1.52097 6.26674 0.46334 8.4518 0.120117C6.70735 2.76873 5.69792 5.83326 5.5268 9.00012H0.0498047ZM19.9498 9.00012H14.4728C14.3017 5.83326 13.2923 2.76873 11.5478 0.120117C13.7329 0.46334 15.7426 1.52097 17.2628 3.12763C18.783 4.73429 19.7279 6.79942 19.9498 9.00012ZM19.9498 11.0001C19.7279 13.2008 18.783 15.2659 17.2628 16.8726C15.7426 18.4793 13.7329 19.5369 11.5478 19.8801C13.2923 17.2315 14.3017 14.167 14.4728 11.0001H19.9508H19.9498ZM7.5298 11.0001H12.4698C12.3052 13.6996 11.4551 16.3126 9.9998 18.5921C8.5445 16.3126 7.6944 13.6996 7.5298 11.0001ZM7.5298 9.00012C7.6944 6.30063 8.5445 3.68768 9.9998 1.40812C11.4551 3.68768 12.3052 6.30063 12.4698 9.00012H7.5298Z" fill={color ?? theme.colors.gradIconColor1}/>
      <defs>
        <linearGradient id="paint0_linear_2:1573" x1="22.1631" y1="23.3695" x2="-4.14424" y2="-0.62347" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1}/>
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Website2;