import React from 'react'
import { CgProfile } from 'react-icons/cg'
import styled from 'styled-components'

const CircleAvatarContainer = styled.div<{ contentSize: string }>`
  object-fit: contain;
  overflow: hidden;
  border: none;
  border-radius: 20px;
  height: ${(props) => props.contentSize};
  width: ${(props) => props.contentSize};

  .avatar-placeholder {
      color: ${({ theme }) => theme.colors.borderColor};
      opacity: 0.3;
      font-size: ${(props) => props.contentSize};
      width: 100%;
      height: 100%:
      display: flex;

  }

   & img {
    width: ${(props) => props.contentSize};
    height: ${(props) => props.contentSize};
  }
`

const scales = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
}

// const scaleVariants = {
//   [scales.XS]: {
//     height: '24px',
//   },
//   [scales.SM]: {
//     height: '28px',
//   },
//   [scales.MD]: {
//     height: '32px',
//   },
//   [scales.LG]: {
//     height: '48px',
//   },
// }
export type Scale = typeof scales[keyof typeof scales]

export interface ProfileAvatarProps {
  height: string
  src?: string
}

const CircleProfileAvatar: React.FC<ProfileAvatarProps> = ({ height, src }) => {
  return (
    <CircleAvatarContainer contentSize={height}>
      {src === undefined ? (
        <CgProfile className="avatar-placeholder" />
      ) : (
        <img className="profile-image" src={src} alt="Profile Avatar" />
      )}
    </CircleAvatarContainer>
  )
}

export default CircleProfileAvatar
