import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps} from "../types"

export interface AddIconProps extends SvgProps {
  size: number
}

const AddIcon: React.FC<AddIconProps> = (props) => {
  const theme = useTheme()
  const { size } = props
  const width = size * 6 / 14
  const height = size * 6 / 14
  return (
    <Svg width={width} height={height} viewBox="0 0 6 6" fill="none" {...props}>
      <path d="M2.57143 2.57143V0H3.42857V2.57143H6V3.42857H3.42857V6H2.57143V3.42857H0V2.57143H2.57143Z" fill="url(#paint0_linear_848:248)"/>
      <defs>
        <linearGradient id="paint0_linear_848:248" x1="6" y1="7.125" x2="-3.15205" y2="0.338456" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1}/>
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity="0.8"/>
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default AddIcon