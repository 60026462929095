import React, { useState, useCallback } from 'react'
import styled from "styled-components"
import Tooltip from 'components/Tooltip'
import { InformationIcon } from "../../atoms/Svg"
import { InformationButtonProps } from "./types"

const InformationContainer = styled.button<{ size: number }>`
  display: grid;
  justify-content: center;
  align-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${({ theme }) => theme.colors.flatCheckBox};
  border: ${({ theme }) => `1px solid ${theme.colors.strokeFlatNorm}`};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`

const InformationButton: React.FC<InformationButtonProps> = ({
  size,
  text,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <Tooltip text={text} show={show}>
      <InformationContainer
        size={size}
        onClick={open}
        onMouseEnter={open}
        onMouseLeave={close}
        {...props}
      >
        <InformationIcon size={size} />
      </InformationContainer>
    </Tooltip>
  )
}

export default InformationButton
