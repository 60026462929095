import React from "react";
// import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ExternalLink: React.FC<SvgProps> = (props) => {
  const { color } = props;

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.85511 4.99132L15.0087 4.99132L15.0087 12.1449M4.99133 15.0087L14.9085 5.09149" fill="transparent" stroke={color ?? "#0A84FF"} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </Svg>
  );
};

export default ExternalLink;