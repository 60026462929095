import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const SortBy2: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.4167 17.4479H14.5833M3.125 7.03125H21.875H3.125ZM6.25 12.2396H18.75H6.25Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeLinecap="round"/>
    </Svg>
  )
}

export default SortBy2