import React from 'react'
import styled from 'styled-components'
import { PriceInfoProps } from './types'
import { Text } from '../../atoms/Text'

const PriceInfoWrapper = styled.div<{ size: number }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: auto;
  height: 28px;
  padding: 4px;
  // background: ${({ theme }) => theme.colors.flatGlobal};
  background: 
    linear-gradient(${({ theme }) => theme.colors.flatGlobal},${({ theme }) => theme.colors.flatGlobal}) padding-box,
    ${({ theme }) => theme.colors.strokeGradNorm} border-box;
  border: 0.4px solid transparent;
  border-radius: 32px;
  margin-right: 4px;
`

const IconWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.flatGlobal};
`

const PriceText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.015em;
  line-height: 15px;
  width: auto;
  margin-right: 4px;
`

  // function for abbreviating price numbers
function abbrNum(number, decPlaces) {
  decPlaces = 10 ** decPlaces;
  const abbrev = [ "K", "M", "B", "T" ];
  // Go through the array backwards, so we do the largest first
  for (let i=abbrev.length-1; i>=0; i--) {
      const size = 10 ** ((i+1)*3);
      if(size <= number) {
          number = Math.round(number*decPlaces/size)/decPlaces;
          if((number === 1000) && (i < abbrev.length - 1)) {
              number = 1;
              i++;
          }
          number += abbrev[i];
          break;
      }
  }
  return number;
}


const PriceInfo: React.FC<PriceInfoProps> = ({
  size=124,
  icon,
  value
}) => (
  <PriceInfoWrapper size={size}>
    <IconWrapper>
      {icon}
    </IconWrapper>
    {/* <PriceText font="bodyRegularLargeBold">{Number(value.amount) === 0 || value.amount === null || value.amount === undefined ? "--" : parseFloat(Number(value.amount).toPrecision(4))}</PriceText> */}
    <PriceText font="bodyRegularLargeBold">{Number(value.amount) === 0 || value.amount === null || value.amount === undefined ? "--" : (abbrNum(Number(value.amount), 2))}</PriceText>
  </PriceInfoWrapper>
)

export default PriceInfo