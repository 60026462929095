import { createReducer } from '@reduxjs/toolkit'
import { getNames, updateOne, clear , setTokens } from './actions'

export interface ZapLabsState {
  readonly [key: string]: { [key: string]: { [key: string]: any } }
  readonly tokenProviders: { [key: string]: { [key: string]: any } }
}

const initialState: ZapLabsState = {
  1: {},
  42: {},
  56: {},
  97: {},
  tokenProviders: {},
}

export default createReducer<ZapLabsState>(initialState, (builder) =>
  builder
    .addCase(getNames, (state, { payload: { chainId, names } }) => {
      const chainIdSet = chainId || 1
      const toSet: { [key: string]: any } = { ...state[chainIdSet] }
      names.forEach((e) => {
        const old = state[chainIdSet] ? state[chainIdSet][e.providerAddress + e.name] : state[1][e.providerAddress + e.name]
        toSet[e.providerAddress + e.name] = { ...old, ...e}
      })
      return {
        ...state,
        [chainIdSet]: toSet,
      }
    })
    .addCase(setTokens, (state, { payload: { providers } }) => {
      return {
        ...state,
        tokenProviders: providers
      }
    })
    .addCase(updateOne, (state, { payload: { chainId, one } }) => {
      const chainIdSet = chainId || 1
      const toSet: { [key: string]: any } = { ...state[chainIdSet] }
      const old = state[chainIdSet][one.providerAddress + one.name]
      toSet[one.providerAddress + one.name] = { ...old, ...one }
      // debugger // eslint-disable-line no-debugger
      // const updatedState = Object.assign(state, { [chainIdSet]: toSet })
      // return updatedState
      return {
        ...state,
        [chainIdSet]: toSet,
      }
    })
    .addCase(clear, (state) => {
      return {
        ...state,
        1: {},
        42: {},
        56: {},
        97: {},
        tokenProviders: {}
      }
    })
    // .addCase(clearUpdatedValues, (state) => {
    //   return {
    //     ...state,
    //     updatedTokens: {},
    //   }
    // }),
)
