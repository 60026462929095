import { scales, variants } from "./types";
import { darkColors } from "../../../theme/colors"

export const scaleVariants = {
	[scales.LG]: {
    height: "64px",
		fontSize: "20px",
		lineHeight: "32px"
  },
  [scales.MD]: {
    height: "56px",
		fontSize: "16px",
		lineHeight: "24px"
  },
  [scales.SM]: {
    height: "48px",
		fontSize: "12px",
		lineHeight: "16px"
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = (theme) => {
	return {
		[variants.PRIMARY]: {
			background: `${theme.colors.gradBtnNorm} padding-box, ${theme.colors.strokeGradNorm} border-box`,
			boxShadow: theme.colors.gradientBtnBoxShadow,
			border: "1px solid",
			borderImageSource: theme.colors.strokeGradNorm,
			color:  theme.colors.btnTextPrimary,
			borderImageSlice: 0,
			":active": {
				background: `${theme.colors.gradBtnInvert} padding-box, ${theme.colors.strokeGradNorm} border-box`,
				boxShadow: theme.colors.gradientBtnPressedBoxShadow,
				color: darkColors.textSecondary
			},
			":hover:not(:active):not(:disabled)": {
				border: "1px solid",
				borderImageSource: theme.colors.strokeGradNorm,
				boxShadow: theme.colors.gradientBtnHoverBoxShadow,
				borderImageSlice: 0,
			},
			// ":focus:not(:active):not(:disabled)": {
			// 	border: "4px solid",
			// 	borderImageSource: theme.colors.strokeGradNorm,
			// 	boxShadow: theme.colors.gradientBtnFocusBoxShadow,
			// },
			":disabled": {
				color:  theme.colors.textOnDisabled,
			},
		},
		[variants.SECONDARY]: {
			background: `linear-gradient(${theme.colors.flatGlobal}, ${theme.colors.flatGlobal}) padding-box, ${theme.colors.strokeGradNorm} border-box`,
			boxShadow: theme.colors.gradientBtnBoxShadow,
			border: "1px solid",
			borderImageSource: theme.colors.strokeGradNorm,
			color: theme.colors.textPrimary,
			borderImageSlice: 0,
			":active": {
				background: `linear-gradient(${theme.colors.flatGlobal}, ${theme.colors.flatGlobal}) padding-box, ${theme.colors.strokeGradNorm} border-box`,
				boxShadow: theme.colors.gradientBtnPressedBoxShadow,
			},
			":hover:not(:active):not(:disabled)": {
				border: "1px solid",
				borderImageSource: theme.colors.strokeGradNorm,
				boxShadow: theme.colors.gradientBtnHoverBoxShadow,
				borderImageSlice: 0,
			},
			// ":focus:not(:active):not(:disabled)": {
			// 	border: theme.name === "Dark" ? `4px solid ${theme.colors.strokeFlatNorm}` : "4px solid",
			// 	borderImageSource: theme.name === "Dark" ? "" : theme.colors.strokeGradNorm,
			// 	boxShadow: theme.colors.gradientBtnFocusBoxShadow,
			// },
			":disabled": {
				boxShadow: "",
				border: theme.name === "Dark" ? `2px solid ${theme.colors.strokeFlatNorm}` : "2px solid",
				borderImageSource: theme.name === "Dark" ? "" : theme.colors.strokeGradNorm,
				color:  theme.colors.textOnDisabled
			},
		},
		[variants.TERTIARY]: {
			background: theme.colors.btnFlatBg,
			border: "0px",
			boxShadow: "none",
			color: theme.colors.textPrimary,
			":active": {
				background: theme.colors.btnBGSecondary,
				color: theme.colors.textOnHover,
			},
			":hover:not(:active):not(:disabled)": {
				color: theme.colors.textPrimary,
				background: theme.colors.btnFlatBg,
				boxShadow: theme.colors.flatBtnHoverBoxShadow,
			},
			":disabled": {
				background: theme.colors.btnBGSecondary,
				border: `1px solid ${theme.colors.strokeFlatNorm}`,
				color: theme.colors.textOnDisabled
			},
		},
	}
};
