import { createReducer } from '@reduxjs/toolkit'
import { createCollection, getCollections, getCollectionList } from './actions'
import { ZapCollectionsState } from './types'

const initialState: ZapCollectionsState = {
  collections: [],
  collectionList: {
    avatar: '',
    collectionName: '',
    count: 0,
  }
}

export default createReducer<ZapCollectionsState>(initialState, (builder) =>
  builder
    .addCase(getCollections, (state, { payload: { collections } }) => {
      return {
        ...state,
        collections,
      }
    })
    .addCase(createCollection, (state, { payload: { collection } }) => {
      return {
        ...state,
        collections: [...state.collections, collection],
      }
    })
    .addCase(getCollectionList, (state, { payload: { collectionList } }) => {
      return {
        ...state,
        collectionList,
      }
    })
)
