import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredSports: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()
  // const fill1 = color || theme.colors.flatOverlay

  return (
    <Svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.40199 18.2664C14.0423 18.2664 17.804 14.5047 17.804 9.8644C17.804 5.2241 14.0423 1.4624 9.40199 1.4624C4.76169 1.4624 1 5.2241 1 9.8644C1 14.5047 4.76169 18.2664 9.40199 18.2664Z" fill="#3480F6" stroke="#252727" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.40186 1.4624V18.2736" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M4.25293 3.22144C4.25293 3.22144 7.77172 6.51042 4.25293 16.1476Z" fill="#3480F6"/>
      <path d="M4.25293 3.22144C4.25293 3.22144 7.77172 6.51042 4.25293 16.1476" stroke="#252727" strokeMiterlimit="10" fill='none'/>
      <path d="M14.7092 3.41602C14.7092 3.41602 11.1832 6.705 14.7092 16.3422Z" fill="#3480F6"/>
      <path d="M14.7092 3.41602C14.7092 3.41602 11.1832 6.705 14.7092 16.3422" stroke="#252727" strokeMiterlimit="10" fill='none'/>
      <path d="M1 9.86385C1 9.86385 10.0986 5.59822 17.8112 9.86385" stroke="#252727" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
      <path d="M6.26383 12.7661C8.5259 10.6117 15.1254 5.71415 22.6441 12.7661C22.8722 13.0007 22.9999 13.315 22.9999 13.6422C22.9999 13.9694 22.8722 14.2837 22.6441 14.5183C20.3533 16.8019 13.6317 22.0801 6.22793 14.5183C6.00625 14.2782 5.88615 13.9617 5.89284 13.635C5.89954 13.3083 6.0325 12.9969 6.26383 12.7661V12.7661Z" fill="#4CBDFE" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M9.16504 16.9018V10.4387" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M19.6284 17.0168V10.5537" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M11.5347 13.7849H17.2581" stroke="#252727" strokeWidth="0.88" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.3926 12.7444V14.8198" stroke="#252727" strokeWidth="0.88" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M12.5547 12.6289V14.7043" stroke="#252727" strokeWidth="0.88" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.0947 12.7444V14.8198" stroke="#252727" strokeWidth="0.88" strokeMiterlimit="10" strokeLinecap="round"/>
    </Svg>
  )
}

export default ColoredSports
