import React from 'react'
import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Categories: React.FC<SvgProps> = (props) => {
  const theme = useTheme()
  const { color } = props

  return (
    <Svg width='25' height='25' viewBox="0 0 25 25" fill="none" {...props}>
      <path d="M18.2109 10.9168H20.2943C22.3776 10.9168 23.4193 9.87516 23.4193 7.79183V5.7085C23.4193 3.62516 22.3776 2.5835 20.2943 2.5835H18.2109C16.1276 2.5835 15.0859 3.62516 15.0859 5.7085V7.79183C15.0859 9.87516 16.1276 10.9168 18.2109 10.9168ZM5.71094 23.4168H7.79427C9.8776 23.4168 10.9193 22.3752 10.9193 20.2918V18.2085C10.9193 16.1252 9.8776 15.0835 7.79427 15.0835H5.71094C3.6276 15.0835 2.58594 16.1252 2.58594 18.2085V20.2918C2.58594 22.3752 3.6276 23.4168 5.71094 23.4168ZM6.7526 10.9168C7.85767 10.9168 8.91748 10.4778 9.69888 9.69644C10.4803 8.91504 10.9193 7.85523 10.9193 6.75016C10.9193 5.64509 10.4803 4.58529 9.69888 3.80388C8.91748 3.02248 7.85767 2.5835 6.7526 2.5835C5.64754 2.5835 4.58773 3.02248 3.80633 3.80388C3.02492 4.58529 2.58594 5.64509 2.58594 6.75016C2.58594 7.85523 3.02492 8.91504 3.80633 9.69644C4.58773 10.4778 5.64754 10.9168 6.7526 10.9168V10.9168ZM19.2526 23.4168C20.3577 23.4168 21.4175 22.9778 22.1989 22.1964C22.9803 21.415 23.4193 20.3552 23.4193 19.2502C23.4193 18.1451 22.9803 17.0853 22.1989 16.3039C21.4175 15.5225 20.3577 15.0835 19.2526 15.0835C18.1475 15.0835 17.0877 15.5225 16.3063 16.3039C15.5249 17.0853 15.0859 18.1451 15.0859 19.2502C15.0859 20.3552 15.5249 21.415 16.3063 22.1964C17.0877 22.9778 18.1475 23.4168 19.2526 23.4168Z" fill="none" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default Categories