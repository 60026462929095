import { Fonts } from "./types";

export const baseText = {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
}

export const typography: Fonts = {
    h1BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "47.78px",
        lineHeight: "58px",
        letterSpacing: "0.01em"
    },
    h1BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "39.81px",
        lineHeight: "48px",
        letterSpacing: "0.01em"
    },
    h2BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "39.81px",
        lineHeight: "48px",
        letterSpacing: "0.01em"
    },
    h2BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "33.18px",
        lineHeight: "40px",
        letterSpacing: "0.01em"
    },
    h3BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "33.18px",
        lineHeight: "40px",
        letterSpacing: "0.01em"
    },
    h3BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "40px",
        letterSpacing: "0.01em"
    },
    h4BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "0.01em"
    },
    h4BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "19.2px",
        lineHeight: "23px",
        letterSpacing: "0.01em"
    },
    h5BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "19.2px",
        lineHeight: "23px",
        letterSpacing: "0.01em"
    },
    h5BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.01em"
    },
    h6BoldLarge: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.01em"
    },
    h6BoldSmall: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "13.33px",
        lineHeight: "16px",
        letterSpacing: "0.01em"
    },
    bodyRegularLarge: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "-0.015em"
    },
    bodyRegularLargeBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "-0.015em"
    },
    bodyRegularExtraLargeBold: {
        ...baseText,
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "28px",
        letterSpacing: "0.03em"
    },
    bodyRegularExtraLargeSemiBold: {
        ...baseText,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "28px",
        letterSpacing: "0.03em"
    },
    bodyRegularSmall: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "13.33px",
        lineHeight: "16px",
        letterSpacing: "-0.015em"
    },
    bodySemiBoldLarge: {
        ...baseText,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "-0.015em"
    },
    bodySemiBoldSmall: {
        ...baseText,
        fontWeight: "600",
        fontSize: "13.33px",
        lineHeight: "16px",
        letterSpacing: "-0.015em"
    },
    captionRegularLarge: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "11.11px",
        lineHeight: "13px",
        letterSpacing: "-0.015em"
    },
    captionRegularSmall: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "9.26px",
        lineHeight: "11px",
        letterSpacing: "-0.015em"
    },
    captionItalicSmall: {
        ...baseText,
        fontStyle: "italic",
        fontWeight: "normal",
        fontSize: "9.26px",
        lineHeight: "11px",
        letterSpacing: "-0.015em"
    },
    captionItalicLarge: {
        ...baseText,
        fontStyle: "italic",
        fontWeight: "400",
        fontSize: "11px",
        lineHeight: "13px",
        letterSpacing: "-0.015em"
    },
    captionSemiBoldLarge: {
        ...baseText,
        fontWeight: "600",
        fontSize: "11.11px",
        lineHeight: "16px",
        letterSpacing: "0.01em"
    },
    captionSemiBoldSmall: {
        ...baseText,
        fontWeight: "600",
        fontSize: "9.26px",
        lineHeight: "11px",
        letterSpacing: "-0.015em"
    },
    captionSemiBoldLargeCap: {
        ...baseText,
        fontWeight: "600",
        fontSize: "11.11px",
        lineHeight: "13px",
        letterSpacing: "-0.015em",
        textTransform: "uppercase"
    },
    captionSemiBoldSmallCap: {
        ...baseText,
        fontWeight: "600",
        fontSize: "9.26px",
        lineHeight: "11px",
        letterSpacing: "-0.015em",
        textTransform: "uppercase"
    },
    footerRegular: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "7.72px",
        lineHeight: "9px",
        letterSpacing: "-0.015em"
    },
    footerHeaderLink: {
        ...baseText,
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "-0.015em"
    },
    footerMainLinks: {
        ...baseText,
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "3%",
    },
    footerBottomLink: {
        ...baseText,
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "0.01em"
    },
    displayExtraBold: {
        ...baseText,
        fontWeight: "800",
        fontSize: "57.33px",
        lineHeight: "69px",
        letterSpacing: "-0.015em"
    },
    buttonSmall: {
        ...baseText,
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.01em"
    },
    buttonMedium: {
        ...baseText,
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.005em"
    },
    buttonLarge: {
        ...baseText,
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0"
    },
    cardPrimaryMedium: {
        ...baseText,
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "-0.015em"
    },
    cardPrimarySemiBold: {
        ...baseText,
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "-0.04em"
    },
    cardPrimaryBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "-0.015em"
    },
    cardSecondarySmall: {
        ...baseText,
        fontWeight: "400",
        fontSize: "8px",
        lineHeight: "10px",
        letterSpacing: "0.01em"
    },
    cardSecondarySmallBold: {
        ...baseText,
        fontWeight: "600",
        fontSize: "8px",
        lineHeight: "10px",
        letterSpacing: "-0.015em"
    },
    cardSecondaryNormal: {
        ...baseText,
        fontWeight: "500",
        fontSize: "8px",
        lineHeight: "10px",
        letterSpacing: "0.04em"
    },
    cardSecondaryMedium: {
        ...baseText,
        fontWeight: "500",
        fontSize: "8px",
        lineHeight: "12px",
        letterSpacing: "0.01em"
    },
    cardSecondaryBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "8px",
        lineHeight: "24px",
        letterSpacing: "-0.015em"
    },
    cardRegularNormal: {
        ...baseText,
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.02em"
    },
    categoryPrimaryBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "9.26px",
        lineHeight: "19px",
        letterSpacing: "0.02em"
    },
    dropdownItemSemiBold: {
        ...baseText,
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "0.01em"
    },
    walletDropNav: {
        ...baseText,
        fontWeight: "500",
        fontSize: "8px",
        lineHeight: "10px",
        letterSpacing: ".02em"
    },
    walletPrimaryBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "21px",
        lineHeight: "25px",
        letterSpacing: "0.01em"
    },
    uploadPrimaryBold: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "29px",
        letterSpacing: "0.01em"
    },
    helpPrimaryMedium: {
        ...baseText,
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.015em"
    },
    connectPrimary: {
        ...baseText,
        fontWeight: "400",
        fontSize: "13.33px",
        lineHeight: "24px",
        letterSpacing: "0.03em"
    },
		editProfileLabel: {
			...baseText,
			fontWeight: "500",
			fontSize: "14px",
			lineHeight: "17px",
			letterSpacing: "0.01em"
		},
    collectionImageDesc: {
        ...baseText,
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.02em"
    },
    collectionInfoLabels: {
        ...baseText,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.01em"
    },
    collectionRanking: {
        ...baseText,
        fontWeight: "700",
        fontSize: "10px",
        lineHeight: "12.1px",
        letterSpacing: "-1.5%"
    },
    collectionCount: {
        ...baseText,
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "15px",
        letterSpacing: "-0.04em"
    },
    collectionTitle: {
        ...baseText,
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing:"0"
    },
    headerText: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "29px",
        letterSpacing:"-0.015em"
    },
    nftCardTitle: {
        ...baseText,
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing:"-0.04em"
    }
}