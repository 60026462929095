import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Youtube: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="22" height="14" viewBox="0 0 22 14" fill="none" {...props}>
	  <path d="M20.9092 2.13238C20.7899 1.72087 20.5576 1.3483 20.2369 1.05429C19.9072 0.751237 19.5031 0.534458 19.0627 0.424411C17.4146 0.00420947 10.8116 0.00420956 10.8116 0.00420956C8.05885 -0.0260851 5.30679 0.107158 2.57096 0.403189C2.13057 0.521365 1.7272 0.742989 1.39673 1.04835C1.07202 1.35055 0.836827 1.72322 0.713964 2.13153C0.418823 3.66951 0.275423 5.23113 0.285698 6.79534C0.275167 8.35815 0.418215 9.91927 0.713964 11.4592C0.834195 11.8658 1.06851 12.2367 1.3941 12.5364C1.71969 12.8361 2.12514 13.0525 2.57096 13.1671C4.24102 13.5865 10.8116 13.5865 10.8116 13.5865C13.5678 13.6168 16.3234 13.4836 19.0627 13.1875C19.5031 13.0775 19.9072 12.8607 20.2369 12.5576C20.5616 12.258 20.7925 11.8853 20.9083 11.4795C21.2111 9.94215 21.3584 8.37988 21.348 6.81487C21.3708 5.24323 21.2237 3.6736 20.9092 2.13153V2.13238ZM8.71236 9.70195V3.88959L14.2061 6.79619L8.71236 9.70195Z" fill="#5E5E5F"/>
    </Svg>
  );
};

export default Youtube;