import React from 'react'
import { useSelector } from 'react-redux'
import useZapToast from 'hooks/useZapToast'
import { AppState } from 'state'
import {  Toast, ToastContainer } from 'designStandard/components/molecules/Toast'

const ZapToastListener = () => {
  const toasts: Toast[] = useSelector((state: AppState) => state.toasts.data)
  const { remove, scan } = useZapToast()

  const handleRemove = (id: string) => remove(id)
  const handleScan = (id: string) => {
    scan(toasts.filter((t) => t.id === id)[0])
    remove(id)
  }

  return <ToastContainer toasts={toasts} onRemove={handleRemove} onClick={handleScan}/>
}

export default ZapToastListener
