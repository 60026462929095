import React from "react"
import { useTheme } from "styled-components"
import Svg from "../Svg"
import { SvgProps } from "../types"

const DocsIcon: React.FC<SvgProps> = (props) => {
  const { color } = props
  const theme = useTheme()

  return (
    <Svg width="32" height="32" viewBox="0 0 32 32"  fill="none" {...props}>
      <path d="M28 4V30H7C5.342 30 4 28.656 4 27C4 25.344 5.342 24 7 24H26V0H6C3.8 0 2 1.8 2 4V28C2 30.2 3.8 32 6 32H30V4H28Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.002 26H7C6.448 26 6 26.448 6 27C6 27.552 6.448 28 7 28H7.002H25.998V26H7.002Z" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  )
}

export default DocsIcon