import { createReducer } from '@reduxjs/toolkit'
import { getDisputedEvents, clear } from './actions'

export interface ZapDisputedEvents {
    DisputedEventsList: {[key: string]: any}
}

const initialState: ZapDisputedEvents = {
    DisputedEventsList: {}
}

export default createReducer<ZapDisputedEvents>(initialState, (builder)=> {
    builder
    .addCase(getDisputedEvents, (state, {payload: {disputedEvents}}) =>{
        const toSet: { [key: string]: any } = {}
        disputedEvents.forEach((e) => {
            toSet[e.id] = e
        })
        return {
            ...state,
            DisputedEventsList: toSet
        }
    })
    .addCase(clear, (state) => {
        return {
            ...state,
            DisputedEventsList: {}
        }
    })
})