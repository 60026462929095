import { CollectionType, NFTCollections } from "../state/zapCollections/types"

const useZapCollections = async (Moralis: any): Promise<CollectionType[]> => {
  const collections: CollectionType[] = []

  const moralisCollections = Moralis.Object.extend('NFTCollections')
  const collectionQuery = new Moralis.Query(moralisCollections)
  const collectionQueryRes = await collectionQuery.find()

  collectionQueryRes.forEach((element: NFTCollections) => collections.push(element?.attributes))
  return Promise.all(collections)
}

export default useZapCollections