import React, { FC, useCallback, useRef, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import { Text } from "designStandard/components/atoms/Text"
import { GridBox } from "designStandard/components/atoms/GridLayout"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Alert, alertVariants} from "designStandard/components/atoms/Alert"
import { ToastProps, types, Types } from "./types"
import ToastAction from './ToastAction'
import { IconButton } from "../IconButton"

const alertTypeMap = {
    [types.INFO]: alertVariants.INFO,
    [types.SUCCESS]: alertVariants.SUCCESS,
    [types.ERROR]: alertVariants.ERROR,
    [types.WARNING]: alertVariants.WARNING,
};

const ToastWrapper = styled(GridBox)<{type: Types }>`
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    right: 16px;
    position: fixed;
    min-width: 230px;
    max-width: calc(100% - 64px);
    min-height: 80px;
    justify-content: initial;
    background: ${({ theme}) => theme.colors.flatGlobal};
    transition: all 250ms ease-in;
    padding: 8px;
    grid-gap: 16px;
		max-width: 350px;
		word-break: break-word;
    border: 2px ridge ${({ type, theme }) =>
        type === 'success' ? theme.colors.primaryGreen : 
        type === 'warning' ? 'orange' :
        type === 'error' ? theme.colors.primaryRed :
        theme.colors.primaryBlue};

    .toast-grid {
        position: relative;
        display: grid;
        width: 100%;
        text-align: right;
    }

    .toast-message {
        text-align: left;
        width: 100%;
        word-break: break-words;
        font-size: 1em;
        line-height: 1.25em;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
`

const CloseIconButton = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.flatGlobal};
    box-shadow: transparent;
    border: none;
    height: 26.67px;
    width: 26.67px;
    .toast-close-btn {
        width: 100%;
        background: ${({ theme}) => theme.colors.flatOverlay};
        padding: 0;
        margin: 0;
        height: 100%;
        box-shadow: none;

        &:hover:not(:active) {
            box-shadow: none;
            border: none;
            }
        }
    }
}
`

const NetworkWrapper = styled(GridBox)`
    // padding: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-rows: 1;
    max-width: 20px;
    margin-left: .5rem;
`

const NetworkStatus = styled.div<{type: string}>`
    width: 8px;
    height: 8px;
    background: ${({ type, theme }) => 
        type === 'success' ? theme.colors.primaryGreen :
        type === 'error' ? theme.colors.primaryRed :
        type === 'warning' ? 'orange' : 
        theme.colors.primaryBlue};
    border-radius: 50%;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Toast: FC<ToastProps> = ({ toast, onRemove, onClick, style, ttl, ...props}) => {
    const timer = useRef<number>();
    const ref = useRef(null);
    const removeHandler = useRef(onRemove);
    const selectHandler = useRef(onClick);
    const { id, title, type, action } = toast;
    
    const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler]);
    const handleSelected = useCallback(() => selectHandler.current(id), [id, selectHandler]);

    const handleMouseEnter = () => {
        clearTimeout(timer.current);
    };

    const handleMouseLeave = () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = window.setTimeout(() => {
                handleRemove();
            }, ttl);
    };

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = window.setTimeout(() => {
            handleRemove();
        }, ttl);

        return () => {
            clearTimeout(timer.current);
        };
    }, [props, ttl, handleRemove]);



    return (
        <CSSTransition nodeRef={ref} timeout={250} style={style} {...props}>
            <ToastWrapper type={alertTypeMap[type]} ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <GridBox className='toast-grid' columns="auto auto auto" gridGap="16px" onClick={handleRemove}>
                {/* <Alert title={title} variant={alertVariants[type]} > */}
                    <NetworkWrapper>
                        <NetworkStatus type={type}/> 
                    </NetworkWrapper>
                    {action ? (
                        <>
                        <Text className='toast-message' font='captionSemiBoldSmall' >
                        {title}
                        </Text>
                        <ToastAction action={action} />
                    </>
                    ) : (
                        <Text className='toast-message' font='captionSemiBoldSmall' color='textPrimary'>{title}</Text>
                    )}
                    {/* <Text font="captionSemiBoldSmall" color="textPrimary" textAlign="right">
                        {toast.title}
                    </Text> */}
                    <CloseIconButton className='close-btn-wrapper' onClick={handleSelected}> 
                        <IconButton className="toast-close-btn">
                            <Text bold font="buttonSmall"  color="textPrimary"
                            className="toast-close-icon">
                                ✕</Text>
                        </IconButton>
                    </CloseIconButton>
                {/* </Alert> */}
                </GridBox>
            </ToastWrapper>
        </CSSTransition>
    )
}

export default Toast
