import React, { useState, useCallback } from 'react'
import styled from "styled-components"
import { ClearIcon } from "../../atoms/Svg"
import { TextboxProps, Type, State } from "./types"

const TextboxWrapper = styled.div<{ type: Type, state: State }>`
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  padding: 15px 35.5px;
  background: ${({ theme, type }) => type === 'filled' ? theme.colors.flatSearchBox : 'transparent'};
  border-radius: 8px;
  border: ${({ theme, type, state }) =>
    state === 'focus'
      ? `1px solid ${theme.colors.primaryBlue}`
      : type === 'filled'
        ? 'none'
        : `1px solid ${theme.colors.strokeFlatNorm}`};
  box-sizing: border-box;
`

const Input = styled.input<{ size: number, state: State, icon: boolean }>`
  width: ${({ size, icon }) => icon ? `${size - 96}px`: `${size - 72}px`};
  background: transparent;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme, state }) =>
    state === 'onHover'
      ? theme.colors.textOnHover
      : state === 'active'
        ? theme.colors.textPrimary
        : theme.colors.textSecondary };
`

const ClearWrapper = styled.div`
  display: block;
  cursor: pointer;
`

const AssistiveText = styled.div`
  margin-top: 4px;
  padding-left: 16px;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.textPrimary};
`

const Textbox: React.FC<TextboxProps> = ({ type, state, icon, assistiveText, text, size, placeholder }) => {
  const [value, setValue] = useState('')

  const handleChange = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const clearHandler = useCallback(() => {
    setValue('')
  }, [])
  
  return (
    <>
      <TextboxWrapper type={type} state={state}>
        <Input
          type="text"
          value={value}
          size={size}
          state={state}
          icon={icon}
          disabled={state === 'inactive'}
          placeholder={placeholder ?? "Input Text"}
          onChange={handleChange}
        />
        {icon && (
          <ClearWrapper onClick={clearHandler}>
            <ClearIcon />
          </ClearWrapper>
        )}
      </TextboxWrapper>
      {assistiveText && <AssistiveText>{text}</AssistiveText>}
    </>
  )
}

export default Textbox
