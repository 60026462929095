import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const VMore: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="3" height="16" viewBox="0 0 3 16" fill="none" {...props}>
	 		<path d="M0 1.53636C0 0.705851 0.671573 0.0325928 1.5 0.0325928C2.32843 0.0325928 3 0.705851 3 1.53636C3 2.36686 2.32843 3.04012 1.5 3.04012C0.671573 3.04012 0 2.36686 0 1.53636Z" fill="url(#paint0_linear_1743_5952)"/>
			<path d="M0 7.55142C0 6.72091 0.671573 6.04765 1.5 6.04765C2.32843 6.04765 3 6.72091 3 7.55142C3 8.38193 2.32843 9.05518 1.5 9.05518C0.671573 9.05518 0 8.38193 0 7.55142Z" fill="url(#paint1_linear_1743_5952)"/>
			<path d="M0 13.5665C0 12.736 0.671573 12.0627 1.5 12.0627C2.32843 12.0627 3 12.736 3 13.5665C3 14.397 2.32843 15.0702 1.5 15.0702C0.671573 15.0702 0 14.397 0 13.5665Z" fill="url(#paint2_linear_1743_5952)"/>
	  	<defs>
			<linearGradient id="paint0_linear_1743_5952" x1="2.85" y1="14.9316" x2="-0.654254" y2="14.3712" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1} />
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
			</linearGradient>
			<linearGradient id="paint1_linear_1743_5952" x1="2.85" y1="14.9316" x2="-0.654254" y2="14.3712" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1} />
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
			</linearGradient>
			<linearGradient id="paint2_linear_1743_5952" x1="2.85" y1="14.9316" x2="-0.654254" y2="14.3712" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1} />
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity} />
			</linearGradient>
			</defs>
    </Svg>
  );
};

export default VMore;