import React from 'react'
import styled from 'styled-components'

const CreateNFTButtonWrapper = styled.div`
  width: 200px;
  padding: 8px;
  margin: 0 8px;
`
const CreateNFTButton = styled.div`
  width: 100%;
  height: 160px;
  min-width: 160px;
  min-height: 160px;
  background: 
     linear-gradient(white, white) padding-box,
     linear-gradient(135deg, rgba(82, 145, 241, 0.7), rgba(34, 174, 169, 0.85)) border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const NFTCreateBtnImg = styled.img`
  width: 50%;
  height: 100px;
  src: ${(p) => p.src};
`

const NFTCreateBtnLabel = styled.p`
  background-image: linear-gradient(45deg, #3480f6, #19dad3);
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
`

const IconLabelButton = (props): JSX.Element => {
  const {imgsrc, label, onClick} = props;
  return (
    <CreateNFTButtonWrapper onClick={onClick}>
      <CreateNFTButton>
        <NFTCreateBtnImg src={imgsrc} />
        <NFTCreateBtnLabel>{label}</NFTCreateBtnLabel>
      </CreateNFTButton>
    </CreateNFTButtonWrapper>
  )
}

export default IconLabelButton
