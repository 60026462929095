import React from 'react'
import { useTheme } from "styled-components";
import Svg  from '../Svg'
import { SvgProps } from '../types'

const SortBy: React.FC<SvgProps> = () => {
    const theme = useTheme();
    const opacity = theme.isDark ? '0.8' : '0.65';

    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.81 0H14.18C17.83 0 20 2.17 20 5.81V14.18C20 17.82 17.83 19.99 14.19 19.99H5.81C2.17 20 0 17.83 0 14.19V5.81C0 2.17 2.17 0 5.81 0Z" fill={theme.colors.flatOverlay}/>
        <path d="M8.23949 4.25C7.81949 4.25 7.48949 4.59 7.48949 5V13.19L5.81949 11.52C5.67835 11.3805 5.48792 11.3023 5.28949 11.3023C5.09106 11.3023 4.90063 11.3805 4.75949 11.52C4.46949 11.81 4.46949 12.29 4.75949 12.58L7.70949 15.53C7.85156 15.6684 8.0412 15.7471 8.23949 15.75C8.33949 15.75 8.42949 15.73 8.52949 15.69C8.70949 15.61 8.85949 15.47 8.93949 15.28C8.97949 15.19 8.99949 15.09 8.99949 14.99V5C8.99434 4.80099 8.9123 4.61171 8.7706 4.47187C8.62891 4.33204 8.43855 4.25252 8.23949 4.25V4.25ZM15.2395 7.42L12.2895 4.47C12.2202 4.40214 12.1388 4.34787 12.0495 4.31C11.9597 4.27044 11.8626 4.25001 11.7645 4.25001C11.6664 4.25001 11.5693 4.27044 11.4795 4.31C11.2995 4.39 11.1495 4.53 11.0695 4.72C11.0295 4.81 11.0095 4.9 11.0095 5V15C11.0095 15.41 11.3495 15.75 11.7595 15.75C12.1695 15.75 12.5095 15.41 12.5095 15V6.81L14.1795 8.48C14.3295 8.63 14.5195 8.7 14.7095 8.7C14.8995 8.7 15.0895 8.63 15.2395 8.48C15.5295 8.19 15.5295 7.71 15.2395 7.42V7.42Z" fill="url(#paint0_linear_0_1)"/>
        <defs>
        <linearGradient id="paint0_linear_0_1" x1="14.9112" y1="15.644" x2="6.62963" y2="9.34295" gradientUnits="userSpaceOnUse">
        <stop stopColor={theme.colors.gradIconColor1}/>
        <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
        </defs>
        </Svg>

        )}

        export default SortBy
