import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import web3NoAccount from 'utils/web3'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getCakeAddress,
  getLotteryAddress,
  getLotteryTicketAddress,
  getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getZapAddress,
  getRegistryAddress,
  getBondageAddress,
  getCurrentCostAddress,
  getTokenFactoryAddress,
  getZapMinerAddress,
  getZapAggregatorAddress,
  getZapMediaAddress,
  getZapAuctionHouseAddress,
  getZapMediaFactoryAddress
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoAbi from 'config/abi/ifo.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryAbi from 'config/abi/lottery.json'
import lotteryTicketAbi from 'config/abi/lotteryNft.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import zapToken from 'config/abi/zapToken.json'
import registry from 'config/abi/registry.json'
import bondage from 'config/abi/bondage.json'
import currentCost from 'config/abi/currentCost.json'
import tokenFactory from 'config/abi/tokenFactory.json'
import tokenDotFactory from 'config/abi/tokenDotFactory.json'
import zap from 'config/abi/zapMaster.json'
import zapAggregator from 'config/abi/zapAggregator.json'
import zapMedia from 'config/abi/zapMedia.json'
import zapAuction from 'config/abi/zapAuction.json'
import mediaFactory from 'config/abi/mediaFactory.json'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract((abi as unknown) as AbiItem, address)
}

/**
 *  Zap Contracts
 */

export const getZapToken = (web3?: Web3, chainID?: number) => {
  return getContract(zapToken, getZapAddress(chainID), web3)
}
export const getErc20 = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}
export const getRegistryContract = (web3?: Web3, chainID?: number) => {
  return getContract(registry, getRegistryAddress(chainID), web3)
}
export const getBondageContract = (web3?: Web3, chainID?: number) => {
  return getContract(bondage, getBondageAddress(chainID), web3)
}
export const getCurrentCostContract = (web3?: Web3, chainID?: number) => {
  return getContract(currentCost, getCurrentCostAddress(chainID), web3)
}
export const getTokenFactoryContract = (web3?: Web3) => {
  return getContract(tokenFactory, getTokenFactoryAddress(), web3)
}
export const getTokenDotFactoryContract = (address: string, web3?: Web3) => {
  return getContract(tokenDotFactory, address, web3)
}
export const getZapMiner = (web3?: Web3, chainID?: number) => {
  return getContract(zap, getZapMinerAddress(chainID), web3)
}

export const getZapAggregator = (web3?: Web3, chainID?: number) => {
  return getContract(zapAggregator, getZapAggregatorAddress(chainID), web3)
}
export const getZapMedia = (web3?: Web3, chainID?: number) => {
  return getContract(zapMedia, getZapMediaAddress(chainID), web3)
}
export const getAuctionHouse = (web3?: Web3, chainID?: number) => {
  return getContract(zapAuction, getZapAuctionHouseAddress(chainID), web3)
}
export const getMediaFactory = (web3?: Web3, chainID?: number) => {
  return getContract(mediaFactory, getZapMediaFactoryAddress(chainID), web3)
}

/**
 *  Pacnake Swap Contracts
 */

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}
export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3)
}
export const getIfoContract = (address: string, web3?: Web3) => {
  return getContract(ifoAbi, address, web3)
}
export const getSouschefContract = (id: number, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), web3)
}
export const getPointCenterIfoContract = (web3?: Web3) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), web3)
}
export const getCakeContract = (web3?: Web3) => {
  return getContract(cakeAbi, getCakeAddress(), web3)
}
export const getProfileContract = (web3?: Web3) => {
  return getContract(profileABI, getPancakeProfileAddress(), web3)
}
export const getPancakeRabbitContract = (web3?: Web3) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), web3)
}
export const getBunnyFactoryContract = (web3?: Web3) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), web3)
}
export const getBunnySpecialContract = (web3?: Web3) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), web3)
}
export const getLotteryContract = (web3?: Web3) => {
  return getContract(lotteryAbi, getLotteryAddress(), web3)
}
export const getLotteryTicketContract = (web3?: Web3) => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), web3)
}
export const getMasterchefContract = (web3?: Web3) => {
  return getContract(masterChef, getMasterChefAddress(), web3)
}
export const getClaimRefundContract = (web3?: Web3) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), web3)
}
export const getTradingCompetitionContract = (web3?: Web3) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), web3)
}
export const getEasterNftContract = (web3?: Web3) => {
  return getContract(easterNftAbi, getEasterNftAddress(), web3)
}
