import { getBondageContract } from 'utils/contractHelpers'

import Web3 from 'web3'
// import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { kovanBlockClient, mainBlockClient, chapelExplorerClient } from 'graph/index';
import { TRANSACTIONS } from 'graph/queries';
import ZapTransaction from '../entities/zapTransaction'

export default async function useZapTransactionsList(web3: Web3, chainId): Promise<ZapTransaction[]> {
  // const chainId = await web3.eth.getChainId();
  // console.log('chainid', chainId)
  const client = chainId === 42 ? kovanBlockClient : chainId === 97 ? chapelExplorerClient: mainBlockClient // FIRST CLIENT SHOULD BE THE MAIN BLOCK CLIENT
  const transactionsG = await client.request(TRANSACTIONS);
  // console.log(transactionsG)

  const retVal: ZapTransaction[] = []

  // for (let i = 0; i < transactionsG.events.length; i++) {
  transactionsG.events.forEach((t) => {
    // const t = transactionsG.events[i];
    const trans = new ZapTransaction()
    trans.txHash = t.txnHash
    trans.block = t.block
    // Remove the T, Z, and spaces to get date formatted
    const timestampRaw = new Date(Number(t.timestamp) * 1000).toISOString()
    trans.timestamp = timestampRaw.replace('T', ' ').substring(0, timestampRaw.length - 5)
    // trans.status = t.status // 'Success' // currently null from subgraph
    trans.status = "success"
    trans.from = t.from
    trans.to = t.to
    trans.dataEncoded = t.data || {name: "", numDots: "0", endpoint: "", numZap: "0"}
    trans.value = t.value
    // trans.transactionFee = Number(Web3.utils.fromWei(String(Number(t.gasPrice) * Number(t.gasUsed)),'ether'))
    trans.transactionFee = ""
    trans.action = t.action
    // const gasLimit = String(Number(t.transactionFee) / Number(t.gasPrice))
    // const gasPriceGwei = String(Math.round(t.gasPrice * 10 ** -9))
    // const gasPriceEthers = String((Number(gasPriceGwei) * 10 ** -9).toFixed(9))

    // trans.gasInfo = `${Number(t.gasUsed) / Number(t.gasPrice)} Gas Used From ${gasLimit} Gas Limit @ ${gasPriceEthers} ether (${gasPriceGwei} gwei)`
    trans.gasInfo = ""
    trans.zap = Web3.utils.fromWei(trans.dataEncoded.numZap,'ether')

    const preposition = trans.dataEncoded.name.toLowerCase() === 'bond' ? 'to' : 'from'
    const verb = t.action.slice(0,1).toUpperCase() + t.action.slice(1)
    const direction = verb.charAt(0) === "B" ? "mint" : "burn"
    const dots = trans.dataEncoded.numDots
    const plural = dots > 1 ? 's' : ''
    const oracleName = trans.dataEncoded.endpoint
    trans.txAction = trans.to ? `${verb} to ${direction} ${dots} ${oracleName}${plural}` : `${verb} ${dots} dot${plural} ${preposition} Oracle ${oracleName}`

    const fromAddressZapTransfer = trans.dataEncoded.name.toLowerCase() === 'bond' ? trans.from : trans.to
    const toAddressZapTransfer = trans.dataEncoded.name.toLowerCase() === 'bond' ? trans.to : trans.from

    trans.tokenTransfer = `${Number(trans.zap).toFixed(4)} ZAP from ${abbreviateAddress(
        fromAddressZapTransfer,
    )} to ${abbreviateAddress(toAddressZapTransfer)}`

    retVal.push(trans)
  })
  // console.log('retval', retVal)
  if(retVal.length !== 0) return retVal
  const transactions = []
  try {
    const bondage = getBondageContract(web3, chainId)

    // @ts-ignore
    const etherscanProvider = new ethers.providers.Web3Provider(web3.currentProvider)

    // const ethereum = window.web3.currentProvider
    const allTransactions = await bondage.getPastEvents("allEvents", { fromBlock: 4000, toBlock: 'latest' })
    // const allTransactions = await etherscanProviderHome.getHistory(contracts.bondage[chainId])
    const transactionArraySliced = allTransactions.slice(allTransactions.length - 51, allTransactions.length)
    for (let i = 0; i < transactionArraySliced.length - 1; i++) {
      const newTxn = new ZapTransaction()

      // newTxn.txHash = transactionArraySliced[i].hash
      newTxn.txHash = transactionArraySliced[i].transactionHash
      const transactionEthersReceipt = await etherscanProvider.getTransactionReceipt(newTxn.txHash)

      newTxn.block = transactionArraySliced[i].blockNumber
      const blockObject = await web3.eth.getBlock(newTxn.block)
      // const transactionReceipt = await web3.eth.getTransactionReceipt(newTxn.txHash)
      const transactionResponse = await etherscanProvider.getTransaction(newTxn.txHash)
      newTxn.to = transactionEthersReceipt.to
      newTxn.from = transactionEthersReceipt.from
      const timestampRaw = new Date(Number(blockObject.timestamp) * 1000).toISOString()
      // Remove the T, Z, and spaces to get date formatted
      newTxn.timestamp = timestampRaw.replace('T', ' ').substring(0, timestampRaw.length - 5)
      newTxn.status = transactionEthersReceipt.status ? 'Success' : 'Failure'

      // const { gasUsed } = transactionEthersReceipt
      // const { gasLimit } = transactionResponse
      // const gasPrice = Number(transactionResponse.gasPrice) * 10 ** -9
      // const BSC = chainId === 56 || chainId === 97
      // const verbage = BSC? "BNB" : "ether"
      // newTxn.transactionFee = (Number(gasUsed) * gasPrice).toString()
      newTxn.transactionFee = ""
      // newTxn.gasInfo = `${gasUsed} Gas Used From ${gasLimit} Gas Limit @ ${Math.round(gasPrice)} ${verbage} (${Math.round(
      //   gasPrice,
      // )} gwei)`
      newTxn.gasInfo = ""

      newTxn.dataEncoded = transactionResponse.data
      newTxn.value = transactionResponse.value.toNumber()

      let zap = null
      if (transactionEthersReceipt.logs.length > 0) {
        zap = Number(transactionEthersReceipt.logs[0].data) * 10 ** -18
        if (!zap) {
          zap = 0
        }
      }
      newTxn.zap = zap
      newTxn.txAction = 'to'
      transactions.push(newTxn)
    }
  } catch (e) {
    console.error(`Transaction error`, e)
  }

  return transactions
}


function abbreviateAddress(address = '', lengthStart = 6, lengthEnd = 4) {
  return `${address.substring(0, lengthStart)}...${address.substring(address.length - lengthEnd, address.length)}`
}
