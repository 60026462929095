import React from "react";
import Svg from "../Svg";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Icon: React.FC<any> = ({size, color1, color2, style}) => {
    // const updatedWidth = parseInt(size)*6/24
    // const updatedHeight = parseInt(size)*4/24
  return (
    <Svg width="12" height="10" viewBox="0 0 12 10" style={style}>
        <path d="M4.869 9.63078C4.811 9.57428 4.563 9.36094 4.359 9.1622C3.076 7.99708 0.976 4.95762 0.335 3.36678C0.232 3.12518 0.0139999 2.51437 0 2.18802C0 1.8753 0.0720005 1.5772 0.218 1.29274C0.422001 0.93814 0.743 0.653678 1.122 0.497808C1.385 0.397468 2.172 0.241598 2.186 0.241598C3.047 0.0857286 4.446 0 5.992 0C7.465 0 8.807 0.0857286 9.681 0.213346C9.695 0.22796 10.673 0.383828 11.008 0.554311C11.62 0.867023 12 1.47784 12 2.13152V2.18802C11.985 2.61374 11.605 3.50901 11.591 3.50901C10.949 5.01413 8.952 7.98344 7.625 9.17681C7.625 9.17681 7.284 9.51291 7.071 9.65904C6.765 9.88699 6.386 10 6.007 10C5.584 10 5.19 9.87238 4.869 9.63078Z"/>
        <defs>
        <linearGradient id="down" x1="6" y1="4.0625" x2="1.56663" y2="-2.51248" gradientUnits="userSpaceOnUse">
        <stop stopColor={color1}/>
        <stop offset="1" stopColor={color2} stopOpacity="0.8"/>
        </linearGradient>
        </defs>
    </Svg>
  );
};

export default Icon;
