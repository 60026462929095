import React from 'react'
// import { useTheme } from 'styled-components'
import Svg from '../Svg'
import { SvgProps } from '../types'

const ColoredDomainNames: React.FC<SvgProps> = (props) => {
  // const { color } = props
  // const theme = useTheme()

  return (
    <Svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.9251 32.9255C25.7203 32.9255 32.8503 25.7955 32.8503 17.0003C32.8503 8.20512 25.7203 1.0752 16.9251 1.0752C8.12992 1.0752 1 8.20512 1 17.0003C1 25.7955 8.12992 32.9255 16.9251 32.9255Z" fill="#3480F6" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M17.0431 33C21.3846 33 24.9041 25.8366 24.9041 17C24.9041 8.16345 21.3846 1 17.0431 1C12.7016 1 9.18213 8.16345 9.18213 17C9.18213 25.8366 12.7016 33 17.0431 33Z" stroke="#252727" strokeMiterlimit="10" fill="#3480F6"/>
      <path d="M4.47559 7.05371C4.47559 7.05371 16.7216 14.4334 29.4702 7.05371" stroke="#252727" strokeMiterlimit="10" fill="none"/>
      <path d="M3.7915 26.0162C7.85026 23.8768 12.3692 22.7588 16.9573 22.7588C21.5453 22.7588 26.0643 23.8768 30.1231 26.0162" stroke="#252727" strokeMiterlimit="10" fill="none"/>
      <path d="M1 17H32.8396" stroke="#252727" strokeMiterlimit="10"/>
      <path d="M26.0912 12.5293H7.74901C7.01066 12.5293 6.41211 13.1278 6.41211 13.8662V19.2138C6.41211 19.9521 7.01066 20.5507 7.74901 20.5507H26.0912C26.8296 20.5507 27.4281 19.9521 27.4281 19.2138V13.8662C27.4281 13.1278 26.8296 12.5293 26.0912 12.5293Z" fill="#252727"/>
      <path d="M12.4331 18.0052L11.77 15.6416H12.3369L12.754 17.2994L13.1818 15.6416H13.7379L14.1658 17.2887L14.5722 15.6416H15.139L14.4759 18.0052H13.8984L13.4492 16.4117L13.0107 18.0052H12.4331Z" fill="#F5F5F9"/>
      <path d="M16.3906 18.0052L15.7168 15.6416H16.2943L16.7008 17.2994L17.1286 15.6416H17.6954L18.1125 17.2887L18.5296 15.6416H19.0965L18.4227 18.0052H17.8452L17.4067 16.4117L16.9574 18.0052H16.3906Z" fill="#F5F5F9"/>
      <path d="M20.3369 18.0052L19.6738 15.6416H20.2407L20.6578 17.2994L21.0856 15.6416H21.6417L22.0695 17.2887L22.476 15.6416H23.0428L22.3797 18.0052H21.8022L21.353 16.4117L20.9145 18.0052H20.3369Z" fill="#F5F5F9"/>
    </Svg>
  )
}

export default ColoredDomainNames
