import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Website2: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M7.076 9C7.676 9 8.162 9.45 8.151 10C8.151 10.55 7.677 11 7.076 11C6.486 11 6 10.55 6 10C6 9.45 6.475 9 7.076 9ZM10.924 9C11.525 9 12 9.45 12 10C12 10.55 11.525 11 10.924 11C10.334 11 9.849 10.55 9.849 10C9.849 9.45 10.323 9 10.924 9ZM15.891 0C17.054 0 18 0.966 18 2.163V21L15.789 19.005L14.544 17.829L13.227 16.579L13.773 18.522H2.109C0.946 18.522 0 17.556 0 16.359V2.163C0 0.966 0.946 0 2.109 0H15.89H15.891ZM11.921 13.713C14.194 13.64 15.069 12.117 15.069 12.117C15.069 8.736 13.587 5.995 13.587 5.995C12.107 4.862 10.697 4.893 10.697 4.893L10.553 5.061C12.302 5.607 13.114 6.395 13.114 6.395C12.1591 5.85699 11.1066 5.51434 10.018 5.387C9.32744 5.309 8.62993 5.31572 7.941 5.407C7.879 5.407 7.827 5.418 7.766 5.428C7.406 5.46 6.531 5.596 5.431 6.09C5.051 6.268 4.824 6.395 4.824 6.395C4.824 6.395 5.678 5.565 7.529 5.019L7.426 4.893C7.426 4.893 6.017 4.862 4.536 5.996C4.536 5.996 3.055 8.736 3.055 12.117C3.055 12.117 3.919 13.639 6.192 13.713C6.192 13.713 6.572 13.241 6.882 12.842C5.575 12.442 5.082 11.602 5.082 11.602C5.082 11.602 5.184 11.676 5.369 11.781C5.379 11.791 5.389 11.802 5.41 11.812C5.441 11.834 5.472 11.844 5.503 11.865C5.76 12.012 6.017 12.127 6.253 12.222C6.675 12.39 7.179 12.558 7.766 12.674C8.64532 12.8464 9.54941 12.8498 10.43 12.684C10.9429 12.5925 11.4433 12.4411 11.921 12.233C12.281 12.096 12.682 11.896 13.104 11.613C13.104 11.613 12.59 12.474 11.242 12.863C11.551 13.262 11.922 13.713 11.922 13.713H11.921Z" fill={color ?? theme.colors.gradIconColor1}/>
      <defs>
        <linearGradient id="paint0_linear_2:1573" x1="22.1631" y1="23.3695" x2="-4.14424" y2="-0.62347" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.colors.gradIconColor1}/>
          <stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Website2;