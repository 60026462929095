import styled from "styled-components";
import { space } from "styled-system";
import { RadioProps, scales } from "./types";

const getScale = ({ scale }: RadioProps) => {
  switch (scale) {
		case scales.XS:
      return 20;
    case scales.SM:
      return 24;
    case scales.MD:
			return 28;
		case scales.LG:
			return 32;
    default:
      return 24;
  }
};

const getBackground = (state: string, checked: boolean, theme: any) => {
	if(state === "disabled") {
		if(checked) {
			return `linear-gradient(${theme.colors.gradBgRadioDisabled}, ${theme.colors.flatCheckBox}) padding-box,	${theme.colors.strokeGradRadio} border-box;`
		}
		return `linear-gradient(${theme.colors.gradBgRadioDisabled}, ${theme.colors.gradBgRadioDisabled}) padding-box,	${theme.colors.strokeGradRadio} border-box;`
	}
	if(checked) {
		if(state === "invalid") {
			return `${theme.colors.gradBtnInvert} padding-box,	${theme.colors.strokeGradError} border-box;`
		}
		return `${theme.colors.gradBtnInvert} padding-box,	${theme.colors.strokeGradRadio} border-box;`
	}
	if(state === "invalid") {
		return `linear-gradient(${theme.colors.flatCheckBox}, ${theme.colors.flatCheckBox}) padding-box,	${theme.colors.strokeGradError} border-box;`
	}
	return `linear-gradient(${theme.colors.flatCheckBox}, ${theme.colors.flatCheckBox})padding-box,	${theme.colors.strokeGradRadio} border-box;`
}

const getBorder = (state: string, theme: any) => {
	if(state === "invalid") {
		return `${theme.colors.strokeGradError}`
	}
	return `${theme.colors.strokeGradRadio}`
}


const Radio = styled.input.attrs({ type: "radio" })<RadioProps>`
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: ${getScale}px;
  width: ${getScale}px;
  vertical-align: middle;
	border: 2px solid;
	border-image-source: ${({ theme, state }) => getBorder(state, theme)};
  border-radius: 50%;
  background: ${({ theme, state }) => getBackground(state, false, theme)};
  border-image-slice: 0;

  &:checked {
    background: ${({ theme, state }) => getBackground(state, true, theme)};
  }

  &:disabled {
    cursor: default;
		background: ${({ theme, checked }) => getBackground("disabled", checked, theme)};
  }
  ${space}
`;

Radio.defaultProps = {
  scale: scales.MD,
};

export default Radio;
