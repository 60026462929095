import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ArrowUp: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const opacity = theme.isDark ? '0.8' : '0.65';
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M12.243 9.343L8 13.586L9.415 15L12.243 12.171L15.071 15L16.486 13.586L12.243 9.343Z" fill="url(#paint0_linear_1682:1037)"/>
			<defs>
			<linearGradient id="paint0_linear_1682:1037" x1="16.486" y1="8.28231" x2="7.51935" y2="18.2565" gradientUnits="userSpaceOnUse">
				<stop stopColor={theme.colors.gradIconColor1}/>
				<stop offset="1" stopColor={theme.colors.gradIconColor2} stopOpacity={opacity}/>
			</linearGradient>
			</defs>
    </Svg>
  );
};

export default ArrowUp;