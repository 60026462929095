import React from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import useWeb3 from 'hooks/useWeb3'
import { GradientButton, connectorLocalStorageKey, useMatchBreakpoints } from 'toolkitUI'
import { setupNetwork } from 'utils/wallet'
import { connectorsByName } from 'connectors'
import { scanLinks } from 'constants/index'
import { truncateAddr } from 'utils/jsonData/nftMarketplace'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import Flex from '../../components/Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import LinkExternal from '../../components/Link/LinkExternal'

interface Props {
  account: string
  providerTitle: string
  logout: () => void
  onDismiss?: () => void
  history?: any
}

const FitContent = styled.div`
  * > * {
    width: fit-content;
  }
  svg {
    width: 24px;
  }
`

const AccountModal: React.FC<Props> = ({ account, providerTitle, logout, onDismiss = () => null, history }) => {
  // const providerTitle = useProviderTitle()
  const { chainId } = useWeb3()
  const base = scanLinks[chainId] ? `${scanLinks[chainId]}/address/` : `https://etherscan.io/address/`
  const profileLink = `${base}${account}`
  const bscType = chainId === 97 || chainId === 56
  const { activate } = useWeb3React()
  const { isSm, isXs } = useMatchBreakpoints()
  const mobile = isSm || isXs
  const moreSpace = mobile && !bscType
  const bscSwith = async (network) => {
    const connector = connectorsByName.injected
    const hasSetup = await setupNetwork(network)
    if (hasSetup) {
      activate(connector)
      window.localStorage.setItem(connectorLocalStorageKey, 'injected')
      onDismiss()
    }
  }
  return (
    <Modal title="Your wallet" onDismiss={onDismiss} styles={{ minHeight: '250px', justifyContent: 'space-between' }}>
      <Text
        fontSize="20px"
        bold
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px' }}
      >
        {truncateAddr(account)}
      </Text>
      {providerTitle !== undefined ? (
        <Text
          fontSize="20px"
          bold
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px', cursor: 'pointer' }}
          onClick={() => {
            history.push('/profile')
            onDismiss()
          }}
        >
          {`Profile: ${providerTitle}`}
        </Text>
      ) : (
        history &&
        history.push && (
          <GradientButton
            onClick={() => {
              history.push('/editprofile')
              onDismiss()
            }}
            scale="sm"
          >
            Create Your Profile
          </GradientButton>
        )
      )}
      <Flex justifyContent="space-evenly" flexDirection={moreSpace ? 'column' : 'row'} mb={bscType ? '8px' : '32px'}>
        <FitContent>
          <LinkExternal small href={profileLink} mr="16px">
            {bscType ? 'View on BscScan' : 'View on Etherscan'}
          </LinkExternal>
        </FitContent>
        <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
      </Flex>

      <Flex justifyContent="space-evenly" flexDirection={moreSpace ? 'column' : 'row'}>
        {!chainId || chainId === 97 ? (
          <Button
            scale="sm"
            mb={moreSpace ? '8px' : null}
            variant="secondary"
            onClick={() => {
              bscSwith('bsc')
            }}
          >
            Switch to BSC
          </Button>
        ) : null}
        {!chainId || chainId === 56 ? (
          <Button
            scale="sm"
            mb={moreSpace ? '8px' : null}
            variant="secondary"
            onClick={() => {
              bscSwith('chapel')
            }}
          >
            Switch to BSC-Test
          </Button>
        ) : null}
        <Button
          scale="sm"
          variant="secondary"
          onClick={() => {
            logout()
            window.localStorage.removeItem(connectorLocalStorageKey)
            onDismiss()
          }}
        >
          Logout
        </Button>
      </Flex>
    </Modal>
  )
}

export default AccountModal
