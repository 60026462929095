import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Metamask: React.FC<SvgProps> = (props) => (
  <Svg width="25" height="25" viewBox="0 0 30 26" fill="none" {...props}>
    <path d="M16.8614 8.30454H13.0386L11.5098 4.50854L16.8614 8.30454Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M11.5098 4.50854H18.3902L16.8614 8.30454L11.5098 4.50854Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M13.0373 8.30392L3.33203 1L11.5085 4.50793L13.0373 8.30392Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M26.5666 1L16.8613 8.30392L18.3901 4.50793L26.5666 1Z" fill="#E2761B" stroke="#E2761B" />
    <path d="M7.3109 11.2815L2.94896 10.1994L2.70312 9.17285L7.3109 11.2815Z" fill="#763D16" stroke="#763D16" />
    <path d="M22.5879 11.2815L27.1957 9.17285L26.9498 10.1994L22.5879 11.2815Z" fill="#763D16" stroke="#763D16" />
    <path d="M3.74023 13.2008L7.31111 11.281L9.19255 11.6666L3.74023 13.2008Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.1593 13.2008L20.707 11.6666L22.5885 11.281L26.1593 13.2008Z" fill="#763D16" stroke="#763D16" />
    <path d="M3.11328 11.4315L7.31213 11.281L3.74126 13.2008L3.11328 11.4315Z" fill="#763D16" stroke="#763D16" />
    <path d="M3.11231 11.4307L2.94922 10.198L7.31116 11.2801L3.11231 11.4307Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.7867 11.4315L26.1588 13.2008L22.5879 11.281L26.7867 11.4315Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.7867 11.4307L22.5879 11.2801L26.9498 10.198L26.7867 11.4307Z" fill="#763D16" stroke="#763D16" />
    <path d="M7.47736 5.80383L7.3109 11.2818L2.70312 9.17313L7.47736 5.80383Z" fill="#763D16" stroke="#763D16" />
    <path d="M9.19394 11.6674L7.3125 11.2818L7.47896 5.80383L9.19394 11.6674Z" fill="#763D16" stroke="#763D16" />
    <path d="M27.1961 9.17313L22.5883 11.2818L22.4219 5.80383L27.1961 9.17313Z" fill="#763D16" stroke="#763D16" />
    <path d="M22.422 5.80383L22.5885 11.2818L20.707 11.6674L22.422 5.80383Z" fill="#763D16" stroke="#763D16" />
    <path d="M9.3766 22.9355L3.32182 25.0007L1.77344 18.9971L9.3766 22.9355Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M28.1247 18.9971L26.5763 25.0007L20.5215 22.9355L28.1247 18.9971Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M7.47852 5.80383L13.0385 8.30496L9.1935 11.6674L7.47852 5.80383Z" fill="#763D16" stroke="#763D16" />
    <path d="M20.7064 11.6674L16.8613 8.30496L22.4213 5.80383L20.7064 11.6674Z" fill="#763D16" stroke="#763D16" />
    <path d="M2.14062 9.55437L2.70407 9.17285L2.94991 10.1994L2.14062 9.55437Z" fill="#763D16" stroke="#763D16" />
    <path d="M27.7585 9.55437L26.9492 10.1994L27.1951 9.17285L27.7585 9.55437Z" fill="#763D16" stroke="#763D16" />
    <path d="M7.52633 13.7458L1.77344 18.996L3.74081 13.2003L7.52633 13.7458Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M22.373 13.7458L26.1586 13.2003L28.1259 18.996L22.373 13.7458Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M3.11184 11.4307L2.31055 10.6598L2.94875 10.198L3.11184 11.4307Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.7871 11.4307L26.9502 10.198L27.5884 10.6598L26.7871 11.4307Z" fill="#763D16" stroke="#763D16" />
    <path d="M6.65414 18.5005L1.77344 18.9974L7.52633 13.7472L6.65414 18.5005Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M22.373 13.7472L28.1259 18.9974L23.2452 18.5005L22.373 13.7472Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M1.77344 18.9964L6.65414 18.4995L9.3766 22.9348L1.77344 18.9964Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M20.5215 22.9348L23.2439 18.4995L28.1247 18.9964L20.5215 22.9348Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M3.73984 13.2004L2.58984 11.7825L3.11187 11.4312L3.73984 13.2004Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.1582 13.2004L26.7862 11.4312L27.3082 11.7825L26.1582 13.2004Z" fill="#763D16" stroke="#763D16" />
    <path d="M2.70473 9.17117L1.95508 4.92456L7.47897 5.80187L2.70473 9.17117Z" fill="#763D16" stroke="#763D16" />
    <path d="M22.4219 5.80187L27.9458 4.92456L27.1961 9.17117L22.4219 5.80187Z" fill="#763D16" stroke="#763D16" />
    <path d="M3.74023 13.2012L9.19255 11.667L7.52576 13.7467L3.74023 13.2012Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M26.1593 13.2012L22.3738 13.7467L20.707 11.667L26.1593 13.2012Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M9.19336 11.6667L13.0384 8.30432L13.3636 13.8463L9.19336 11.6667Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M13.3625 13.8466L7.52539 13.7467L9.19218 11.667L13.3625 13.8466Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M20.7054 11.667L22.3722 13.7467L16.5352 13.8466L20.7054 11.667Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M16.5352 13.8463L16.8604 8.30432L20.7054 11.6667L16.5352 13.8463Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M3.33203 1L13.0373 8.30392L7.47727 5.8028L3.33203 1Z" fill="#763D16" stroke="#763D16" />
    <path d="M26.5671 1L27.9458 4.92549L22.4219 5.8028L26.5671 1Z" fill="#763D16" stroke="#763D16" />
    <path d="M7.47897 5.8028L1.95508 4.92549L3.33374 1L7.47897 5.8028Z" fill="#763D16" stroke="#763D16" />
    <path d="M22.4213 5.8028L16.8613 8.30392L26.5666 1L22.4213 5.8028Z" fill="#763D16" stroke="#763D16" />
    <path d="M13.0391 8.30432H16.8619L16.5366 13.8463L13.0391 8.30432Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M16.5366 13.8463H13.3643L13.0391 8.30432L16.5366 13.8463Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M9.37676 22.9348L6.6543 18.4995L9.92305 18.4031L9.37676 22.9348Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M20.5229 22.9348L19.9766 18.4031L23.2453 18.4995L20.5229 22.9348Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M9.86011 16.0439L7.52539 13.7472L13.3625 13.847L9.86011 16.0439Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M16.5352 13.847L22.3722 13.7472L20.0375 16.0439L16.5352 13.847Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M7.52648 13.7472L9.92305 18.4041L6.6543 18.5005L7.52648 13.7472Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M23.2453 18.5005L19.9766 18.4041L22.3731 13.7472L23.2453 18.5005Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M7.52539 13.7472L9.86011 16.0439L9.92196 18.4041L7.52539 13.7472Z" fill="#E4751F" stroke="#E4751F" />
    <path d="M22.3731 13.7472L19.9766 18.4041L20.0384 16.0439L22.3731 13.7472Z" fill="#E4751F" stroke="#E4751F" />
    <path d="M10.5341 23.6822L9.37695 22.9344L12.7157 23.5752L10.5341 23.6822Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M17.1836 23.5752L20.5223 22.9344L19.3651 23.6822L17.1836 23.5752Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M12.6928 22.3705L12.7157 23.5758L9.37695 22.935L12.6928 22.3705Z" fill="#D7C1B3" stroke="#D7C1B3" />
    <path d="M17.2064 22.3705L20.5223 22.935L17.1836 23.5758L17.2064 22.3705Z" fill="#D7C1B3" stroke="#D7C1B3" />
    <path d="M12.9822 19.9629L9.37695 22.9348L9.92325 18.4031L12.9822 19.9629Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M16.918 19.9629L19.9769 18.4031L20.5232 22.9348L16.918 19.9629Z" fill="#E4761B" stroke="#E4761B" />
    <path d="M9.37695 22.934L12.9822 19.9622L12.6928 22.3695L9.37695 22.934Z" fill="#D7C1B3" stroke="#D7C1B3" />
    <path d="M20.5232 22.934L17.2073 22.3695L16.918 19.9622L20.5232 22.934Z" fill="#D7C1B3" stroke="#D7C1B3" />
    <path d="M13.3637 13.8458L11.9527 15.0731L9.86133 16.0427L13.3637 13.8458Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M20.0375 16.0427L17.9462 15.0731L16.5352 13.8458L20.0375 16.0427Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M9.92318 18.402L9.86133 16.0419L12.8125 16.8043L9.92318 18.402Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M19.9753 18.402L17.0859 16.8043L20.0371 16.0419L19.9753 18.402Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M12.8125 16.8045L9.86133 16.0421L11.9527 15.0725L12.8125 16.8045Z" fill="#233447" stroke="#233447" />
    <path d="M17.0859 16.8045L17.9458 15.0725L20.0371 16.0421L17.0859 16.8045Z" fill="#233447" stroke="#233447" />
    <path d="M11.9531 15.0731L13.3641 13.8458L12.813 16.8051L11.9531 15.0731Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M17.9462 15.0731L17.0863 16.8051L16.5352 13.8458L17.9462 15.0731Z" fill="#CD6116" stroke="#CD6116" />
    <path d="M13.4908 15.5955L13.3633 13.8458H16.5356L13.4908 15.5955Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M16.5351 13.8458L16.4076 15.5955H13.4902L16.5351 13.8458Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M12.8125 16.8051L13.3637 13.8458L13.4912 15.5955L12.8125 16.8051Z" fill="#E4751F" stroke="#E4751F" />
    <path d="M17.0869 16.8051L16.4082 15.5955L16.5357 13.8458L17.0869 16.8051Z" fill="#E2761B" stroke="#E2761B" />
    <path d="M12.7147 23.5757L12.9103 24.569L10.5332 23.6827L12.7147 23.5757Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M19.3654 23.6827L16.9883 24.569L17.1838 23.5757L19.3654 23.6827Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M12.9827 19.9629L9.92383 18.403L12.8132 16.8053L12.9827 19.9629Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M17.0875 16.8053L19.9769 18.403L16.918 19.9629L17.0875 16.8053Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M13.4912 15.5958L13.4898 19.6093L12.8125 16.8054L13.4912 15.5958Z" fill="#E4751F" stroke="#E4751F" />
    <path d="M16.4082 15.5958L17.0869 16.8054L16.4095 19.6093L16.4082 15.5958Z" fill="#E4751F" stroke="#E4751F" />
    <path d="M12.8125 16.8053L13.4898 19.6092L12.9821 19.9629L12.8125 16.8053Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M16.9179 19.9629L16.4102 19.6092L17.0875 16.8053L16.9179 19.9629Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M13.4902 15.5958H16.4076L16.4089 19.6093L13.4902 15.5958Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M16.4103 19.6093H13.4902L13.4916 15.5958L16.4103 19.6093Z" fill="#F6851B" stroke="#F6851B" />
    <path d="M12.9117 24.5691L12.7162 23.5758L12.6934 22.3705L12.9117 24.5691Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M17.2067 22.3705L17.1838 23.5758L16.9883 24.5691L17.2067 22.3705Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M12.6934 22.3704L12.9553 22.0338L12.9117 24.5689L12.6934 22.3704Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M12.9102 24.5689L12.9538 22.0338H16.944L12.9102 24.5689Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M16.944 22.0338L16.9875 24.5689H12.9102L16.944 22.0338Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M16.9889 24.5689L16.9453 22.0338L17.2073 22.3704L16.9889 24.5689Z" fill="#C0AD9E" stroke="#C0AD9E" />
    <path d="M12.9553 22.033L12.6934 22.3695L12.9827 19.9622L12.9553 22.033Z" fill="#161616" stroke="#161616" />
    <path d="M16.918 19.9622L17.2073 22.3695L16.9453 22.033L16.918 19.9622Z" fill="#161616" stroke="#161616" />
    <path d="M13.4609 19.9079L13.4887 19.6083H16.4087L13.4609 19.9079Z" fill="#161616" stroke="#161616" />
    <path d="M16.4087 19.6083L16.4364 19.9079H13.4609L16.4087 19.6083Z" fill="#161616" stroke="#161616" />
    <path d="M12.9824 19.962L13.4902 19.6083L13.4624 19.9079L12.9824 19.962Z" fill="#161616" stroke="#161616" />
    <path d="M16.4379 19.9079L16.4102 19.6083L16.9179 19.962L16.4379 19.9079Z" fill="#161616" stroke="#161616" />
    <path d="M12.9824 19.9622L13.1376 20.2749L12.9551 22.033L12.9824 19.9622Z" fill="#161616" stroke="#161616" />
    <path d="M13.4624 19.9084L13.1376 20.2752L12.9824 19.9625L13.4624 19.9084Z" fill="#161616" stroke="#161616" />
    <path d="M16.9442 22.033L16.7617 20.2749L16.9169 19.9622L16.9442 22.033Z" fill="#161616" stroke="#161616" />
    <path d="M16.9175 19.9625L16.7624 20.2752L16.4375 19.9084L16.9175 19.9625Z" fill="#161616" stroke="#161616" />
    <path d="M13.1367 20.2753H16.7619L16.9444 22.0334L13.1367 20.2753Z" fill="#161616" stroke="#161616" />
    <path d="M16.9453 22.0334H12.9551L13.1376 20.2753L16.9453 22.0334Z" fill="#161616" stroke="#161616" />
    <path d="M16.4371 19.9084L16.7619 20.2752H13.1367L16.4371 19.9084Z" fill="#161616" stroke="#161616" />
    <path d="M13.1367 20.2752L13.4616 19.9084H16.4371L13.1367 20.2752Z" fill="#161616" stroke="#161616" />
  </Svg>
)

export default Metamask