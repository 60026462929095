import { useState, useEffect } from 'react'
import { useMoralis } from 'react-moralis'
import { getWeb3NoAccount } from 'utils/web3'

const useWeb3 = () => {
  const { web3, user, isAuthenticated } = useMoralis()
  const [enabledWeb3, SetEnabledWeb3] = useState(getWeb3NoAccount())
  const [accountInfo, SetAccountInfo] = useState({
    account: undefined,
    chainId: undefined
  })

  useEffect(() => {
    if (!user && !isAuthenticated) SetEnabledWeb3(getWeb3NoAccount())
    // @ts-ignore
    else SetEnabledWeb3(web3)
  }, [web3, user, isAuthenticated])

  useEffect(() => {
    // const user1 = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_MORALIS_MAIN_APPLICATION_ID}/currentUser`));
    // const user2 = JSON.parse(localStorage.getItem(`Parse/${process.env.REACT_APP_MORALIS_APPLICATION_ID}/currentUser`));
    const account = user?.get("ethAddress")
    // let chainId
    // if (user1 === null && user2 === null) {
    //   chainId = 56
    // } else {
    const  chainId = Number(web3.givenProvider?.chainId ?? '56')
    // }
    SetAccountInfo({
      account,
      chainId 
    })
  }, [user, web3.givenProvider?.chainId])

  return {
    web3: enabledWeb3,
    chainId: accountInfo.chainId,
    account: accountInfo.account
  }
}

export default useWeb3
