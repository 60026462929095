import { useEffect } from "react";

// chunks array into chunks of maximum size
// evenly distributes items among the chunks
export default function hideMenuOnOutsideClicked(ref, hideMenu) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                hideMenu(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        };
    }, [ref, hideMenu])
}