import { useMemo } from 'react'
import { kebabCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { Toast, toastTypes  } from 'designStandard/components/molecules/Toast'
import {
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  scan as scanToast,
} from 'state/actions'

/**
 * @redesign 0.0.1v  -  call @useToast to use these actions
 * See `src/state/hooks.ts`
 */
const useZapToast = () => {

  /** Adds date at the end of id */
  const toDateId = (id: string, sep?: string | '_') => {
    return [id, Date.now().toString()].join(sep)
  }

  const dispatch = useDispatch()
  const result = useMemo(() => {
    const push = (toast: Toast) => dispatch<any>(pushToast(toast))
    const scan = (toast: Toast) => dispatch(scanToast(toast))

    /* calls the methods above to instantly display the specified toast item. */
    return {
      /**  
       * Below are predefined set of toasts for specific application states. Customizable to some degree. 
        @dynamic indicates toasts with more required params than the ones not labeled @dynamic
      */

      toastWallet: (type: 'success' | 'warning' | 'info', payload?: any, id?: string | 'zap-wallet') => {
        /* info' type but no payload, don't show toast. */
        if (type === 'info' && !payload) return null;

        return push({
          id: kebabCase(toDateId(id)), 
          type: toastTypes[type],
          title: payload || (type === 'success' ? 'Wallet connected' : 
            'No wallet connection'),
            description: 'wallet connection indicator. Generally, custom payload message should be expected with "info" toastType; otherwise, defaults to customizable messages on each type.'
          })
      },

  
      toastProcessing: (type: 'info' | 'warning', payload?: any, id?: string | 'zap-processing') => {
        return push({
          id: kebabCase(toDateId(id)), 
          type: toastTypes[type], 
          title: payload || (type === 'warning' ? 'Still processing...' : 
            'Processing...'),
            description: 'Processing state change indicator. Can be applied anywhere there exists a sequences of events with hold ups.'
          })
      },


      /**
       * If payload is provided, then the default message for the specified @toastType will be overridden. 
       * @required @param {type: 'success' | 'warning' | 'info' | 'error'} toastTypes
       * @param {string} payload - string message to display in the toast. 
       */
      toastTransaction: (type: string, payload?: string, id?: string | 'zap-tx') => {
        return push({
          id: kebabCase(toDateId(id)), 
          type: toastTypes[type], 
          title: payload || (type === 'success' ? 'Transaction Complete' : 
            type === 'warning' ? 'Something went wrong. Try again later.' : 
            type === 'error' ? 'Unable to complete transaction.' : type === 'info' && 'Invalid transaction'),
            description: 'Transaction guides.  If no payload given, displays default messages for each variant.'
          })
      },
      
      
      /**
       * @dynamic all user-related events use cases. 
       * No predefined values except id.
       * @required @param {type: string, payload: any}
       * @param {action?: ToastAction}  - ToastAction requires `text` and `url` data.
       */
      toastUser: (type: 'success' | 'error' | 'warning' | 'info', payload: string, link?: string, action?: any, id?: string | 'zap-event-user') => {
        return push({
          id: kebabCase(toDateId(id)), 
          type: toastTypes[type], 
          action,
          title: payload,
            description: 'Notification pertaining to the current user. Payload is required. ToastAction data is optionally passed into `action`. Email notification payload.'
          })
      },

      /** @dynamic */
      /** Similar to @toastUser but pertains to global marketplace activities. */
      
      toastMarket: (type: 'success' | 'error' | 'warning' | 'info', payload: string, link?: string, action?, id?: string | 'zap-event-market') => {
        return push({
          id: kebabCase(toDateId(id)), 
          type: toastTypes[type], 
          action,
          title: payload,
            description: 'Notifications pertaining to all marketplace activities. Payload is required, and any thumbnail contents URL or redirect link can be passed into action(?). Toast payload can also be used as email notification contents'
          })
      },
      toastButton: (payload?: Toast) => {
          return scan(payload)
        },
      push,
      scan,
      remove: (id: string) => dispatch<any>(removeToast(id)),
      clear: () => dispatch<any>(clearToast()),
    }
  }, [dispatch])

    return result
}

export default useZapToast