import React from 'react'

const TabIndexContext = React.createContext(null)

const useTabIndex = () => {
    const [index, setIndex] = React.useContext(TabIndexContext);

    const handleTabIndex = (value) => {
        setIndex(value);
    };

    return { tabIndex: index, onTabIndexChange: handleTabIndex };
};

const TabIndexContextProvider = ({ children }) => {
    const [index, setIndex] = React.useState(0);
    return (
        <TabIndexContext.Provider value={[index, setIndex]}>
        {children}
        </TabIndexContext.Provider>
    )
}

export { TabIndexContextProvider, useTabIndex }
