/* eslint-disable @typescript-eslint/no-unused-vars */
import useWindowSize from 'hooks/useWindowSize'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import TokenIcon from "./TokenIcon";
import Zap from './TokenIcons/Zap';

export interface TokenPairIconProps {
    tokenLeft: string;
    tokenRight: string;
  width: number;
  scale?: string;
}

interface Size {
  width: number | undefined
  height: number | undefined
}

// const Icons = (IconModule as any) as { [key: string]: React.FC<SvgProps> }

const IconWrapper = styled.div<{scale?: string}>`
    width: ${(props) => props.scale === "sm" ? '35px' : '45px'};
    height: ${(props) => props.scale === "sm" ? '28px' : '45px'};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.mediaQueries.xs} {
      margin-right: ${(props) => props.scale === "sm" ? '7px' : '10px'};
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      margin-right: ${(props) => props.scale === "sm" ? '7px' : '15px'};
    }
    ${({ theme }) => theme.mediaQueries.md} {
      margin-right: ${(props) => props.scale === "sm" ? '7px' : '20px'};
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      margin-right: ${(props) => props.scale === "sm" ? '7px' : '25px'};
    }
    ${({ theme }) => theme.mediaQueries.xl} {
      margin-right: ${(props) => props.scale === "sm" ? '7px' : '25px'};
    }
    > div > svg {
      margin-left: 0px;
    }
`

const IconContainer = styled.div<{isLeft?: boolean, scale: string}>`
  position: absolute;
  bottom:  ${(props) => props.isLeft ? '16%' : props.scale === 'sm' ? '16%' : '15%'};
  left: ${(props) => props.isLeft ? props.scale === 'sm' ? '10%' : '0' : props.scale === 'sm' ? '45%' : '35%'};
  top: ${(props) => props.scale === 'sm' ?  null : '25%'};
  z-index: ${(props) => props.isLeft ? "5" : "2"};

  ${({ theme }) => theme.mediaQueries.lg} {
    right: ${(props) => props.isLeft ? props.scale === 'sm' ? '100%' : '80%' : '35%'};
    left: ${(props) => props.isLeft ? null : '50%'};
    top: ${(props) => props.scale === 'sm' ? null : '15%'};
  }
`

/**
 * Pancakeswap version
 */
const CurrencyIcon = styled.div<{width: number, height: number, src: string}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url(${props.src})`};
  border-radius: 50%;
`

const XCDIcon = styled.div<{width: number, height: number, scale: string}>`
  text-align: center;
  line-height: ${(props) => props.width}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: lightgrey;
  border-radius: 50%;
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: ${(props) => props.scale === "sm" ? '7px' : '10px'};
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: ${(props) => props.scale === "sm" ? '7px' : '12px'};
  }
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: ${(props) => props.scale === "sm" ? '7px' : '12px'};
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: ${(props) => props.scale === "sm" ? '7px' : '12px'};
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: ${(props) => props.scale === "sm" ? '7px' : '15px'};
  }
`

const Icon: React.FC<TokenPairIconProps> = ({ tokenLeft, tokenRight, width, scale }) => {
  const size: Size = useWindowSize()
  const [flag, setFlag] = useState(false);
  const smSize = scale === 'sm' ? 18 : 22;
  const mdSize = scale === 'sm' ? 18 : 26;
  const lgSize = scale === 'sm' ? 18 : 38;
  const sizeLeft = size.width < 400 ? smSize : size.width < 968 ? mdSize : lgSize;
  const sizeRight =  scale === 'sm' ? sizeLeft : sizeLeft - 1.0;
  const imgURL = `https://wise.com/public-resources/assets/flags/rectangle/${tokenLeft.toLowerCase()}.png`;
    
  const handleOnCompleted = useCallback(
    (iconName) => setFlag(true),
    []
  );

  const handleIconError = useCallback((err) => setFlag(false), []);
  
  const iconElementLeft = (
    <IconContainer isLeft scale={scale}>
      { flag ?
        (tokenLeft.toLowerCase() === 'zap' ?
        <Zap width={`${sizeLeft}px`} height={`${sizeLeft}px`}/>
        :
        <TokenIcon name={tokenLeft.toLowerCase()} onCompleted={handleOnCompleted} onError={handleIconError} width={`${sizeLeft}px`} height={`${sizeLeft}px`} viewBox='0 0 32 32'/>
        ):
        (
          tokenLeft.toLowerCase() === 'xcd' ?
          <XCDIcon width={sizeLeft} height={sizeLeft} scale={scale}>XCD</XCDIcon>
          :
          <CurrencyIcon width={sizeLeft} height={sizeLeft} src={imgURL}/>
        )
      }
    </IconContainer>
  )
  const iconElementRight = (
      <IconContainer scale={scale}>
        <TokenIcon name={tokenRight.toLowerCase()} onCompleted={handleOnCompleted} onError={handleIconError} width={`${sizeRight}px`} height={`${sizeRight}px`} viewBox='0 0 32 32'/>
      </IconContainer>
  )

  /**
   * Pancakeswap version
   */
  return (
    <div>
      <IconWrapper scale={scale}>
             {iconElementLeft}{iconElementRight}
        </IconWrapper>
    </div>
  )
}

export default Icon