import { createReducer } from '@reduxjs/toolkit'
import { getMiningEvents, clear } from './actions'

export interface ZapMiningEvents {
   MiningEventsList: { [key: string]: any}
   CurrentMiningEventsList: { [key: string]: any}
}

const initialState: ZapMiningEvents = {
  MiningEventsList: {},
  CurrentMiningEventsList: {}
}

export default createReducer<ZapMiningEvents>(initialState, (builder) =>
  builder
    .addCase(getMiningEvents, (state, { payload: { miningEvents, currentMiningEvents } }) => {
      const toSet: { [key: string]: any } = {}
      const toSetCurrent: { [key: string]: any } = {}
      miningEvents.forEach((e) => {
        toSet[e.id] = e
      })
      currentMiningEvents.forEach((e) => {
        toSetCurrent[e.id] = e
      })
      return {
        ...state,
        MiningEventsList: toSet,
        CurrentMiningEventsList: toSetCurrent,
      }
    })
    .addCase(clear, (state) => {
      return {
        ...state,
        MiningEventsList: {},
        CurrentMiningEventsList: {},
      }
    }),
)
