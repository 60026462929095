import React from 'react'
import styled, { keyframes } from 'styled-components'
import { TopCollectionProps } from './types'
import { Text } from '../../atoms/Text'


const placeHolderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`

const CollectionContainer = styled.div<{ size: number; isLoading: boolean }>`
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: ${({ isLoading }) => (isLoading ? 'space-between' : 'flex-start')};
  align-items: center;
  width: ${({ size }) => `${size}px`};
  height: 52px;
  padding: 10px 8.53px;
  background: ${({ theme, isLoading }) => (isLoading ? theme.colors.skeletonCard : theme.colors.flatGlobal)};
  box-shadow: ${({ theme }) => theme.shadows.popSoft};
  border-radius: 16px;
`

const NumberWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: ${({ theme }) => `0.5px solid ${theme.colors.strokeFlatNorm}`};
  border-radius: 5.33333px;
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 48px;
`

const TextWrapper = styled.div`
  height: 31px;
  display: grid;
  justify-content: center;
  align-items: center;
`

const SkeletonNumber = styled(NumberWrapper)`
  margin-right: 0;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  border: none;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const SkeletonAvatar = styled.div`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  border-radius: 50%;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const SkeletonRect = styled.div`
  width: 120px;
  height: 24px;
  background: ${({ theme }) => theme.colors.skeletonPrimary};
  border-radius: 100px;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeHolderShimmer};
  -webkit-animation-timing-function: linear;
  background-image: linear-gradient(to right, ${({ theme }) => theme.colors.skeletonPrimary} 0%, ${({ theme }) => theme.colors.skeletonSecondary} 50%, ${({ theme }) => theme.colors.skeletonPrimary} 100%);
  background-repeat: no-repeat;
`

const Title = styled(Text)`
  max-width: 126px;
  max-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  // line-break: anywhere;
`

const TopCollection: React.FC<TopCollectionProps> = ({
  isLoading,
  size = 214,
  ranking,
  avatar,
  collectionName,
  count,
  onSelect,
  ...props
}) => (
  <CollectionContainer size={size} isLoading={isLoading} {...props} onClick={onSelect}>
    {isLoading ? (
      <>
        <SkeletonNumber />
        <SkeletonAvatar />
        <SkeletonRect />
      </>
    ) : (
      <>
        <NumberWrapper>
          <Text font="collectionRanking" color="textSecondary">
            {ranking}
          </Text>
        </NumberWrapper>
        <Avatar src={avatar} alt="Avatar" />
        <TextWrapper>
          <Title font="collectionTitle">
            {collectionName.length > 15 ? `${collectionName.slice(0, 15)}...` : collectionName}
          </Title>
          <Text font="collectionCount" color="primaryBlue">
            {count}
          </Text>
        </TextWrapper>
      </>
    )}
  </CollectionContainer>
)

export default TopCollection
