/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useDynamicSVGImport, UseDynamicSVGImportOptions, useMatchBreakpoints } from 'toolkitUI/hooks'
import Zap from './TokenIcons/Zap'

export interface TokenIconProps {
  name: string
  onCompleted?: UseDynamicSVGImportOptions['onCompleted']
  onError?: UseDynamicSVGImportOptions['onError']
}

const Icon: React.FC<TokenIconProps | any> = ({ name, onCompleted, onError, ...rest }) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, {
    onCompleted,
    onError,
  })
  const { isXs, isSm } = useMatchBreakpoints()
  const mobile = isXs || isSm
  if (error) {
    return <></>
  }
  if (loading) {
    return <div>...</div>
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />
  }
  return <Zap />
}

export default Icon
