import React from 'react'
import styled from 'styled-components'
import { useDynamicSVGImport, UseDynamicSVGImportOptions } from 'designStandard/hooks'
import ZapTokenIcon from './ZapToken'

const DefaultIcon = styled.div<{ isRight?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ isRight }) => !isRight ? '#722FFF' : '#70DC2B'};
`

export interface TokenIconProps {
  name: string
  onCompleted?: UseDynamicSVGImportOptions['onCompleted']
  onError?: UseDynamicSVGImportOptions['onError']
  isRight?: boolean
}

const Icon: React.FC<TokenIconProps | any> = ({ name, onCompleted, onError, isRight, ...rest }) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, {
    onCompleted,
    onError,
  })

  if (error) {
    return <></>
  }
  if (loading) {
    return <DefaultIcon isRight={isRight} />
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />
  }
  return <ZapTokenIcon />
}

export default Icon
