import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  const { color } = props;
  const theme = useTheme();

  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M3.17 7.44 12 12.55l8.77-5.08M12 21.61v-9.07" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.61 12.83V9.17c0-1.38-.99-3.06-2.2-3.73l-5.34-2.96c-1.14-.64-3-.64-4.14 0L4.59 5.44c-1.21.67-2.2 2.35-2.2 3.73v5.66c0 1.38.99 3.06 2.2 3.73l5.34 2.96c.57.32 1.32.48 2.07.48.75 0 1.5-.16 2.07-.48" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.2 21.4a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4ZM23 22l-1-1" fill="transparent" stroke={color ?? theme.colors.gradIconColor2} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;