import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

interface HeartProps extends SvgProps {
  isColor?: boolean
}

const HeartFill: React.FC<HeartProps> = (props) => {
  // const { isColor } = props
  const theme = useTheme();
  const color1 = theme.colors.heartFill;
  const color2 = theme.colors.heartStroke;

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M13.6998 2.58521C12.1915 2.58521 10.8415 3.31854 9.99984 4.44354C9.56995 3.86755 9.01172 3.3997 8.36945 3.07712C7.72718 2.75454 7.01856 2.58611 6.29984 2.58521C3.7415 2.58521 1.6665 4.66854 1.6665 7.24354C1.6665 8.23521 1.82484 9.15187 2.09984 10.0019C3.4165 14.1685 7.47484 16.6602 9.48317 17.3435C9.7665 17.4435 10.2332 17.4435 10.5165 17.3435C12.5248 16.6602 16.5832 14.1685 17.8998 10.0019C18.1748 9.15187 18.3332 8.23521 18.3332 7.24354C18.3332 4.66854 16.2582 2.58521 13.6998 2.58521V2.58521Z" fill="url(#paint0_linear_4405_32226)"/>
      <defs>
      <linearGradient id="paint0_linear_4405_32226" x1="9.99984" y1="2.58521" x2="9.99984" y2="17.4185" gradientUnits="userSpaceOnUse">
          <stop stopColor={color1}/>
          <stop offset="1" stopColor={color2}/>
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default HeartFill;